export const Styles = ({ colors }) => ({
  main: {
    "& .containers": {
      display: "block",
      position: "relative",
      cursor: "pointer",
      fontSize: "22px",
      paddingLeft: "35px",
      marginBottom: "12px",
      "-webkit-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      "user-select": "none",
    },
    "& .containers input": {
      position: "absolute",
      opacity: "0",
      cursor: "pointer",
      height: "0",
      width: "0",
    },

    "& .checkmark": {
      position: "absolute",
      top: 0,
      left: 0,
      height: 20,
      width: 20,
      backgroundColor: colors.hexaWhite,
      border: "1px solid",
      borderRadius: 4,
      "&:after": {
        content: '""',
        position: "absolute",
        display: "none",
      },
    },

    "& .containers input:checked ~ .checkmark": {
      backgroundColor: colors.black,
    },

    "& .containers input:checked ~ .checkmark:after": {
      display: "block",
    },
    "& .containers .checkmark::after": {
      left: "7px",
      top: "2px",
      width: "4px",
      height: "9px",
      border: `solid ${colors.hexaWhite}`,
      borderWidth: "0 3px 3px 0",
      "-webkit-transform": "rotate(45deg)",
      "-ms-transform": "rotate(45deg)",
      transform: "rotate(45deg)",
    },
  },
});
