import propTypes from "prop-types";
import { Typography } from "v2/components";
import styles from "./SponsorContainer.module.css";

const SponsorContainer = (props) => {
  return (
    <div className={styles.container}>
      <Typography as="documentLabel">Sponsored By:</Typography>
      <img src={props.image} alt={props.name} />
    </div>
  );
};

SponsorContainer.propTypes = {
  image: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
};

export default SponsorContainer;
