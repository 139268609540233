import {
  submitJobDetailsURL,
  submitSponsorSuggestionURL,
} from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { POST } from "utils";
import mixpanel from "utils/mixPanel";

type JobDetails = {
  firstName: string;
  lastName: string;
  email: string;
  number: string;
  propertyUuid: number;
  details: string;
  serviceName: string;
  partnerName: string;
};
type SponsorSuggestions = {
  firstName: string;
  lastName: string;
  email: string;
  number: string;
  propertyId: number;
  postcode: string;
  details: string;
  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
};

type AirTableActions = {
  submitJobDetails: APIAction<JobDetails, boolean>;
  submitSponsorSuggestions: APIAction<void, boolean>;
};

export default function useAirtableActions(): AirTableActions {
  const submitJobDetailsRequest = async (onSubmitJobDetails: JobDetails) => {
    if (onSubmitJobDetails) {
      mixpanel.track("Get a quote on upgrades", {
        ...onSubmitJobDetails,
        propertyId: onSubmitJobDetails.propertyUuid,
      });
      return POST(submitJobDetailsURL(), {
        firstName: onSubmitJobDetails.firstName,
        lastName: onSubmitJobDetails.lastName,
        mobileNumber: onSubmitJobDetails.number,
        description: onSubmitJobDetails.details,
        email: onSubmitJobDetails.email,
        propertyUuid: onSubmitJobDetails.propertyUuid,
        serviceName: onSubmitJobDetails.serviceName,
        partnerName: onSubmitJobDetails.partnerName,
      });
    }
  };

  const submitSponsorSuggestionsRequest = async (
    onSubmitSponsorSuggestionsRequest: SponsorSuggestions
  ) => {
    if (onSubmitSponsorSuggestionsRequest) {
      mixpanel.track("Suggest a sponsor", {
        ...onSubmitSponsorSuggestionsRequest,
        email: onSubmitSponsorSuggestionsRequest.email,
        fistName: onSubmitSponsorSuggestionsRequest.firstName,
        lastName: onSubmitSponsorSuggestionsRequest.lastName,
        number: onSubmitSponsorSuggestionsRequest.number,
        propertyId: onSubmitSponsorSuggestionsRequest.propertyId,
        district: onSubmitSponsorSuggestionsRequest.postcode.split(" ")[0],
      });
      return POST(submitSponsorSuggestionURL(), {
        firstName: onSubmitSponsorSuggestionsRequest.firstName,
        lastName: onSubmitSponsorSuggestionsRequest.lastName,
        mobileNumber: onSubmitSponsorSuggestionsRequest.number,
        email: onSubmitSponsorSuggestionsRequest.email,
        propertyId: onSubmitSponsorSuggestionsRequest.propertyId,
        suggestedBy: `${onSubmitSponsorSuggestionsRequest.user.firstName} ${onSubmitSponsorSuggestionsRequest.user.lastName}`,
        suggestedByEmail: onSubmitSponsorSuggestionsRequest.user.email,
      });
    }
  };

  const submitJobDetailsPromise = useAsync<
    APIActionResponse<JobDetails>,
    APIActionError
  >(submitJobDetailsRequest, false);

  const submitSponsorSuggestionsPromise = useAsync<
    APIActionResponse<void>,
    APIActionError
  >(submitSponsorSuggestionsRequest, false);

  return {
    submitJobDetails: {
      trigger: submitJobDetailsPromise.execute,
      state: submitJobDetailsPromise.status === "pending" ? "loading" : "idle",
      error: submitJobDetailsPromise.error?.errorDetails,
      result: submitJobDetailsPromise.status === "success",
    },
    submitSponsorSuggestions: {
      trigger: submitSponsorSuggestionsPromise.execute,
      state:
        submitSponsorSuggestionsPromise.status === "pending"
          ? "loading"
          : "idle",
      error: submitSponsorSuggestionsPromise.error?.errorDetails,
      result: submitSponsorSuggestionsPromise.status === "success",
    },
  };
}
