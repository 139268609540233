import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFDisplay(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  if (props.url) {
    return (
      <Document file={{ url: props.url }} onLoadSuccess={onDocumentLoadSuccess}>
        <Page key={`page_1`} pageNumber={1} />
        {/* {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))} */}
      </Document>
    );
  }
  return null;
}
