export const Styles = ({ colors, fonts, breakpoints }) => ({
  main: {
    background: colors.hexWhite,
    margin: "0 24px",
    fontFamily: fonts.primary,
    fontStyle: "normal",
    padding: 0,
    "& svg": {
      cursor: "pointer",
    },
    "& .cross-icon": {
      display: "flex",
      justifyContent: "flex-end",
      margin: "25px  0 0 0",
    },
  },
  compliant: {
    alignItems: "center",
    [breakpoints.RESPONSIVE]: {
      padding: 0,
      "& .contents": {
        height: "100vh",
        width: "100%",
      },
    },
  },
  logo: {
    display: "flex",
    marginTop: "24px",
    justifyContent: "flex-end",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    "& .icon-check": {
      display: "flex",
      textAlign: "center",
      margin: "67px 0 29px 0",
      "& svg": {
        width: "53px",
        height: "53px",
      },
    },
    "& .sub-heading": {
      display: "flex",
      textAlign: "center",
      width: "auto",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .text-heading": {
      width: "auto",
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      margin: "16px 0",
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "32px",
      alignItems: "center",
    },
    "& .button": {
      "& button": {
        width: "109px",
        height: "40px",
        color: colors.black,
        border: `1px solid ${colors.black}`,
      },
    },
  },
});
