export const styles = ({ colors, fonts, breakpoints }) => ({
  houseHold: {
    "& svg": {
      cursor: "pointer",
    },
    [breakpoints.MOBILE_DEVICE]: {
      padding: "0 !important",
      "& .contents": {
        width: "100%",
        margin: "0 !important",
        height: "100vh !important",
        top: "0",
      },
    },
  },
  text: {
    display: "flex",
    marginTop: "24px",
    "& .pop-up-heading": {
      color: colors.primary,
    },
    "& p": {
      width: 552,
      height: 16,
      [breakpoints.MOBILE_DEVICE]: {
        height: "39px",
      },
    },
    "& img": {
      cursor: "pointer",
      margin: "0 0 13px 16px",
      "&:focus": {
        outline: "none",
      },
    },
  },
  main: {
    background: colors.hexWhite,
    margin: "0 24px",
    fontFamily: fonts.primary,
    fontStyle: "normal",
    "& .number-view> div": {
      paddingTop: "20px",
    },
    "& span": {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.darkGrey,
    },
  },
  section: {
    margin: "24px 0",
    "& .list-view": {
      paddingTop: "8px",
    },
  },
  bottomSection: {
    "& .number-view> div": {
      padding: "4px 0",
    },
  },
});
