export const styles = ({ colors, fonts, breakpoints }) => ({
  main: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& #mapIcon": {
      [breakpoints.RESPONSIVE]: {
        display: "none",
      },
    },
    "& .icon": {
      height: "72px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      outline: "none",
      [breakpoints.RESPONSIVE]: {
        height: "40px",
      },
    },
    "& .icon.icon-background": {
      background: colors.azure,
      pointerEvents: "none",
      "& rect": {
        fill: colors.azure,
      },
    },
    "& svg": {
      cursor: "pointer",
      minWidth: "48px",
    },
    "& #iconWrapper": {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      [breakpoints.RESPONSIVE]: {
        display: "flex",
        justifyContent: "space-between",
        minWidth: "50vw",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "1rem 1rem 0 0",
      },
    },
    "& #iconWrapperVertical": {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "1rem 1rem 0 0",
    },
    "& #menuTitle": {
      display: "none",
      [breakpoints.RESPONSIVE]: {
        display: "flex",
        padding: "0.5rem 0",
      },
    },
    "& #menuText": {
      display: "flex",
    },
    [breakpoints.RESPONSIVE]: {
      bottom: 0,
      height: "10rem",
      width: "100%",
      left: 0,
    },
  },
  dropDown: {
    position: "absolute",
    width: "304px",
    minHeight: "220px",
    height: "auto",
    right: 0,
    top: 73,
    background: colors.hexaWhite,
    boxShadow: `-2px 2px 6px ${colors.grapeRGB}`,
    "& .cross-icon": {
      display: "none",
    },
    [breakpoints.RESPONSIVE]: {
      width: "100%",
      minHeight: "100vh",
      top: 0,
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      "& .cross-icon": {
        height: "60px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: 25,
        "& svg": {
          cursor: "pointer",
        },
      },
    },
    "& .list": {
      padding: 16,
      fontFamily: fonts.primary,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      cursor: "pointer",
      outline: "none",
    },
    "& .list.contact-ynuo": {
      color: colors.primary,
    },
    "& .list.user-info": {
      background: colors.azure,
      display: "flex",
      flexDirection: "column",
      "& .user-name": {
        display: "flex",
        justifyContent: "space-between",
        letterSpacing: "0.002em",
        color: colors.grey,
        [breakpoints.RESPONSIVE]: {
          marginTop: "5rem",
        },
      },
      "& .user-email": {
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: colors.grey,
      },
    },
  },
});
