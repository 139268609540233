import useModal from "Hooks/useModal";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import propTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { mixPanel } from "utils";
import {
  Input,
  RadioGroup,
  Row,
  Row2Items,
  SelectInput,
} from "v2/components/Form";
import { optionsType } from "v2/components/Form/SelectInput/SelectInputTypes";
import Links from "v2/components/Link/Link";
import Markdown from "v2/components/Markdown/Markdown";
import ModalV2 from "v2/components/ModalV2/Modal";
import Typography from "v2/components/Typography/Typography";
import * as yup from "yup";
import Button from "../../Form/Button/Button";
import { Form } from "../../Form/Form";
import styles from "./PropertyForm.module.css";
import { propertyFormTooltipData } from "./propertyFormConstants";

const Link = (props) => (
  <span>
    {props.text}
    <Links as="_blank" path={props.to}>
      {props.clickableText}
    </Links>
  </span>
);

const PropertyForm = (props) => {
  const [
    isTooltipModalOpen,
    openToolTipModal,
    closeToolTipModal,
    tooltipModalData,
  ] = useModal();

  let isReadOnly = false; //props.isReadOnly;
  if (!isReadOnly)
    isReadOnly = props.initialValues.CRMUniqueIdentifier ? true : false;

  const formik = props.formik;

  const onSaveCallback = () => {
    props.onSave(formik.values);
    mixPanel.track("Submit property info");
  };

  const selectedPropertyOption = useMemo(
    () =>
      props.propertyTypes.find(
        ({ value }) => value === formik.values.propertyType
      ),
    [formik.values.propertyType]
  );

  const selectedPropertyTenancyTypeOption = useMemo(
    () =>
      props.tenancyTypeOptions.find(
        ({ value }) => value === formik.values.tenancyType
      ),
    [formik.values.tenancyTypeOptions]
  );
  const selectedUsageIntentionOption = useMemo(
    () =>
      props.usageIntentionOptions.find(
        ({ value }) => value === formik.values.usageIntention
      ),
    [formik.values.usageIntention]
  );

  useWhyDidYouUpdate("Property Form", props);
  return (
    <>
      {isTooltipModalOpen && (
        <ModalV2 onClose={closeToolTipModal}>
          <div className={styles.crossIcon}>
            <CrossIcon onClick={closeToolTipModal} />
          </div>
          <div className={styles.modal}>
            <Markdown
              information={propertyFormTooltipData[tooltipModalData.field]}
              components={{ Link }}
            />
          </div>
        </ModalV2>
      )}
      <div className={styles.main}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          {isReadOnly ? (
            <>
              <Typography as="h2">
                Please use your CRM to edit your information.
              </Typography>
              <Typography as="PropertyBox">
                As this property was created via a CRM we have locked the
                Property Input Form to stop any mismatch of data.
              </Typography>
            </>
          ) : (
            <div>
              <Typography as="h2">
                Please fill in your property information so that we can:
              </Typography>
              <Typography as="PropertyBox">
                <ul>
                  <li>Build your investor report</li>
                  <li>Build your Journey</li>
                  <li>Monitor your property for fines</li>
                </ul>
              </Typography>
            </div>
          )}

          <Row2Items>
            <SelectInput
              label="Property Type"
              id="propertyType"
              name="propertyType"
              inputRef={props.formRef}
              placeholder="Enter the property type"
              readOnly={isReadOnly}
              options={props.propertyTypes}
              defaultValue={selectedPropertyOption}
              onInputTip={() => openToolTipModal({ field: "propertyType" })}
              onOptionChange={({ value }) =>
                formik.setFieldValue("propertyType", value)
              }
            />
            <Input
              label={
                formik.values.propertyType === "Flat" ||
                formik.values.propertyType === "House"
                  ? "How many floors are in the property"
                  : formik.values.propertyType === "Building"
                  ? "How many floors are in the building"
                  : "Number of Storeys"
              }
              type="number"
              min={0}
              id="numberOfStoreys"
              name="numberOfStoreys"
              placeholder=" Enter number of storeys"
              readOnly={isReadOnly}
              value={formik.values.numberOfStoreys}
              onInputTip={() => openToolTipModal({ field: "numberOfStoreys" })}
              onChange={formik.handleChange}
              error={formik.errors.numberOfStoreys}
            />
          </Row2Items>
          <Row2Items>
            <Input
              label="Bedrooms/Units"
              type="number"
              min={0}
              name="roomsOrUnits"
              id="roomsOrUnits"
              readOnly={isReadOnly}
              value={formik.values.roomsOrUnits}
              error={formik.errors.roomsOrUnits}
              placeholder="Enter number of bedrooms"
              onInputTip={() => openToolTipModal({ field: "roomsOrUnits" })}
              onChange={formik.handleChange}
            />
            <SelectInput
              label="What is your intention for this property?"
              id="usageIntention"
              name="usageIntention"
              placeholder="Select one option from the list"
              readOnly={isReadOnly}
              options={props.usageIntentionOptions}
              defaultValue={
                selectedUsageIntentionOption
                  ? selectedUsageIntentionOption
                  : props.usageIntentionOptions[1]
              }
              onOptionChange={({ value }) =>
                formik.setFieldValue("usageIntention", value)
              }
            />
          </Row2Items>
          {!!formik.values.propertyType && (
            <>
              {formik.values.propertyType !== "House" && (
                <>
                  <Row>
                    <RadioGroup
                      name="commercialPremises"
                      id="commercialPremises"
                      label="Is there a commercial premises in the property?"
                      readOnly={isReadOnly}
                      options={props.commercialPremissesOptions}
                      selected={formik.values.commercialPremises}
                      onInputTip={() =>
                        openToolTipModal({ field: "commercialPremises" })
                      }
                      onChooseOption={(value) =>
                        formik.setFieldValue("commercialPremises", value)
                      }
                    />
                  </Row>
                  <Row>
                    <RadioGroup
                      name="buildingConstructionType"
                      id="buildingConstructionType"
                      label="Is your building Converted or Purpose built?"
                      readOnly={isReadOnly}
                      options={props.buildingConstructionTypeOptions}
                      selected={formik.values.buildingConstructionType}
                      onInputTip={() =>
                        openToolTipModal({ field: "buildingConstructionType" })
                      }
                      onChooseOption={(value) =>
                        formik.setFieldValue("buildingConstructionType", value)
                      }
                    />
                  </Row>
                </>
              )}
              {formik.values.propertyType !== "Building" && (
                <Row>
                  <RadioGroup
                    label="Do you already know who will be living in the property?"
                    id="knowWhoLiveInProperty"
                    name="knowWhoLiveInProperty"
                    readOnly={isReadOnly}
                    // disabled={hasTenancy}
                    options={props.knowsWhoLiveInThePropertyOptions}
                    selected={formik.values.knowWhoLiveInProperty}
                    onChooseOption={(optionSelected) =>
                      formik.setFieldValue(
                        "knowWhoLiveInProperty",
                        optionSelected
                      )
                    }
                  />
                </Row>
              )}
              {formik.values.knowWhoLiveInProperty === "Yes" &&
                formik.values.propertyType !== "Building" && (
                  <>
                    <Row>
                      <SelectInput
                        label="Tenancy type"
                        name="tenancyType"
                        id="tenancyType"
                        readOnly={isReadOnly}
                        // disabled={hasTenancy}
                        options={props.tenancyTypeOptions}
                        defaultValue={
                          selectedPropertyTenancyTypeOption
                            ? selectedPropertyTenancyTypeOption
                            : props.tenancyTypeOptions[2]
                        }
                        error={formik.errors.tenancyType}
                        onInputTip={() =>
                          openToolTipModal({ field: "tenancyType" })
                        }
                        onOptionChange={({ value }) =>
                          formik.setFieldValue("tenancyType", value)
                        }
                      />
                    </Row>
                    <Row2Items>
                      <Input
                        label="Number of tenants"
                        type="number"
                        min={0}
                        name="numberOfTenants"
                        id="numberOfTenants"
                        readOnly={isReadOnly}
                        // disabled={hasTenancy}
                        value={formik.values.numberOfTenants}
                        error={formik.errors.numberOfTenants}
                        placeholder="Enter number of tenants"
                        onInputTip={() =>
                          openToolTipModal({ field: "numberOfTenants" })
                        }
                        onChange={formik.handleChange}
                      />
                      <Input
                        label="Number of households"
                        type="number"
                        min={0}
                        name="numberOfHouseholds"
                        id="numberOfHouseholds"
                        readOnly={isReadOnly}
                        // disabled={hasTenancy}
                        value={formik.values.numberOfHouseholds}
                        error={formik.errors.numberOfHouseholds}
                        placeholder="Enter number of households"
                        onInputTip={() =>
                          openToolTipModal({ field: "numberOfHouseholds" })
                        }
                        onChange={formik.handleChange}
                      />
                    </Row2Items>
                    <Row2Items>
                      <Input
                        label="Tenancy start date"
                        type="date"
                        name="tenancyStartDate"
                        id="tenancyStartDate"
                        readOnly={isReadOnly}
                        // disabled={hasTenancy}
                        value={formik.values.tenancyStartDate}
                        error={formik.errors.tenancyStartDate}
                        onChange={formik.handleChange}
                      />
                      <Input
                        label="Tenancy end date"
                        type="date"
                        name="tenancyEndDate"
                        id="tenancyEndDate"
                        readOnly={isReadOnly}
                        // disabled={hasTenancy}
                        value={formik.values.tenancyEndDate}
                        error={formik.errors.tenancyEndDate}
                        onChange={formik.handleChange}
                      />
                    </Row2Items>
                  </>
                )}
            </>
          )}
          <Row>
            <div className={styles.buttonSubmit}>
              {formik.values.knowWhoLiveInProperty === "No" && (
                <>
                  <Button
                    id="Stonly_PropertyFormSave"
                    disabled={
                      !formik.values.propertyType ||
                      !formik.values.usageIntention
                    }
                    type="button"
                    actionName="Save"
                    onClick={onSaveCallback}
                  />
                  <Button
                    id="Stonly_PropertyFormSubmit"
                    disabled={
                      !formik.values.propertyType ||
                      !formik.values.usageIntention
                    }
                    type="submit"
                    actionName="Start your full journey ➔"
                  />
                </>
              )}
              {formik.values.knowWhoLiveInProperty === "Yes" && (
                <>
                  <Button
                    id="Stonly_PropertyFormSave"
                    disabled={
                      !formik.values.propertyType ||
                      !formik.values.usageIntention
                    }
                    type="button"
                    actionName="Save"
                    onClick={onSaveCallback}
                  />
                  <Button
                    id="Stonly_PropertyFormSubmit"
                    disabled={
                      !formik.values.roomsOrUnits ||
                      !formik.values.numberOfHouseholds ||
                      !formik.values.roomsOrUnits
                    }
                    type="submit"
                    actionName="Start your full journey ➔"
                  />
                </>
              )}
              {/* {isReadOnly && (
                <Button actionName="Next" onClick={props.onNext} />
              )} */}
            </div>
          </Row>
        </Form>
      </div>
    </>
  );
};
PropertyForm.propType = {
  onSubmit: propTypes.func,
  onSave: propTypes.func,
  propertyTypes: optionsType,
  usageIntentionOptions: optionsType,
  propertyCategoryOptions: optionsType,
  tenancyTypeOptions: optionsType,
  knowsWhoLiveInThePropertyOptions: propTypes.arrayOf(propTypes.string),
  buildingConstructionTypeOptions: propTypes.arrayOf(propTypes.string),
  commercialPremissesOptions: propTypes.arrayOf(propTypes.string),
  isReadOnly: propTypes.bool,
  initialValues: propTypes.shape({
    propertyType: propTypes.string,
    numberOfStoreys: propTypes.number,
    numberOfHouseholds: propTypes.number,
    propertyCategory: propTypes.string,
    tenancyType: propTypes.string,
    roomsOrUnits: propTypes.number,
  }),
};

PropertyForm.defaultProps = {
  isReadOnly: false,
  onSubmit: (values, actions) => {},
  onSave: (values, actions) => {},
  usageIntentionOptions: [
    { value: "liveInLandlord", label: "Live in landlord" },
    { value: "investmentProperty", label: "Investment property" },
    { value: "prospecting", label: "Prospecting" },
  ],
  commercialPremissesOptions: ["Yes", "No", "Unknown"],
  propertyCategoryOptions: [
    { value: "Single Dwelling", label: "Single Dwelling" },
    { value: "254 HMO", label: "254 HMO" },
    { value: "257 HMO", label: "257 HMO" },
    { value: "Licensable HMO", label: "Licensable HMO" },
    { value: "Unknown", label: "Unknown" },
  ],
  tenancyTypeOptions: [
    { value: "Unknown", label: "Unknown" },
    { value: "Individual tenancies", label: "Individual tenancies" },
    { value: "Shared tenancy (AST)", label: "Shared tenancy (AST)" },
    { value: "Corporate tenancy", label: "Corporate tenancy" },
    { label: "Serviced accommodation", value: "Serviced accommodation" },
  ],
  propertyTypes: [
    { value: "Flat", label: "Flat" },
    { value: "House", label: "House" },
    { value: "Building", label: "Building" },
  ],
  knowsWhoLiveInThePropertyOptions: ["Yes", "No"],
  buildingConstructionTypeOptions: ["Converted", "Purpose built", "Unknown"],
  initialValues: {
    propertyType: "",
    numberOfStoreys: 1,
    usageIntention: "",
    numberOfHouseholds: 0,
    numberOfTenants: 0,
    roomsOrUnits: 0,
    tenancyEndDate: new Date(),
    knowWhoLiveInProperty: "No",
    propertyCategory: "Unknown",
    tenancyType: "Unknown",
    commercialPremises: "Unknown",
    buildingConstructionType: "Unknown",
  },
};

export default PropertyForm;
