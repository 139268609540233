export const Styles = ({ colors, breakpoints }) => ({
  main: {
    display: "flex",
    position: "relative",
    [breakpoints.RESPONSIVE]: {
      width: "100%",
    },
    "& .input-div-inactive": {
      border: "2px solid #C4CDD5",
      "border-radius": "4px",
      display: "flex",
      "align-items": "center",
      position: "relative",
      width: "100%",
      "&:hover": {
        border: `2px solid ${colors.primary}`,
        caretColor: colors.primary,
      },
      "& .disabled-input": {
        "&:read-only": {
          background: colors.cmyk,
        },
      },
      "& input": {
        cursor: "pointer",
        margin: 0,
        border: "none",
        height: "44px",
        "&:-placeholder": {
          color: colors.black,
        },
        "&:read-only": {
          background: colors.hexaWhite,
        },
      },
      "& .input-errors": {
        background:
          "linear-gradient(0deg, rgba(226, 87, 76, 0.1), rgba(226, 87, 76, 0.1)), #FFFFFF",
        border: `2px solid ${colors.errorRed}`,
        borderRadius: "4px",
        "&:read-only": {
          background:
            "linear-gradient(0deg, rgba(226, 87, 76, 0.1), rgba(226, 87, 76, 0.1)), #FFFFFF",
        },
      },
      "& .list-menu": {
        display: "inline-block",
        height: "auto",
        zIndex: 99,
        boxShadow:
          "0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 16px rgba(9, 30, 66, 0.25)",
        borderRadius: "3px",
        position: "absolute",
        top: "46px",
        "& li": {
          "& .list": {
            height: "30px",
            alignItems: "center",
            display: "flex",
          },
          margin: "0",
        },
        [breakpoints.RESPONSIVE]: {
          width: "100%",
        },
      },
      [breakpoints.RESPONSIVE]: {
        width: "100%",
      },
      "& .disabled": {
        pointerEvents: "none",
      },
      "& .arrow": {
        cursor: "pointer",
        width: "50px",
        height: "27px",
        "& path": {
          stroke: colors.accountLabel,
        },
      },
      "& .arrow-up": {
        cursor: "pointer",
        width: "50px",
        height: "27px",
        "& path": {
          stroke: colors.primary,
        },
      },
    },
  },
});
