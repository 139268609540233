import {
  privacyPolicyURL,
  termsAndConditionsURL,
} from "constants/AppConstants";
import { ReactComponent as FeedbackIcon } from "images/feedbackIcon.svg";
import { ReactComponent as Account } from "images/account.svg";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as Dashboard } from "images/dashboard.svg";
import { ReactComponent as Map } from "images/map.svg";
import { ReactComponent as Home } from "images/quickcheck.svg";
import { useApiUserActions } from "Apis/YunoApi/useApiUserActions";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import withStyles from "react-jss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { subdomain } from "utils";
import { Links } from "v2/components";
import { styles } from "./styles";
const NavBar = ({ classes, onProperty, onClick }) => {
  const history = useHistory();
  const dropDownDiv = useRef(null);
  const { logoutUserRequest } = useApiUserActions();
  const [LoginInfo, { name }] = useSelector((store) => [
    store.LoginInfo,
    store.WebInfo,
  ]);
  const [showDropDown, setShowDropDown] = useState(false);
  useEffect(() => {
    if (showDropDown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = ({ target }) => {
    if (dropDownDiv.current && !dropDownDiv.current.contains(target)) {
      return setShowDropDown(!showDropDown);
    }
  };

  const handleClick = (url, page) => {
    onClick();
    if (!window.location.pathname.includes(url)) history.push(url, page);
  };

  const showList = () => {
    setShowDropDown(!showDropDown);
  };
  useEffect(() => {
    if (logoutUserRequest.result === true) {
      window.localStorage.removeItem("authUser");
      redirect("/");
    }
  }, [logoutUserRequest.result]);

  const openMail = () => {
    window.open(`mailto:${subdomain()}@goyuno.com`, "_blank");
  };
  const redirect = (path) => history.push(path);
  return (
    <>
      <div className={classes.main}>
        <div id={onProperty ? "iconWrapper" : "iconWrapperVertical"}>
          {/* <div className="icon" role="button" tabIndex="0" id="Feedback">
            <FeedbackIcon />
          </div> */}
          <div id="menuTitle">App</div>
          <div
            id="Dashboard"
            className="icon"
            role="button"
            tabIndex="0"
            onClick={() => handleClick("/dashboard", { page: "Dashboard" })}
            onKeyPress={() => history.push("/dashboard", { page: "Dashboard" })}
          >
            <Dashboard /> {!onProperty && <div id="menuText">Dashboard</div>}
          </div>
          <div
            className="icon"
            id="mapIcon"
            role="button"
            tabIndex="0"
            onClick={() => handleClick("/map", { page: "Map" })}
            onKeyPress={() => history.push("/map", { page: "Map" })}
          >
            <Map /> {!onProperty && <div id="menuText">Map</div>}
          </div>
          <div
            role="button"
            tabIndex="0"
            onClick={() => handleClick("/start-search", { page: "Search" })}
            onKeyPress={() => history.push("/start-search", { page: "Search" })}
            className="icon"
          >
            <Home />{" "}
            {!onProperty && <div id="menuText">New Property Search</div>}
          </div>
          <div
            role="button"
            tabIndex="0"
            onClick={showList}
            onKeyPress={showList}
            className={`${showDropDown ? "icon icon-background" : "icon"}`}
          >
            <Account /> {!onProperty && <div id="menuText">Account</div>}
          </div>
        </div>
      </div>
      {showDropDown && (
        <div className={classes.dropDown} ref={dropDownDiv}>
          <div className="user-info list">
            <span className="user-name">
              {name}{" "}
              <CrossIcon
                role="button"
                tabIndex="0"
                onClick={showList}
                onKeyPress={showList}
              />
            </span>
            <span className="user-email">{LoginInfo.email}</span>
          </div>
          <div
            className="list contact-ynuo"
            role="button"
            tabIndex="0"
            onClick={openMail}
            onKeyPress={openMail}
          >
            Contact Yuno
          </div>
          <div className="list contact-ynuo">
            <Links as="termsAndConditions" path={termsAndConditionsURL}>
              Terms and Conditions
            </Links>
          </div>
          <div className="list contact-ynuo">
            <Links as="termsAndConditions" path={privacyPolicyURL}>
              Privacy Policy
            </Links>
          </div>
          <div className="list contact-ynuo">
            <Links as="termsAndConditions" path={privacyPolicyURL}>
              Cookies Policy
            </Links>
          </div>
          {!dropDownDiv && <div className="list">Pricing</div>}
          <div
            role="button"
            tabIndex="0"
            onClick={logoutUserRequest.trigger}
            className="list"
          >
            Log out
          </div>
        </div>
      )}
    </>
  );
};
NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(memo(NavBar));
