import React, { useState } from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import { WhiteBox } from "components";
import { Typography } from "v2/components";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { useStyles } from "./styles";

const LicenseList = ({
  theme,
  showDetails = true,
  gudieLines = [],
  text = "",
}) => {
  const classes = useStyles(theme);
  const [showLicense, setShowLicense] = useState(showDetails);

  return (
    <WhiteBox className={classes.main}>
      <div className={classes.headingDiv}>
        <div className={classes.heading}>
          <Typography
            as="subtitle"
            className="heading-subtitle"
            color={theme.colors.primary}
          >
            {text}
          </Typography>
          {showLicense && <ArrowUp onClick={() => setShowLicense(false)} />}
          {!showLicense && <ArrowDown onClick={() => setShowLicense(true)} />}
        </div>
      </div>
      {showLicense && (
        <div className={classes.licenseList}>
          {gudieLines.map((value, key) => (
            <div key={key} className="details">
              {value}
            </div>
          ))}
        </div>
      )}
    </WhiteBox>
  );
};

LicenseList.propTypes = {
  theme: propTypes.object.isRequired,
  showDetails: propTypes.bool,
  gudieLines: propTypes.array,
  text: propTypes.string,
};

export default withTheme(LicenseList);
