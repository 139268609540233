import propTypes from "prop-types";
import LicenceInfo from "../LicenceInfo/LicenceInfo";
import Markdown from "../Markdown/Markdown";
import styles from "./PlanningView.module.css";
import {
  usePropertyDetails,
  usePropertyLicenceActions,
} from "context/propertyContext/PropertyContext";
import AddIcon from "v2/components/Icons/AddIcon";
import { Planning } from "components";
import { useState } from "react";
const PlanningView = (props) => {
  const [state, setState] = useState({
    showPlanning: false,
  });
  const { showPlanning } = state;
  const handleModal = (modal, value) => {
    setState((state) => ({ ...state, [modal]: !value }));
  };

  const propertyDetails = usePropertyDetails();

  const { onUpdateItemOrder } = usePropertyLicenceActions();

  return (
    <div className={styles.container}>
      {propertyDetails.planning.map((planning) => (
        <div className={styles.planning}>
          {planning.label}
          <div className={styles.planningWrap}>
            <div className={styles.planningPrice}>£{planning.cost}</div>
            <AddIcon
              id="Stonly_AddToOrder"
              isSelected={planning.ordered}
              // isDisabled={readOnly}
              toolTip="Add to order"
              selectedToolTip="Remove from order"
              onClick={(data) =>
                onUpdateItemOrder({
                  ...data,
                  itemId: planning.propertyItemId,
                })
              }
            />
          </div>
        </div>
      ))}

      <main>
        <div>
          {props.title === "Article 4" && (
            <Markdown information={props.article4Markdown} />
          )}
          {props.title === "Sui Generis" && (
            <Markdown information={props.suiGenerisMarkdown} />
          )}
        </div>
        <LicenceInfo
          isIssueDateRequired={true}
          isExpiryDateRequired={false}
          emptyMessage="Upload your planning application, planning response or issued planning permission if you have one."
          documentName={props.title}
          documentTypes={props.documentTypes}
          licence={props.licence}
          canUploadFiles={props.canUploadFiles}
        />

        <Planning
          isOpen={showPlanning}
          onClose={() => handleModal("showPlanning", showPlanning)}
        />
      </main>
    </div>
  );
};

PlanningView.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  licence: LicenceInfo.propTypes.licence,
  canUploadFiles: propTypes.bool,
  documentTypes: LicenceInfo.propTypes.documentTypes,
  package: propTypes.shape({
    price: propTypes.string.isRequired,
    currentPackage: propTypes.string.isRequired,
  }).isRequired,
};

export default PlanningView;
