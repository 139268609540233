import React, { useRef, useState, useEffect } from "react";
import propTypes, { arrayOf } from "prop-types";
import styles from "./BreadcrumbMobile.module.css";
import { ReactComponent as HomeIcon } from "images/requirement.svg";
import { ReactComponent as Circle } from "images/circle.svg";
import { ReactComponent as CircleCheck } from "images/filledCircle.svg";
import { ReactComponent as ActionRequiredYellow } from "images/actionRequiredYellow.svg";
import { ReactComponent as ActionRequiredRed } from "images/actionRequiredRed.svg";
import { ReactComponent as InfoLogo } from "images/infoLogo.svg";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import Typography from "../Typography/Typography";
import { usePropertyDetails } from "context/propertyContext/PropertyContext.js";
import { journeySteps } from "views/web/Property/Journey/constants";

const Step = (props) => {
  const { url } = useRouteMatch();
  const { location } = useHistory();
  const isSelected = location.pathname.includes(`${url}/journey/${props.path}`);
  const propertyDetails = usePropertyDetails();

  const current = new Date();
  const date1month = `${current.getFullYear()}-${
    current.getMonth() + 2
  }-${current.getDate()}`;
  const date3months = `${current.getFullYear()}-${
    current.getMonth() + 4
  }-${current.getDate()}`;

  const [redAction, setRedAction] = useState();
  const [yellowAction, setYellowAction] = useState();
  const [red, setRed] = useState(false);
  const [yellow, setYellow] = useState(false);
  const [licenceRed, setLicenceRed] = useState(false);
  const [licenceYellow, setLicenceYellow] = useState(false);

  useEffect(() => {
    props.name === "Licensing & Registers" &&
      propertyDetails.licences.map((documents, index) => {
        documents.documents?.map((expiryDate, index) => {
          if (expiryDate.expiryDate <= date1month) {
            return (
              setRedAction(true), setYellowAction(false), setLicenceRed(true)
            );
          } else if (
            expiryDate.expiryDate > date1month &&
            expiryDate.expiryDate <= date3months
          ) {
            return (
              setYellowAction(true), setRedAction(false), setLicenceYellow(true)
            );
          } else if (expiryDate.expiryDate > date3months) {
            return setYellowAction(false), setRedAction(false);
          }
        });
      });
  }, [propertyDetails.licences]);

  useEffect(() => {
    props.name === "Documentation and Certification" &&
      propertyDetails.certificates.map((documents, index) => {
        documents.documents?.map((expiryDate, index) => {
          if (expiryDate.expiryDate < date1month) {
            return setRedAction(true), setYellowAction(false), setRed(true);
          }
          if (
            expiryDate.expiryDate > date1month &&
            expiryDate.expiryDate <= date3months
          ) {
            return setYellowAction(true), setRedAction(false), setYellow(true);
          }
          if (expiryDate.expiryDate > date3months) {
            return setYellowAction(false), setRedAction(false);
          }
        });
      });
  }, [propertyDetails.certificates]);

  return (
    <>
      <Link
        className={styles.circleLink}
        to={{
          pathname: `${url}/journey/${props.path}${
            !props.Component && props.steps && props.steps.length
              ? `/journey/${props.steps[0].path}`
              : ""
          }`,
          state: {
            page: props.name,
          },
        }}
      >
        {isSelected && red && (
          <div className={styles.selected}>
            <ActionRequiredRed className={styles.firstColumn} />
            <Typography as="tabText">
              <b>{props.name}</b>
            </Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions urgently required, please update your documentation.
              </div>
            </div>
          </div>
        )}
        {!isSelected && red && (
          <div className={styles.notSelected}>
            <ActionRequiredRed className={styles.firstColumn} />
            <Typography as="tabText">{props.name}</Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions urgently required, please update your documentation.
              </div>
            </div>
          </div>
        )}
        {!isSelected && licenceRed && (
          <div className={styles.notSelected}>
            <ActionRequiredRed className={styles.firstColumn} />
            <Typography as="tabText">{props.name}</Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions urgently required, please update your documentation.
              </div>
            </div>
          </div>
        )}
        {isSelected && licenceYellow && !licenceRed && (
          <div className={styles.selected}>
            <ActionRequiredYellow className={styles.firstColumn} />
            <Typography as="tabText">
              <b>{props.name}</b>
            </Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions required in the next few months, please update your
                documentation.
              </div>
            </div>
          </div>
        )}
        {!isSelected && !licenceRed && licenceYellow && (
          <div className={styles.notSelected}>
            <ActionRequiredYellow className={styles.firstColumn} />
            <Typography as="tabText">{props.name}</Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions required in the next few months, please update your
                documentation.
              </div>
            </div>
          </div>
        )}
        {isSelected && licenceRed && (
          <div className={styles.selected}>
            <ActionRequiredRed className={styles.firstColumn} />
            <Typography as="tabText">
              <b>{props.name}</b>
            </Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions urgently required, please update your documentation.
              </div>
            </div>
          </div>
        )}
        {isSelected && !red && yellow && (
          <div className={styles.selected}>
            <ActionRequiredYellow className={styles.firstColumn} />
            <Typography as="tabText">
              <b>{props.name}</b>
            </Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions required in the next few months, please update your
                documentation.
              </div>
            </div>
          </div>
        )}
        {isSelected &&
          !yellow &&
          !red &&
          props.name === "Documentation and Certification" && (
            <div className={styles.selected}>
              <CircleCheck
                className={`${styles.firstColumn} ${styles.checked}`}
              />
              <Typography as="tabText">
                <b>{props.name}</b>
              </Typography>
            </div>
          )}
        {!isSelected &&
          !yellow &&
          !red &&
          props.name === "Documentation and Certification" && (
            <div className={styles.notSelected}>
              <Circle className={styles.firstColumn} />
              <Typography as="tabText">{props.name}</Typography>
            </div>
          )}
        {isSelected &&
          !licenceYellow &&
          !licenceRed &&
          props.name === "Licensing & Registers" && (
            <div className={styles.selected}>
              <CircleCheck
                className={`${styles.firstColumn} ${styles.checked}`}
              />
              <Typography as="tabText">
                <b>{props.name}</b>
              </Typography>
            </div>
          )}
        {!isSelected &&
          !licenceRed &&
          !licenceYellow &&
          props.name === "Licensing & Registers" && (
            <div className={styles.notSelected}>
              <Circle className={styles.firstColumn} />
              <Typography as="tabText">{props.name}</Typography>
            </div>
          )}
        {!isSelected && !red && yellow && (
          <div className={styles.notSelected}>
            <ActionRequiredYellow className={styles.firstColumn} />
            <Typography as="tabText">{props.name}</Typography>
            <div className={styles.dropdown}>
              <InfoLogo />
              <div className={styles.infoTip}>
                Actions required in the next few months, please update your
                documentation.
              </div>
            </div>
          </div>
        )}
        {isSelected &&
          props.name !== "Documentation and Certification" &&
          props.name !== "Licensing & Registers" && (
            <div className={styles.selected}>
              <CircleCheck
                className={`${styles.firstColumn} ${styles.checked}`}
              />
              <Typography as="tabText">
                <b>{props.name}</b>
              </Typography>
            </div>
          )}
        {!isSelected &&
          props.name !== "Documentation and Certification" &&
          props.name !== "Licensing & Registers" && (
            <div className={styles.notSelected}>
              <Circle className={styles.firstColumn} />
              <Typography as="tabText">{props.name}</Typography>
            </div>
          )}
      </Link>
      {!props.isLastStep && (
        <>
          <span>
            {props.steps &&
              isSelected &&
              props.steps.map((journeyData, index) => {
                return (
                  <div className={styles.innerStep} onClick={props.closeMenu}>
                    <Step
                      key={index}
                      id={`Journey_${journeyData.name}`}
                      path={`${props.path}/${journeyData.path}`}
                      isLastStep={index === props.steps.length - 1}
                      name={journeyData.name}
                    />
                  </div>
                );
              })}
          </span>
        </>
      )}
    </>
  );
};

const BreadcrumbMobile = (props) => {
  const ref = useRef(null);
  return (
    <>
      <div className={styles.container} ref={ref}>
        <div className={styles.bcsteps}>
          {journeySteps.map((journeyData, index) => {
            if (!props.checkFilter(journeyData.filter)) {
              return null;
            }
            return (
              <div
                className={styles.step}
                key={journeyData.name}
                id={`Journey_${journeyData.name}`}
                onClick={props.closeMenu}
              >
                <Step
                  key={index}
                  isLastStep={index === journeySteps.length - 1}
                  {...journeyData}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

BreadcrumbMobile.propType = {
  journey: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      steps: arrayOf({
        name: propTypes.string,
      }),
    })
  ),
};

BreadcrumbMobile.defaultProps = {
  journey: [],
};

export default BreadcrumbMobile;
