import React from "react";
import propTypes from "prop-types";
import WithStyles from "react-jss";
import { Styles } from "./styles";

const Row = ({ children, className, classes }) => (
  <div className={`${className} ${classes.row}`}>{children}</div>
);

Row.propTypes = {
  children: propTypes.any.isRequired,
  className: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
};

export default WithStyles(Styles)(Row);
