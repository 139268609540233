export const Styles = ({ colors, breakpoints }) => ({
  main: {
    display: "flex",
    width: "100%",
    height: "44px",
    alignItems: "center",
    justifyContent: "space-between",
    [breakpoints.MOBILE_DEVICE]: {
      height: "75px",
    },
    "& .content-icon": {
      margin: "8px 13px 8px 16px",
      width: "28px",
      height: "28px",
      left: "calc(50% - 28px/2)",
      top: "calc(50% - 28px/2)",
      background: "rgba(255, 255, 255, 0.2)",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& svg>path": {
        stroke: colors.hexaWhite,
      },
    },
    "& .content": {
      margin: "0 13px 0 16px",
    },
    "& .main-data": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .text-message": {
        color: colors.hexaWhite,
        "& .text": {
          marginRight: "8px",
        },
      },
    },
    "& .closs-icon": {
      display: "flex",
      justifyContent: "flex-end",
      margin: "0  16px",
      height: "12px",
      width: "12px",
      cursor: "pointer",
      "& path": {
        stroke: colors.hexaWhite,
      },
    },
  },
  error: {
    background: colors.error,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: colors.hexaWhite,
  },
  success: {
    background: colors.primary,
  },
});
