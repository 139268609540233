import { getPropertySponsorsURL, setPropertySponsorsURL } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { useMemo } from "react";
import { GET, PUT } from "utils";
import mixpanel from "../../utils/mixPanel";

type APISponsor = {
  name: string;
  logoUrl: string;
  businessId: string;
  address: string;
};

type AppSponsor = {
  image: string;
  id: string;
  name: string;
  address: string;
};

type SetSponsorData = {
  propertyId: string;
  sponsorData: AppSponsor;
};

type SponsorActions = {
  getPropertySponsors: APIAction<string, Array<AppSponsor>>;
  setPropertySponsor: APIAction<SetSponsorData, AppSponsor | null>;
};

export default function useSponsorActions(): SponsorActions {
  const getSponsorsRequest = async (propertyId: string) => {
    return GET(getPropertySponsorsURL(propertyId));
  };

  const setPropertySponsorsRequest = async (data: SetSponsorData) => {
    await PUT(setPropertySponsorsURL(data.propertyId), {
      sponsorId: parseInt(data.sponsorData.id),
    });

    return { data: data.sponsorData, meta: null };
  };
  const getSponsorsPromise = useAsync<
    APIActionResponse<Array<APISponsor>>,
    APIActionError
  >(getSponsorsRequest, false);
  const setPropertySponsorPromise = useAsync<
    APIActionResponse<AppSponsor>,
    APIActionError
  >(setPropertySponsorsRequest, false);

  const parsedSponsorsList = useMemo(
    (): Array<AppSponsor> =>
      getSponsorsPromise.value?.data
        ? getSponsorsPromise.value.data.map((sponsor) => ({
            id: `${sponsor.businessId}`,
            image: sponsor.logoUrl,
            name: sponsor.name,
            address: sponsor.address,
          }))
        : [],
    [getSponsorsPromise.value]
  );
  return {
    getPropertySponsors: {
      trigger: getSponsorsPromise.execute,
      state: getSponsorsPromise.status === "pending" ? "loading" : "idle",
      error: getSponsorsPromise.error?.errorDetails,
      result: parsedSponsorsList,
    },
    setPropertySponsor: {
      trigger: setPropertySponsorPromise.execute,
      state:
        setPropertySponsorPromise.status === "pending" ? "loading" : "idle",
      error: setPropertySponsorPromise.error?.errorDetails,
      result: setPropertySponsorPromise.value?.data || null,
    },
  };
}
