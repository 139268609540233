import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { style } from "./style";

export const Input = React.forwardRef(
  (
    {
      classes,
      type,
      placeholder,
      name,
      onChange,
      onKeyUp,
      value,
      onBlur,
      onFocus,
      className,
      error,
      readOnly,
      ...props
    },
    ref
  ) => (
    <>
      <input
        readOnly={readOnly}
        type={type}
        placeholder={placeholder}
        name={name}
        className={`${classes.inputField} ${className} ${
          error ? classes.error : ""
        }`}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyUp={onKeyUp}
        {...props}
        ref={ref}
      />
      {error && <span className={classes.errorText}>{error}</span>}
    </>
  )
);

Input.propTypes = {
  type: propTypes.string,
  placeholder: propTypes.string,
  name: propTypes.string,
  classes: propTypes.object,
  variant: propTypes.string,
  onChange: propTypes.func,
  onKeyUp: propTypes.func,
  selectedValue: propTypes.string,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  className: propTypes.string,
  readOnly: propTypes.bool,
  min: propTypes.any,
};

Input.defaultProps = {
  type: "text",
  placeholder: "text",
  name: "name",
  classes: {},
  onChange: () => null,
  onKeyUp: () => null,
  value: "",
  onBlur: () => null,
  onFocus: () => null,
  className: "",
  readOnly: false,
};

export default withStyles(style)(Input);
