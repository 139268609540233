import { createUseStyles } from "react-jss";
import { hexToRgb } from "utils";

export const useStyles = createUseStyles({
  dropDown: {
    "& .selected": {
      display: "flex",
      flexDirection: "row",
      "min-height": 32,
      width: "auto",
      borderRadius: "4px",
      alignItems: "center",
      paddingLeft: 12,
      background: ({ color }) => hexToRgb(color, "0.15"),
      "& span": {
        background: ({ dotColor }) => dotColor,
        height: 5,
        width: 5,
        borderRadius: "50%",
        marginRight: 10,
      },
      "& .text": {
        marginRight: 10,
        color: ({ text }) => text,
      },
    },
    width: "100%",
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    "& svg": {
      marginRight: 10,
      "& path": {
        stroke: ({ colors }) => colors.black,
      },
    },

    "&:hover $dropDowns": {},
  },
  dropDowns: {
    display: "none",
    position: "absolute",
    backgroundColor: ({ colors }) => colors.hexaWhite,
    width: "248px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: 3,
    fontFamily: ({ fonts }) => fonts.primary,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: ({ colors }) => colors.black,
    "& .active": {
      backgroundColor: ({ colors }) => colors.bg5,
    },
    "& .text": {
      padding: "0 16px",
      height: 40,
      cursor: "pointer",
      outline: "none",
      display: "flex",
      alignItems: "center",
      "& span": {
        outline: "none",
      },
    },
  },
});
