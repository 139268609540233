export const styles = ({ colors, fonts }) => ({
  text: {
    display: "flex",
    justifyContent: "space-between",
    "& h3": {
      fontStyle: "normal",
      letterSpacing: ({ spacing = "0.002em" }) => spacing,
      fontWeight: ({ weight }) => weight || "bold",
      fontFamily: fonts.primary,
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.hamptonsSecondary,
      margin: "24px",
    },
    "& img": {
      margin: "24px",
      cursor: "pointer",
    },
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      marginLeft: "24px",
    },
    "& input": {
      width: "352px",
      height: "48px",
      margin: "0 24px 0 24px",
      border: `2px solid ${colors.hamptonsFocus}`,
      boxSizing: "border-box",
    },
    "& Button": {
      width: "352px",
      height: "48px",
      margin: "0 24px 16px 24px",
    },
  },
  search: {
    marginTop: "24px !important",
  },
  inputDiv: {
    position: "relative",
    "& span": {
      position: "absolute",
      bottom: "-19px",
    },
  },
});
