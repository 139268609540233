import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, SimpleButton } from "components";
import { Typography } from "v2/components";
import { ReactComponent as MailIcon } from "images/mail.svg";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import theme from "theme";
import { textCopy } from "./constants";
import { Styles } from "./Styles";

const { colors } = theme;
const setting = {
  width: 640,
  height: 426,
  top: "0",
  color: colors.hexaWhite,
};
const CertificationModal = ({ isOpen, classes, onClose, propertyType }) => (
  <Modal isOpen={isOpen} setting={setting} className={classes.compliant}>
    <div className={classes.main}>
      <div className="cross-icon">
        <CrossIcon onClick={onClose} onKeyDown={onClose} />
      </div>
      <div className={classes.content}>
        <div className="icon-check">
          <MailIcon />
        </div>
        <div className="text-heading">
          <Typography as="h6">We've notified the interested parties</Typography>
        </div>
        <div className="sub-heading">{textCopy[propertyType]}</div>
        <div className="button">
          <SimpleButton onClick={onClose}>Ok, thanks</SimpleButton>
        </div>
      </div>
    </div>
  </Modal>
);
CertificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired,
};

export default withStyles(Styles)(memo(CertificationModal));
