import React, { useState } from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import { SimpleButton, WhiteBox } from "components";
import { Typography } from "v2/components";
import { ReactComponent as HomeIcon } from "images/home.svg";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { useStyles } from "./styles";

const PropertyDetails = ({
  theme,
  ChangeProperty,
  showDetails = true,
  PropertyForm = {},
}) => {
  const classes = useStyles(theme);
  const [showProperty, setShowProperty] = useState(showDetails);
  const { primary } = theme.colors;
  return (
    <WhiteBox className={classes.main}>
      <div className={classes.headingDiv}>
        <div className={classes.locationIcon}>
          <HomeIcon />
        </div>
        <div className={classes.heading}>
          <Typography
            as="subtitle"
            className="heading-subtitle"
            color={primary}
          >
            Property details
          </Typography>
          {showProperty && <ArrowUp onClick={() => setShowProperty(false)} />}
          {!showProperty && <ArrowDown onClick={() => setShowProperty(true)} />}
        </div>
      </div>
      <div className={classes.propertyDetails}>
        {showProperty && (
          <>
            <div className="details">
              <Typography as="subtitle" fontWeight="500" color={primary}>
                People
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.numberOfTenants || "-"}
              </Typography>
            </div>
            <div className="details">
              <Typography as="subtitle" fontWeight="500" color={primary || "-"}>
                Household
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.numberOfHouseholds || "-"}
              </Typography>
            </div>
            <div className="details">
              <Typography as="subtitle" fontWeight="500" color={primary || "-"}>
                Storeys
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.numberOfStoreys || "-"}
              </Typography>
            </div>
            <div className="details">
              <Typography as="subtitle" fontWeight="500" color={primary || "-"}>
                Rooms/Units
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.roomUnit || "-"}
              </Typography>
            </div>
            <div className="details">
              <Typography as="subtitle" fontWeight="500" color={primary}>
                Property Category
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.propertyCategory || "-"}
              </Typography>
            </div>
            <div className="details">
              <Typography as="subtitle" fontWeight="500" color={primary}>
                Property Type
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.propertyType || "-"}
              </Typography>
            </div>
            <div className="details">
              <Typography as="subtitle" fontWeight="500" color={primary}>
                Tenancy Type
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.tenancyType || "-"}
              </Typography>
            </div>

            <div className="details last-div">
              <Typography as="subtitle" fontWeight="500" color={primary}>
                Commercial premises in building?
              </Typography>
              <Typography as="subtitle" color={primary}>
                {PropertyForm.commercialPremises || "-"}
              </Typography>
            </div>
          </>
        )}
        <div className={classes.buttonDiv}>
          <SimpleButton onClick={ChangeProperty}>Change</SimpleButton>
        </div>
      </div>
    </WhiteBox>
  );
};

PropertyDetails.propTypes = {
  theme: propTypes.object.isRequired,
  ChangeProperty: propTypes.func.isRequired,
  showDetails: propTypes.bool,
  PropertyForm: propTypes.object,
};

export default withTheme(PropertyDetails);
