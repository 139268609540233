import propTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import Typography from "../Typography/Typography";
import styles from "./Link.module.css";

const Links = (props) => {
  switch (props.as) {
    case "a":
      return (
        <a href={props.path} className={styles.atag}>
          <Typography as="subtitle">{props.children}</Typography>
        </a>
      );
    case "termsAndConditions":
      return (
        <a
          href={props.path}
          target="_blank"
          className={styles.termsAndConditions}
        >
          {props.children}
        </a>
      );
    case "link":
      return (
        <Link
          to={{
            pathname: props.path,
            state: {
              page: props.name,
            },
          }}
          className={styles.aLink}
        >
          <Typography as="link">
            {props.description || `go to ${props.path.substring(1)}`}
          </Typography>
        </Link>
      );
    case "navLink":
      return (
        <NavLink to={props.path} className={`${styles.aLink}`}>
          <span
            className={` ${
              props.children === "Overview" &&
              window.location.pathname.includes("overview")
                ? styles.activeOverview
                : props.children === "Compare Rents by Letting Type" &&
                  window.location.pathname.includes("compareRents")
                ? styles.activeRents
                : ""
            }`}
          >
            {props.children}
          </span>
        </NavLink>
      );
    case "click":
      return (
        <span
          className={styles.spanClick}
          role="button"
          tabIndex="0"
          onClick={props.onClick}
          onKeyPress={props.onClick}
          {...props.linlProps}
        >
          {props.children}
        </span>
      );
    case "clickSupport":
      return (
        <a
          className={styles.clickSupport}
          href={"mailto:support@goyuno.com"}
          onKeyPress={props.onClick}
        >
          {props.children}
        </a>
      );
    case "_blank":
      return (
        <a
          rel="noopener noreferrer"
          href={props.path}
          target="_blank"
          className={styles.atag}
        >
          {props.children}
        </a>
      );
    default:
      return (
        <p
          className={props.disabled ? styles.disabled : styles.className}
          onClick={props.disabled ? null : props.onClick}
          {...props.linlProps}
        >
          {props.children}
        </p>
      );
  }
};

Links.propTypes = {
  children: propTypes.node.isRequired,
  as: propTypes.string,
  linlProps: propTypes.object,
  path: propTypes.string,
  onClick: propTypes.func,
  description: propTypes.string,
};

Links.defaultProps = {
  as: "p",
  linlProps: {},
  path: "",
  children: "",
  onClick: () => {},
  descripton: "",
};

export default Links;
