import { packagesURL } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { GET } from "utils";

type Packages = {
  packages: Array<{
    name: string;
    packageId: number;
    priceInLondon: string;
    priceRestOfUk: string;
  }>;
  services: Array<{
    name: string;
    packages: Array<{
      enabled: boolean;
      packageName: string;
      tag: string | null;
    }>;
  }>;
};

type PackagesActions = {
  getPackages: APIAction<any, Packages | null>;
};

export const useApiPackagesActions = (): PackagesActions => {
  const getPackages = async (propType: string) => {
    return GET(packagesURL(), { propertyType: propType });
  };

  const getPackagesPromise = useAsync<
    APIActionResponse<Packages>,
    APIActionError
  >(getPackages, false);
  return {
    getPackages: {
      trigger: getPackagesPromise.execute,
      state: getPackagesPromise.status === "pending" ? "loading" : "idle",
      error: getPackagesPromise.error?.errorDetails,
      result: getPackagesPromise.value?.data || null,
    },
  };
};
