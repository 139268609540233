const Styles = ({ colors, fonts, breakpoints }) => ({
  form: {
    "& input": {
      margin: "8px 0",
    },
    "& .input-div": {
      margin: "8px 0 26px 0",
      position: "relative",
      "& .label-icon": {
        display: "flex",
        "& svg": {
          cursor: "pointer",
          marginLeft: 12,
        },
      },
    },
  },

  label: {
    fontStyle: "normal",
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "500",
    fontFamily: fonts.primary,
    fontSize: ({ fontSize }) => fontSize || "16px",
    lineHeight: ({ lineHeight }) => lineHeight || "16px",
    marginBottom: "8px",
    color: colors.black,
  },
  button: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& .address-link": {
      color: colors.primary,
    },
    [breakpoints.RESPONSIVE]: {
      flexDirection: "column-reverse",
      "& .address-link": {
        marginTop: "10px",
      },
    },
    "& button": {
      [breakpoints.RESPONSIVE]: {
        width: "100%",
      },
    },
  },
});

export default Styles;
