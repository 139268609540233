import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { ReactComponent as HelpIcon } from "images/help.svg";
import { SimpleButton } from "components";
import { Typography } from "v2/components";
import { styles } from "./styles";

const PropertyBox = ({
  classes,
  subHeading = "",
  className = "",
  heading,
  onClick = () => {},
  buttonText = "",
  isButton = false,
}) => (
  <div className={classes.main}>
    <div className={`${classes.headingDiv} ${className}`}>
      <HelpIcon className="home-icon" />
      <div className={classes.heading}>
        <Typography as="h6" className="text">
          {heading}
        </Typography>
        <Typography as="label" className="sub-heading">
          {subHeading}
        </Typography>
      </div>
    </div>
    {isButton && (
      <SimpleButton onClick={onClick} className="property-button">
        {buttonText}
      </SimpleButton>
    )}
  </div>
);

PropertyBox.propTypes = {
  classes: PropTypes.object.isRequired,
  subHeading: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  heading: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default withStyles(styles)(memo(PropertyBox));
