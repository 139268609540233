import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { useStyles } from "./styles";

const Header = ({ classes, children, className = "" }) => (
  <div className={`${classes.whiteBox} ${className}`}>{children}</div>
);

Header.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.any.isRequired,
  className: propTypes.string,
};

export default withStyles(useStyles)(Header);
