import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyle from "react-jss";
import { Styles } from "./Styles";

const CircleRound = ({ classes, className = "" }) => (
  <div className={`${classes.circleRound} ${className}`} />
);

CircleRound.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyle(Styles)(memo(CircleRound));
