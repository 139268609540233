export const styles = ({ colors, fonts }) => ({
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    "& a": {
      fontFamily: fonts.primary,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.black,
      padding: "8px 16px",
      textDecoration: "none",
      border: "1px solid #ddd",
      background: colors.hexaWhite,
      outline: "none",
      cursor: "pointer",
      "&:hover": {
        background: colors.yunoActive,
      },
    },
    "& .disabled": {
      pointerEvents: "none",
    },
    "& .active": {
      border: `1px solid ${colors.grey4}`,
      boxShadow: `inset 0px -3px 0px ${colors.primary}`,
    },
  },
});
