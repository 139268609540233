import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  saveDataOnAirTable,
  parseLicence,
  parsePropertyData,
  getWebInfo,
  storeWebInfo,
} from "utils";

export const AirTableHook = (onSave = false) => {
  const [airTableCall, setAirtableCall] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [LicenseReducer, propertyForm, newAddress, Documents, WebInfo] =
    useSelector((store) => [
      store.LicenseReducer,
      store.PropertyReducer,
      store.Addresses,
      store.DocumentReducer,
      store.WebInfo,
    ]);
  useEffect(() => {
    if (airTableCall || onSave) {
      saveDataAirTable();
    }
  }, [airTableCall, onSave]);
  const saveDataAirTable = () => {
    const { licenseList } = LicenseReducer;
    const airTableData = {
      ...parsePropertyData({
        ...propertyForm,
      }),
      propertyAddress: Object.values(newAddress.address).join(),
      uprn: newAddress.address.uprn || 0,
      council: licenseList.councilName,
      ward: licenseList.ward,
      date: new Date(),
      ...parseLicence(Documents.documents),
    };
    if (onSave) {
      airTableData["downloadedReport?"] = true;
    }
    saveDataOnAirTable(airTableData, editRow);
    setEditRow(false);
    if (onSave) {
      const webInformation = getWebInfo();
      if (webInformation.updateKey) {
        webInformation.updateKey = "";
        storeWebInfo(webInformation);
      }
    }
    setAirtableCall(false);
  };
  return [
    [LicenseReducer, propertyForm, newAddress, Documents, WebInfo],
    setAirtableCall,
    setEditRow,
    airTableCall,
  ];
};
