import { ReactComponent as Loader } from "images/loader.svg";

import { Overlay } from "views/Overlay/Overlay";
import styles from "./Spinner.module.css";

export const Spinner = () => {
  return (
    <Overlay variant="light">
      <Loader className={styles.container} />
    </Overlay>
  );
};
