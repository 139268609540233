import React from "react";

export default function useInput(
  initialValue,
  errorMessage = "",
  type = "text"
) {
  const [value, setValue] = React.useState(initialValue);
  const [error, setError] = React.useState("");
  const isMounted = React.useRef(false);

  const onValueChange = React.useCallback((event) => {
    switch (type) {
      case "files":
        if (Array.isArray(event)) {
          setValue(event);
        } else {
          setValue((prevState) => [
            ...prevState,
            ...Object.values(event.target.files),
          ]);
        }
        break;
      case "select":
        setValue(event);
        break;

      default:
        setValue(event.target.value);
        break;
    }
  }, []);

  React.useEffect(() => {
    if (isMounted.current) {
      checkInput();
    } else {
      isMounted.current = true;
    }
  }, [value]);

  const checkInput = React.useCallback(() => {
    if (type === "files" && !value.length && errorMessage) {
      setError(errorMessage);
    } else if (value === "" && errorMessage) {
      setError(errorMessage);
    } else {
      setError("");
    }
  }, [value, errorMessage, type]);

  return [value, error, onValueChange, checkInput];
}
