import { DarkArrowDown, GreenArrowUp } from "images";
import PropTypes from "prop-types";
import React, { memo } from "react";
import withStyle from "react-jss";
import { Link, useHistory, useParams } from "react-router-dom";
import { checkWebInIframe, useWindowDimensions } from "utils";
import { Image, ModalV2 } from "v2/components";
import { formatter } from "yuno-formatting";
import { Styles } from "./Styles";
import StatusModal from "v2/components/PropertyStatusHighlight/StatusModal/StatusModal";
import useModal from "Hooks/useModal";

const AddressDetails = ({
  classes,
  onView,
  isOpen = false,
  logout = false,
  propertyDetails,
}) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const params = useParams();
  const goBack = () => {
    history.push(params.propertyID ? "/dashboard" : "/start-search");
  };
  const [isModalOpen, openModal, closeModal, ModalData] = useModal();

  return (
    <>
      <div className={classes.navBar}>
        {/* TODO the rendering logic in this component needs to be reviewed. */}
        {width > 786 || checkWebInIframe ? (
          <div className="address-div">
            {!checkWebInIframe && !logout && (
              <span className="heading">
                <Link to="/dashboard">Saved properties</Link>
                {">"}
              </span>
            )}
            <span className="address">
              {propertyDetails.postcode
                ? formatter.formatShortAddress(propertyDetails)
                : ""}
            </span>
            <span
              className={classes.viewDetails}
              onClick={onView}
              role="button"
              tabIndex="0"
              onKeyPress={onView}
            >
              View Details
            </span>
            <span className={classes.hDivider}>|</span>
            <span
              className={classes.viewDetails}
              onClick={openModal}
              role="button"
              tabIndex="0"
            >
              View Actions
            </span>
            {isModalOpen && (
              <ModalV2 onClose={closeModal}>
                <StatusModal
                  onClose={closeModal}
                  actionRequired={propertyDetails.actionRequired}
                  status={"Uncompliant"}
                  onView={openModal}
                  property={propertyDetails}
                />
              </ModalV2>
            )}
          </div>
        ) : (
          <>
            {!checkWebInIframe && (
              <>
                <span
                  role="button"
                  tabIndex="0"
                  onKeyPress={() => goBack()}
                  onClick={() => goBack()}
                />
                <span className="small-address">
                  {propertyDetails.addressLine1
                    ? propertyDetails.addressLine1
                    : ""}
                </span>
                <span
                  className={classes.viewDetails}
                  role="button"
                  tabIndex="0"
                  onKeyPress={onView}
                  onClick={onView}
                >
                  {isOpen ? (
                    <Image src={GreenArrowUp} alt="Up" className="view-icon" />
                  ) : (
                    <Image
                      src={DarkArrowDown}
                      alt="Down"
                      className="view-icon"
                    />
                  )}
                </span>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
AddressDetails.propType = {
  classes: PropTypes.object.isRequired,
  onView: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  logout: PropTypes.bool,
};
export default withStyle(Styles)(memo(AddressDetails));
