import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, SimpleButton } from "components";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { useWindowDimensions } from "utils";
import theme from "theme";
import { styles } from "./styles";

const { colors } = theme;
const setting = {
  width: 432,
  height: 192,
  top: 200,
  color: colors.hexaWhite,
};

const DeleteConfirmation = ({
  heading,
  isOpen,
  subTitle,
  onClose,
  classes,
  onDelete,
}) => {
  const { width } = useWindowDimensions();
  setting.width = width > 800 ? 640 : "80%";
  return (
    <Modal isOpen={isOpen} setting={setting} className={classes.delete}>
      <div className={classes.main}>
        <div className={classes.text}>
          <Typography as="subtitle" color={colors.primary}>
            {heading}
          </Typography>
          <CrossIcon onClick={onClose} onKeyDown={onClose} />
        </div>
        <div className={classes.subTitle}>{subTitle}</div>
        <div className={classes.button}>
          <SimpleButton onClick={onClose} className="cancel">
            Cancel
          </SimpleButton>
          <SimpleButton onClick={onDelete} className="delete">
            Delete
          </SimpleButton>
        </div>
      </div>
    </Modal>
  );
};
DeleteConfirmation.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  onDelete: propTypes.func.isRequired,
  subTitle: propTypes.func.isRequired,
  heading: propTypes.func.isRequired,
};

export default withStyles(styles)(DeleteConfirmation);
