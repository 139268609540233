import { useApiBusinessActions } from "Apis/YunoApi/useApiBusinessActions";
import useApiGuidelinesActions from "Apis/YunoApi/useApiGuidelinesActions";
import useApiPropertyAnalytics from "Apis/YunoApi/useAPIPropertyAnalytics";
import useApiPropertyCertificateActions from "Apis/YunoApi/useApiPropertyCertificateActions";
import useApiPropertyItemActions from "Apis/YunoApi/useApiPropertyItemActions";
import useApiPropertyWorkOrderActions from "Apis/YunoApi/useApiPropertyWorkOrderActions";
import usePropertyActions from "Apis/YunoApi/usePropertyActions";
import useSponsorActions from "Apis/YunoApi/useSponsorActions";
import { Packages } from "components";
import useModal from "Hooks/useModal";
import usePickSponsor from "Hooks/usePickSponsor";
import propTypes from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ModalV2, PickSponsor } from "v2/components";
import BusinessUpgrade from "v2/components/BusinessUpgrade/BusinessUpgrade";
import { Spinner } from "v2/components/Spinner/Spinner";
import { markdownContent } from "./propertyConstants";
import { markdownContentAspire } from "./propertyConstants";

import {
  addCertificateInPropertyDetails,
  addLicenceToPropertyDetails,
  convertCertificates,
  convertLicence,
  deleteCertificateFilesInPropertyDetails,
  deleteCertificateInPropertyDetails,
  deleteLicenceFileInPropertyDetails,
  updateCertificateFilesInPropertyDetails,
  updateCertificateInPropertyDetails,
  updateCertificateOrderStatusInPropertyDetails,
  updateLicenceFilesInPropertyDetails,
  updateLicenceInPropertyDetails,
  updateLicenceOrderedInPropertyDetails,
} from "./propertyContextUtils";
import NetworkError from "v2/components/NetworkError/NetworkError";

const PropertyContext = createContext();
const PropertyAllowedActionsContext = createContext();
const PropertyGuidelinesContext = createContext();
const PropertyUpdateContext = createContext();
const PropertySponsorContext = createContext();
const PropertyLicenceActionsContext = createContext();
const PropertyCertificateActionsContext = createContext();
const PropertyWorkOrderActionsContext = createContext();
const PropertyAnalyticsContext = createContext();

export function usePropertyDetails() {
  return useContext(PropertyContext);
}

export function usePropertyAllowedActions() {
  return useContext(PropertyAllowedActionsContext);
}

export function usePropertyUpdate() {
  return useContext(PropertyUpdateContext);
}

export function usePropertyGuidelines() {
  return useContext(PropertyGuidelinesContext);
}

export function usePropertySponsorContext() {
  return useContext(PropertySponsorContext);
}

export function usePropertyLicenceActions() {
  return useContext(PropertyLicenceActionsContext);
}

export function usePropertyCertificateActions() {
  return useContext(PropertyCertificateActionsContext);
}
export function usePropertyWorkOrderActionsContext() {
  return useContext(PropertyWorkOrderActionsContext);
}
export function usePropertyAnalyticsActionsContext() {
  return useContext(PropertyAnalyticsContext);
}

const parsePropertyDetails = (propertyDetails) => {
  let buildingLicence = propertyDetails.licences.find(
    ({ licenceType }) => licenceType === "S257"
  );
  if (buildingLicence) {
    buildingLicence = {
      ...buildingLicence,
      guideline: buildingLicence.guideline || [],
      documents: buildingLicence.itemUploads.map((item) =>
        convertLicence(item, buildingLicence.licenceType)
      ),
      certificates: buildingLicence.documents.map(
        ({ propertyEvidenceId }) => propertyEvidenceId
      ),
    };
  }
  return {
    ...propertyDetails,
    analytics: propertyDetails.analytics || [],
    isHMO:
      propertyDetails.numberOfTenants >= 3 &&
      propertyDetails.numberOfHouseholds >= 2,
    knowWhoLiveInProperty:
      propertyDetails.knowWhoLiveInProperty === true
        ? "Yes"
        : propertyDetails.knowWhoLiveInProperty === false
        ? "No"
        : propertyDetails.knowWhoLiveInProperty,
    planning: propertyDetails.planning
      .filter((planning) => planning.label !== "Planning Permission")
      .map((planning) => {
        return {
          ...planning,
          documents: planning.itemUploads.map((item) =>
            convertLicence(item, planning.licenceType)
          ),
        };
      }),
    licences: propertyDetails.licences
      .filter(
        ({ licenceType }) =>
          propertyDetails.propertyType === "Building" || licenceType !== "S257"
      )
      .map((licence) => {
        let licenceType = `${licence.licenceType} Licence`;
        if (licence.licenceType === "S257") {
          licenceType = "Additional (S257) Licence";
        } else if (licence.licenceType === "Additional") {
          licenceType = "Additional (S254) Licence";
        } else if (licence.licenceType === "Landlord Register") {
          licenceType = "Landlord Register";
        }
        return {
          ...licence,
          licenceType,
          documents: licence.itemUploads.map((item) =>
            convertLicence(item, licence.licenceType)
          ),
          certificates: licence.documents.map(
            ({ propertyEvidenceId }) => propertyEvidenceId
          ),
        };
      }),
    buildingLicence,
    markdownContent,
    markdownContentAspire,
    certificates: propertyDetails.certificates.map(convertCertificates),
  };
};

const PropertyProvider = (props) => {
  const [propertyDetails, setPropertyDetails] = useState(
    !!props.propertyData ? parsePropertyDetails(props.propertyData) : null
  );
  const [propertyGuidelines, setPropertyGuidelines] = useState(null);
  const { getProperty, updateProperty, addTenancy, updateTenancy } =
    usePropertyActions({
      propertyId: props.propertyId,
    });
  const { approveWorkOrder, submitWorkOrder } =
    useApiPropertyWorkOrderActions();

  const {
    getOverviewAnalytics,
    getCompareRentsAnalytics,
    getEPC,
    getAirDnaAnalytics,
  } = useApiPropertyAnalytics();

  const { getPropertySponsors, setPropertySponsor } = useSponsorActions();

  const { upgradeBusiness } = useApiBusinessActions();

  const { getGuidelines } = useApiGuidelinesActions();

  const [
    createItem,
    createItemFile,
    updateItem,
    updateItemOrder,
    deleteItemFile,
  ] = useApiPropertyItemActions({ propertyId: props.propertyId });

  const [
    createCertificate,
    createCertificateFiles,
    updateCertificate,
    updatedCertificateOrder,
    updateItemNotRequired,
    deleteCertificate,
    deleteCertificateFile,
  ] = useApiPropertyCertificateActions({ propertyId: props.propertyId });

  const [
    isSponsorModalOpen,
    onPickSponsorCallBack,
    sponsorsForProperty,
    onConfirmCallBack,
    onSubmitSponsorSuggestionCallback,
    closeSponsorModal,
  ] = usePickSponsor({
    user: props.user,
    onSuggestionSubmitted: getProperty.trigger,
    onSponsorPicked: () => getProperty.trigger(),
  });

  const [isPackageModalOpen, openPackageModal, closeModal] = useModal();
  const [isNetworkErrorOpen, openNetworkErrorModal] = useModal();

  const canViewPremiumData =
    props.customer &&
    !props.token &&
    (!props.customer.sponsorable || !!propertyDetails?.sponsorId);

  //new line
  useEffect(() => {
    props.setPropertyType(propertyDetails?.propertyType);
  }, [propertyDetails?.propertyType]);

  useEffect(() => {
    if (props.propertyId && !propertyDetails) {
      getProperty.trigger(props.token);
    }
    getEPC.trigger({ propertyUuid: props.propertyId });
  }, [props.propertyId]);

  useEffect(() => {
    if (updateProperty.result) {
      setPropertyDetails(parsePropertyDetails(updateProperty.result));
    }
  }, [updateProperty.result]);

  useEffect(() => {
    if (
      updateProperty.error &&
      updateProperty.error.message === "Network Error"
    ) {
      openNetworkErrorModal();
    }
  }, [updateProperty.error]);

  useEffect(() => {
    if (setPropertySponsor.result) {
      setPropertyDetails((previousValue) => ({
        ...previousValue,
        sponsor: setPropertySponsor.result,
        sponsorId: setPropertySponsor.result.id,
      }));
    }
  }, [setPropertySponsor.result]);

  useEffect(() => {
    if (getProperty.result) {
      setPropertyDetails(parsePropertyDetails(getProperty.result));
      getGuidelines.trigger(getProperty.result.osCustodianId);
      if (!getProperty.result.sponsor) {
        getPropertySponsors.trigger(props.propertyId);
      }
    }
  }, [getProperty.result]);

  useEffect(() => {
    if (propertyDetails && !propertyDetails.package) {
      // I would like this default logic to be moved to the BE but this would require a refactor to the BE code.
      const defaultPackage = props.packages.packages.length
        ? props.packages.packages.find(({ name }) => name === "Platinum")
        : null;
      if (defaultPackage) {
        defaultPackage.price = propertyDetails.isInLondon
          ? defaultPackage.priceInLondon
          : defaultPackage.priceRestOfUk;
      }
      setPropertyDetails((previousValue) => ({
        ...previousValue,
        package: defaultPackage,
        packageId: defaultPackage.packageId,
      }));
      if (!getGuidelines.result && getGuidelines.state === "idle") {
        getGuidelines.trigger(propertyDetails.osCustodianId);
      }
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (getGuidelines.result) {
      setPropertyGuidelines(getGuidelines.result);
    }
  }, [getGuidelines.result]);

  useEffect(() => {
    if (createItem.result?.licences) {
      setPropertyDetails((previousValue) => {
        return Object.assign(
          addLicenceToPropertyDetails(
            previousValue,
            createItem.result.licences
          ),
          { actionRequired: createItem.result.actionRequired }
        );
      });
    }
  }, [createItem.result?.actionRequired]);

  useEffect(() => {
    if (createItemFile.result) {
      setPropertyDetails((previousValue) =>
        updateLicenceFilesInPropertyDetails(
          previousValue,
          createItemFile.result
        )
      );
    }
  }, [createItemFile.result]);

  useEffect(() => {
    if (deleteItemFile.result) {
      setPropertyDetails((previousValue) =>
        deleteLicenceFileInPropertyDetails(previousValue, deleteItemFile.result)
      );
    }
  }, [deleteItemFile.result]);

  useEffect(() => {
    if (updateItem.result.onUpdateData) {
      setPropertyDetails((previousValue) =>
        Object.assign(
          updateLicenceInPropertyDetails(
            previousValue,
            updateItem.result.onUpdateData
          ),
          { actionRequired: updateItem.result.actionRequired }
        )
      );
    }
  }, [updateItem.result.status]);

  useEffect(() => {
    if (createCertificate.result) {
      setPropertyDetails((previousValue) =>
        addCertificateInPropertyDetails(previousValue, createCertificate.result)
      );
    }
  }, [createCertificate.result]);

  useEffect(() => {
    if (updatedCertificateOrder.result) {
      setPropertyDetails((previousValue) =>
        updateCertificateOrderStatusInPropertyDetails(
          previousValue,
          updatedCertificateOrder.result
        )
      );
    }
  }, [updatedCertificateOrder.result]);

  useEffect(() => {
    if (updateItemOrder.result) {
      setPropertyDetails((previousValue) =>
        updateLicenceOrderedInPropertyDetails(
          previousValue,
          updateItemOrder.result
        )
      );
    }
  }, [updateItemOrder.result]);

  useEffect(() => {
    if (deleteCertificate.result) {
      setPropertyDetails((previousValue) =>
        deleteCertificateInPropertyDetails(
          previousValue,
          deleteCertificate.result
        )
      );
    }
  }, [deleteCertificate.result]);

  useEffect(() => {
    if (updateCertificate.result) {
      setPropertyDetails((previousValue) =>
        updateCertificateInPropertyDetails(
          previousValue,
          updateCertificate.result
        )
      );
    }
  }, [updateCertificate.result]);

  useEffect(() => {
    if (createCertificateFiles.result) {
      setPropertyDetails((previousValue) =>
        updateCertificateFilesInPropertyDetails(
          previousValue,
          createCertificateFiles.result
        )
      );
    }
  }, [createCertificateFiles.result]);

  useEffect(() => {
    if (deleteCertificateFile.result) {
      setPropertyDetails((previousValue) =>
        deleteCertificateFilesInPropertyDetails(
          previousValue,
          deleteCertificateFile.result
        )
      );
    }
  }, [deleteCertificateFile.result]);

  const selectSponsorCallback = (data) => {
    setPropertySponsor.trigger({
      sponsorData: data,
      propertyId: props.propertyId,
    });
    closeSponsorModal();
  };

  const approverWorkOrderCallback = useCallback(() => {
    if (props.token && propertyDetails) {
      approveWorkOrder.trigger({
        token: props.token,
        propertyUuid: propertyDetails.uuid,
      });
    }
  }, [props.token, propertyDetails, approveWorkOrder]);

  const submitWorkOrderCallback = useCallback(() => {
    if (propertyDetails) {
      submitWorkOrder.trigger({
        packageId: propertyDetails.packageId,
        propertyUuid: propertyDetails.uuid,
      });
    }
  }, [propertyDetails, submitWorkOrder]);
  useEffect(() => {
    if (submitWorkOrder.result && submitWorkOrder.state === "idle") {
      getProperty.trigger();
    }
  }, [submitWorkOrder.state]);

  const parseUpdatePropertyData = async (propertyInfoData) => {
    // Extract all tenancy info
    const {
      hasTenancies,
      tenancyId,
      propertyUuid,
      numberOfHouseholds,
      numberOfTenants,
      tenancyStartDate,
      tenancyEndDate,
      tenancyType,
      ...otherInfoData
    } = propertyInfoData;

    // Update the property with everything else
    const parsedUpdateData = {
      ...otherInfoData,
      knowWhoLiveInProperty: otherInfoData.knowWhoLiveInProperty === "Yes",
    };
    if (canViewPremiumData && otherInfoData.roomsOrUnits) {
      getAnalyticsCallBack("compareRents", {
        bedrooms: propertyInfoData.roomsOrUnits,
      });
    }
    if (!hasTenancies)
      await addTenancy.trigger({
        propertyUuid,
        numberOfHouseholds,
        numberOfTenants,
        tenancyStartDate: tenancyStartDate ? tenancyStartDate.toString() : null,
        tenancyEndDate: tenancyEndDate ? tenancyEndDate.toString() : null,
        tenancyType,
        status: "current",
      });
    else
      await updateTenancy.trigger({
        propertyUuid,
        tenancyId,
        numberOfHouseholds,
        numberOfTenants,
        tenancyStartDate: tenancyStartDate ? tenancyStartDate.toString() : null,
        tenancyEndDate: tenancyEndDate ? tenancyEndDate.toString() : null,
        tenancyType,
        status: "current",
      });

    await updateProperty.trigger(parsedUpdateData);
  };

  const pressGo = () => {
    const parseDetails = propertyDetails;
    delete parseDetails.knowWhoLiveInProperty;
    updateProperty.trigger(parseDetails);
  };

  const updatePropertyPackage = useCallback((propertyDataToUpdate) => {
    updateProperty.trigger({
      packageId: propertyDataToUpdate.packageId,
    });
  }, []);

  const getAnalyticsCallBack = useCallback(
    (analyticsName, data = {}) => {
      if (analyticsName === "overview") {
        getOverviewAnalytics.trigger({
          propertyUuid: propertyDetails.uuid,
          uprn: propertyDetails.UPRN,
        });
      }
      if (analyticsName === "compareRents") {
        getCompareRentsAnalytics.trigger({
          propertyUuid: propertyDetails.uuid,
          bedrooms: data.bedrooms || "1",
        });
      }
      if (analyticsName === "epc") {
        getEPC.trigger({
          propertyUuid: propertyDetails.uuid,
        });
      }
      if (analyticsName === "airdna") {
        getAirDnaAnalytics.trigger({
          propertyUuid: propertyDetails.uuid,
          bedrooms: data.bedrooms || "1",
        });
      }
    },
    [propertyDetails]
  );

  useEffect(() => {
    getProperty.trigger();
  }, [getOverviewAnalytics.result, getCompareRentsAnalytics.result]);
  if (
    !propertyDetails ||
    !props.user ||
    !props.customer ||
    !props.packages ||
    updateProperty.state === "pending"
  ) {
    return <Spinner />;
  }
  return (
    <>
      {isNetworkErrorOpen && (
        <ModalV2>
          <NetworkError message={updateProperty.error.message} />
        </ModalV2>
      )}
      {!propertyDetails.sponsor &&
        props.customer.sponsorable &&
        isSponsorModalOpen &&
        !updateProperty.error && (
          <ModalV2>
            <PickSponsor
              sponsors={sponsorsForProperty}
              onCancel={closeSponsorModal}
              onConfirm={onConfirmCallBack}
              onSubmitSponsorSuggestions={onSubmitSponsorSuggestionCallback}
            />
          </ModalV2>
        )}
      {props.user.role === "business user" && isSponsorModalOpen && (
        <ModalV2>
          <BusinessUpgrade
            onCancel={closeSponsorModal}
            onSubmit={(data) => upgradeBusiness.trigger(data)}
          />
        </ModalV2>
      )}
      {propertyDetails.package && (
        <Packages
          isOpen={isPackageModalOpen}
          data={props.packages}
          onClose={closeModal}
          isLondonCouncil={!!propertyDetails.isLondonCouncil}
          currentPackage={propertyDetails.package}
          selectPackage={updatePropertyPackage}
        />
      )}
      <PropertyUpdateContext.Provider
        value={{
          updatePropertyInfo: parseUpdatePropertyData,
          openPackageModal,
          pressGo,
        }}
      >
        <PropertyLicenceActionsContext.Provider
          value={{
            onCreate: createItem.trigger,
            onUpdate: updateItem.trigger,
            onUpdateItemOrder: updateItemOrder.trigger,
            onDeleteFile: deleteItemFile.trigger,
          }}
        >
          <PropertyCertificateActionsContext.Provider
            value={{
              onCreate: createCertificate.trigger,
              onDelete: deleteCertificate.trigger,
              onDeleteFile: deleteCertificateFile.trigger,
              onUpdate: updateCertificate.trigger,
              onAddToOrder: updatedCertificateOrder.trigger,
              onDocumentRequirementChange: updateItemNotRequired.trigger,
            }}
          >
            <PropertyContext.Provider value={propertyDetails}>
              <PropertyGuidelinesContext.Provider value={propertyGuidelines}>
                <PropertySponsorContext.Provider
                  value={{
                    openSponsorModal: () => {
                      onPickSponsorCallBack({
                        propertyId: props.propertyId,
                        postcode: propertyDetails.postcode,
                      });
                    },
                    sponsors: getPropertySponsors.result,
                    onSelectSponsor: selectSponsorCallback,
                  }}
                >
                  <PropertyAllowedActionsContext.Provider
                    value={{
                      canViewPremiumData,
                      readOnly:
                        propertyDetails.status === "Work in progress" ||
                        props.token, // this is likely to change because we want landlords to be able to change the order and possibly the property details
                      landlordApproval:
                        propertyDetails.status !== "Work in progress" &&
                        props.token,
                    }}
                  >
                    <PropertyWorkOrderActionsContext.Provider
                      value={{
                        approveWorkOrder: approverWorkOrderCallback,
                        submitWorkOrder: submitWorkOrderCallback,
                      }}
                    >
                      <PropertyAnalyticsContext.Provider
                        value={{
                          getAnalytics: getAnalyticsCallBack,
                          epc: getEPC.result,
                        }}
                      >
                        {props.children}
                      </PropertyAnalyticsContext.Provider>
                    </PropertyWorkOrderActionsContext.Provider>
                  </PropertyAllowedActionsContext.Provider>
                </PropertySponsorContext.Provider>
              </PropertyGuidelinesContext.Provider>
            </PropertyContext.Provider>
          </PropertyCertificateActionsContext.Provider>
        </PropertyLicenceActionsContext.Provider>
      </PropertyUpdateContext.Provider>
    </>
  );
};

PropertyProvider.propTypes = {
  propertyId: propTypes.string.isRequired,
  propertyData: propTypes.any,
  user: propTypes.any.isRequired,
  customer: propTypes.any.isRequired,
  packages: propTypes.any.isRequired,
};
export { PropertyProvider };
