import Flex from "v2/components/Layout/Flex";

export const styles = ({ colors, fonts, breakpoints }) => ({
  hmo: {
    "& svg": {
      cursor: "pointer",
    },
    [breakpoints.MOBILE_DEVICE]: {
      padding: "0 !important",
      "& .contents": {
        width: "100%",
        margin: "0 !important",
        height: "100vh !important",
        top: "0",
      },
    },
  },
  exitButton: {
    display: "flex",
    position: "fixed",
    width: "575px",
    justifyContent: "flex-end",
  },
  text: {
    display: "flex",
    marginTop: "24px",
    "& p": {
      width: 552,
      height: 48,
      [breakpoints.MOBILE_DEVICE]: {
        height: "129px",
      },
    },
    "& svg": {
      cursor: "pointer",
      margin: "0 0 13px 16px",
      "&:focus": {
        outline: "none",
      },
    },
  },
  note: {
    marginTop: "8px",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.darkGrey,
  },
  endNote: {
    marginTop: "12px",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.darkGrey,
    "& .link": {
      color: colors.textLink,
      textDecoration: "underline",
      textDecorationColor: colors.textLink,
    },
  },
  main: {
    background: colors.hexWhite,
    margin: "0 24px",
    fontFamily: fonts.primary,
    fontStyle: "normal",
    "& span": {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.darkGrey,
    },
  },
  section: {
    margin: "24px 0",
    "& .list-view": {
      paddingTop: "8px",
    },
  },
});
