import {
  DOCUMENT_LIST,
  DOCUMENT_ERROR,
  LOADING,
  APICALL,
  UPDATE_FREEHOLDER_EMAIL,
} from "./constants";
import { initialState } from "./initialState";

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DOCUMENT_LIST:
      return { ...state, documents: { ...payload }, loading: false };
    case UPDATE_FREEHOLDER_EMAIL:
      return {
        ...state,
        loading: false,
        documents: {
          ...state.documents,
          freeholderEmail: payload,
        },
      };
    case DOCUMENT_ERROR:
      return {
        ...state,
        apiError: { ...payload },
        documents: { ...initialState.documents },
      };
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case APICALL:
      return {
        ...state,
        loading: true,
        documents: payload,
        apiError: payload,
      };
    default:
      return state;
  }
};
