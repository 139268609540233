import useAirtableActions from "Apis/YunoApi/useAirtableActions";
import { useUserData } from "context/environmentContext/EnvironmentContext";
import useModal from "../../../Hooks/useModal";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import React, { useCallback } from "react";
import { ReactComponent as BackgroundCircle } from "../../../images/BackgroundCircle.svg";
import Logo2 from "../../../images/bafe-logo.png";
import Logo3 from "../../../images/bm_trada.png";
import Logo4 from "../../../images/firas-Logo.png";
import { ReactComponent as Logo1 } from "../../../images/Fire-protection-association-logo.svg";
import Logo5 from "../../../images/gsr-logo-no-keyline.png";
import Logo6 from "../../../images/IFEDA-logo.png";
import Logo7 from "../../../images/logo.png";
import Logo8 from "../../../images/napit-logo.png";
import Logo9 from "../../../images/NICEICELECSALOGO.png";
import { ReactComponent as PriceLogo } from "../../../images/price.svg";
import Logo10 from "../../../images/SafeContractor-logo.png";
import Logo11 from "../../../images/ukas-quality-management-logo-EABD6740FA-seeklogo.png";
import Modal from "../../components/ModalV2/Modal";
import Button from "../Form/Button/Button";
import Typography from "../Typography/Typography";
import SavedJobDetails from "./SubmitJobDetails/SavedJobDetails";
import SubmitJobDetails from "./SubmitJobDetails/SubmitJobDetails";
import styles from "./UpgradeBanner.module.css";

const UpgradeBanner = (props) => {
  const [
    isSubmitJobDetailsModalOpen,
    openSubmitJobDetailsModal,
    closeSubmitJobDetailsModal,
  ] = useModal();
  const { submitJobDetails } = useAirtableActions();
  const user = useUserData();
  const onSubmitDetails = () => {
    openSubmitJobDetailsModal({
      name: props.name,
    });
  };

  const [isSavedModalOpen, openSavedModal, closeSavedModal] = useModal();

  React.useEffect(() => {
    if (submitJobDetails.result) {
      openSavedModal({
        name: props.name,
      });
    }
  }, [submitJobDetails.result]);

  const submitJobDetailsCallback = useCallback(
    (jobDetails) => {
      submitJobDetails.trigger({
        ...jobDetails,
        propertyId: props.propertyId,
      });
    },
    [props.propertyId, submitJobDetails]
  );

  useWhyDidYouUpdate("UpgradeBanner", props);
  return (
    <div className={styles.container}>
      {isSubmitJobDetailsModalOpen && (
        <Modal onClose={closeSubmitJobDetailsModal}>
          <SubmitJobDetails
            {...user}
            onClose={closeSubmitJobDetailsModal}
            onSave={submitJobDetailsCallback}
          />
        </Modal>
      )}
      {isSavedModalOpen && (
        <Modal onClose={closeSubmitJobDetailsModal}>
          <SavedJobDetails onClose={closeSavedModal} />
        </Modal>
      )}
      <BackgroundCircle />
      <div className={styles.header}>
        <Typography as="upgradeBanner">
          Looking to get quotes for upgrades?
        </Typography>
        <Typography as="upgradeBannerSub">
          We can help you to connect with accredited companies and compare
          quotes.
        </Typography>
      </div>
      <div className={styles.listContainer}>
        <div className={styles.list}>
          <Typography as="upgradeBannerList">
            1- Upload your job details
          </Typography>
          <Typography as="upgradeBannerList">2- Get a quote</Typography>
          <Typography as="upgradeBannerList">3- Job complete</Typography>
        </div>
        <div>
          <PriceLogo />
        </div>
      </div>
      <div className={styles.button}>
        <Button actionName="Submit job details" onClick={onSubmitDetails} />
      </div>
      <br />
      <Typography as="upgradeBannerList">
        Note: Please look out for the below accreditations when instructing
        contractors. We do not provide works directly, we are simply offering a
        quoting service to vetted contractors.
      </Typography>
      <div className={styles.wrapLogos}>
        <div className={styles.logos}>
          <img src={Logo3} />
          <Logo1 />
          <img src={Logo2} />
          <img src={Logo4} />
          <img src={Logo11} />
          <img src={Logo7} />
          <img src={Logo6} />
          <img src={Logo9} />
          <img src={Logo10} />
          <img src={Logo8} />
          <img src={Logo5} />
        </div>
      </div>
    </div>
  );
};

UpgradeBanner.propTypes = {
  name: propTypes.string,
  propertyId: propTypes.string,
};

UpgradeBanner.defaultProps = {};

export default UpgradeBanner;
