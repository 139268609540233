export const Styles = ({ colors, breakpoints }) => ({
  main: {
    [breakpoints.RESPONSIVE]: {
      padding: "0",
    },
    "& .contents": {
      backgroundColor: colors.hexaWhite,
    },
    "& .approval-modal": {
      "& .header-div": {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 20px",
        color: colors.hexaWhite,
        height: "70px",
        backgroundColor: colors.primary,
        alignItems: "center",
        "& svg ": {
          cursor: "pointer",
          "& path": {
            stroke: colors.hexaWhite,
          },
        },
      },
      "& .email-preview": {
        margin: "55px 31px 11px 29px",
        "& .email-preview-edit": {
          display: "flex",
          justifyContent: "space-between",
          "& .edit-div": {
            outline: "none",
            color: colors.primary,
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "24px",
            textAlign: "right",
            letterSpacing: "0.002em",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "& .tick": {
              height: "15px",
              width: "7px",
              marginBottom: "3px",
              border: `solid ${colors.primary}`,
              borderWidth: "0px 2px 2px 0px",
              "-webkit-transform": "rotate(45deg)",
              "-moz-transform": "rotate(45deg)",
              "-o-transform": "rotate(45deg)",
              display: "block",
              marginRight: "12px",
            },
            "& svg": {
              marginRight: "2px",
              "& path": {
                stroke: colors.primary,
              },
            },
          },
          "& .email-preview-text": {
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.002em",
            color: colors.black,
          },
        },
        "& .email-content": {
          background: colors.listBackground,
          marginTop: "11px",
          minHeight: "500px",
          height: "auto",
          overflowY: "auto",
          border: `2px solid ${colors.inputGrey}`,
          boxSizing: "border-box",
          borderRadius: "4px",
          padding: "20px 16px",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "14px",
          lineHeight: "22px",
          color: colors.labelColor,
          "& textarea": {
            width: "100%",
            border: "none",
            height: "390px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "22px",
            color: colors.labelColor,
            overflow: "visible",
            "&:disabled": {
              background: colors.listBackground,
              resize: "none",
            },
          },
          "& .name": {
            marginBottom: "12px",
          },
          "& .dynamic-data": {
            marginBottom: "12px",
          },
          "& .list": {
            display: "flex",
            flexDirection: "column",
            "& .data": {
              marginBottom: "2px",
            },
          },
          "& .total-list": {
            margin: "20px 0 40px 0",
            "& .price": {
              color: colors.black,
            },
          },
          "& .buttons": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [breakpoints.RESPONSIVE]: {
              flexDirection: "column",
            },
            "& .animation": {
              marginBottom: "20px",
            },
            "& .icon-button": {
              marginRight: "12px",
              background: "none",
              borderColor: colors.hexaWhite,
              "& path": {
                stroke: colors.hexaWhite,
              },
            },
            "& .left-button": {
              marginRight: "38px",
              [breakpoints.RESPONSIVE]: {
                marginRight: "0",
                marginBottom: "20px",
              },
            },
            "& .email-button": {
              padding: "0.8rem",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.primary,
              color: colors.hexaWhite,
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              letterSpacing: "0.01em",
            },
          },
          "& .footer": {
            marginTop: "48px",
            paddingTop: "12px",
          },
        },
      },
      "& .form": {
        display: "flex",
        marginTop: "38px",
        flexDirection: "column",
        margin: "0 31px 0 29px",
        "& .line": {
          marginBottom: "31px",
          border: `2px solid ${colors.inputGrey}`,
        },
        "& .remove-form": {
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "15px",
          lineHeight: "16px",
          letterSpacing: "0.01em",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "29px",
          color: colors.labelColor,
          outline: "none",
          cursor: "pointer",
          "& .icon": {
            marginRight: "12px",
            "& path": {
              stroke: colors.labelColor,
            },
          },
        },
        "& .add-more": {
          display: "flex",
          alignItems: "center",
          outline: "none",
          cursor: "pointer",
          "& .icon": {
            marginRight: "12px",
          },
          "& .add-more-text": {
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "16px",
            letterSpacing: "0.01em",
            color: colors.primary,
          },
        },
        "& .form-heading": {
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.002em",
          color: colors.black,
          marginBottom: "26px",
        },
        "& .form-input": {
          display: "flex",
          width: "100%",
          [breakpoints.RESPONSIVE]: {
            flexDirection: "column",
            width: "96%",
            "& .input": {
              width: "100% !important",
              marginRight: "0",
            },
            "& .input-right": {
              width: "100% !important",
              marginTop: "20px",
            },
            "&  input": {
              width: "100%",
            },
          },
          "& .input": {
            width: "265px",
            marginRight: "40px",
          },
          "& .input-right": {
            width: "265px",
          },
          "& input": {
            width: "100%",
          },
        },
        "& .input-full": {
          width: "100%",
          margin: "21px 0 29px 0",
          "& input": {
            width: "96%",
          },
        },
      },
      "& .footer-text": {
        margin: "26px 31px 0 29px",
        display: "flex",
        flexDirection: "column",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "22px",
        "& .footer-heading": {
          color: colors.black,
          marginBottom: "10px",
        },
        "& .footer-subheading": {
          fontSize: "13px",
          fontStyle: "italic",
          color: colors.labelColor,
        },
      },
      "& .button-group > *": {
        marginRight: "1rem",
      },
      "& .button-group": {
        display: "flex",
        justifyContent: "flex-end",
        margin: "48px 32px 50px 0",
        [breakpoints.RESPONSIVE]: {
          flexDirection: "column-reverse",
          margin: "26px 31px 50px 29px",
          boxSizing: "border-box",
        },
        "& button": {
          height: "40px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          [breakpoints.RESPONSIVE]: {
            width: "100%",
            height: "48px",
            boxSizing: "border-box",
          },
          "&:disabled": {
            opacity: "0.5",
            cursor: "not-allowed",
            pointerEvents: "none",
            backgroundColor: colors.hamptonsNewDisabled,
          },
        },
        "& .cancel-button": {
          width: "104px",
          marginRight: "18px",
          background: colors.hexaWhite,
          color: colors.black,
          border: `1px solid ${colors.inputGrey}`,
          [breakpoints.RESPONSIVE]: {
            marginTop: "20px",
            width: "100%",
            boxSizing: "border-box",
          },
        },
      },
    },
  },
});
