import propTypes from "prop-types";
import { Typography } from "..";
import styles from "./Divider.module.css";

const Divider = (props) => {
  return (
    <div className={styles.container}>
      <span />
      <Typography>{props.dividerText}</Typography>
      <span />
    </div>
  );
};
Divider.propTypes = {
  dividerText: propTypes.string.isRequired,
};

export default Divider;
