export const useStyles = ({ colors, breakpoints, fonts }) => ({
  "@keyframes fadeInUp": {
    from: {
      opacity: "0",
      "-webkit-transform": "translate3d(0, 100%, 0)",
      transform: "translate3d(0, 100%, 0)",
    },

    to: {
      opacity: "1",
      "-webkit-transform": "none",
      transform: "none",
    },
  },
  main: {
    margin: "0 32px",
    paddingBottom: "20px",
    "& .amount-div": {
      width: "100%",
      marginBottom: "24px",
      "& .heading-text": {
        minHeight: "40px",
        height: "auto",
        width: 544,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: colors.darkGrey,
        [breakpoints.MOBILE_DEVICE]: {
          width: "280px",
        },
      },
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "32px",
    paddingBottom: "21px",
    "& .heading-text": {
      color: colors.primary,
    },
    "& .pdf-creating": {
      transition: "all .3s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
      background: colors.hamptonsSecondary,
      "& text": {
        animationDelay: "3s",
        animation: "$fadeInUp 1s ease-in-out 0s forwards",
      },
      "&:before": {
        width: "0%",
        position: "absolute",
        left: "0",
        bottom: "2px",
        height: "3px",
        backgroundColor: colors.primary,
        transition: "all 3s linear",
      },
      "&:after": {
        height: "calc(100% - 3px)",
        transition: "all .2s ease-in-out",
        transitionDelay: "3s",
      },
    },
    [breakpoints.MOBILE_DEVICE]: {
      "& .button": {
        position: "fixed",
        left: 0,
        bottom: 65,
        width: "100%",
        zIndex: 2,
        height: "64px",
        transition: "all .3s ease",
        "& button": {
          width: "90%",
          margin: "8px 15px",
          transition: "bottom .3s ease, opacity 1s ease",
          transitionDelay: ".3s",
        },
      },
    },
  },
  listView: {
    border: `1px solid ${colors.darkGrey}`,
    boxSizing: "border-box",
    minHeight: "20px",
    height: "auto",
    "& svg": {
      cursor: "pointer",
    },
    "& .detail-content": {
      margin: "17px",
    },
    "& path": {
      stroke: colors.black,
    },
    "& .content": {
      margin: "17px",
      display: "flex",
      flexDirection: "row",
      "& .animation": {
        marginLeft: "12px",
      },
      "& .heading-text": {
        color: colors.primary,
        marginLeft: "12px",
        minHeight: "24px",
        height: "auto",
        width: 372,
      },
      "& .heading-building": {
        width: 430,
      },
      "& .licence": {
        width: 472,
      },
      "& .icon-text": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        "& .amount": {
          fontFamily: fonts.primary,
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "20px",
          textAlign: "right",
          letterSpacing: "0.002em",
          color: colors.hamptonsSecondary,
        },
        "& .certificates": {
          marginRight: 40,
          [breakpoints.MOBILE_DEVICE]: {
            marginRight: 0,
          },
        },
      },
    },
  },
  amountDiv: {
    border: `1px solid ${colors.darkGrey}`,
    minHeight: "50px",
    background: colors.hamptonsSecondary,
    marginBottom: "20px",
    "& .total": {
      boxShadow: "none !important",
    },
    "& .amount": {
      boxShadow: `inset 0px -0.5px 0px ${colors.hexaWhite}`,
      minHeight: "36",
      height: "auto",
      margin: "8px 16px 0 16px",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "8px",
      "& .total-text": {
        width: 372,
      },
      "& .total-amount": {
        color: colors.hexaWhite,
      },
    },
  },
});
