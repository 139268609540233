import useModal from "../../../../Hooks/useModal";
import { useToggle } from "Hooks/useToggle";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import { ReactComponent as DeletePropertyIcon } from "images/deleteProperty.svg";
import { ReactComponent as UploadIcon } from "images/uploads.svg";
import propTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import AddIcon from "v2/components/Icons/AddIcon";
import { ReactComponent as CertificateIcon } from "../../../../images/certificateNotRequired.svg";
import { ReactComponent as CheckIcon } from "../../../../images/checked.svg";
import { ReactComponent as CrossIcon } from "../../../../images/crossIcon.svg";
import { ReactComponent as InfoIcon } from "../../../../images/helpCertificate.svg";
import { ReactComponent as ActionRequiredYellow } from "images/actionRequiredYellow.svg";
import { ReactComponent as ActionRequiredRed } from "images/actionRequiredRed.svg";
import ExpandIcon from "../../ExpandIcon/ExpandIcon";
import Checkbox from "../../Form/Checkbox/Checkbox";
import Modal from "../../ModalV2/Modal";
import Typography from "../../Typography/Typography";
import UploadCertificate from "../../Upload/UploadCertificate/UploadCertificate";
import styles from "./Certificate.module.css";

const parseNodeRecursive = (children, tagName) => (
  <Typography as={tagName}>
    {children.map(({ type, ...childData }) => {
      if (type === "element") {
        return parseNodeRecursive(childData.children, childData.tagName);
      }
      return childData.value;
    })}
  </Typography>
);

const parseNode = (node) => parseNodeRecursive(node.children, node.tagName);

const Certificate = (props) => {
  const [isMainOpen, setIsMainOpen] = useToggle(false);
  const [isDocumentsSectionOpen, setIsDocumentsSectionOpen] = useToggle(false);
  const [isModalOpen, openModal, closeModal, modalData] = useModal();
  const [isInfoModalOpen, infoOpenModal, infoCloseModal, infoModalData] =
    useModal();
  const [isRequired, toggleIsRequired] = useToggle(
    props.certificate.isNotRequiredByUser
  );
  const onToggleIsRequired = () => {
    props.actions.onDocumentRequirementChange({
      value: !isRequired,
      certificateId: props.certificate.id,
    });
    toggleIsRequired();
  };

  const onSave = (data) => {
    props.actions.onCreate({
      ...data,
      certificateId: props.certificate.id,
    });
    closeModal();
  };

  const onUpdate = (data) => {
    props.actions.onUpdate(data);
    closeModal();
  };

  const onInfoOpen = (e) => {
    e.stopPropagation();
    infoOpenModal({
      name: props.info,
    });
  };

  const onUploadOpen = () => {
    openModal({
      name: props.certificate.name,
    });
  };

  const onDelete = (event, document) => {
    event.stopPropagation();
    props.actions.onDelete({
      ...document,
      certificateId: document.id,
    });
  };

  const onAddIcon = useCallback(
    (data) => {
      props.actions.onAddToOrder({
        ...data,
        certificateId: props.certificate.id,
      });
    },
    [props.certificate.id]
  );

  const current = new Date();
  const date1month = `${current.getFullYear()}-${
    current.getMonth() + 2
  }-${current.getDate()}`;
  const date3months = `${current.getFullYear()}-${
    current.getMonth() + 4
  }-${current.getDate()}`;

  const [redAction, setRedAction] = useState();
  const [yellowAction, setYellowAction] = useState();
  useEffect(() => {
    props.certificate.documents?.map((expiryDate, index) => {
      if (expiryDate.expiryDate <= date1month) {
        return setRedAction(true), setYellowAction(false);
      } else if (
        expiryDate.expiryDate > date1month &&
        expiryDate.expiryDate <= date3months
      ) {
        return setYellowAction(true), setRedAction(false);
      } else if (expiryDate.expiryDate > date3months) {
        return setYellowAction(false), setRedAction(false);
      }
    });
  }, [props.certificate.documents]);
  useWhyDidYouUpdate("Certificate", props);
  return (
    <div className={styles.container}>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <UploadCertificate
            isSave={!props.certificate.reference}
            onClose={closeModal}
            onUpdate={onUpdate}
            onSave={onSave}
            onDelete={props.actions.onDeleteFile}
            {...modalData}
          />
        </Modal>
      )}
      {isInfoModalOpen && (
        <Modal onClose={infoCloseModal}>
          <span className={styles.markdownContainer}>
            <span className={styles.topModal}>
              <Typography as="licenseName">{props.certificate.name}</Typography>
              <span className={styles.crossIcon}>
                <CrossIcon
                  onClick={infoCloseModal}
                  onKeyDown={infoCloseModal}
                />
              </span>
            </span>

            <div className={styles.modalSnippet}>
              <Typography as="licenseDescription">
                {props.certificate.description}
              </Typography>

              <ReactMarkdown
                components={{
                  // Map `h1` (`# heading`) to use `h2`s.
                  h1: ({ node }) => parseNode(node),
                  h2: ({ node }) => parseNode(node),
                  h3: ({ node }) => parseNode(node),
                  h4: ({ node }) => parseNode(node),
                  h5: ({ node }) => parseNode(node),
                  h6: ({ node }) => parseNode(node),
                  p: ({ node }) => parseNode(node),
                }}
                children={props.certificate.information}
              />
            </div>
          </span>
        </Modal>
      )}
      <header>
        <span className={styles.certification} onClick={setIsMainOpen}>
          {props.certificate.documents.length >= 1 && redAction && (
            <ActionRequiredRed />
          )}
          {props.certificate.documents.length >= 1 && yellowAction && (
            <ActionRequiredYellow />
          )}
          {props.certificate.documents.length >= 1 &&
            !yellowAction &&
            !redAction && <CheckIcon />}
          <span className={styles.licenseName}>
            <Typography as="licenseName">{props.certificate.name}</Typography>
          </span>
          {props.certificate.price === "0.00" ||
            (props.certificate.price !== null && (
              <span className={styles.licensePrice}>
                <Typography as="licensePrice">
                  {" "}
                  £{props.certificate.price}
                </Typography>
              </span>
            ))}
        </span>

        <span className={styles.actionGroup}>
          {!!isRequired && (
            <span className={styles.toolTip}>
              <CertificateIcon />
              <Typography as="licenseName">
                <span className={styles.toolTipText}>
                  Document not required
                </span>
              </Typography>
            </span>
          )}
          {props.certificate.information && (
            <span className={styles.toolTipInfo}>
              <InfoIcon onClick={onInfoOpen} />
              <span className={styles.toolTipInfoText}>Info</span>
            </span>
          )}

          {props.canUploadFiles && (
            <span className={styles.toolTip}>
              <UploadIcon
                className={styles.uploadIcon}
                onClick={onUploadOpen}
              />
              <Typography as="licenseName">
                <span className={styles.toolTipText}>Upload</span>
              </Typography>
            </span>
          )}
          {props.certificate.price !== "0.00" && (
            <AddIcon
              id="Stonly_AddToOrder"
              isSelected={props.certificate.isSelected}
              toolTip="Add to order"
              selectedToolTip="Remove from order"
              onClick={onAddIcon}
            />
          )}
          <ExpandIcon onClick={setIsMainOpen} />
        </span>
      </header>
      {!!isMainOpen && (
        <main>
          {props.certificate.description && (
            <Typography as="licenseDescription">
              {props.certificate.description}
            </Typography>
          )}

          <div className={styles.uploadSection}>
            <header>
              {!props.certificate.documents.length && (
                <Typography as="documentLabel">
                  <p>You haven’t attached any documents.</p>
                </Typography>
              )}
              {props.certificate.documents.length === 1 && (
                <Typography as="documentLabel">
                  <span
                    className={styles.expand}
                    onClick={setIsDocumentsSectionOpen}
                  >
                    {props.certificate.documents.length} document attached.
                  </span>
                </Typography>
              )}
              {props.certificate.documents.length > 1 && (
                <Typography as="documentLabel">
                  <span
                    className={styles.expand}
                    onClick={setIsDocumentsSectionOpen}
                  >
                    {props.certificate.documents.length} documents attached.
                  </span>
                </Typography>
              )}
              {!!props.certificate.documents.length && (
                <ExpandIcon onClick={setIsDocumentsSectionOpen} />
              )}
            </header>
            {isDocumentsSectionOpen && (
              <main>
                {props.certificate.documents.map((document, index) => (
                  <div className={styles.documentInfo} key={index}>
                    <span className={styles.referenceDoc}>
                      {props.certificate.documents.length >= 1 && redAction && (
                        <ActionRequiredRed />
                      )}
                      {props.certificate.documents.length >= 1 &&
                        yellowAction && <ActionRequiredYellow />}
                      {props.certificate.documents.length >= 1 &&
                        !yellowAction &&
                        !redAction && <CheckIcon />}
                      <Typography as="documentLabel">
                        {props.certificate.name} - Ref:
                      </Typography>
                      <Typography as="documentRef">
                        <span
                          className={styles.editDoc}
                          onClick={() => openModal(document)}
                        >
                          {document.reference}
                        </span>
                      </Typography>
                    </span>

                    <span className={styles.actionGroup}>
                      <span className={styles.expiryDate}>
                        <Typography as="documentLabel">
                          Expiry Date:{" "}
                        </Typography>
                        <Typography as="documentRef">
                          {new Intl.DateTimeFormat("en-GB").format(
                            new Date(document.expiryDate)
                          )}
                        </Typography>
                      </span>
                      <span onClick={() => openModal(document)}>
                        <Typography as="documentEditBtn">
                          <span className="editButton">Edit</span>
                        </Typography>
                      </span>
                      <DeletePropertyIcon
                        onClick={(event) => onDelete(event, document)}
                      />
                    </span>
                  </div>
                ))}
              </main>
            )}
          </div>
          {!props.certificate.isRequiredOptional && (
            <div className={styles.checkbox}>
              <Checkbox
                onChange={onToggleIsRequired}
                defaultChecked={isRequired}
              />
              <Typography as="documentLabel">
                I do not require the {props.certificate.name}.
              </Typography>
            </div>
          )}
        </main>
      )}
    </div>
  );
};

Certificate.propTypes = {
  certificate: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    price: propTypes.string.isRequired,
    isRequiredOptional: propTypes.bool.isRequired,
    isNotRequiredByUser: propTypes.bool,
    information: propTypes.string,
    description: propTypes.string,
    isSelected: propTypes.bool,
    documents: propTypes.arrayOf(propTypes.shape(UploadCertificate.propTypes)),
  }).isRequired,
  actions: propTypes.shape({
    onCreate: propTypes.func,
    onDelete: propTypes.func,
    onDeleteFile: propTypes.func,
    onUpdate: propTypes.func,
    onAddToOrder: propTypes.func,
    onDocumentRequirementChange: propTypes.func,
  }).isRequired,
  canUploadFiles: propTypes.bool,
};

Certificate.defaultProps = {
  actions: {
    onCreate: () => {},
    onDelete: () => {},
    onDeleteFile: () => {},
    onUpdate: () => {},
    onAddToOrder: () => {},
    onDocumentRequirementChange: () => {},
  },
};

export default Certificate;
