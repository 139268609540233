import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { WhiteBox, Button, AnimationLoading } from "components";
import { Typography } from "v2/components";
import { ReactComponent as ListIcon } from "images/license.svg";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as CertificateIcon } from "images/certificate.svg";
import { ReactComponent as CertificateIconNew } from "images/certificateNew.svg";
import {
  propertyNoFindText,
  licenseText,
  propertyTypes,
} from "constants/AppConstants";
import { useStyles } from "./styles";

const ReportList = ({
  classes,
  listData,
  loading,
  className,
  createReport,
  pdfLoading = false,
  propertyType = "Flat",
}) => {
  let haveNoAdditionalLicense = false;
  const [listDocuments, setListDocuments] = useState({
    certificates: [],
    licences: [],
    planning: [],
    totalCost: "XX.XX",
  });
  useEffect(() => {
    if (listData.licences) {
      setListDocuments({ ...listData });
    }
  }, [listData]);
  const showList = (key, isShow) => {
    listDocuments.licences[key].isShow = isShow;
    setListDocuments({ ...listDocuments });
  };
  const { certificates, licences, planning, totalCost } = listDocuments;
  const checkLicences = (licences) => {
    const licenceS257 = licences.some(
      (item) => item.licenceType === propertyTypes.licenceS257
    );
    return licenceS257 && licences.length === 1;
  };
  return (
    <div className={className}>
      <WhiteBox>
        <div className={classes.main}>
          <div className={classes.header}>
            <Typography as="subtitle" className="heading-text">
              Property Report
            </Typography>
            <div className="button">
              <Button
                className={pdfLoading ? "pdf-creating" : null}
                disable={
                  listDocuments.licences.length === 0 &&
                  listDocuments.certificates.length === 0
                }
                onClick={createReport}
              >
                {pdfLoading ? "Preparing..." : "Create report"}
              </Button>
            </div>
          </div>
          <div className="amount-div">
            <Typography as="subtitle" className="heading-text">
              Prices given below have been estimated with the advice of local
              experts to help you with your costings
            </Typography>
          </div>

          {loading && (
            <div className={classes.listView}>
              <div className="content">
                <ListIcon className="list-icon" />
                <div className="icon-text">
                  <AnimationLoading
                    height="15px"
                    width="200px"
                    className="animation"
                  />
                </div>
              </div>
              <div className="content">
                <CertificateIcon className="list-icon" />
                <div className="icon-text">
                  <AnimationLoading
                    height="15px"
                    width="100px"
                    className="animation"
                  />
                </div>
              </div>
            </div>
          )}
          {(licences.length === 0 || checkLicences(licences)) && !loading && (
            <>
              {propertyType !== propertyTypes.Building && (
                <div className={classes.listView}>
                  <div className="content">
                    <ListIcon className="list-icon" />
                    <div className="icon-text">
                      <Typography
                        as="subtitle"
                        className="heading-text licence"
                      >
                        {propertyNoFindText[propertyType]}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {!loading &&
            licences.map((value, key) => {
              if (value.licenceType === propertyTypes.licenceS257) {
                haveNoAdditionalLicense = true;
              }
              return (
                <div className={classes.listView} key={key}>
                  <div className="content">
                    <ListIcon className="list-icon" />
                    <div className="icon-text">
                      <Typography as="subtitle" className="heading-text">
                        {value.licenceType !== propertyTypes.licenceS257
                          ? `This property could need a ${value.licenceType} licence`
                          : licenseText(value.licenceType)[propertyType][0]}
                      </Typography>
                      <span className="amount">
                        {value.cost ? `~ £ ${value.cost}` : "-"}
                      </span>
                      {value.isShow && (
                        <ArrowUp onClick={() => showList(key, false)} />
                      )}
                      {!value.isShow && (
                        <ArrowDown onClick={() => showList(key, true)} />
                      )}
                    </div>
                  </div>
                  {value.isShow && (
                    <div className="detail-content">
                      {value.licenceType === propertyTypes.licenceS257 && (
                        <>
                          <Typography>
                            It is the freeholders responsibility to get
                            additional HMO licence for the building if the
                            following conditions are met.
                          </Typography>
                          {value.guideline.map((rule, keys) => (
                            <Typography key={keys}>-{rule}</Typography>
                          ))}
                        </>
                      )}
                      <Typography>
                        {value.licenceType === "S257"
                          ? `Pre-application the freeholder will need the following
													documents.`
                          : `Your property may need this. Pre-application you will need
													the following certificates.`}
                      </Typography>
                      {value.documents.map((label, keys) => (
                        <Typography key={keys}>-{label.label}</Typography>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          {!loading &&
            propertyType !== "House" &&
            !haveNoAdditionalLicense &&
            licenseText(0)[`No${propertyType}`].map((value, key) => (
              <React.Fragment key={key}>
                <div className={classes.listView}>
                  <div className="content">
                    <ListIcon className="list-icon" />
                    <div className="icon-text">
                      <Typography
                        as="subtitle"
                        className="heading-text licence"
                      >
                        {value}
                      </Typography>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          {!loading &&
            propertyType === propertyTypes.Building &&
            licences.length > 0 && (
              <div className={classes.listView}>
                <div className="content">
                  <ListIcon className="list-icon" />
                  <div className="icon-text">
                    <Typography as="subtitle" className="heading-text licence">
                      {licenseText(0)[propertyType][1]}
                    </Typography>
                  </div>
                </div>
              </div>
            )}

          {!loading &&
            (planning.length === 0 ? (
              <div className={classes.listView}>
                <div className="content">
                  <CertificateIconNew className="list-icon" />
                  <div className="icon-text">
                    <Typography as="subtitle" className="heading-text">
                      Planning permission may not be required
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              planning.map((value, key) => (
                <div className={classes.listView} key={`${key}-certificates`}>
                  <div className="content">
                    <CertificateIconNew className="list-icon" />
                    <div className="icon-text">
                      <Typography as="subtitle" className="heading-text">
                        {`${value.label} Planning permission may be required`}
                      </Typography>
                      <span className="amount certificates">
                        {value.cost ? `~ £ ${value.cost}` : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ))}
          {!loading &&
            certificates.map((value, key) => (
              <div className={classes.listView} key={`${key}-certificates`}>
                <div className="content">
                  <CertificateIcon className="list-icon" />
                  <div className="icon-text">
                    <Typography as="subtitle" className="heading-text">
                      {value.label}
                    </Typography>
                    <span className="amount certificates">
                      {value.price ? `~ £ ${value.price}` : "-"}
                    </span>
                  </div>
                </div>
              </div>
            ))}

          {!loading &&
            (listDocuments.licences.length !== 0 ||
              listDocuments.certificates.length !== 0) && (
              <div className={classes.amountDiv}>
                <div className="total amount">
                  <Typography as="subtitle" className="total-text">
                    Guideline costing for renting this property
                  </Typography>
                  <div className="total-amount">£{totalCost}</div>
                </div>
              </div>
            )}
        </div>
      </WhiteBox>
    </div>
  );
};

ReportList.propTypes = {
  listData: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  createReport: PropTypes.func.isRequired,
  pdfLoading: PropTypes.bool,
  propertyType: PropTypes.string,
};

export default withStyles(useStyles)(memo(ReportList));
