import PropTypes from "prop-types";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkWebInIframe, getLoginInfo, getWebInfo, mixPanel } from "utils";

mixPanel.init();
const MixPanelTracker = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      const { email, userId } = getLoginInfo();
      const { name } = getWebInfo();
      mixPanel.identify(userId);
      mixPanel.people.set({
        email,
      });
      mixPanel.track("page-view", {
        embedded: checkWebInIframe,
        Customer: name,
        email,
        page: history.location.state?.page || history.location.pathname,
      });
    });
  }, []);
  return <>{children}</>;
};
MixPanelTracker.propTypes = {
  children: PropTypes.any.isRequired,
};
export default MixPanelTracker;
