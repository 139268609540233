import React, { memo } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { styles } from "./styles";
import { Typography } from "v2/components";

export const List = React.forwardRef(
  (
    {
      classes,
      options,
      name,
      onClick,
      selected,
      className,
      error,
      noResults,
      isLoading,
      ...props
    },
    ref
  ) => (
    <>
      <ul
        {...props}
        role="menu"
        defaultValue={selected}
        className={`${classes.list} ${className}`}
      >
        {!isLoading &&
          options.map(({ value, key }, index) => (
            <li key={index}>
              <div
                className="list"
                role="button"
                onClick={() => onClick(key, index)}
                tabIndex={0}
                onKeyPress={() => onClick(key, index)}
                ref={ref}
              >
                {value}
              </div>
            </li>
          ))}
        {isLoading && (
          <li>
            <Typography className="list" as="subtitle">
              Loading...
            </Typography>
          </li>
        )}
        {!isLoading && noResults && (
          <li>
            <Typography className="list" as="subtitle">
              No results found.
            </Typography>
          </li>
        )}
      </ul>
      {error && <span className={classes.errorText}>{error}</span>}
    </>
  )
);

List.propTypes = {
  type: propTypes.string,
  selected: propTypes.any,
  name: propTypes.string,
  classes: propTypes.object,
  options: propTypes.array,
  onClick: propTypes.func,
  selectedValue: propTypes.string,
  className: propTypes.string,
  isLoading: propTypes.bool,
  noResults: propTypes.bool,
};

List.defaultProps = {
  selected: null,
  options: [],
  name: "name",
  classes: {},
  onChange: () => null,
  className: "",
  isLoading: false,
  noResults: false,
};

export default withStyles(styles)(memo(List));
