import React, { useState, memo, useEffect } from "react";
import PropType from "prop-types";
import withStyle from "react-jss";
import { Input } from "v2/components";
import { List } from "components";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as ArrowUP } from "images/arrowup.svg";
import { Styles } from "./Styles";

const SelectSearch = ({
  options = [],
  onChange,
  classes,
  listClassName = "",
  className = "",
  ...props
}) => {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [selectOption, setSelectOption] = useState([...options]);
  useEffect(() => {
    setSelectOption([...options]);
  }, [options]);
  const handleChange = (id) => {
    setInputValue(selectOption.find((value) => value.key === id).value);
    setShow(false);
  };
  const hideDropDown = () => {
    setTimeout(() => {
      setShow(false);
    }, 100);
  };
  const handleIuput = ({ target: { value } }) => {
    const newArray = [...selectOption];
    setInputValue(value);
    if (value) {
      return setSelectOption([
        ...newArray.filter((val) =>
          val.value.toLowerCase().includes(value.toLowerCase())
        ),
      ]);
    }
    setSelectOption([...options]);
  };
  return (
    <div className={classes.main}>
      <div className="input-div-inactive">
        <Input
          {...props}
          value={inputValue}
          className={className}
          onChange={handleIuput}
          onFocus={() => setShow(true)}
          onBlur={hideDropDown}
          autoComplete="off"
        />
        {!show && <ArrowDown className="arrow" onClick={() => setShow(true)} />}
        {show && <ArrowUP className="arrow" onClick={() => setShow(false)} />}
      </div>

      {show && selectOption.length > 0 && (
        <List
          className={listClassName}
          options={selectOption}
          onClick={handleChange}
        />
      )}
    </div>
  );
};
SelectSearch.propType = {
  options: PropType.array,
  onChange: PropType.func.isRequired,
  classes: PropType.object.isRequired,
  className: PropType.string,
  listClassName: PropType.string,
};

export default withStyle(Styles)(memo(SelectSearch));
