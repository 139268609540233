import { createUseStyles } from "react-jss";
import Theme from "theme";

const { breakpoints } = Theme;
export const useStyles = createUseStyles({
  main: {
    width: "416px",
    [breakpoints.RESPONSIVE]: {
      width: "100%",
    },
    minHeight: "72px",
    height: "auto",
    "&  .content": {
      padding: "27px 0 0 24px",
    },
  },
  ListIcon: {
    margin: "27.67px 0 0 26.05px",
    "& svg": {
      height: "16.67",
      width: "15",
    },
    "& path": {
      stroke: ({ colors: { black } }) => black,
    },
    "& rect": {
      stroke: ({ colors: { black } }) => black,
    },
  },
  heading: {
    margin: "0 0 0 18.05px",
    paddingBottom: "30px",
    display: "flex",
    "& .heading-subtitle": {
      width: 337,
      minHeight: 40,
      height: "auto",
    },
    [breakpoints.RESPONSIVE]: {
      width: "100%",
      justifyContent: "space-between",
    },
    [breakpoints.MOBILE_DEVICE]: {
      width: "87%",
      marginBottom: "20px",
    },
    "& path": {
      stroke: ({ colors: { black } }) => black,
    },
    "& rect": {
      stroke: ({ colors: { black } }) => black,
    },
    "& svg": {
      cursor: "pointer",
      marginLeft: "13px",
      [breakpoints.MOBILE_DEVICE]: {
        marginRight: "44px",
      },
      [breakpoints.IPAD]: {
        marginRight: "29px",
        marginLeft: "0px",
      },
      "&:focus": {
        outline: "none",
      },
    },
  },
  headingDiv: {
    display: "flex",
  },
  buttonDiv: {
    marginTop: "26px",
    paddingBottom: "24px",
    "& button": {
      border: ({ colors: { hamptonsSecondary } }) =>
        `1px solid ${hamptonsSecondary}`,
    },
  },
  licenseList: {
    marginLeft: "15px",
    paddingBottom: "3px",
    "& .details": {
      [breakpoints.MOBILE_DEVICE]: {
        width: "87%",
      },
      [breakpoints.RESPONSIVE]: {
        width: "100%",
      },
      display: "flex",
      minHeight: "20px",
      height: "auto",
      width: "373px",
      marginBottom: "28px",
      fontFamily: ({ fonts: { primary } }) => primary,
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      "&::first-letter": {
        fontWeight: "bold",
      },
    },
    "& .last-div": {
      boxShadow: "none !important",
    },
  },
});
