import Colors from "theme/colors";

export const hmoPopData = {
  heading:
    "To determine what is an HMO we need to start with what ‘HMO’ stands for, ie. House in Multiple Occupancy.",
  subheading: {
    first: {
      heading: "England and Wales",
      text: [
        "The standard definition of a section 254 HMO is:",
        "• 3 or more tenants",
        "• More than 1 household",
        "• Generally speaking that share facilities (kitchen, bathroom etc) though legally should meet standard test, self-contained flat test or converted building test HMO definition.",
        "To classify as a 254 HMO a property does not need to be converted or adapted in any way.",
        "",
        "Section 257 HMOs",
        "Refers to buildings that:",
        "• Have been converted into self-contained flats; and",
        "• Less than two-thirds of the flats are owner-occupied; and",
        "• The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply. ",
        "If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.",
        "",
        "The full definition of all HMO’s can be found in the Housing Act 2004 Sections 254 to 260.",
      ],
    },
    second: {
      heading: "Scotland",
      text: [
        "An HMO (House in Multiple Occupancy) is:",
        "",
        "three or more individuals (“qualifying persons”) from more than two families reside in the property as their main residence and share some facilities, for example a kitchen and/or a bathroom.",
        "",
        "Payment of rent is not a prerequisite for a property to qualify as a HMO. Emergency accommodation, such as homeless units, can be classified as HMOs if the above criterion applies.",
        "",
        "Housing (Scotland) Act 2006.",
        "",
        "Owner Occupiers:",
        "Owner occupiers do not count as “qualifying persons” for the purposes of calculating whether a licence is required. Accommodation occupied by the owner and two other unrelated persons won’t be considered a HMO and will not require a HMO licence. A property occupied by the owner and three other unrelated persons will require a licence.",
      ],
    },
  },
  notes: `Note – some councils operate their Additional licensing scheme (s257) if the property has less than 50%
	of the flats owner occupied.`,
  hmoText: {
    url: "https://www.legislation.gov.uk/ukpga/2004/34/part/7/crossheading/meaning-of-house-in-multiple-occupation",
    first: "The full definition of all HMO’s can be found in the",
    last: "Sections 254 to 260.",
    urlText: "Housing Act 2004",
  },
};
export const licensingPopData = {
  headerText: {
    header: "England and Wales",
    subHeader: "Licensing",
    firstText:
      "This is the Local Authority’s process of improving living standards in rental accommodation and proving that landlords, and property managers, are ‘fit and proper’ persons to operate the property. The type of licence a property requires varies depending on a number of different factors, including the number of tenants and the council your property is located in.",
    secondText: 'HMO is "House in Multiple Occupation".',
  },
  subheading: {
    first: {
      heading: "Mandatory HMO licensing",
      text: "Generally applicable to (Section 254) HMOs with 5 or more tenants, from more than 1 household sharing facilities.",
    },
    second: {
      heading: "Additional HMO licensing",
      text: "Any (Section 254) HMO that doesn't fall under the Mandatory scheme may need this licence and certain types of converted buildings (Section 257). This is not an England & Wales wide scheme and only specific boroughs have this in place.",
    },
    third: {
      heading: "Selective licensing",
      text: "If a property is in the selected area, it may require this licence, no matter the occupancy. This is not an England & Wales wide scheme and only specific boroughs have it in place.",
      extraText:
        "Failure to have the correct licensing in place can result in fines of up to £30,000, criminal prosecution, rent repayment order and even being added to rogue landlord and agent database.",
    },
  },
  scotlandHeader: {
    title: "Scotland",
    subText: [
      'HMO is "House in Multiple Occupation".',
      "",
      "HMO licensing",
      "Generally applies to all HMOs which in Scotland 3 people from 3 or more families, sharing facilities in the property.",
    ],
  },
};

export const planningPopData = {
  title: "Planning Permission",
  england: {
    header: "England and Wales",
    sections: [
      {
        heading: "Planning permission",
        paragraphs: [
          "This is the legal process of determining whether proposed development, or building change of use, should be permitted. In this process we are looking at two specific types of planning permission, SuI Generis and Article 4 (for small HMOs).",
        ],
      },
      {
        heading: "Article 4 Directive for (Section 254) HMOs ",
        paragraphs: [
          "For smaller (Section 254) HMOs (3 to 6 tenants) a change of use from a single dwelling house (use class C3) to a small HMO shared by 3 to 6 unrelated residents (use class C4) often does not need planning permission as change happens under permitted development.",
          "However, local planning authorities have the power to bring in an Article 4 direction under the Town and Country Planning Order 2015. This removes the permitted development rights and therefore requires landlord to gain planning permission to convert their property from C3 to C4.",
          "Article 4 can be applied borough wide or to specific areas/streets/wards within a borough and differs from council to council.",
          "(This applies in England, Wales always requires a planning application for all change of use from C3 to C4 (Section 254) HMO's).",
          "If you are already running an HMO before this directive comes in then you may not need to apply for Article 4 planning permission, though always consult an expert.",
        ],
      },
      {
        heading: "Immediate and non-immediate schemes",
        paragraphs: [
          "https://www.legislation.gov.uk/uksi/2015/596/schedule/3/made?view=plain",
          "Usually Planning departments consult them implement but they can implement immediately though this only lasts for 6 months then they must follow due process to extend.",
        ],
      },
      {
        heading: "Sui Generis planning permission",
        paragraphs: [
          "This will generally always be required for large (Section 254) HMOs of 7 or more tenants that has had “material change of use”.",
        ],
      },
      {
        heading: "Lawful Development (Lawful Use)",
        paragraphs: [
          "What is a lawful development certificate?",
          "There are 2 types of lawful development certificate. A local planning authority can grant a certificate confirming that:",
          `(a) an existing use of land, or some operational development, or some activity being carried out in breach of a planning condition, is lawful for planning purposes under section 191 of the Town and Country Planning Act 1990; or`,
          `(b) a proposed use of buildings or other land, or some operations proposed to be carried out in, on, over or under land, would be lawful for planning purposes under section 192 of the Town and Country Planning Act 1990.`,
          "If you want to be certain that the existing use of a building is lawful for planning purposes or that your proposal does not require planning permission, you can apply for a 'Lawful Development Certificate' (LDC).",
          "",
          "Example could be a property that is being ran as an HMO but has been running for a long time that means a LDC can be applied for and you do not need to apply for Article 4or Sui Generis planning permission. Speak to an expert for specific advice to your property.",
        ],
        links: [
          {
            path: "https://www.gov.uk/guidance/lawful-development-certificates",
            text: "https://www.gov.uk/guidance/lawful-development-certificates",
          },
        ],
      },
    ],
  },
  scotland: {
    header: "Scotland",
    sections: [
      {
        heading: "Coming soon!",
        paragraphs: [],
      },
    ],
  },
};

export const addPropertyHeading =
  "Confirm the property details below to find out the compliance needs";

export const propertyCategory = [
  {
    label: "Single Dwelling",
    value: "Single Dwelling",
  },
  {
    label: "254 HMO",
    value: "254 HMO",
  },
  {
    label: "257 HMO",
    value: "257 HMO",
  },
  {
    label: "Licensable HMO",
    value: "Licensable HMO",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
];
export const packagesList = {
  5: {
    key: 5,
    value: "LICENCE APPLICATION",
    priceInLondon: "475",
    priceRestOfUk: "375",
    desc: `Taking the Licensing application process off your hands. (Not advised for HMOs, see Gold package+)
What's included? Licence application service (Includes Fire Floor plan), Inspection of property collecting data for
 application (up to 5 tenants)`,
  },
  4: {
    key: 4,
    value: "COMPLIANCE REPORT",
    priceInLondon: "525",
    priceRestOfUk: "425",
    desc: `Want to know the most likely outcome of going through licensing specific to your property?
What's included? Licensing and Compliance Report (Floor plan not included) - Including case review (up to 5 tenants)`,
  },
  3: {
    key: 3,
    value: "GOLD",
    priceInLondon: "695",
    priceRestOfUk: "595",
    desc: `Gold expert service with licensing and compliance.
Licence application service (Includes Fire Floor plan), Inspection of property collecting data for application,
 Licensing and Compliance Report (up to 5 tenants)`,
  },
  2: {
    key: 2,
    value: "PLATINUM",
    priceInLondon: "795",
    priceRestOfUk: "695",
    desc: `Our most popular Licensing package is designed to take the entire licensing consultancy off your hands.
         What’s included? Licence application service (Includes Fire Floor plan), inspection of property collecting
          data for application, Licensing and Compliance Report, additional 1 hour expert support, in minimum 30min
          sessions/calls (up to 5 tenants)`,
  },
  1: {
    key: 1,
    value: "DIAMOND",
    priceInLondon: "1195",
    priceRestOfUk: "995",
    desc: `Our top tier Licensing package designed for more complex cases leading into simple redesigns.
        What's included? Licence application service (Includes Fire Floor plan), inspection of property
        collecting data for application, Licensing and Compliance Report, additional 3 hours expert support,
         chasing council for licence until its granted, providing updates to client, and 360 Video tour of
         the property. Minimum 30min sessions/calls (up to 5 tenants)`,
  },
};

export const propertyType = [
  {
    label: "Flat",
    value: "Flat",
  },
  {
    label: "House",
    value: "House",
  },
  {
    label: "Building",
    value: "Building",
  },
];

export const tenancyType = [
  {
    label: "Unknown",
    value: "Unknown",
  },
  {
    label: "Individual tenancies",
    value: "Individual tenancies",
  },
  {
    label: "Shared tenancy (AST)",
    value: "Shared tenancy (AST)",
  },
  {
    label: "Corporate tenancy",
    value: "Corporate tenancy",
  },
  {
    label: "Serviced accommodation",
    value: "Serviced accommodation",
  },
];

export const commercialPremisise = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
];

export const propertyTypeData = {
  propertyType: {
    first: {
      heading: "Property type",
      subHeading: "What is a Flat?",
      text: [
        "A Flat is a self contained housing unit, that occupies only part of a building.",
      ],
    },
    second: {
      heading: "What is a House?",
      subHeading: "A House is a single residential unit.",
      text: [],
    },
    third: {
      heading: "What is a Building?",
      subHeading:
        "A Building can contain multiple housing or commercial units.",
      text: [],
    },
  },
  propertyCategory: {
    first: {
      heading: "What is a Single Dwelling?",
      subHeading: "A family or tenants sharing that do not form an HMO.",
      text: [],
    },
    second: {
      heading: "What does HMO stand for?",
      subHeading:
        "House in Multiple Occupancy or House of Multiple Occupation.",
      text: [],
    },
    third: {
      heading: "What is a Section S254 HMO?",
      subHeading: "The standard definition of a section 254 HMO is:",
      text: [
        "• 3 or more tenants",
        "• More than 1 household",
        "• Generally speaking that share facilities (kitchen, bathroom etc) though legally should meet standard test, self-contained flat test or converted building test HMO definition.",
      ],
    },
    fouth: {
      heading: "What is a Section S257 HMO?",
      subHeading: "Refers to buildings that:",
      link: {
        text: "The full definition of all HMO’s can be found in the",
        linkText: "Housing Act 2004 Sections 254 to 260",
        url: "https://www.legislation.gov.uk/ukpga/2004/34/part/7/crossheading/meaning-of-house-in-multiple-occupation",
      },
      text: [
        "• Have been converted into self-contained flats; and",
        "• Less than two-thirds of the flats are owner-occupied; and",
        "• The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply. ",
        "If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.",
      ],
    },
    fifth: {
      heading: "What is a Licensable HMO?",
      subHeading: "If you know the property is an HMO and requires a licence",
      text: [],
    },
  },
  commercialPremises: {
    first: {
      heading: "Commercial premises",
      subHeading:
        "Does the building have a commercial unit on the premises? e.g. a cafe/restaurant/gym on any floor of the building, including ground floor.",
      text: [],
    },
  },
  tenancyType: {
    first: {
      heading: "What is an Individual Tenancy?",
      subHeading:
        "Individual Tenancies are where the property is let on a room by room basis and the landlord has an individual agreement with each tenant.",
      text: [],
    },
    second: {
      heading: "What is a Shared Tenancy (AST)?",
      subHeading:
        "Shared Tenancy, or single AST, is where the whole property is let to all tenants on the same agreement, normally to a family or group of friends living together.",
      text: [],
    },
    third: {
      heading: "What is a Corporate Tenancy?",
      subHeading:
        "A Corporate Tenancy is when a company takes on a residential tenancy agreement as the tenant, rather than an individual.",
      text: [],
    },
  },
  numberStoreys: {
    first: {
      heading: "Number of storeys",
      subHeading: 'What is a "Storey"?',
      text: [
        "A storey is any floor that is in use for living purposes and can include attics, cellars and mezzanines if used for living purposes.",
      ],
    },
    second: {
      heading: "How to count storeys by Property Type",
      subHeading: "",
      text: [
        "Flat",
        "This is the storeys inside of the flat. For licensing purposes bear in mind that if there is a commercial premises in the building then they ask you to add this on as a storey too. (We have not added this storey on for you in the commercial premises question)",
      ],
    },
    third: {
      heading: "",
      subHeading: "",
      text: ["House", "This is the total amount of storey's in the house."],
    },
    fourth: {
      heading: "",
      subHeading: "",
      text: [
        "Building",
        "This is the total number of storey's within the whole building. You must include commercial premises, like shops and restaurants, if located in the building.",
      ],
    },
    fifth: {
      heading: "",
      subheading: "",
      text: [
        "Please review the legislation or speak to an expert if you are unsure https://www.legislation.gov.uk/uksi/2006/371/made",
      ],
    },
    sixth: {
      heading: "",
      subheading: "FAQ",
      text: [
        "Does an external terrace count as a storey? No not as a storey, unless its adapted for use as living accommodation.",
        "What if the commercial premises is not directly above, or below, the flat but in the same building, does it count or not? Yes it does.",
      ],
    },
  },
  numberRooms: {
    first: {
      heading: "Number of bedrooms",
      subHeading: "",
      text: [
        "If the property is a flat/house, Bedrooms are the number rooms used for sleeping within the property.",
        "If the property is a building, Units are the number of self-contained flats within the building.",
      ],
    },
  },
  numberPeople: {
    first: {
      heading: "Number of tenants",
      subHeading:
        "How many people live in the property as their primary residence? This can also include children, carers and domestic staff in the property.",
      text: [],
    },
  },
  living: {
    first: {
      heading: "Do you know who will be living in the property?",
      subHeading: `This is normally through a signed tenancy agreement with names and contact details of
      those that will be occupying the property.`,
      text: [],
    },
  },
  houseHoldData: {
    first: {
      heading: "What is a “household”? ",
      subHeading: "",
      text: [
        "A single person, or members of the same family who live together. Each tenant from a separate family is classed as a separate household.",
      ],
    },
    second: {
      heading: "What constitutes a “family”?",
      text: [
        "Tenants who are married or living together as a couple, form one family. This includes couples in same-sex relationships and their children.",
        "All relatives & half-relatives count as the same family e.g. grandparents, step-parents, aunts, uncles, siblings & step-siblings. A half-blood relationship is treated the same as full blood and a stepchild is treated the same as a child.",
        "Any household domestic staff are included in the household if they do not pay rent while living with the person(s) they are working for, this may also depend on their contract terms.",
      ],
    },
  },
};
export const propertyFields = {
  propertyType: "propertyType",
  houseHoldData: "houseHoldData",
  numberPeople: "numberPeople",
  numberRooms: "numberRooms",
  numberStoreys: "numberStoreys",
  tenancyType: "tenancyType",
  commercialPremises: "commercialPremises",
  propertyCategory: "propertyCategory",
  living: "living",
};
export const houseHoldData = {
  heading: "What is a “household”? ",
  subheading: {
    first: {
      text: `A single person, or members of the same family who live together.
				Each tenant from a separate family is classed as a separate household.`,
    },
    second: {
      heading: "What constitutes a “family”?",
      text: [
        `Tenants who are married or living together as a couple,
				form one family. This includes couples in same-sex relationships and their children.`,
        `All relatives & half-relatives count as the same family
				e.g. grandparents, step-parents, aunts, uncles, siblings & step-siblings.`,
        `Any household domestic staff are included in the household
				 if they do not pay rent while living with the person(s)
				 they are working for, this may also depend on their contract terms`,
      ],
    },
  },
};

export const EmbeddingText = {
  title:
    "Sorry! You don’t have access to embed this site with your subscription",
  subTitle:
    "If you think this is incorrect or if you wish to upgrade your subscription, please contact us at",
  email: "info@goyuno.com",
  emailSubject: "Embedded Property Compliance Tool",
};
export const LeadgenData = {
  heading:
    "Enter your details to get your property compliance and planning report",
};

export const yunoWebUrl = "https://goyuno.com";
export const propertyNoFindText = {
  Flat: [
    `Great, it looks like there is no property/HMO licensing required for this property.`,
    `Just because you may not need licensing doesn't mean there are no further safety works to proceed with, please review the full report for more details.`,
  ],
  House: [
    `Based on the property details provided, this property
	probably doesn't need a HMO/Property licence`,
  ],
  Building: [],
};

export const licenseText = (licenseName) => ({
  Flat: [
    `The building this flat is in may need an Additional (${licenseName}) licence`,
  ],
  Building: [
    ``,
    `The flats internal to this building may need their own licences.
						Do a search for a specific flat to get their report.`,
  ],
  NoFlat: [
    "The building this flat is in may not need an Additional (S257) licence",
  ],
  NoBuilding: [
    "Great, it looks like there is no Additional (S257) licence required for this building.",
    `Just because you may not need licensing for the building doesn't mean there are no further safety works to proceed with, please review the full report for more details.`,
    `You may be running a Section 257 HMO`,
  ],
  House: [""],
});

export const leftImage = process.env.REACT_APP_LOGIN;
export const signupImage = process.env.REACT_APP_SIGNUPIMAGE;
export const propertyTypes = {
  Building: "Building",
  House: "House",
  Flat: "Flat",
  licenceS257: "S257",
};

export const propertyStatus = [
  {
    key: "Uncompliant",
    value: "Uncompliant",
    color: Colors.errorRed,
    second: Colors.errorRed,
  },
  {
    key: "Action Required",
    value: "Action Required",
    color: Colors.label2,
    second: Colors.label2,
  },
  {
    key: "Work in progress",
    value: "Work in progress",
    color: Colors.bg3,
    second: Colors.primary,
  },
  {
    key: "Compliant",
    value: "Compliant",
    color: Colors.bg6,
    second: Colors.secondary,
  },
];
export const propertyColors = {
  Uncompliant: {
    first: Colors.errorRed,
    second: Colors.errorRed,
    color: Colors.errorRed,
  },
  Compliant: {
    first: Colors.hamptonsNewPrimary,
    second: Colors.hamptonsNewPrimary,
    color: Colors.hamptonsNewPrimary,
  },
  Work_in_progress: {
    first: Colors.workInProgressDocumentColor,
    second: Colors.workInProgressDocumentColor,
    color: Colors.workInProgressDocumentColor,
  },
  Items_outstanding: {
    first: Colors.bg1,
    second: Colors.soildBg1,
    color: Colors.soildBg1,
  },
  Action_required: {
    first: Colors.label2,
    second: Colors.label2,
    color: Colors.label2,
  },
  Application_submitted: {
    first: Colors.bg7,
    second: Colors.label2,
    color: Colors.bg7,
  },
  UnCompliant: {
    first: Colors.unCompaintColor,
    second: Colors.unCompaintColor,
    color: Colors.errorRed,
  },
  Documents_expiring_soon: {
    first: Colors.bg5,
    second: Colors.grey,
    color: Colors.bg5,
  },
  extra: { first: Colors.bg5, second: Colors.grey, color: Colors.bg5 },
};

export const roles = {
  customerUser: "customer user",
  operator: "operator",
  landlord: "landlord",
};

export const allLicense = {
  Mandatory: "Mandatory",
  Additional: "Additional",
  S257: "S257",
};

export const councilText = {
  // eslint-disable-next-line max-len
  first:
    "Looks like you are near the edge of a local scheme, please check the pin location on the map in the property info tab below (double-click the marker to move it) ",
  second: `Your compliance check request has been submitted.
  We'll notify you once it's completed.`,
  third: `Your compliance request for has been
   completed. The data for this property is correct`,
};

export const termsAndConditionsURL = "https://goyuno.com/terms-conditions/";
export const privacyPolicyURL = "https://goyuno.com/privacy-policy/";
export const goYunoLink = "https://goyuno.com";
