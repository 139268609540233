import { savePropertyFreeHolder, sentNotification } from "Apis";
import { CertificationModal, Form, Spinner } from "components";
import propTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";
import withStyles from "react-jss";
import swal from "sweetalert";
import {
  checkAllRequiredFieldsWithKey,
  checkRequiredField,
  mixPanel,
  validateEmail,
  validatePhone,
} from "utils";
import { Button, Input, Row2Items, SelectInput } from "v2/components";
import { formErrorForm, options } from "./constants";
import Styles from "./styles";

const LeaseholderCertificationForm = ({
  classes,
  className = "",
  buttonText = "Send Notification",
  propertyId,
  propertyType,
  propertyHolders = [],
  isLogout = false,
}) => {
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([{ ...formErrorForm }]);
  const [values, setValues] = useState([]);
  useEffect(() => {
    setValues([{ ...formErrorForm }]);
    if (propertyHolders.length > 0) {
      const errorForm = [];
      propertyHolders.forEach(() => {
        errorForm.push(formErrorForm);
      });
      setErrors(errorForm);
      setValues([...propertyHolders]);
    }
  }, []);
  const handleInput = useCallback(
    (name, value, index) => {
      const formValue = JSON.parse(JSON.stringify(values));
      formValue[index][name] = value;
      setValues([...formValue]);
    },
    [values, setValues]
  );
  const addForm = (isAdd = true, index = 0) => {
    const formErrors = [...errors];
    const formValue = [...values];
    if (isAdd) {
      formErrors.push(formErrorForm);
      formValue.push(formErrorForm);
    } else {
      formErrors.splice(index, 1);
      formValue.splice(index, 1);
    }
    setErrors([...formErrors]);
    setValues([...formValue]);
  };
  const checkError = (name, value, index) => {
    const formErrors = [...errors];
    const checkError = { ...formErrors[index] };
    switch (name) {
      case "email":
        Object.assign(checkError, validateEmail(name, value));
        break;
      case "contactNumber":
        Object.assign(checkError, validatePhone(name, value));
        break;
      default:
        Object.assign(checkError, checkRequiredField(name, value));
        break;
    }
    formErrors[index] = checkError;
    setErrors([...formErrors]);
  };
  const removeError = (name, index) => {
    const formErrors = [...errors];
    const checkError = { ...formErrors[index] };
    checkError[name] = "";
    formErrors[index] = { ...checkError };
    setErrors([...formErrors]);
  };
  const checkAllError = () => {
    const formValues = [...values];
    const allErrors = [];
    let isError = true;
    formValues.forEach((value) => {
      const errorObject = {
        ...checkAllRequiredFieldsWithKey(formErrorForm, value),
      };
      allErrors.push(errorObject);
      if (isError) {
        isError = Object.values(errorObject).every((errorMsg) => !errorMsg);
      }
    });
    setErrors([...allErrors]);
    return isError;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkAllError()) {
      setLoading(true);
      const allForms = [...values];
      const allPromise = allForms.map((value) =>
        savePropertyFreeHolder(propertyId, value)
      );
      Promise.all(allPromise)
        .then(async () => {
          await sentNotification(propertyId);
          mixPanel.track("Notify free/leaseholders", {
            propertyId,
          });
          setLoading(false);
          closePopup();
        })
        .catch(({ message }) => {
          setLoading(false);
          swal("some thing went wrong", message, "error");
        });
    }
  };
  const closePopup = useCallback(() => {
    setPopup((value) => !value);
  }, [setPopup]);
  return (
    <>
      <CertificationModal
        isOpen={popup}
        propertyType={propertyType}
        onClose={closePopup}
      />
      <Form onSubmit={handleSubmit} className={`${classes.form} modal-form`}>
        {loading && <Spinner />}
        {values.map((form, index) => (
          <>
            {index !== 0 && <hr className="line-divide" />}
            <Row2Items>
              <SelectInput
                label="Select Type"
                readOnly={isLogout}
                className="select"
                listClassName="list"
                name="type"
                selected={form.type}
                options={options}
                onOptionChange={({ value }) =>
                  handleInput("type", value, index)
                }
                onFocus={({ label }) => removeError(label, index)}
                onBlur={({ value }) => checkError("type", value, index)}
                error={false}
              />
              <Input
                label="Full Name"
                readOnly={isLogout}
                type="text"
                name="fullName"
                value={form.fullName}
                onChange={({ target: { name, value } }) =>
                  handleInput(name, value, index)
                }
                onFocus={({ target: { name } }) => removeError(name, index)}
                onBlur={({ target: { name, value } }) =>
                  checkError(name, value, index)
                }
                error={errors[index].fullName}
                placeholder="Enter Full Name"
              />
            </Row2Items>
            <Row2Items className={`${classes.select} modal-select`}>
              <Input
                label="Email Address"
                readOnly={isLogout}
                type="email"
                name="email"
                value={form.email}
                onChange={({ target: { name, value } }) =>
                  handleInput(name, value, index)
                }
                onFocus={({ target: { name } }) => removeError(name, index)}
                onBlur={({ target: { name, value } }) =>
                  checkError(name, value, index)
                }
                placeholder="Enter Email address"
                error={errors[index].email}
              />
              <Input
                label="Contact Number"
                readOnly={isLogout}
                type="number"
                name="contactNumber"
                value={form.contactNumber}
                onChange={({ target: { name, value } }) =>
                  handleInput(name, value, index)
                }
                onFocus={({ target: { name } }) => removeError(name, index)}
                onBlur={({ target: { name, value } }) =>
                  checkError(name, value, index)
                }
                placeholder="Enter contact number"
                error={errors[index].contactNumber}
              />
            </Row2Items>
            {index !== 0 && !form.createdAt && (
              <div
                className="remove-form"
                role="button"
                tabIndex="0"
                onKeyPress={() => addForm(false, index)}
                onClick={() => addForm(false, index)}
              >
                Remove
              </div>
            )}
          </>
        ))}
        <div className={`${classes.button} modal-button`}>
          <Button
            className={className}
            type="submit"
            disabled={!propertyId || isLogout}
            actionName={buttonText}
          />
        </div>
      </Form>
    </>
  );
};

LeaseholderCertificationForm.propTypes = {
  classes: propTypes.object,
  className: propTypes.string,
  buttonText: propTypes.string,
  propertyId: propTypes.number.isRequired,
  propertyHolders: propTypes.array,
  propertyType: propTypes.string.isRequired,
  isLogout: propTypes.bool,
};
export default withStyles(Styles)(memo(LeaseholderCertificationForm));
