import React, { memo, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import { propertyStatus, propertyColors } from "constants/AppConstants";
import { useStyles } from "./styles";

const PropertyStatus = ({ theme, value, onChange }) => {
  const makeText = value.split(" ").join("_");
  const [selected, setSelected] = useState("");
  const [color, setColors] = useState({
    color: Object.prototype.hasOwnProperty.call(propertyColors, makeText)
      ? propertyColors[makeText].first
      : "",
    text: Object.prototype.hasOwnProperty.call(propertyColors, makeText)
      ? propertyColors[makeText].color
      : "",
    dotColor: Object.prototype.hasOwnProperty.call(propertyColors, makeText)
      ? propertyColors[makeText].second
      : "",
  });
  useEffect(() => {
    setSelected(value);
    setColors({
      color: propertyColors[makeText].first,
      text: propertyColors[makeText].color,
      dotColor: propertyColors[makeText].second,
    });
  }, [value, makeText]);
  const classes = useStyles({
    ...theme,
    ...color,
  });
  const handleInput = useCallback(
    ({ value, colors }) => {
      setColors({ ...colors });
      setSelected(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className={classes.dropDown}>
      <div className="selected">
        <div className="text">{selected}</div>
      </div>
      <div className={classes.dropDowns}>
        {propertyStatus.map((val, key) => (
          <div
            key={key}
            className={`text ${val.key === selected ? "active" : ""}`}
          >
            <span
              role="button"
              onKeyPress={() =>
                handleInput({
                  value: val.key,
                  colors: { color: val.color, dotColor: val.second },
                })
              }
              tabIndex={0}
              onClick={() =>
                handleInput({
                  value: val.key,
                  colors: { color: val.color, dotColor: val.second },
                })
              }
            >
              {val.value}
            </span>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};
PropertyStatus.propTypes = {
  theme: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTheme(memo(PropertyStatus));
