export const Styles = ({ colors, fonts, breakpoints }) => ({
  submitWork: {
    alignItems: "center",
    "& .contents": {
      backgroundColor: colors.hexaWhite,
      [breakpoints.RESPONSIVE]: {
        height: "100vh",
      },
    },
  },
  main: {
    background: colors.hexWhite,
    margin: "0 24px",
    fontFamily: fonts.primary,
    fontStyle: "normal",
    padding: 0,
    "& svg": {
      cursor: "pointer",
    },
  },
  logo: {
    display: "flex",
    marginTop: "24px",
    justifyContent: "flex-end",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    "& .icon-check": {
      display: "flex",
      textAlign: "center",
      margin: "px 0 29px 0",
    },
    "& .sub-heading": {
      display: "flex",
      textAlign: "center",
      marginBottom: "32px",
      width: "440px",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.labelColor,
      [breakpoints.RESPONSIVE]: {
        width: "100%",
      },
    },
    "& .text-heading": {
      width: "100%",
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      alignItems: "center",
      margin: "20px 0",
      "& .heading": {
        fontStyle: "normal",
        width: "100%",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "32px",
        color: colors.black,
        textAlign: "center",
        [breakpoints.RESPONSIVE]: {
          width: "100%",
        },
      },
    },
    "& .button": {
      "& button": {
        color: colors.black,
        border: `1px solid ${colors.labelColor}`,
        width: "151px",
        height: "45px",
      },
    },
  },
});
