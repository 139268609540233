import { history } from "utils";

const {
  location: { state = {} },
} = history;
export const initialState = {
  licenseList: state?.licenseList || {},
  apiError: {},
  loading: false,
};
