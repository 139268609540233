import React from "react";
import Typography from "../Typography/Typography";
import styles from "./Iframe.module.css";

export const Iframe = (props) => {
  return (
    <div className={styles.container} id={props.id}>
      <iframe title={props.title} src={props.src} className={styles.iframe} />
      <Typography as="subtitle">
        Yuno Group is not responsible for the website displayed in the box above
      </Typography>
    </div>
  );
};
