export default {
  LARGE_DEKSTOP: "@media (min-width: 1600px)",
  IPAD: "@media (min-width:768px) and (max-width:991px)",
  IPAD_LANDSCAPE: "@media (min-width:991px) and (max-width:1024px)",
  MOBILE_DEVICE: "@media (min-width:320px) and (max-width: 767px)",
  MOBILE_HEIGHT: "@media (max-height:480px)",
  IPAD_AND_SMALLER: "@media (max-width:1024px)",
  RESPONSIVE: "@media only screen and (max-width: 768px)",
  RESPONSIVE_ALERTBOX: "@media only screen and (max-width: 800px)",
  IFRAMERESPONSIVE: "@media (min-width:800px) and (max-width:1200px)",
  TABLET: "@media (max-width:780px)",
  MOBILE: "@media (max-width: 480px)",
};
