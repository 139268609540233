import { ReactComponent as Loader } from "images/loader.svg";
import React from "react";
import { withTheme } from "react-jss";
import { useStyles } from "./styles";

const Spinner = ({ theme }) => {
  const classes = useStyles(theme);
  return <Loader className={classes.spinner} stroke={theme.colors.primary} />;
};
export default withTheme(Spinner);
