import propTypes from "prop-types";
import styles from "./SavedJobDetails.module.css";
import Typography from "../../Typography/Typography";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as Logo } from "images/yunologo.svg";
import { ReactComponent as MailLogo } from "images/mail.svg";
import Button from "../../Form/Button/Button";

const SavedJobDetails = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography as="h6License">{props.title}</Typography>
        <Logo className={styles.logo}></Logo>
        <CrossIcon
          className={styles.cross}
          onClick={props.onClose}
          onKeyDown={props.onClose}
        />
      </div>
      <div className={styles.content}>
        <span className={styles.mailLogo}>
          <MailLogo />
        </span>
        <span className={styles.mainText}>
          <Typography as="jobDetailsMainText">
            We've received your job details
          </Typography>
        </span>

        <span className={styles.subText}>
          <Typography as="jobDetailsSubText">
            Someone from our team will be in touch within the next few business
            hours with quotes for your job.
          </Typography>
        </span>
        <span className={styles.button}>
          <Button
            actionName="Ok, thanks"
            variant="secondary"
            onClick={props.onClose}
          />
        </span>
      </div>
    </div>
  );
};

SavedJobDetails.propTypes = {
  name: propTypes.string.isRequired,
  title: propTypes.string,
  onClose: propTypes.func,
  onSave: propTypes.func,
};
SavedJobDetails.defaultProps = {
  title: "",
};
export default SavedJobDetails;
