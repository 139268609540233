export const Styles = ({ colors, fonts }) => {
  const circle = {
    boxSizing: "border-box",
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    display: "flex",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "12%",
  };
  const toolTip = {
    position: "relative",
    display: " inline-block",
    "&:hover > .tooltiptext": {
      visibility: "visible",
    },
    "& .tooltiptext": {
      visibility: "hidden",
      width: 155,
      minHeight: "30px",
      height: "30px",
      backgroundColor: colors.darkGreyToolTip,
      fontFamily: fonts.primary,
      color: colors.hexaWhite,
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: 1,
      top: "28px",
      left: "-67px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.09)",
      "&:after": {
        content: '""',
        left: "72px",
        top: "-4px",
        width: "10px",
        height: "10px",
        position: "absolute",
        bottom: ({ bottom = "39px" }) => bottom,
        background: colors.darkGreyToolTip,
        transform: "rotate(45deg)",
      },
      "& .text": {
        margin: 12,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: `${colors.hexaWhite} !important`,
      },
    },
  };
  return {
    image: {
      ...toolTip,
      "& svg > path": {
        fill: colors.primary,
      },
    },
    circleDynamic: {
      border: `1.5px solid ${colors.primary}`,
      ...toolTip,
      ...circle,
      alignItems: "center",
      justifyContent: "center",
      color: colors.primary,
      lineHeight: "12%",
      fontFamily: "none",
      "& .content": {
        display: "flex",
        fontSize: "21px",
        fontFamily: "none",
      },
    },
    circleRound: {
      border: "1.5px solid",
      ...circle,
    },
    warningCircle: {
      border: `1.5px solid ${colors.warningColor}`,
      padding: "2px",
      ...circle,
      borderColor: colors.warningColor,
      background: colors.warningColor,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      "& svg": {
        width: "14px",
        "& rect": {
          fill: colors.hexaWhite,
        },
        "& path": {
          stroke: colors.hexaWhite,
          strokeWidth: "1.2",
        },
      },
    },
    dangerCircle: {
      border: `1.5px solid ${colors.errorRed}`,
      ...circle,
      borderColor: colors.errorRed,
      background: colors.errorRed,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      "& svg": {
        paddingBottom: 2,
        "& rect": {
          fill: colors.hexaWhite,
        },
        "& path": {
          stroke: colors.hexaWhite,
        },
      },
    },
    tickbg: {
      border: `1.5px solid ${colors.hamptonsSecondary}`,
      ...circle,
      background: colors.hamptonsSecondary,
      ...toolTip,
    },
    tick: {
      height: "10px",
      width: "6px",
      border: `solid ${colors.hexaWhite}`,
      borderWidth: "0px 2px 2px 0px",
      "-webkit-transform": "rotate(45deg)",
      "-moz-transform": "rotate(45deg)",
      "-o-transform": "rotate(45deg)",
      display: "block",
      marginTop: "3px",
      marginLeft: "7px",
    },
  };
};
