export const styles = ({ colors, fonts, breakpoints }) => ({
  hmo: {
    "& svg": {
      cursor: "pointer",
    },
    [breakpoints.MOBILE_DEVICE]: {
      padding: "0 !important",
      "& .contents": {
        width: "100%",
        margin: "0 !important",
        height: "100vh !important",
        top: "0",
      },
    },
  },
  text: {
    display: "flex",
    marginTop: "24px",
    "& .subtitle": {
      fontSize: "20px !important",
    },
    "& p": {
      width: 552,
      height: 0,
    },
    "& svg": {
      cursor: "pointer",
      margin: "0 0 13px 16px",
      "&:focus": {
        outline: "none",
      },
    },
  },
  note: {
    marginTop: "8px",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.darkGrey,
  },
  main: {
    background: colors.hexWhite,
    margin: "0 24px",
    fontFamily: fonts.primary,
    fontStyle: "normal",
    "& span": {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.darkGrey,
    },
    "& .link": {
      color: colors.hamptonsSecondary,
      textDecoration: "underline",
      textDecorationColor: colors.hamptonsSecondary,
    },
  },
  section: {
    marginBottom: "24px",
    "& .list-view": {
      paddingTop: "8px",
    },
  },
  footerSection: {
    float: "right",
  },
  secondHeading: {
    marginBottom: "15px",
  },
});
