import { ReactComponent as Triangle } from "images/triangle.svg";
import propTypes from "prop-types";
import { Typography } from "v2/components";
import styles from "./AlertBox.module.css";

const AlertBox = (props) => {
  return (
    <div
      className={styles.container}
      onClick={props.onClick}
      onKeyPress={props.onClick}
      role="button"
      tabIndex="0"
    >
      <Triangle className={styles.image} />
      <div className={styles.content}>
        <Typography as="alertBox">{props.alertMessage}</Typography>
        {props.children}
      </div>
    </div>
  );
};

AlertBox.propTypes = {
  alertMessage: propTypes.string.isRequired,
};

export default AlertBox;
