import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";

import { Typography } from "v2/components";
import { styles } from "./styles";

const TextBox = ({
  classes,
  children,
  className = "",
  heading,
  icon = true,
  rightText = false,
  subHeading = "",
}) => {
  const [showDetails, setShowDetails] = useState(true);
  return (
    <div className={`${classes.main} ${className}`}>
      <div className="heading">
        <div
          className="text-click"
          role="button"
          tabIndex="0"
          onKeyPress={() => (icon ? setShowDetails(!showDetails) : null)}
          onClick={() => (icon ? setShowDetails(!showDetails) : null)}
        >
          <Typography as="subtitle">{heading}</Typography>
        </div>
        {icon && (
          <>
            {showDetails && (
              <ArrowUp onClick={() => setShowDetails(!showDetails)} />
            )}
            {!showDetails && (
              <ArrowDown onClick={() => setShowDetails(!showDetails)} />
            )}
          </>
        )}
        {rightText && subHeading}
      </div>
      {showDetails && <div className="details">{children}</div>}
    </div>
  );
};

TextBox.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  heading: PropTypes.string.isRequired,
  rightText: PropTypes.bool,
  subHeading: PropTypes.string,
};

export default withStyles(styles)(memo(TextBox));
