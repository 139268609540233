import PropTypes from "prop-types";

import style from "./Row.module.css";

const Row = ({ children }) => <div className={style.container}>{children}</div>;

Row.propTypes = {
  children: PropTypes.any.isRequired,
};
export default Row;
