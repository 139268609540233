import {
  usePropertyDetails,
  usePropertySponsorContext,
} from "context/propertyContext/PropertyContext.js";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate.js";
import { useCallback, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Breadcrumb from "v2/components/Breadcrumb/Breadcrumb";
import BreadcrumbMobile from "v2/components/Breadcrumb/BreadcrumbMobile.jsx";
import { journeySteps } from "./constants.js";
import styles from "./Journey.module.css";
import { checkFilter } from "./utils.js";
import { useWindowDimensions } from "utils/windowDimensions.js";

export default function Journey(props) {
  const width = useWindowDimensions();
  const { url } = useRouteMatch();
  const match = useRouteMatch();
  const history = useHistory();
  const propertyDetails = usePropertyDetails();
  const { openSponsorModal } = usePropertySponsorContext();
  useEffect(() => {
    if (propertyDetails && !propertyDetails.propertyType) {
      props.goHome();
    }
    props.setActionRequired(propertyDetails.actionRequired);
  }, [propertyDetails]);

  const checkFilterCallback = useCallback(
    (filter) => {
      return checkFilter(propertyDetails, filter);
    },
    [propertyDetails]
  );

  const handleOnNext = (currentStepIndex, currentSubStepIndex) => {
    const currentStep = journeySteps[currentStepIndex];
    if (currentStep.steps?.length && currentSubStepIndex === undefined) {
      history.push(
        `${match.url}/${currentStep.path}/${currentStep.steps[0].path}`
      );
      return;
    }
    if (
      currentStep.steps?.length &&
      currentSubStepIndex !== undefined &&
      currentSubStepIndex < currentStep.steps.length - 1
    ) {
      history.push(
        `${match.url}/${currentStep.path}/${
          currentStep.steps[currentSubStepIndex + 1].path
        }`
      );

      return;
    }

    let nextValidIndex = currentStepIndex + 1;
    while (
      nextValidIndex < journeySteps.length &&
      !checkFilter(propertyDetails, journeySteps[nextValidIndex].filter)
    ) {
      nextValidIndex++;
    }
    if (nextValidIndex < journeySteps.length) {
      history.push(`${url}/${journeySteps[nextValidIndex].path}`);
    } else {
      props.onNext();
    }
  };

  const nextName = (currentStepIndex, currentSubStepIndex) => {
    const currentStep = journeySteps[currentStepIndex];
    if (currentStep.steps?.length && currentSubStepIndex === undefined) {
      return currentStep.steps[0].name;
    }
    if (
      currentStep.steps?.length &&
      currentSubStepIndex !== undefined &&
      currentSubStepIndex < currentStep.steps.length - 1
    ) {
      return currentStep.steps[currentSubStepIndex + 1].name;
    }

    let nextValidIndex = currentStepIndex + 1;
    while (
      nextValidIndex < journeySteps.length &&
      !checkFilter(propertyDetails, journeySteps[nextValidIndex].filter)
    ) {
      nextValidIndex++;
    }
    if (nextValidIndex < journeySteps.length) {
      return journeySteps[nextValidIndex].name;
    }
  };

  const handleOnPrevious = (currentStepIndex, currentSubStepIndex) => {
    const currentStep = journeySteps[currentStepIndex];
    if (currentStep.steps?.length && currentSubStepIndex === undefined) {
      history.push(
        `${match.url}/${currentStep.path}/${currentStep.steps[0].path}`
      );
      return;
    }
    if (
      currentStep.steps?.length &&
      currentSubStepIndex !== undefined &&
      currentSubStepIndex < currentStep.steps.length &&
      currentSubStepIndex !== 0
    ) {
      //TODO: fix the current function case the "currentStep" is 0
      history.push(
        `${match.url}/${currentStep.path}/${
          currentStep.steps[currentSubStepIndex - 1].path
        }`
      );

      return;
    }

    let previousValidIndex = currentStepIndex - 1;
    while (
      previousValidIndex < journeySteps.length &&
      currentStepIndex !== 0 &&
      !checkFilter(propertyDetails, journeySteps[previousValidIndex].filter)
    ) {
      previousValidIndex--;
    }
    if (previousValidIndex < journeySteps.length && currentStepIndex !== 0) {
      history.push(`${url}/${journeySteps[previousValidIndex].path}`);
    } else {
      props.onPrevious();
    }
  };
  useEffect(() => {
    //openSponsorModal();
  }, []);

  useWhyDidYouUpdate("Journey", props);
  return (
    <div className={styles.container}>
      {width <= 768 && (
        <BreadcrumbMobile
          journey={journeySteps}
          checkFilter={checkFilterCallback}
          openSponsorModal={openSponsorModal}
        />
      )}
      <Breadcrumb
        journey={journeySteps}
        checkFilter={checkFilterCallback}
        openSponsorModal={openSponsorModal}
      />

      <Switch>
        {journeySteps.flatMap((journeyStep, stepIndex) => {
          if (!checkFilterCallback(journeyStep.filter)) {
            return null;
          }
          if (journeyStep.steps) {
            //Render sub steps Routes before the steps routes.
            return journeyStep.steps.map((step, subStepIndex) => (
              <Route
                key={step.path}
                path={`${url}/${journeyStep.path}/${step.path}`}
              >
                <step.Component
                  key={subStepIndex}
                  name={step.name}
                  nextName={nextName(stepIndex, subStepIndex)}
                  path={step.path}
                  goHome={props.goHome}
                  onNext={() => handleOnNext(stepIndex, subStepIndex)}
                  onPrevious={() => handleOnPrevious(stepIndex, subStepIndex)}
                />
              </Route>
            ));
          }
        })}
        {journeySteps.map(
          ({ path, Component, filter = {}, name, steps = [] }, stepIndex) => {
            if (!checkFilterCallback(filter)) {
              return null;
            }
            return (
              <Route key={path} path={`${url}/${path}`}>
                {!!Component && (
                  <Component
                    key={stepIndex}
                    name={name}
                    path={path}
                    nextName={nextName(stepIndex)}
                    goHome={props.goHome}
                    onNext={() => handleOnNext(stepIndex)}
                    onPrevious={() => handleOnPrevious(stepIndex)}
                  />
                )}
                {!Component && !!steps.length && (
                  <Redirect
                    to={{
                      pathname: `${url}/${path}/${steps[0].path}`,
                      state: {
                        page: steps[0].name,
                      },
                    }}
                  />
                )}
              </Route>
            );
          }
        )}
        <Route path={`${url}/`}>
          <Redirect
            to={{
              pathname: `${url}/${journeySteps[0].path}`,
              state: {
                page: journeySteps[0].name,
              },
            }}
          />
        </Route>
      </Switch>
    </div>
  );
}
