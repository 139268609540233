//sourced from https://usehooks.com/useTheme/
import { useLayoutEffect } from "react";
import { hexToRgb } from "utils";

export function useTheme(theme) {
  useLayoutEffect(
    () => {
      // Iterate through each value in theme object
      for (const key in theme) {
        // Update css variables in document's root element
        document.documentElement.style.setProperty(`--${key}`, theme[key]);
        if (key === "secondary") {
          document.documentElement.style.setProperty(
            `--${key}RGB`,
            hexToRgb(theme[key], 1)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB90`,
            hexToRgb(theme[key], 0.9)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB80`,
            hexToRgb(theme[key], 0.8)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB70`,
            hexToRgb(theme[key], 0.7)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB60`,
            hexToRgb(theme[key], 0.6)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB50`,
            hexToRgb(theme[key], 0.5)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB40`,
            hexToRgb(theme[key], 0.4)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB30`,
            hexToRgb(theme[key], 0.3)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB20`,
            hexToRgb(theme[key], 0.2)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB10`,
            hexToRgb(theme[key], 0.1)
          );
        }
        if (key === "primary") {
          document.documentElement.style.setProperty(
            `--${key}RGB`,
            hexToRgb(theme[key], 1)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB90`,
            hexToRgb(theme[key], 0.9)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB80`,
            hexToRgb(theme[key], 0.8)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB70`,
            hexToRgb(theme[key], 0.7)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB60`,
            hexToRgb(theme[key], 0.6)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB50`,
            hexToRgb(theme[key], 0.5)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB40`,
            hexToRgb(theme[key], 0.4)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB30`,
            hexToRgb(theme[key], 0.3)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB20`,
            hexToRgb(theme[key], 0.2)
          );
          document.documentElement.style.setProperty(
            `--${key}RGB10`,
            hexToRgb(theme[key], 0.1)
          );
        }
      }
    },
    [theme] // Only call again if theme object reference changes
  );
}
