import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { Styles } from "./Styles";

const ErrorAlert = ({
  text,
  children,
  content = "",
  classes,
  onClose,
  isShow,
  isError,
  className = "",
}) => (
  <div>
    {isShow && (
      <div
        className={`${classes.main} ${
          isError ? classes.error : classes.success
        } `}
      >
        <div className="main-data">
          {content && <div className="content-icon">{content}</div>}
          <div className="text-message">
            <span className="text">{text}</span>
            {children}
          </div>
        </div>
        <CrossIcon onClick={onClose} className="closs-icon" />
      </div>
    )}
  </div>
);
ErrorAlert.propType = {
  text: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  Content: PropTypes.any,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default withStyles(Styles)(memo(ErrorAlert));
