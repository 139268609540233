import { useMemo } from "react";
import * as runtime from "react/jsx-runtime.js";
import { useMDXComponents } from "@mdx-js/react";
import { runSync } from "xdm/lib/run";
import { compile } from "xdm";
import remarkGfm from "remark-gfm";

export async function compileMDXFunction(mdx) {
  return String(
    await compile(mdx, {
      _contain: true,
      providerImportSource: "#",
      remarkPlugins: [remarkGfm],
      outputFormat: "function-body",
      useDynamicImport: true,
    })
  );
}

export function useMDXFunction(code) {
  return useMemo(() => {
    const result = runSync(code, {
      ...runtime,
      useMDXComponents,
    });
    return result?.default;
  }, [code]);
}
