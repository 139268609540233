import {
  usePropertyAnalyticsActionsContext,
  usePropertyDetails,
} from "context/propertyContext/PropertyContext";
import propTypes from "prop-types";
import { Children } from "react";

import { Image, Typography } from "v2/components";
import EPCGraph from "v2/components/EPCGraph/EPCGraph";
import Flex from "v2/components/Layout/Flex";
import Markdown from "v2/components/Markdown/Markdown";
import PDFDisplay from "v2/components/PDFDisplay/PDFDisplay";
import JourneyLayout from "../../JourneyLayout/JourneyLayout";
import styles from "./Common.module.css";
import InfoDownloads from "./InfoDownloads/InfoDownloads";
import Providers from "./Providers/Providers";

const Disclaimer = () => {
  return (
    <Flex>
      <span className={styles.disclaimer}>
        <Typography as="noteText">
          Remember the owner of the property is, usually, the 'Responsible
          Person'. This person’s responsibility is to instruct someone competent
          and, in the worst case scenario, to stand in court if it all goes
          wrong to explain why they chose them to carry out this risk
          assessment. If you are picking a fire risk assessor on behalf of the
          Responsible Person you should be equally as diligent. As a minimum,
          you should look for credentials under bodies like the IFA, IFM, and
          continuous development of skills.
        </Typography>
      </span>
    </Flex>
  );
};

const Notes = (props) => {
  return (
    <Flex>
      <span className={styles.notes}>
        <Typography as="h6">Notes</Typography>
        <Typography as="noteText">{props.children}</Typography>
      </span>
    </Flex>
  );
};

const TextLink = (props) => {
  return (
    <a href={props.to}>
      <Typography as="link">{props.children}</Typography>
    </a>
  );
};

const Divider = () => {
  return <div className={styles.divider}></div>;
};

const TextImageBox = (props) => {
  return (
    <div className={styles.textImageBoxContainer}>
      <div className={styles.textImageBoxText}>{props.children}</div>
      <div className={styles.textImageBoxImage}>
        <img src={props.src}></img>
      </div>
    </div>
  );
};

function Common(props) {
  const propertyDetails = usePropertyDetails();
  const { epc } = usePropertyAnalyticsActionsContext();
  const components = {
    Disclaimer,
    Providers,
    Image,
    PDFDisplay,
    EPCGraph: (props) => <EPCGraph {...props} {...epc} />,
    InfoDownloads,
    Notes,
    TextLink,
    Divider,
    TextImageBox,
  };
  const markdown = propertyDetails.markdownContent[props.path]?.markdown;
  const markdownAspire = propertyDetails.markdownContent[props.path]?.markdown;
  return (
    <JourneyLayout
      name={props.name}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      nextName={props.nextName}
    >
      <div className={styles.markdownContainer}>
        {!!markdownAspire &&
          window.location.origin === "https://aspire.goyuno.com" && (
            <Markdown
              information={
                propertyDetails.markdownContentAspire[props.path].markdown
              }
              components={components}
              contentProps={{
                ...propertyDetails.markdownContentAspire[props.path].props,
              }}
            />
          )}
        {!!markdown &&
          window.location.origin !== "https://aspire.goyuno.com" && (
            <Markdown
              information={propertyDetails.markdownContent[props.path].markdown}
              components={components}
              contentProps={{
                ...propertyDetails.markdownContent[props.path].props,
              }}
            />
          )}
      </div>
    </JourneyLayout>
  );
}

Common.propTypes = {
  onNext: propTypes.func,
  onPrevious: propTypes.func,
};

export default Common;
