import dayjs from "dayjs";
import Typography from "../Typography/Typography";
import styles from "./EPCGraph.module.css";
import { ReactComponent as EpcRatingA } from "../../../images/epcRatingA.svg";
import { ReactComponent as EpcRatingB } from "../../../images/epcRatingB.svg";
import { ReactComponent as EpcRatingC } from "../../../images/epcRatingC.svg";
import { ReactComponent as EpcRatingD } from "../../../images/epcRatingD.svg";
import { ReactComponent as EpcRatingE } from "../../../images/epcRatingE.svg";
import { ReactComponent as EpcRatingF } from "../../../images/epcRatingF.svg";
import { ReactComponent as EpcRatingG } from "../../../images/epcRatingG.svg";
import { ReactComponent as ImpactA } from "../../../images/ImpactA.svg";
import { ReactComponent as ImpactB } from "../../../images/ImpactB.svg";
import { ReactComponent as ImpactC } from "../../../images/ImpactC.svg";
import { ReactComponent as ImpactD } from "../../../images/ImpactD.svg";
import { ReactComponent as ImpactE } from "../../../images/ImpactE.svg";
import { ReactComponent as ImpactF } from "../../../images/ImpactF.svg";
import { ReactComponent as ImpactG } from "../../../images/ImpactG.svg";
import { ReactComponent as NoEPC } from "images/group360.svg";

const ratings = [
  styles.rating_a,
  styles.rating_b,
  styles.rating_c,
  styles.rating_d,
  styles.rating_e,
  styles.rating_f,
  styles.rating_g,
];
const epcRatings = [
  <EpcRatingA />,
  <EpcRatingB />,
  <EpcRatingC />,
  <EpcRatingD />,
  <EpcRatingE />,
  <EpcRatingF />,
  <EpcRatingG />,
];
const impactRatings = [
  <ImpactA />,
  <ImpactB />,
  <ImpactC />,
  <ImpactD />,
  <ImpactE />,
  <ImpactF />,
  <ImpactG />,
];
const impact_ratings = [
  styles.impact_rating_a,
  styles.impact_rating_b,
  styles.impact_rating_c,
  styles.impact_rating_d,
  styles.impact_rating_e,
  styles.impact_rating_f,
  styles.impact_rating_g,
];

const getRating = (rating) => {
  if (rating > 91) return 0;
  else if (rating > 80) return 1;
  else if (rating > 68) return 2;
  else if (rating > 54) return 3;
  else if (rating > 38) return 4;
  else if (rating > 20) return 5;
  else if (rating > 0) return 6;
};

const ExpiryDate = (props) => {
  const validDate = dayjs(props.date);
  const expiresSoon = dayjs().add(6, "months");
  const formattedValidDate = validDate.format("DD MMMM YYYY");
  if (dayjs().isAfter(validDate)) {
    return (
      <div className={styles.expired}>
        <Typography as="subtitle">
          Your properties EPC certificate expired on {formattedValidDate}
        </Typography>
      </div>
    );
  }
  if (validDate.isBefore(expiresSoon)) {
    return (
      <div className={styles.expiresSoon}>
        <Typography as="subtitle">
          Your properties EPC certificate expires soon {formattedValidDate}
        </Typography>
      </div>
    );
  }
  return (
    <Typography>
      Your properties EPC certificate expires on {formattedValidDate}
    </Typography>
  );
};

const EPCGraph = (props) => {
  const potential = getRating(props.potential);
  const current = getRating(props.current);
  const potential_impact = getRating(props.potential_impact);
  const current_impact = getRating(props.current_impact);
  return (
    <>
      <div className={styles.container}>
        {!props.potential && !props.current && (
          <div className={styles.noEPCContainer}>
            <div className={styles.noEPCBox}>
              <NoEPC />
              <Typography as="h5">
                No EPC Certificate Available for This Property
              </Typography>
            </div>
          </div>
        )}

        {props.potential && props.current && (
          <>
            <div className={styles.ratingsContainer}>
              <div className={styles.ratingContainer}>
                <div className={styles.ratingSvg}>{epcRatings[current]}</div>
                <Typography as="h4">
                  Your property's Energy Efficiency Rating
                </Typography>
              </div>
              <div className={styles.ratingContainer}>
                <div className={styles.ratingSvg}>
                  {impactRatings[current_impact]}
                </div>
                <Typography as="h4">
                  Your property's Environmental Impact Rating
                </Typography>
              </div>
            </div>
            <ExpiryDate date={props.valid_until} />
            <div className={styles.chartContainer}>
              <div className={styles.chart}>
                <div className={styles.bars}>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span className={styles.th}>
                        Energy Efficiency Rating
                      </span>
                    </div>
                    <div className={styles.col_2}>
                      <span className={styles.th}>Current</span>
                    </div>
                    <div className={styles.col_3}>
                      <span className={styles.th}>Potential</span>
                    </div>
                  </div>
                </div>
                <div className={`${styles.bars}`}>
                  <div className={styles.bar}>
                    <small>Very energy efficient - lower running costs</small>
                  </div>
                </div>
                <div className={`${styles.bars} ${styles.noborder_bottom}`}>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.rating_a} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(92+)</span>
                        <span className={styles.title}>A</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.rating_b} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(81-91)</span>
                        <span className={styles.title}>B</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.rating_c} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(69-80)</span>
                        <span className={styles.title}>C</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.rating_d} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(55-68)</span>
                        <span className={styles.title}>D</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.rating_e} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(39-54)</span>
                        <span className={styles.title}>E</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.rating_f} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(21-38)</span>
                        <span className={styles.title}>F</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.rating_g} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(1-20)</span>
                        <span className={styles.title}>G</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div
                    className={`${styles.pointer} ${styles.current} ${ratings[current]}`}
                    style={{ top: current * 32 + 6 + "px" }}
                  >
                    <span>{props.current}</span>
                  </div>
                  <div
                    className={`${styles.pointer} ${styles.potential} ${ratings[potential]}`}
                    style={{ top: potential * 32 + 6 + "px" }}
                  >
                    <span>{props.potential}</span>
                  </div>
                </div>
                <div className={`${styles.bars} ${styles.noborder_top}`}>
                  <div className={styles.bar}>
                    <small>Not energy efficient - higher running costs</small>
                  </div>
                </div>
              </div>
              <div className={styles.chart}>
                <div className={styles.bars}>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span className={styles.th}>
                        Environmental Impact Rating
                      </span>
                    </div>
                    <div className={styles.col_2}>
                      <span className={styles.th}>Current</span>
                    </div>
                    <div className={styles.col_3}>
                      <span className={styles.th}>Potential</span>
                    </div>
                  </div>
                </div>
                <div className={`${styles.bars} ${styles.noborder_bottom}`}>
                  <div className={styles.bar}>
                    <small>
                      Very environmentally friendly - lower CO2 emissions
                    </small>
                  </div>
                </div>
                <div className={`${styles.bars} ${styles.noborder_bottom}`}>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.impact_rating_a} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(92+)</span>
                        <span className={styles.title}>A</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.impact_rating_b} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(81-91)</span>
                        <span className={styles.title}>B</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.impact_rating_c} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(69-80)</span>
                        <span className={styles.title}>C</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.impact_rating_d} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(55-68)</span>
                        <span className={styles.title}>D</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.impact_rating_e} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(39-54)</span>
                        <span className={styles.title}>E</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.impact_rating_f} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(21-38)</span>
                        <span className={styles.title}>F</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div className={styles.bar}>
                    <div className={styles.col_1}>
                      <span
                        className={`${styles.block} ${styles.impact_rating_g} ${styles.borderRadius}`}
                      >
                        <span className={styles.range}>(1-20)</span>
                        <span className={styles.title}>G</span>
                      </span>
                    </div>
                    <div className={styles.col_2}></div>
                    <div className={styles.col_3}></div>
                  </div>
                  <div
                    className={`${styles.pointer} ${styles.current} ${impact_ratings[current_impact]}`}
                    style={{ top: current_impact * 32 + 6 + "px" }}
                  >
                    <span>{props.current_impact}</span>
                  </div>
                  <div
                    className={`${styles.pointer} ${styles.potential} ${impact_ratings[potential_impact]}`}
                    style={{ top: potential_impact * 32 + 6 + "px" }}
                  >
                    <span>{props.potential_impact}</span>
                  </div>
                </div>
                <div className={`${styles.bars} ${styles.noborder_top}`}>
                  <div className={styles.bar}>
                    <small>
                      Not environmentally friendly - higher CO2 emissions
                    </small>
                  </div>
                </div>
              </div>
            </div>
            Contains public sector information licensed under the Open
            Government Licence v3.0.
          </>
        )}
      </div>
      <br />
    </>
  );
};

export default EPCGraph;
