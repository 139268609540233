export const Styles = ({ colors, breakpoints }) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    [breakpoints.RESPONSIVE]: {
      padding: "0",
    },
    "& .contents": {
      background: colors.hexaWhite,
      [breakpoints.RESPONSIVE]: {
        width: "100%",
        margin: "0 !important",
        height: "100vh !important",
        top: "0",
      },
    },
    "& .header": {
      padding: "0  27px 0 30px",
      display: "flex",
      justifyContent: "space-between",
      height: "70px",
      alignItems: "center",
      backgroundColor: colors.primary,
      "& .heading": {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: colors.hexaWhite,
      },
      "& svg ": {
        cursor: "pointer",
        "& path": {
          stroke: colors.hexaWhite,
        },
      },
    },
    "& .body": {
      margin: "26px 27px 70px 30px",
      "& .body-heading": {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        letterSpacing: " 0.002em",
        color: colors.black,
      },
      "& .body-subheading": {
        marginTop: "25px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: colors.labelColor,
      },
    },
    "& .footer": {
      margin: "0  27px 30px 30px",
      display: "flex",
      justifyContent: "flex-end",
      [breakpoints.RESPONSIVE]: {
        justifyContent: "center",
        flexDirection: "column-reverse",
        alignItems: "center",
      },
      "& .close-button": {
        height: "45px",
        width: "104px",
        background: colors.hexaWhite,
        marginRight: "12px",
        border: `1px solid ${colors.inputGrey}`,
        color: colors.black,
        [breakpoints.RESPONSIVE]: {
          width: "100%",
        },
      },
      "& .signup-button": {
        height: "45px",
        width: "104px",
        background: colors.primary,
        color: colors.hexaWhite,
        boxSizing: "border-box",
        border: `1.4px solid ${colors.primary}`,
        borderRadius: "4px",
        textDecoration: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [breakpoints.RESPONSIVE]: {
          width: "100%",
          marginBottom: "20px",
        },
      },
    },
  },
});
