import Theme from "../../../../theme";

const { breakpoints } = Theme;
const ButtonStyles = ({ colors, fonts }) => {
  const button = {
    textAlign: "center",
    fontStyle: "normal",
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "600",
    fontFamily: fonts.primary,
    width: ({ width }) => width || "160px",
    justifyContent: ({ align }) => align && align,
    cursor: "pointer",
    outline: "none",
    background: "none",
    border: "none",
    padding: "12px 16px",
    fontSize: ({ fontSize }) => fontSize || 16,
    [breakpoints.MOBILE_DEVICE]: {
      width: "327px",
    },
  };
  const simpleButton = {
    textAlign: "center",
    fontStyle: "normal",
    color: colors.hamptonsSecondary,
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "600",
    fontFamily: fonts.primary,
    width: ({ width }) => width || "91px",
    justifyContent: ({ align }) => align && align,
    cursor: "pointer",
    outline: "none",
    background: "none",
    border: "none",
    borderRadius: "48px",
    padding: "8px 16px",
    fontSize: ({ fontSize }) => fontSize || 14,
    "&:disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
      pointerEvents: "none",
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || `${colors.hamptonsNewDisabled}`,
    },
  };
  const whiteButton = {
    textAlign: "center",
    fontStyle: "normal",
    color: colors.black,
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "500",
    fontFamily: fonts.primary,
    width: ({ width }) => width || "151px",
    height: ({ width }) => width || "45px",
    justifyContent: ({ align }) => align && align,
    cursor: "pointer",
    outline: "none",
    background: "none",
    border: `2px solid ${colors.inputGrey} `,
    mixBlendMode: "normal",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "8px 16px",
    backgroundColor: ({ backgroundColor }) =>
      backgroundColor || `${colors.white}`,
    fontSize: ({ fontSize }) => fontSize || 16,
    [breakpoints.MOBILE_DEVICE]: {
      width: "327px !important",
      bottom: "30px",
      position: "absolute",
      left: 0,
      right: 0,
      margin: "auto",
    },
    "&:disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
      pointerEvents: "none",
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || `${colors.hamptonsNewDisabled}`,
    },
  };
  const whitePrimary = {
    textAlign: "center",
    fontStyle: "normal",
    color: colors.primary,
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "500",
    fontFamily: fonts.primary,
    width: ({ width }) => width || "151px",
    height: ({ width }) => width || "45px",
    justifyContent: ({ align }) => align && align,
    cursor: "pointer",
    outline: "none",
    background: "none",
    border: `2px solid ${colors.primary} `,
    mixBlendMode: "normal",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "8px 16px",
    backgroundColor: ({ backgroundColor }) =>
      backgroundColor || `${colors.white}`,
    fontSize: ({ fontSize }) => fontSize || 16,
    [breakpoints.MOBILE_DEVICE]: {
      bottom: "30px",
      position: "absolute",
      left: 0,
      right: 0,
      margin: "auto",
    },
    "&:disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
      pointerEvents: "none",
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || `${colors.hamptonsNewDisabled}`,
    },
  };
  return {
    button: {
      ...button,
      height: "48px",
      borderRadius: "4px",
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || `${colors.primary}`,
      color: ({ color }) => color || `rgb(${colors.white})`,
      "&:disabled": {
        cursor: "not-allowed",
        pointerEvents: "none",
        backgroundColor: ({ backgroundColor }) =>
          backgroundColor || `${colors.inputGrey} !important`,
      },
    },
    simpleButton: {
      ...simpleButton,
      height: "32px",
      padding: "0px",
      backgroundColor: ({ color }) => color || `rgb(${colors.white})`,
    },
    whiteButton: {
      ...whiteButton,
      height: "45px",
      padding: "0px",
    },
    whitePrimary: {
      ...whitePrimary,
      height: "45px",
      padding: "0px",
    },
  };
};

export default ButtonStyles;
