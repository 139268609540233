import { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { leftImage } from "constants/AppConstants";

import leftImageBlur from "images/signupImageBlur.svg";
import styles from "./LoginLeftSection.module.css";
//TODO: change this component to be a hook instead of a full component

const LoginLeftSection = memo(
  ({ imageUrl = leftImage, blurImage = leftImageBlur }) => {
    const [imgLoaded, setImgLoaded] = useState(false);
    useEffect(() => {
      loadImage();
    }, [imageUrl]);
    const loadImage = () => {
      const image = new Image();
      image.onload = () => {
        setImgLoaded(true);
      };
      image.src = imageUrl;
    };
    return (
      <img
        src={!imgLoaded ? blurImage : imageUrl}
        alt="left-side"
        className={styles.loginLeftSection}
      />
    );
  }
);
LoginLeftSection.propTypes = {
  imageUrl: PropTypes.string,
  blurImage: PropTypes.string,
};
export default LoginLeftSection;
