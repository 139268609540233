import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { ReactComponent as CheckIcon } from "images/circlecheck.svg";
import { styles } from "./styles";

const AlertMessage = ({
  classes,
  text,
  className,
  isShow,
  animationClass = "animate__fadeInDown",
}) => (
  <>
    {isShow && (
      <div
        className={`animate__animated ${classes.main} ${className} ${animationClass} `}
      >
        <CheckIcon />
        <div className="message">{text}</div>
      </div>
    )}
  </>
);
AlertMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  isShow: PropTypes.bool.isRequired,
  className: PropTypes.string,
};
export default withStyles(styles)(memo(AlertMessage));
