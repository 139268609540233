import { getWebInfo, checkWebInIframe } from "utils";

const { name } = getWebInfo();
const Styles = ({ colors, fonts, breakpoints }) => ({
  form: {
    width: "100%",
    marginTop: "32px",
    [breakpoints.RESPONSIVE]: {
      width: "384px",
    },
    [breakpoints.MOBILE_DEVICE]: {
      width: "100%",
    },
    "& input": {
      margin: "8px 0",
    },
    "& select": {
      margin: "8px 0",
    },
    "& .input-model": {
      "& label": {
        marginBottom: "24px",
      },
      "& input": {
        width: "272px",
      },
    },
    "& .input-div": {
      marginBottom: "26px",
      position: "relative",
      "& .info-second": {
        position: "relative",
      },
    },
    "& .modal-button": {
      [breakpoints.RESPONSIVE]: {
        width: "100%",
        position: "fixed",
        bottom: checkWebInIframe ? "0" : "65px",
      },
    },
  },
  labelDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "& .help-icon": {
      cursor: "pointer",
      margin: "0 0 6px 12px",
      fill: name === "sprift" ? colors.hamptonsSecondary : "none",
      "& .question-stock": {
        stroke: name === "sprift" ? colors.hamptonsSecondary : "",
      },
    },
  },
  smallInput: {
    "& .info": {
      marginRight: "24px",
      [breakpoints.MOBILE_DEVICE]: {
        marginRight: "17px",
      },
    },
  },
  select: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    [breakpoints.MOBILE_DEVICE]: {
      "& .long-label": {
        width: "auto",
        maxWidth: "272px",
      },
      "& .last-select > svg": {
        top: "53px",
      },
    },
    "& select": {
      height: "48px",
      width: "379px",
      [breakpoints.MOBILE_DEVICE]: {
        width: "285px",
      },
    },
    "& input": {
      width: "364px",
      [breakpoints.MOBILE_DEVICE]: {
        width: "255px",
      },
    },
  },
  label: {
    fontStyle: "normal",
    display: "flex",
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "500",
    fontFamily: fonts.primary,
    fontSize: ({ fontSize }) => fontSize || "16px",
    lineHeight: ({ lineHeight }) => lineHeight || "16px",
    marginBottom: "8px",
    color: colors.black,
    "& .required-start": {
      fontSize: "18px",
      marginLeft: "2px",
      color: colors.red,
    },
  },
  button: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    [breakpoints.MOBILE_DEVICE]: {
      justifyContent: "none",
      height: "64px",
      zIndex: "2",
      width: "100%",
      position: "fixed",
      left: "0",
      right: "0",
      bottom: "65px",
      "& button": {
        width: "90%",
        margin: "8px auto",
      },
    },
  },
  radio: {
    display: "flex",
    marginTop: "24px",
  },
});

export default Styles;
