import Link from "components/Link";
import useModal from "Hooks/useModal";
import { ModalV2, Typography } from "v2/components";
import Button from "v2/components/Form/Button/Button";
import styles from "./InfoDownloads.module.css";

function InfoDownloads(props) {
  const [isModalOpen, openModal, closeModal, modalData] = useModal();

  return (
    <div>
      {isModalOpen && (
        <ModalV2 onClose={closeModal}>
          {props.text.map((item) => {
            if (item.link)
              return (
                <div key={item.title} className={styles.itemContainer}>
                  <Typography as="h6" className={styles.title}>
                    {item.title}
                  </Typography>
                  <Typography>{item.text}</Typography>
                  <Link as={item.link.type} path={item.link.url}>
                    <Button
                      actionName={item.link.text}
                      className={styles.button}
                    />
                  </Link>
                </div>
              );
            else {
              return (
                <div key={item.title} className={styles.itemContainer}>
                  <Typography as="h6" className={styles.title}>
                    {item.title}
                  </Typography>
                  <Typography>{item.text}</Typography>
                </div>
              );
            }
          })}
        </ModalV2>
      )}
      <Button actionName="Info Button" onClick={openModal} />
    </div>
  );
}
export default InfoDownloads;
