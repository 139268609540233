export const styles = ({ colors, fonts, breakpoints }) => {
  const inputField = {
    display: "flex",
    color: colors.hexaWhite,
    fontFamily: fonts.primary,
    fontWeight: 500,
    outline: "none",
    [breakpoints.MOBILE_DEVICE]: {
      width: "271px",
      marginRight: 0,
      marginBottom: 18,
      fontSize: 14,
    },
    "&::-webkit-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-moz-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-ms-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "& :hover": {
      color: colors.skyBlue,
    },
  };
  return {
    select: {
      ...inputField,
      width: "436px",
      fontFamily: fonts.primary,
      background: colors.hexaWhite,
      padding: "12px 0 12px 16px",
      fontSize: 16,
      border: "none",
      borderRadius: 4,
      position: "relative",
      textTransform: "none",
      "-moz-appearance": "none",
      "& option": {
        fontFamily: fonts.primary,
        fontWeight: 1,
        fontSize: "1rem",
        lineHeight: "1.5",
        padding: 0,
      },
      "-webkit-appearance": "none",
      "&::-moz-appearance": {
        display: "none",
      },
      color: colors.black,
      "&::-ms-expand": {
        display: "none",
      },
      "&:disabled": {
        background: colors.cmyk,
      },
    },
    error: {},
    errorText: {
      color: colors.red,
      paddingTop: "6px",
      width: "436px",
    },
    downArrow: {
      pointerEvents: "none",
      position: "absolute",
      left: "calc(100% - 10%)",
      top: "46px",
      "& path": {
        stroke: colors.black,
      },
    },
  };
};
