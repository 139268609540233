export const Styles = ({ colors }) => ({
  tickmark: {
    position: "relative",
    display: "inline-block",
    width: "16px",
    height: "16px",
    "&::before": {
      position: "absolute",
      left: "0",
      top: "50%",
      height: "50%",
      width: "2px",
      backgroundColor: colors.primary,
      content: '""',
      transform: "translateX(10px) rotate(-45deg)",
      transformOrigin: "left bottom",
    },
    "&::after": {
      position: "absolute",
      left: "0",
      bottom: "0",
      height: "2px",
      width: "100%",
      backgroundColor: colors.primary,
      content: '""',
      transform: "translateX(10px) rotate(-45deg)",
      transformOrigin: "left bottom",
    },
  },
});
