export const Warnings = {
  Red: "red",
  Yellow: "yellow",
  Green: "green",
  Grey: "grey",
};

export const Actions = {
  MissingUPRN: "missing uprn",
  ConfirmPin: "confirm pin location",
  LicenceRequired: "licence required",
  LicenceExpiring: "licence expiring soon",
  LicenceExpired: "licence expired",
  DocumentsExpiring: "documents expiring soon",
  DocumentsExpired: "documents expired",
  PlanningRequired: "planning required",
};
