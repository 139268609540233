import { useOnClickOutside } from "Hooks/ClickOutside";
import propTypes from "prop-types";
import { useRef } from "react";
import { Overlay } from "views/Overlay/Overlay";
import styles from "./Modal.module.css";

const ModalV2 = (props) => {
  const ref = useRef(null);
  useOnClickOutside(ref, props.onClose);
  return (
    <Overlay>
      <div className={styles.container} ref={ref}>
        {props.children}
      </div>
    </Overlay>
  );
};

ModalV2.propTypes = {
  onClose: propTypes.func,
};

ModalV2.defaultProps = {
  onClose: () => {},
};

export default ModalV2;
