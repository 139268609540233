import useInput from "Hooks/useInput";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import React from "react";
import {
  privacyPolicyURL,
  termsAndConditionsURL,
} from "../../../../constants/AppConstants";
import Input from "../../Form/Input/Input";
import Link from "../../Link/Link";
import Typography from "../../Typography/Typography";
import styles from "./SubmitJobDetails.module.css";
import UploadLayout from "./SubmitJobDetailsLayout";
const SubmitJobDetails = (props) => {
  const [firstName, firstNameError, setFirstName, checkFirstName] = useInput(
    props.firstName,
    "This field is required"
  );

  const [lastName, lastNameError, setLastName, checkLastName] = useInput(
    props.lastName,
    "This field is required"
  );

  const [email, emailError, setEmail, checkEmail] = useInput(
    props.email,
    "An email is required"
  );

  const [number, numberError, setNumber, checkNumber] = useInput(
    props.number,
    "A contact number is required"
  );

  const [details, detailsError, setDetails, checkDetails] = useInput(
    props.details,
    "details are required"
  );

  const checkFormFields = React.useCallback(() => {
    checkFirstName();
    checkLastName();
    checkEmail();
    checkNumber();
  }, [checkFirstName, checkLastName, checkEmail, checkNumber]);

  const onSave = () => {
    checkFormFields();
    if (firstName !== "" && lastName !== "" && email !== "" && number !== "") {
      props.onClose();
      props.onSave({
        firstName,
        lastName,
        email,
        number,
        details,
      });
    }
  };

  useWhyDidYouUpdate("Submit Job Details", props);
  return (
    <div className={styles.container}>
      <UploadLayout title={props.title} onClose={props.onClose} onSave={onSave}>
        <div className={styles.row}>
          <Input
            label="First Name"
            placeholder="Enter your first name"
            value={firstName}
            onChange={setFirstName}
            error={firstNameError}
          />

          <Input
            error={lastNameError}
            placeholder="Enter your last name"
            value={lastName}
            onChange={setLastName}
            label="Last Name"
            name="lastName"
          />
        </div>
        <div className={styles.row}>
          <Input
            type="email"
            name="email"
            label="Email address"
            placeholder="Enter your email address"
            value={email}
            onChange={setEmail}
            error={emailError}
          />

          <Input
            type="number"
            name="contactNumber"
            label="Contact number"
            placeholder="Enter your contact number"
            value={number}
            onChange={setNumber}
            error={numberError}
          />
        </div>
        <div className={styles.jobDetailsrow}>
          <Typography as="">Job details</Typography>

          <textarea
            type="text"
            name="jobDetails"
            placeholder="Describe your job details"
            label="Job details"
            value={details}
            onChange={setDetails}
            className={styles.textarea}
          />
        </div>
        <div className={styles.checkboxRow}>
          <Typography as="termsAndConditions">
            By submitting your job details, you agree to Yuno’s
            <Link as="termsAndConditions" path={termsAndConditionsURL}>
              {" Terms"}
            </Link>
            {" and "}
            <Link as="termsAndConditions" path={privacyPolicyURL}>
              Privacy Policy.
            </Link>
          </Typography>
        </div>
      </UploadLayout>
    </div>
  );
};

SubmitJobDetails.propTypes = {
  firstName: propTypes.string,
  lastName: propTypes.string,
  email: propTypes.string,
  number: propTypes.string,
  files: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      url: propTypes.string,
    })
  ),
  onClose: propTypes.func,
  onSave: propTypes.func,
};

SubmitJobDetails.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  firstName: "",
  lastName: "",
  email: "",
  number: "",
  details: "",
  title: "Submit your job details",
  files: [],
};

export default SubmitJobDetails;
