import { combineReducers } from "redux";
import { reducer as PropertyReducer } from "./PropertyDetails/reducer";
import { reducer as LicenseReducer } from "./License/reducer";
import { reducer as DocumentReducer } from "./PropertyLicense";
import { reducer as Addresses } from "./Address";
import { reducer as WebInfo } from "./WebInfo";
import { reducer as LoginInfo } from "./Login";

export const reducer = combineReducers({
  PropertyReducer,
  LicenseReducer,
  DocumentReducer,
  Addresses,
  WebInfo,
  LoginInfo,
});
