import { loginUser, signupAgentUser, landlordRegister } from "Apis";
import { storeUserAuth } from "utils";
import { USRE_INFO } from "./constants";

export const userAuth = (data) => (dispatch) =>
  new Promise((Resolve, Rejected) => {
    loginUser(data)
      .then(({ data }) => {
        storeUserAuth(data);
        dispatch({ type: USRE_INFO, payload: data });
        Resolve(data);
      })
      .catch((err) => Rejected(err));
  });

export const userRegister = (data) => (dispatch) =>
  new Promise((Resolve, Rejected) => {
    signupAgentUser(data)
      .then(({ data }) => {
        storeUserAuth(data);
        dispatch({ type: USRE_INFO, payload: data });
        Resolve(data);
      })
      .catch((err) => Rejected(err));
  });

export const landLordRegister = (data) => (dispatch) =>
  new Promise((Resolve, Rejected) => {
    landlordRegister(data)
      .then(({ data }) => {
        storeUserAuth(data);
        dispatch({ type: USRE_INFO, payload: data });
        Resolve(data);
      })
      .catch((err) => Rejected(err));
  });
