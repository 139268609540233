export const Styles = ({ colors, breakpoints }) => ({
  main: {
    display: "flex",
    "& .input-div-inactive": {
      position: "relative",
      width: "auto",
      [breakpoints.RESPONSIVE]: {
        width: "93%",
      },
      "& .arrow": {
        position: "absolute",
        right: "0",
        top: "0",
        margin: "24px 22px 0 0",
        cursor: "pointer",
        [breakpoints.RESPONSIVE]: {
          margin: "24px 0 0 0",
        },
        "& path": {
          stroke: colors.primary,
        },
      },
    },
  },
});
