import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import theme from "theme";
import {
  hostName,
  setWebInfo,
  storeWebInfo,
  getWebInfo as localInfo,
  subdomain,
} from "utils";
import { getDomainInfo } from "store/WebInfo";

const constants = {
  local: "localhost:3000",
  name: "yuno",
};
export const WebHook = (apiCall = true) => {
  const dispatch = useDispatch(hostName);
  const { colors } = theme;
  const {
    logoUrl,
    name = subdomain() || "yuno",
    faviconUrl,
    primaryColour,
    secondaryColour,
  } = localInfo();
  const [themes, setThemes] = useState(theme);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(logoUrl);
  useEffect(() => {
    if (apiCall) {
      getWebInfo();
      setWebInfo({ name, faviconUrl });
      setPrimaryColor(primaryColour, secondaryColour);
    }
  }, []);
  const getWebInfo = () => {
    dispatch(getDomainInfo())
      .then((webInfo) => {
        setWebInfo(webInfo);
        const { logoUrl, primaryColour, secondaryColour } = webInfo;
        setLogo(logoUrl);
        setPrimaryColor(primaryColour, secondaryColour);
        storeWebInfo(webInfo);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const setPrimaryColor = (primary, hamptonsSecondary) => {
    if (primary) {
      setThemes({
        ...theme,
        colors: {
          ...colors,
          primary,
          hamptonsSecondary,
          primary: primary,
          secondary: hamptonsSecondary,
        },
      });
    }
  };
  return [
    {
      loading,
      themes,
      colors,
      logo,
    },
    setThemes,
  ];
};
