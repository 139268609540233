import {
  saveData,
  savePropertyDetails,
  updateData,
  updatePropertyDetails,
} from "Apis";
import { Logo } from "images";
import { hamptons } from "theme/colors";
import { londonPostCodeRegex } from "utils/FormValidation/regexConstants";
import mixpanel from "utils/mixPanel";

export const subdomain = () =>
  window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : false;
export const checkWebInIframe = window.location !== window.parent.location;
export const hostName = window.location.host;
export const getHostName = (url) => {
  const link = document.createElement("a");
  link.href = url;
  return link.hostname;
};
export const storeWebInfo = (data) => {
  localStorage.setItem("webInfo", JSON.stringify(data));
};
export const getPropertyUuid = () => {
  const uuid = window.location.pathname.split("/")[2];
  if (
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      uuid
    )
  )
    return uuid;
};

export const getWebInfo = () => {
  const webInfo = localStorage.getItem("webInfo");
  if (webInfo) {
    return JSON.parse(webInfo);
  }
  const { primary, hamptonsSecondary } = hamptons;
  return {
    yunoDomain: "",
    logoUrl: Logo,
    name: subdomain() || "yuno",
    faviconUrl: "",
    primaryColour: primary,
    secondaryColour: hamptonsSecondary,
    embedded: true,
    leadgen: true,
    updateKey: "",
  };
};
export const getLoginInfo = () => {
  const customer = localStorage.getItem("authUser");

  if (customer) {
    return JSON.parse(customer);
  }
  return {
    userId: "",
    email: "",
    role: "",
    accessToken: "",
  };
};

export const checkAuth = () => {
  const { accessToken } = getLoginInfo();
  if (accessToken || checkWebInIframe) {
    return true;
  }
  return false;
};

export const propertyVisited = () => {
  const loginInfo = getLoginInfo();
  let visitedProperties = loginInfo.visitedProperties || [];
  const uuid = getPropertyUuid();
  if (!uuid) return true;
  const visited =
    visitedProperties &&
    visitedProperties.length > 0 &&
    visitedProperties.includes(uuid);
  if (!visited) {
    visitedProperties.push(uuid);
    localStorage.setItem("authUser", JSON.stringify(loginInfo));
  }
  return visited;
};

export const getRecentTenancy = (tenancies) => {
  if (tenancies.length === 0)
    // If no tenancies return false (Don't display anything)
    return false;

  if (tenancies.length === 1)
    // If there is only one tenancy, return that
    return tenancies[0];

  // If there are more than 1 tenancies, additional checks needed

  // Firstly get tenancy with latest startDate
  const latestStartDate = tenancies.reduce((a, b) => {
    return new Date(a.tenancyStartDate) > new Date(b.tenancyStartDate) ? a : b;
  });

  // Save new list of tenancies with the latest start date
  const latestStartDateTenancies = tenancies.filter(
    (t) => t.tenancyStartDate === latestStartDate.tenancyStartDate
  );

  // If there is only one tenancy, return that tenancy
  if (latestStartDateTenancies.length === 1) return latestStartDate;

  // If there was more than one, next check to see most recent "updatedAt" date
  const latestUpdated = latestStartDateTenancies.reduce((a, b) => {
    return new Date(a.updatedAt) > new Date(b.updatedAt) ? a : b;
  });

  // Save new list of all tenancies with the same and most recent "updatedAt" date
  const latestUpdatedTenancies = latestStartDateTenancies.filter(
    (t) => t.updatedAt === latestUpdated.updatedAt
  );

  // If there is only one tenancy, return it
  if (latestUpdatedTenancies.length == 1) return latestUpdated;

  // If there was more than one, next check to see most recent "createdAt" date
  const latestCreated = latestUpdatedTenancies.reduce((a, b) => {
    return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
  });

  // Return latest created
  return latestCreated;
};

export const pageVisited = () => {
  const loginInfo = getLoginInfo();
  let visitedPages = loginInfo.visitedPages || [];
  const page = window.location.pathname;
  if (!page) return true;
  const visited =
    visitedPages && visitedPages.length > 0 && visitedPages.includes(page);
  if (!visited) {
    visitedPages.push(page);
    localStorage.setItem("authUser", JSON.stringify(loginInfo));
  }
  return visited;
};

export const checkAuthInIframe = () => {
  const { accessToken } = getLoginInfo();
  if (accessToken && checkWebInIframe) {
    return true;
  }
  return false;
};

export const setWebInfo = ({ faviconUrl, name }) => {
  if (faviconUrl) {
    const favIcon = document.getElementById("favicon");
    favIcon.href = faviconUrl;
  }
  document.title = name;
};

export const parseProperty = (data) => {
  const {
    licences = [{ documents: [] }],
    certificates = [],
    planning = [],
  } = data;
  const certificateIds = [];
  const licenceIds = [];
  const newPlanning = [];
  if (licences.length > 0) {
    licences.forEach((value) => {
      value.documents.forEach((documentId) => {
        licenceIds.push(documentId.licenceDocumentId);
      });
    });
  }
  certificates.forEach((value) => {
    certificateIds.push(value.certificateDocumentId);
  });
  planning.forEach((value) => {
    newPlanning.push(value.label);
  });
  return {
    certificateIds,
    licenceIds,
    planning: newPlanning,
    manualCheckRequested: "false",
    manualCheckComplete: "false",
  };
};

export const imageToBase64 = async (url) => {
  if (Logo === url) {
    return Logo;
  }
  return fetch(url, { cache: "no-cache" })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
};

export const mailObject = ({ propertyDetails, documents, address }) => {
  delete address.latitude;
  delete address.longitude;
  const { certificates, licences, totalCost } = documents;
  const {
    commercialPremises,
    numberOfHouseholds,
    numberOfStoreys,
    numberOfTenants,
    propertyCategory,
    propertyType,
    roomUnit,
    tenancyType,
  } = propertyDetails;
  let addressDiv = "";
  let certificatesDiv = "";
  let licencesDiv = "";
  Object.values(address).forEach((value) => {
    if (value) {
      addressDiv += `${value}, %0a`;
    }
  });
  certificates.forEach((value) => {
    certificatesDiv += `${value.label} - ~ £ ${value.price}  %0a`;
  });
  licences.forEach((value) => {
    licencesDiv += `This property could need a ${value.licenceType} licence - ~ £ ${value.cost}  %0a`;
    value.documents.forEach((label) => {
      licencesDiv += `  -${label.label}%0a`;
    });
  });
  return `mailto:${subdomain()}@goyuno.com?subject=Property Report Enquiry
	&body=Hi,%0A%0a.

	%0A%0a Property Address %0A%0a
		${addressDiv}
		%0A%0a Property Details %0A%0a
		Tenants: ${numberOfTenants || "-"}%0a
		Household: ${numberOfHouseholds || "-"}%0a
		Storeys: ${numberOfStoreys || "-"}%0a
		Rooms / Units: ${roomUnit || "-"}%0a
		Property Category: ${propertyCategory || "-"}%0a
		Property Type: ${propertyType || "-"}%0a
		Tenancy Type: ${tenancyType || "-"}%0a
		Commercial premises in building??: ${commercialPremises || "-"}%0a
		%0A%0a Property Report %0A%0a
		${certificatesDiv}
		${licencesDiv}
		%0A%0a Total Cost :- ${totalCost}%0A%0a
		%0A%0a Created at :- ${dateFormat()}%0A%0a
		`;
};

export const removeEmptyValue = (data) => {
  const newData = { ...data };
  for (const type in newData) {
    if (newData[type] === null || newData[type] === undefined) {
      delete newData[type];
    }
  }
  return newData;
};

export const makeAirTableObject = (data, id) => {
  const dataObject = {
    records: [
      {
        fields: {
          ...removeEmptyValue(data),
          Customer: subdomain(),
        },
      },
    ],
  };
  if (id !== 0) {
    dataObject.records[0].id = id;
  }
  return dataObject;
};

export const parsePropertyData = (data, isDB = false) => {
  const newData = { ...data };
  const {
    numberOfTenants = 0,
    numberOfHouseholds = 0,
    numberOfStoreys = 0,
    roomUnit = 0,
    commercialPremises = "",
    status = "Items outstanding",
  } = newData;
  delete newData.commercialPremises;
  delete newData.roomUnit;
  const roomUnits = parseInt(roomUnit);
  const parseData = {
    ...newData,
    numberOfTenants: parseInt(numberOfTenants),
    numberOfHouseholds: parseInt(numberOfHouseholds),
    numberOfStoreys: parseInt(numberOfStoreys),
    numberOfRoomsOrUnits: roomUnits,
    commercialPremises,
  };

  if (isDB) {
    parseData.status = status;
    parseData.roomsOrUnits = roomUnits;
  }
  return parseData;
};

export const savePropertyDetailsDb = (
  { propertyDetails, address, License, leadId },
  id
) => {
  const {
    address_line_one,
    flatName,
    buildingName,
    buildingNumber,
    town,
    postcode,
    uprn,
    latitude,
    longitude,
  } = address;
  const { councilName, ward } = License;
  const propertyData = {
    ...parsePropertyData(propertyDetails, true),
    addressLine1: address_line_one,
    flatName,
    buildingName,
    city: town,
    postcode,
    buildingNumber,
    UPRN: uprn || 0,
    council: councilName,
    ward,
    leadId,
    latitude,
    longitude,
  };
  if (id !== 0) {
    mixpanel.track("Complete property info", removeEmptyValue(propertyData));
    return updatePropertyDetails(id, removeEmptyValue(propertyData));
  }
  return savePropertyDetails(removeEmptyValue(propertyData));
};

export const saveDataOnAirTable = (data, isEdit) => {
  const { updateKey } = getWebInfo();
  if (!updateKey || isEdit) {
    return new Promise((Resolve) => {
      saveData(makeAirTableObject(data, 0))
        .then(({ data }) => {
          const { records } = JSON.parse(data);
          const webInfo = getWebInfo();
          webInfo.updateKey = records[0].id;
          storeWebInfo(webInfo);
          Resolve(webInfo);
        })
        .catch((err) => Resolve(err));
    });
  }
  return new Promise((Resolve) => {
    updateData(makeAirTableObject(data, updateKey))
      .then(({ data }) => {
        Resolve(data);
      })
      .catch((err) => {
        Resolve(err);
      });
  });
};

export const parseLicence = (data) => {
  const { licences = [], certificates = [], planning = [] } = data;
  const certificate = [];
  const licence = [];
  const newPlanning = [];
  licences.forEach((value) => {
    licence.push(value.licenceType);
  });
  certificates.forEach((value) => {
    certificate.push(value.label);
  });
  planning.forEach((value) => {
    newPlanning.push(value.label);
  });
  return {
    certificates: certificate.join(","),
    licence: licence.join(","),
    planning: newPlanning.join(","),
  };
};

export const parseLondonPostCode = (address) =>
  address.match(londonPostCodeRegex)
    ? address.match(londonPostCodeRegex)[0]
    : "";

const addZero = (value) => {
  if (value < 10) {
    value = `0${value}`;
  }
  return value;
};

export const dateFormat = () => {
  const today = new Date();
  const day = addZero(today.getDate());
  const month = addZero(today.getMonth() + 1);
  const year = today.getFullYear();
  const hr = addZero(today.getHours());
  const min = addZero(today.getMinutes());
  const sec = addZero(today.getSeconds());
  return `${day}/${month}/${year} ${hr}:${min}:${sec}`;
};

export const storeUserAuth = (data) => {
  Object.assign(data, { visitedProperties: [], visitedPages: [] });
  localStorage.setItem("authUser", JSON.stringify(data));
};

export const parseUrl = (queryParameter, key = "") => {
  if (key) {
    return new URLSearchParams(queryParameter).get(key);
  }
  return new URLSearchParams(queryParameter);
};
export const removeHash = (value) => {
  if (!value) return false;
  return value.replace("#", "");
};

export const minDeskTopWidth = 786;

export const formatter = (number) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
  })
    .format(number)
    .replace(/^(\D+)/, " $1");
export const replaceAll = (string, search, replace) =>
  string.split(search).join(replace);
export const diffDate = (date) => {
  const date1 = new Date();
  const date2 = new Date(date);
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
export const checkTime = (date) => {
  const currentTime = Math.round(new Date().getTime() / 1000, 0);
  const enterDate = Math.round(new Date(date).getTime() / 1000, 0);
  return currentTime > enterDate;
};
export const parseBool = (string) => String(string).toLowerCase() === "true";
export const hexToRgb = (hex, opacity = 0) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${opacity})`
    : null;
};
export const filterDataArray = (array, key) => {
  let i = 0;
  const object = {};
  array.forEach((val) => {
    if (val.checked) {
      object[`${key}[${i}]`] = val.key;
      i++;
    }
  });
  return object;
};

export const firstUpperLetter = (string = "") => {
  if (!string) return "";
  if (string.length === 1) return string;

  const stringArray = string.split(" ");
  return stringArray
    .map((val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
    .join(" ")
    .replace("Null", "");
};

/**
 * Takes a address object and returns a string with the formated address
 *
 * @param {object} address - address information
 * @param {string} [address.flatName] - flat name
 * @param {string} [address.buildingName] - building name
 * @param {string} address.buildingNumber - building number
 * @param {string} address.address_line_one - building address
 * @param {string} address.town - town
 * @param {string} [address.postcode] - postcode
 */
export const formatAddress = (address) => {
  if (!Object.keys(address).length) {
    return "";
  }
  // be carefully to not delete the spaces in the end each line of the template string.
  let formattedAddress = `
    ${
      address.flatName && address.flatName !== "" ? `${address.flatName},` : ""
    } 
    ${
      address.buildingName && address.buildingName !== ""
        ? address.buildingName
        : ""
    } 
    ${address.buildingNumber} 
    ${address.addressLine1}, 
    ${address.city}`;
  formattedAddress = firstUpperLetter(formattedAddress);
  if (address.postcode) {
    formattedAddress += `, ${address.postcode}`;
  }

  return formattedAddress.replace("Null", "");
};
