import {
  approveWorkOrder,
  certificatesURL,
  checkRequest,
  councils,
  documents,
  emailTemplate,
  forgetPasswordEmail,
  freeHolder,
  freeHolderNotify,
  getDomainInfo,
  getS3Url,
  gets3UrlPlanning,
  inActiveCouncils,
  landlordInfo,
  landlordSignup,
  leadsUrl,
  licencesURL,
  loginUrl,
  notifyForApproval,
  osApiURL,
  packages,
  propertyAddVerifiedURL,
  updateProperty,
  propertyMatchCoordinate,
  propertyMatchURL,
  propertyStats,
  propertyURL,
  resetPassword as createNewPassword,
  saveDataAirTable,
  signupAgent,
  submitWorkOrderUrl,
  updateDocument,
  boundingPolygonsURL,
} from "constants/urls";
import { DELETE, GET, Patch, POST, PUT } from "utils";
import mixpanel from "../utils/mixPanel";

export const getGuideLine = (postCode) => GET(councils(postCode));

export const checkPostCode = (postCode) => GET(councils(postCode));

export const getDocuments = (postCode, params) =>
  GET(documents(postCode), params);

export const getWebInfo = () => GET(getDomainInfo);

export const saveData = (data) => POST(saveDataAirTable, data);
export const submitWorkOrder = ({ propertyId, packageId }) => {
  mixpanel.track("Submit work order", {
    propertyId,
    packageId,
  });
  return POST(submitWorkOrderUrl(propertyId), { packageId });
};
export const updateData = (data) => Patch(saveDataAirTable, data);

export const loginUser = (data) => POST(loginUrl, data);

export const savePropertyDetails = (data) => {
  mixpanel.track("Create Property", { ...data });

  return POST(propertyAddVerifiedURL, data);
};
export const updatePropertyAddress = (data, propertyId) => {
  return PUT(updateProperty(propertyId), data);
};
export const matchPropertyByPostcode = (data) => POST(propertyMatchURL, data);
export const matchPropertyByCoordinate = (data) =>
  POST(propertyMatchCoordinate, data);

export const updatePropertyDetails = (id, data) =>
  PUT(`${propertyURL}/${id}`, data);

export const getProperties = (perPage, page, fields = {}) =>
  GET(propertyURL, { perPage, page, ...fields });

export const getPropertiesInBoundingBox = (minLong, minLat, maxLong, maxLat) =>
  GET(
    `${propertyURL}/boundingBox?minLat=${minLat}&minLong=${minLong}&maxLat=${maxLat}&maxLong=${maxLong}`
  );

export const getPolygons = async (minLong, minLat, maxLong, maxLat) => {
  const data = {
    lat1: minLat,
    lat2: maxLat,
    long1: minLong,
    long2: maxLong,
  };
  POST(boundingPolygonsURL, data);
};

export const getAllPackages = () => GET(packages);

export const deleteProperties = (id) => DELETE(`${propertyURL}/${id}`);

export const getPropertyDetails = (id, token) =>
  GET(
    `${propertyURL}/${id}`,
    token
      ? {
          token,
        }
      : ""
  );

export const getPropertyStats = () => GET(propertyStats);

export const getS3details = (propertyId, propertyCertificateId, type) => {
  let url = getS3Url(propertyId, propertyCertificateId, type);
  const data = {
    contentType: "application/pdf",
  };
  if (isNaN(propertyCertificateId)) {
    url = gets3UrlPlanning(propertyId);
    Object.assign(data, {
      planningType: propertyCertificateId,
    });
  }
  return POST(url, data);
};
export const uploadFileS3Bucket = (url, data, options) =>
  POST(url, data, options);

export const uploadDocumentInDb = (
  propertyId,
  propertyCertificateId,
  data,
  type
) => {
  let url = updateDocument(propertyId, propertyCertificateId, type);
  if (isNaN(propertyCertificateId)) {
    url = updateDocument(propertyId, "", type);
    Object.assign(data, {
      planningType: propertyCertificateId,
    });
  }
  return PUT(url, data);
};

export const setLicenceDocState = ({ propertyId, licenceId }, data) =>
  Patch(`${propertyURL}/${propertyId}/${licencesURL}/${licenceId}`, data);

export const setCertificateDocState = ({ propertyId, certificateId }, data) =>
  Patch(
    `${propertyURL}/${propertyId}/${certificatesURL}/${certificateId}`,
    data
  );
export const setPlanningDocState = ({ propertyId }, data) =>
  Patch(`${propertyURL}/${propertyId}/planning`, data);
/**
 * get a lis of addresses to the search term.
 *
 * @param {string} query - search term
 * @param {object} options - request options
 * @param {boolean} [options.precision] - if true increase the precision when looking for the address
 * @returns {Array<object>}
 */
export const getOsApiList = (query, options) => {
  mixpanel.track("Address search", { query });
  return GET(osApiURL, {
    address: query,
  });
};
export const signupAgentUser = (data) => POST(signupAgent, data);
export const saveToLead = (data) => POST(leadsUrl, data);
export const submitCheckRequest = (propertyId, data) =>
  POST(checkRequest(propertyId), data);
export const savePropertyFreeHolder = (propertyId, data) =>
  POST(freeHolder(propertyId), data);
export const sentNotification = (propertyId) =>
  POST(freeHolderNotify(propertyId));
export const getInactive = () => GET(inActiveCouncils, { limit: 500 });
export const resetPassword = (email) => POST(forgetPasswordEmail, { email });
export const changePassword = (data) => POST(createNewPassword, data);
export const getEmailTemplate = () => GET(emailTemplate);
export const sendApprovalEmail = (data, propertyId) => {
  return POST(notifyForApproval(propertyId), data);
};
export const approveWorkOrderRequest = (data) =>
  POST(approveWorkOrder(data), data);
export const getLandlordInfo = (propertyId) => GET(landlordInfo(propertyId));
export const deleteLandlordInfo = (propertyId, landlordId) =>
  DELETE(landlordInfo(propertyId, landlordId));
export const landlordRegister = (data) => POST(landlordSignup, data);
