import React, { useRef, useEffect } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, Links } from "components";
import { Typography } from "v2/components";
import { hmoPopData } from "constants/AppConstants";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { useWindowDimensions } from "utils";
import theme from "theme";
import { styles } from "./styles";

const { colors } = theme;
const setting = {
  width: 640,
  height: 520,
  top: 80,
  color: colors.hexaWhite,
};
const {
  heading,
  subheading: { first, second },
  notes,
  hmoText,
} = hmoPopData;
const HMO = ({ isOpen, onClose, classes }) => {
  const { width } = useWindowDimensions();
  setting.width = width > 800 ? 640 : "80%";
  const wrapperRef = useRef(null);
  const handleClick = ({ target, offsetX }) => {
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      if (offsetX <= target.clientWidth) {
        onClose();
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  return (
    <Modal
      isOpen={isOpen}
      setting={setting}
      ref={wrapperRef}
      className={classes.hmo}
    >
      <div className={classes.main}>
        <div className={classes.exitButton}>
          <CrossIcon onClick={onClose} onKeyDown={onClose} />
        </div>
        <div className={classes.text}>
          <Typography as="subtitle" color={colors.black}>
            {heading}
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography as="p" fontWeight="600">
            {first.heading}
          </Typography>
          <div className="list-view">
            {first.text.map((value, key) => (
              <div key={key}>
                <span>{value}</span> <br />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.bottomSection}>
          <Typography as="p" fontWeight="600">
            {second.heading}
          </Typography>
          <div className="number-view">
            {second.text.map((value, key) => (
              <div key={key}>
                <span>{value}</span> <br />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.note}>{notes}</div>
        <div className={classes.endNote}>
          {hmoText.first}{" "}
          <Links as="_blank" path={hmoText.url} className="link">
            {hmoText.urlText}
          </Links>{" "}
          {hmoText.last}
        </div>
      </div>
    </Modal>
  );
};
HMO.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(HMO);
