import React from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { Button } from "components";
import { Typography } from "v2/components";
import { styles } from "./styles";

const Heading = ({
  classes,
  onClick = () => null,
  className = "",
  heading,
  buttonText = "",
  buttonText2 = "",
  isButton = true,
}) => (
  <div className={`${classes.main} ${className}`}>
    <Typography as="h6" className="main-heading">
      {heading}
    </Typography>
    {isButton && (
      <>
        <Button className="button br-4" onClick={onClick}>
          {buttonText2}
        </Button>
      </>
    )}
  </div>
);

Heading.propTypes = {
  heading: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonText2: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default withStyles(styles)(Heading);
