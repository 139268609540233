import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Link, NavLink } from "react-router-dom";
import { Styles } from "./styles";

const Links = ({
  children,
  as,
  linlProps,
  path = "",
  classes,
  onClick,
  className,
}) => {
  switch (as) {
    case "a":
      return (
        <a href={path} className={`${classes.atag} ${className}`}>
          {children}
        </a>
      );
    case "link":
      return (
        <Link to={path} className={`${classes.aLink} ${className}`}>
          {children}
        </Link>
      );
    case "navLink":
      return (
        <NavLink href={path} className={`${classes.aLink} ${className}`}>
          {children}
        </NavLink>
      );
    case "click":
      return (
        <span
          className={`${classes.spanClick} ${className}`}
          role="button"
          tabIndex="0"
          onClick={onClick}
          onKeyPress={onClick}
          {...linlProps}
        >
          {children}
        </span>
      );
    case "_blank":
      return (
        <a
          rel="noopener noreferrer"
          href={path}
          target="_blank"
          className={` ${classes.atag} ${className}`}
        >
          {children}
        </a>
      );

    default:
      return (
        <p className={`${className}`} {...linlProps}>
          {children}
        </p>
      );
  }
};

Links.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.node.isRequired,
  as: propTypes.node,
  linlProps: propTypes.object,
  onClick: propTypes.func,
  className: propTypes.string,
};

Links.defaultProps = {
  as: "p",
  linlProps: {},
  children: "",
  className: "",
  onClick: null,
};

export default withStyles(Styles)(Links);
