import React, { useState } from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import { SimpleButton, PropertyDetails, LicenseList, Links } from "components";
import { Typography } from "v2/components";
import { useWindowDimensions, checkWebInIframe, subdomain } from "utils";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as LocationIcon } from "images/location.svg";
import { useStyles } from "./styles";

const AddressBox = ({
  theme,
  ChangeAddress,
  address,
  PropertyForm,
  licenseList,
  councilName = null,
  showPropertyDetails = false,
  showLicenseList = false,
  ChangeProperty = () => null,
}) => {
  const classes = useStyles(theme);
  const { width } = useWindowDimensions();
  const showDetails = width > 700;
  const [showAddress, setShowAddress] = useState(showDetails);
  const {
    address_line_one,
    buildingName,
    buildingNumber,
    postcode,
    flatName,
    town,
  } = address;
  const { councilGuidelines = [] } = licenseList;
  return (
    <div className={classes.main}>
      <div className="address-line" />
      <div className="content">
        <div className={classes.headingDiv}>
          <div className={classes.locationIcon}>
            <LocationIcon />
          </div>
          <div className={classes.heading}>
            <Typography as="subtitle" className="heading">
              Property location
            </Typography>
            {showAddress && <ArrowUp onClick={() => setShowAddress(false)} />}
            {!showAddress && <ArrowDown onClick={() => setShowAddress(true)} />}
          </div>
        </div>
        {showAddress && (
          <div className={classes.addressList}>
            <u>
              {flatName && (
                <span>
                  {flatName}
                  <br />
                </span>
              )}
              {buildingName && (
                <span>
                  {buildingName}
                  <br />
                </span>
              )}
              {(buildingNumber || address_line_one) && (
                <span>
                  {`${buildingNumber} ${address_line_one}`}
                  <br />
                </span>
              )}
              {(postcode || town) && (
                <span>
                  {`${town} ${postcode}`}
                  <br />
                </span>
              )}
              {councilName && councilName}
            </u>
          </div>
        )}
        <div className={classes.buttonDiv}>
          <SimpleButton onClick={ChangeAddress}>Change</SimpleButton>
          {!checkWebInIframe && subdomain() !== "sprift" && (
            <Links
              as="link"
              path="/start-search"
              name="Search"
              className="link"
            >
              Start new search
            </Links>
          )}
        </div>
      </div>
      {showLicenseList &&
        councilGuidelines.map((value, key) => (
          <LicenseList
            key={key}
            text={value.title}
            showDetails={showDetails}
            gudieLines={value.guidelineText}
          />
        ))}
      {showPropertyDetails && (
        <PropertyDetails
          PropertyForm={PropertyForm}
          ChangeProperty={ChangeProperty}
          showDetails={showDetails}
        />
      )}
    </div>
  );
};

AddressBox.propTypes = {
  theme: propTypes.object.isRequired,
  ChangeAddress: propTypes.func.isRequired,
  address: propTypes.object.isRequired,
  councilName: propTypes.string,
  showPropertyDetails: propTypes.bool,
  showLicenseList: propTypes.bool,
  ChangeProperty: propTypes.func,
  PropertyForm: propTypes.object,
};

export default withTheme(AddressBox);
