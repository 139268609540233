import { create } from "axios";
import { apiUrl as baseURL, getLoginInfo } from "utils";
import { serverErrors } from "./handleServerError";

export const axios = create({
  baseURL,
  headers: {
    common: { "Content-Type": "application/json" },
  },
});

axios.interceptors.response.use(
  (response) => successResponce(response),
  (error) => Promise.reject(serverErrors(error))
);

axios.interceptors.request.use(
  (config) => {
    const request = config;
    const { accessToken } = getLoginInfo();
    if (accessToken && request.url !== "lead") {
      request.headers.common["x-access-token"] = accessToken;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

const successResponce = (result) => {
  const { data } = result;
  return data;
};
