import React, { memo } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { styles } from "./styles";

export const Select = React.forwardRef(
  (
    {
      classes,
      options,
      name,
      onChange,
      selected,
      onBlur,
      onFocus,
      className,
      error,
      size,
      isPackage = false,
      ...props
    },
    ref
  ) => (
    <>
      <select
        {...props}
        name={name}
        defaultValue={selected}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
        className={`${classes.select} ${className}`}
        size={size}
      >
        {isPackage
          ? options.map(
              ({ name, packageId, priceRestOfUk, priceInLondon }, index) => (
                <option
                  value={packageId}
                  name={name}
                  priceInLondon={priceInLondon}
                  priceRestOfUk={priceRestOfUk}
                  key={index}
                >
                  {name}
                </option>
              )
            )
          : options.map(({ value, key }, index) => (
              <option value={key} key={index}>
                {value}
              </option>
            ))}
      </select>
      {error && <span className={classes.errorText}>{error}</span>}
      <ArrowDown className={classes.downArrow} />
    </>
  )
);

Select.propTypes = {
  type: propTypes.string,
  selected: propTypes.any,
  name: propTypes.string,
  classes: propTypes.object,
  options: propTypes.array,
  onChange: propTypes.func,
  selectedValue: propTypes.string,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  className: propTypes.string,
  isPackage: propTypes.bool,
};

Select.defaultProps = {
  selected: null,
  options: [],
  name: "name",
  classes: {},
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
  className: "",
  size: 0,
  isPackage: false,
};

export default withStyles(styles)(memo(Select));
