import { getDocuments as documentList } from "Apis";
import { DOCUMENT_LIST, DOCUMENT_ERROR, LOADING, APICALL } from "./constants";

export const getDocuments = (postCode, params) => (dispatch) => {
  dispatch({ type: APICALL, payload: {} });
  const apiParams = {};
  for (const key in params) {
    if (params[key]) {
      apiParams[key] = params[key];
    }
  }
  return new Promise((Resolve, Reject) => {
    documentList(postCode, apiParams)
      .then(({ data }) => {
        dispatch({ type: DOCUMENT_LIST, payload: data });
        Resolve(data);
      })
      .catch(({ clientError, message }) => {
        if (clientError) {
          dispatch({ type: DOCUMENT_ERROR, payload: message });
          Reject(clientError);
        }
      })
      .finally(() => {
        dispatch({ type: LOADING, payload: false });
      });
  });
};
