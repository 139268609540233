import React, { useState, useCallback, useEffect } from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import {
  Image,
  AnimationLoading,
  AddressDetails,
  ViewDetails as AddressInfo,
} from "components";
import { useWindowDimensions, propertyVisited } from "utils";
import { useHistory, useRouteMatch } from "react-router";
import styles from "./Header.module.css";
import Navbar from "../Navbar";
import { useToggle } from "Hooks/useToggle";
import { ReactComponent as ClosedMenu } from "images/closedMenu.svg";
import BreadcrumbMobile from "v2/components/Breadcrumb/BreadcrumbMobile.jsx";
import { checkFilter } from "views/web/Property/Journey/utils";
import GetSupport from "views/web/Property/GetSupport/GetSupport";
import Journey from "views/web/Property/Journey/Journey";
import PropertyInfo from "views/web/Property/PropertyInfo/PropertyInfo";
import { ReactComponent as PlanIcon } from "images/complaint.svg";
import { ReactComponent as PropertyIcon } from "images/propertyInfo.svg";
import { ReactComponent as HomeIcon } from "images/requirement.svg";
import { ReactComponent as ArrowDown } from "images/tabviewarrow.svg";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as Circle } from "images/circle.svg";
import { ReactComponent as Info } from "images/danger.svg";
import { NavLink } from "react-router-dom";
import useModal from "Hooks/useModal";
import { ModalV2, Typography } from "v2/components";
import StatusModal from "v2/components/PropertyStatusHighlight/StatusModal/StatusModal";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {
  usePropertyDetails,
  usePropertyUpdate,
} from "context/propertyContext/PropertyContext";

const Header = React.memo(
  ({ theme, logo, loading = false, currentPath, propertyDetails }) => {
    const [isModalOpen, openModal, closeModal, ModalData] = useModal();
    const { width } = useWindowDimensions();
    const history = useHistory();
    const { url } = useRouteMatch();
    const [isOpen, toggle] = useToggle(false);
    const [onProperty, setOnProperty] = useState(false);
    const [mapPage, setMapPage] = useState(false);

    const isVisited = propertyVisited();

    const [state, setState] = useState({
      isPaneOpen: !isVisited,
      isPaneOpenLeft: !isVisited,
    });

    const tabList = [
      {
        name: "Property Report",
        path: "property-info",
        Component: PropertyInfo,
        icon: PropertyIcon,
      },
      {
        name: "Journey",
        path: "journey",
        Component: Journey,
        icon: HomeIcon,
      },
      {
        name: "Get Support (Order)",
        path: "get-support",
        Component: GetSupport,
        icon: PlanIcon,
      },
    ];

    const handleEditClick = () => {
      history.push({
        pathname: `${url}/property-info`,
        state: { tabName: tabList.property },
      });
      toggle();
    };

    const goToDashboard = () => {
      closeModal();
      setState({ isPaneOpenLeft: false });
      history.push(`/dashboard/properties`);
    };

    const checkFilterCallback = useCallback(
      (filter) => {
        return checkFilter(propertyDetails, filter);
      },
      [propertyDetails]
    );
    useEffect(() => {
      if (
        window.location.pathname.includes("property-info") ||
        window.location.pathname.includes("journey") ||
        window.location.pathname.includes("get-support") ||
        window.location.pathname.includes("/map")
      ) {
        setOnProperty(true);
      } else setOnProperty(false);
      setMapPage(window.location.pathname.includes("/map"));
    }, [window.location.pathname]);

    const closeMenu = () => {
      closeModal();
      setState({ isPaneOpenLeft: false });
    };
    const closeMenuActions = () => {
      openModal();
      setState({ isPaneOpenLeft: false });
    };
    return (
      <>
        {!mapPage ? (
          <>
            <SlidingPane
              isOpen={state.isPaneOpenLeft}
              from="left"
              width={width <= 900 ? "70%" : width <= 1502 ? "30%" : "20%"}
              onRequestClose={() => setState({ isPaneOpenLeft: false })}
              style={{ "z-index": "99" }}
            >
              <div className={styles.selectedMobileMenu}>
                <div className={styles.navbar}>
                  <Navbar onProperty={onProperty} onClick={closeMenu} />
                  <div className={styles.closeIcon}>
                    <CrossIcon onClick={closeMenu} />
                  </div>
                </div>
                {window.location.pathname.includes("property") && (
                  <div>
                    <div className={styles.tabviewTitle}>Property</div>
                    <div
                      className={`${styles.tabview} ${styles.tabviewActive}`}
                    >
                      <NavLink
                        className={styles.navlinkActive}
                        activeClassName={`${styles.link} ${styles.selected}`}
                        to={{
                          pathname: `${url}/property-info`,
                        }}
                        onClick={closeMenu}
                      >
                        <PropertyIcon /> Property Report
                      </NavLink>
                    </div>
                    <div className={`${styles.arrow} ${styles.arrowActive}`}>
                      <ArrowDown />
                    </div>

                    <div className={styles.propertyReport}>
                      <div
                        className={
                          window.location.pathname.includes("overview")
                            ? `${styles.propertyReportPathActive}`
                            : `${styles.propertyReportPath}`
                        }
                      >
                        <NavLink
                          activeClassName={`${styles.link} ${styles.selected}`}
                          to={{
                            pathname: `${url}/property-info/overview`,
                          }}
                          onClick={closeMenu}
                        >
                          <Circle className={` ${styles.checked}`} />
                          Overview
                        </NavLink>
                      </div>
                      <div
                        className={
                          window.location.pathname.includes("compareRents")
                            ? `${styles.propertyReportPathActive}`
                            : `${styles.propertyReportPath}`
                        }
                      >
                        <NavLink
                          activeClassName={`${styles.link} ${styles.selected}`}
                          to={{
                            pathname: `${url}/property-info/compareRents`,
                          }}
                          onClick={closeMenu}
                        >
                          <Circle className={` ${styles.checked}`} />
                          Compare rents
                        </NavLink>
                      </div>

                      <div
                        className={
                          window.location.search.includes("pif")
                            ? styles.propertyReportPathActive
                            : styles.propertyReportPath
                        }
                      >
                        <NavLink
                          activeClassName={`${styles.link} ${styles.selected}`}
                          to={{
                            pathname: `${url}/property-info/overview`,
                            search: `?pif=true`,
                          }}
                          onClick={closeMenu}
                        >
                          <Circle className={` ${styles.checked}`} />
                          Property Input Form
                        </NavLink>
                      </div>
                    </div>

                    <div
                      className={
                        window.location.pathname.includes("journey") ||
                        window.location.pathname.includes("get-support")
                          ? `${styles.tabview} ${styles.tabviewActive}`
                          : `${styles.tabview}`
                      }
                    >
                      <NavLink
                        activeClassName={`${styles.link} ${styles.selected}`}
                        to={{
                          pathname: `${url}/journey`,
                        }}
                        onClick={closeMenu}
                      >
                        <HomeIcon /> Journey
                      </NavLink>
                    </div>
                    <div
                      className={
                        window.location.pathname.includes("journey") ||
                        window.location.pathname.includes("get-support")
                          ? `${styles.arrow} ${styles.arrowActive}`
                          : `${styles.arrow}`
                      }
                    >
                      <ArrowDown />
                    </div>
                    <div className={styles.breadcrumbMobile}>
                      <BreadcrumbMobile
                        checkFilter={checkFilterCallback}
                        closeMenu={closeMenu}
                      />
                    </div>
                    <div
                      className={
                        window.location.pathname.includes("get-support")
                          ? `${styles.tabview} ${styles.tabviewActive}`
                          : `${styles.tabview}`
                      }
                    >
                      <NavLink
                        activeClassName={`${styles.link} ${styles.selected}`}
                        to={{
                          pathname: `${url}/get-support`,
                        }}
                        onClick={closeMenu}
                      >
                        <PlanIcon /> Get Support
                      </NavLink>
                    </div>
                    <div className={styles.tabviewDetails}>
                      <div
                        className={styles.viewDetails}
                        onClick={closeMenuActions}
                        role="button"
                        tabIndex="0"
                      >
                        View Actions
                      </div>
                    </div>
                  </div>
                )}
                {!window.location.pathname.includes("property") && (
                  <div className={styles.slidePaneInfo}>
                    <Info />
                    <Typography as="heading">
                      Select or add a property to view your property menu
                    </Typography>
                  </div>
                )}
              </div>
            </SlidingPane>

            {isModalOpen && (
              <ModalV2 onClose={closeModal}>
                <StatusModal
                  onClose={closeModal}
                  actionRequired={propertyDetails.actionRequired}
                  status={"Uncompliant"}
                  onView={openModal}
                  property={propertyDetails}
                />
              </ModalV2>
            )}
            <div className={styles.header}>
              <div className={styles.burger}>
                <ClosedMenu
                  onClick={() =>
                    setState({ isPaneOpenLeft: !state.isPaneOpenLeft })
                  }
                />
              </div>
              <div className={styles.logo}>
                {loading && <AnimationLoading width="10px" />}
                {propertyDetails && (
                  <AddressDetails
                    onView={toggle}
                    isOpen={isOpen}
                    propertyDetails={propertyDetails}
                  />
                )}
                {!loading && (
                  <>
                    <Image
                      src={logo}
                      alt="logo"
                      id="app-logo"
                      className="image-logo"
                      onClick={goToDashboard}
                    />
                  </>
                )}
              </div>
            </div>
            {isOpen && propertyDetails && (
              <AddressInfo
                handleClose={toggle}
                handleEdit={handleEditClick}
                propertyDetails={propertyDetails}
              />
            )}
          </>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.logo}>
                {loading && <AnimationLoading width="10px" />}
                {!loading && (
                  <Image
                    src={logo}
                    alt="logo"
                    id="app-logo"
                    className="image-logo"
                    onClick={goToDashboard}
                  />
                )}
                {propertyDetails && (
                  <AddressDetails
                    onView={toggle}
                    isOpen={isOpen}
                    propertyDetails={propertyDetails}
                  />
                )}
              </div>
              <div className={styles.navbar}>
                <Navbar onProperty={onProperty} onClick={() => {}} />
              </div>
            </div>
            {isOpen && propertyDetails && (
              <AddressInfo
                handleClose={toggle}
                handleEdit={handleEditClick}
                propertyDetails={propertyDetails}
              />
            )}
          </>
        )}
      </>
    );
  }
);

Header.propTypes = {
  theme: propTypes.object.isRequired,
  logo: propTypes.string.isRequired,
  loading: propTypes.bool,
};

export default withTheme(Header);
