import BuildingCertification from "./StepComponents/BuildingCertification/BuildingCertification";
import Certificates from "./StepComponents/Certificates/Certificates";
import Common from "./StepComponents/Common/Common";
import LettingAndSales from "./StepComponents/LettingsAndSales/LettingAndSales";
import Licensing from "./StepComponents/Licensing/Licensing";
import Planning from "./StepComponents/Planning/Planning";

export const journeySteps = [
  {
    name: "Planning",
    path: "planning",
    eventName: "View planning section",
    filter: {
      propertyType: "*",
    },
    Component: Planning,
  },
  {
    name: "Licensing & Registers",
    path: "licensing",
    eventName: "View licensing section",
    filter: {
      propertyType: "*",
    },
    Component: Licensing,
  },
  {
    name: "Finance",
    path: "finance",
    filter: {
      propertyType: "*",
    },
    steps: [
      {
        name: "Mortgage",
        path: "mortgage",
        eventName: "View mortgage section",
        filter: {
          propertyType: "*",
        },
        Component: Common,
      },
      {
        name: "Insurance",
        path: "insurance",
        eventName: "View insurance section",
        filter: {
          propertyType: "*",
        },
        Component: Common,
      },
      {
        name: "Development / Upgrade",
        path: "development-upgrade",
        eventName: "View development-upgrade section",
        filter: {
          propertyType: "*",
        },
        Component: Common,
      },
      {
        name: "Financial Advice",
        path: "financial-advice",
        eventName: "View financial section",
        filter: {
          propertyType: "*",
        },
        Component: Common,
      },
      {
        name: "Tax",
        path: "tax",
        eventName: "View tax section",
        filter: {
          propertyType: "*",
        },
        Component: Common,
      },
    ],
  },

  {
    name: "Fire Safety",
    path: "fire-safety",
    eventName: "View fire safety section",
    filter: {
      propertyType: "*",
    },
    Component: Common,
  },
  {
    name: "Going Green (Energy)",
    path: "going-green",
    eventName: "View energy section",
    filter: {
      propertyType: "*",
    },
    Component: Common,
  },
  {
    name: "Design",
    path: "design",
    eventName: "View design section",
    filter: {
      propertyType: "*",
    },
    Component: Common,
  },
  {
    name: "Documentation and Certification",
    path: "documentation-and-certification",
    eventName: "View documentation and certification section",
    filter: {
      propertyType: "*",
    },
    Component: Certificates,
  },
  {
    name: "Building Certification",
    path: "building-certification",
    eventName: "View building certification section",
    filter: {
      propertyType: "Flat",
    },
    Component: BuildingCertification,
  },
  {
    name: "Leaseholder Certification",
    path: "leaseholder-certification",
    eventName: "View leaseholder certification section",
    filter: {
      propertyType: "Building",
    },
    Component: BuildingCertification,
  },
  {
    name: "Property Management, Letting and Sales",
    path: "property-management-letting-and-sales",
    eventName: "View property management letting and sales section",
    filter: {
      propertyType: "*",
    },
    Component: LettingAndSales,
  },
];
