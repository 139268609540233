export const styles = ({ colors, fonts, breakpoints }) => {
  const inputField = {
    display: "flex",
    color: colors.hexaWhite,
    fontFamily: fonts.primary,
    fontWeight: 500,
    outline: "none",
    [breakpoints.MOBILE_DEVICE]: {
      width: "271px",
      marginRight: 0,
      marginBottom: 18,
      fontSize: 14,
    },
    "&::-webkit-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-moz-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-ms-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
  };
  return {
    list: {
      ...inputField,
      "& .list": {
        outline: "none",
        padding: "6px 12px",
      },
      width: "100%",
      fontFamily: fonts.primary,
      background: colors.hexaWhite,
      fontSize: 16,
      border: "none",
      listStyleType: "none",
      padding: 0,
      position: "absolute",
      textTransform: "none",
      display: "flex",
      flexDirection: "column",
      height: 180,
      overflow: "scroll",
      overflowX: "hidden",
      "-moz-appearance": "none",
      boxShadow:
        "0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 16px rgba(9, 30, 66, 0.25)",
      borderRadius: "3px",
      margin: 0,
      [breakpoints.MOBILE_DEVICE]: {
        width: "100%",
      },
      "& :hover": {
        background: colors.listBackground,
      },
      "& li": {
        fontFamily: fonts.primary,
        marginBottom: "11px",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "1.5",
        padding: "0px",
        cursor: "pointer",
      },
      "-webkit-appearance": "none",
      "&::-moz-appearance": {
        display: "none",
      },
      color: colors.black,
      "&::-ms-expand": {
        display: "none",
      },
      "&:disabled": {
        background: colors.cmyk,
      },
    },
    error: {},
    errorText: {
      color: colors.red,
      paddingTop: "6px",
      width: "436px",
    },
    downArrow: {
      pointerEvents: "none",
      position: "absolute",
      left: "calc(100% - 10%)",
      top: "46px",
      "& path": {
        stroke: colors.black,
      },
    },
  };
};
