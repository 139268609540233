import { useDidMountEffect } from "Hooks/useDidMountEffect";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { useEffect, useState } from "react";
import { InputDebounce, Select, SelectInput } from "v2";
import Typography from "../Typography/Typography";
import styles from "./FilterProperties.module.css";
import { useWindowDimensions } from "utils";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import Cookies from "universal-cookie";

const cookies = new Cookies();
function FilterProperties(props) {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(null);
  const [filterValues, setFilterValues] = useState();
  const { width } = useWindowDimensions();
  const clearAllFilters = () => {
    setFilterValues({ none: null });
    setFilters(null); //set filters to null because react doesn't cause a rerender if the new state is the same of previous
  };
  useEffect(() => {
    if (cookies.get("filters")) {
      setFilterValues(cookies.get("filters"));
    }
  }, []);

  useDidMountEffect(() => {
    if (filters === null) {
      //this is to force the select inputs list to rerender when the state is set to null
      setFilters(props.filters);
    }
  }, [filters]);

  // Used when a filter is selected
  useEffect(() => {
    if (filterValues) {
      props.onChange(filterValues);
    }
  }, [filterValues]);

  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);

  const handleFilterChange = (headerToFilter, value) => {
    if (!value && filterValues?.hasOwnProperty(headerToFilter)) {
      setFilterValues((previousState) => {
        const newData = { ...previousState };
        delete newData[headerToFilter];
        return newData;
      });
    } else if (value) {
      setFilterValues({ ...filterValues, [headerToFilter]: value });
    }
  };

  // Basic function for overriding the filter labels without having to touch the backend
  const getFilterLabel = (filter) => {
    switch (filter.label) {
      case "S257":
        return "Additional 257";
      case "Additional":
        return "Additional 254";
      default:
        return filter.label;
    }
  };

  // useWhyDidYouUpdate("FilterProperties", props);
  return (
    <div className={styles.container}>
      {width <= 720 && (
        <>
          <div
            className={styles.titleFiltersMobile}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <Typography as="h2">Filters</Typography>
            {showFilter && <ArrowDown className={styles.icon} />}
            {!showFilter && <ArrowUp className={styles.icon} />}
          </div>
          {showFilter && (
            <div className={styles.filtersMobile} id="Filters">
              <span
                className={styles.clearFiltersMobile}
                onClick={clearAllFilters}
              >
                <Typography as="h4">Clear All Filters</Typography>
                <CrossIcon />
              </span>
              {filters &&
                filters.map((filter) => {
                  // This maps through the filters and applies any overwritten labels
                  if (filter.values)
                    filter.values.map(
                      (value) => (value.label = getFilterLabel(value))
                    );

                  if (filter.values) {
                    return (
                      <SelectInput
                        variant="secondary"
                        key={filter.name}
                        className={styles.filter}
                        isClearable={true}
                        placeholder={filter.name}
                        options={filter.values}
                        onOptionChange={(event) =>
                          handleFilterChange(
                            filter.filterName,
                            event ? [event.value] : null
                          )
                        }
                      />
                    );
                  } else {
                    return (
                      <InputDebounce
                        variant="dashboardFilter"
                        key={filter.name}
                        placeholder="Filter Address"
                        delay={500}
                        onChange={(value) =>
                          handleFilterChange(filter.filterName, value || null)
                        }
                        autocomplete={false}
                      />
                    );
                  }
                })}
            </div>
          )}
        </>
      )}
      {width > 720 && (
        <>
          <div className={styles.titleFilters}>
            <Typography as="h2">Filters</Typography>
          </div>
          <div className={styles.filters} id="Filters">
            {filters &&
              filters.map((filter) => {
                //Used to override labels
                if (filter.values)
                  filter.values.map(
                    (value) => (value.label = getFilterLabel(value))
                  );

                let defaultValue = filter.name;
                if (
                  filterValues &&
                  filterValues.hasOwnProperty(filter.filterName)
                ) {
                  const value = filterValues[filter.filterName][0];
                  defaultValue = value;
                }
                if (filter.values) {
                  return (
                    <Select
                      variant="secondary"
                      key={filter.name}
                      className={styles.filter}
                      isClearable={true}
                      placeholder={filter.name}
                      options={filter.values}
                      defaultValue={defaultValue}
                      onOptionChange={(event) => {
                        handleFilterChange(
                          filter.filterName,
                          event ? [event.target.value] : null
                        );
                      }}
                    />
                  );
                } else if (filter.name === "Address") {
                  return (
                    <InputDebounce
                      variant="dashboardFilter"
                      key={filter.name}
                      placeholder="Filter Address"
                      delay={2000}
                      value={filterValues?.address}
                      onChange={(value) =>
                        handleFilterChange(filter.filterName, value || null)
                      }
                      autocomplete={false}
                    />
                  );
                }
              })}
          </div>
          <div className={styles.clearFiltersContainer}>
            <span className={styles.clearFilters} onClick={clearAllFilters}>
              <Typography as="h4">Clear All Filters</Typography>
              <CrossIcon />
            </span>
          </div>
        </>
      )}
    </div>
  );
}

FilterProperties.propTypes = {};

export default FilterProperties;
