export const checkFilter = (propertyDetails, filter) => {
  //transforms object into array of property/value [["propertyType","Building"]]
  const stepFilters = Object.entries(filter);
  return stepFilters.some(([propertyName, value]) => {
    if (value === "*") {
      return true;
    }
    return !!value.includes(propertyDetails[propertyName]);
  });
};
