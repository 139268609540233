import propTypes from "prop-types";
import styles from "./UploadLayout.module.css";
import Typography from "../Typography/Typography";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as Logo } from "images/yunologo.svg";
import Button from "../Form/Button/Button";

const UploadLayout = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography as="h6License">{props.title}</Typography>
        <Logo className={styles.logo}></Logo>
        <CrossIcon
          className={styles.cross}
          onClick={props.onClose}
          onKeyDown={props.onClose}
        />
      </div>
      <div className={styles.content}>
        {props.children}
        <div className={styles.button}>
          <Button
            actionName="Cancel"
            variant="secondary"
            onClick={props.onClose}
          />
          {props.isSave && <Button actionName="Save" onClick={props.onSave} />}
          {!props.isSave && (
            <Button actionName="Update" onClick={props.onUpdate} />
          )}
        </div>
      </div>
    </div>
  );
};

UploadLayout.propTypes = {
  title: propTypes.string.isRequired,
  isSave: propTypes.string.isRequired,
  onClose: propTypes.func,
  onSave: propTypes.func,
  onUpdate: propTypes.func,
};

UploadLayout.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  onUpdate: () => {},
};

export default UploadLayout;
