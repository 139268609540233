import { ErrorBoundary, Spinner } from "components";
import { EnvironmentProvider } from "context/environmentContext/EnvironmentContext.jsx";
import { WebHook } from "Hooks";
import { useTheme } from "Hooks/useTheme";
import React, { Suspense } from "react";
import { ThemeProvider } from "react-jss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthRoute, LoginRoute, MixPanelTracker } from "utils";
import Property from "./web/Property/Property.jsx";

const WebRoute = React.lazy(() =>
  import(/* webpackChunkName: "web" */ "./web/index.jsx")
);
const Login = React.lazy(() =>
  import(/* webpackChunkName: "login" */ "./login")
);
const Signup = React.lazy(() =>
  import(/* webpackChunkName: "signup" */ "./Signup")
);
const ForgotPassword = React.lazy(() =>
  import(/* webpackChunkName: "forgotpassword" */ "./ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import(/* webpackChunkName: "resetpassword" */ "./ResetPassword")
);
const PropertyDetailsLogout = React.lazy(() =>
  import(
    /* webpackChunkName: "propertyDetailsLogout" */ "./web/PropertyDetailsLogout"
  )
);

const App = () => {
  const [{ themes }] = WebHook(true);
  useTheme(themes.colors);
  useTheme(themes.fonts);

  return (
    <ThemeProvider theme={themes}>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <EnvironmentProvider>
            <MixPanelTracker>
              <ErrorBoundary>
                <Switch>
                  <Route
                    path="/property/:propertyId/token/:token"
                    component={Property}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/register/:propertyId?/:leadEmail?"
                    component={Signup}
                  />
                  <Route
                    exact
                    path="/landlord-register"
                    render={(props) => <Signup isLandlord {...props} />}
                  />
                  <AuthRoute path="/:start/:params?" component={WebRoute} />
                  <LoginRoute exact path="/" component={Login} />
                  <Route render={() => <div>405</div>} />
                </Switch>
              </ErrorBoundary>
            </MixPanelTracker>
            </EnvironmentProvider>
          </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  );
};
export default App;
