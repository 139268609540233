import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, AddressForm, Button } from "components";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { styles } from "./styles";

const setting = {
  width: 400,
  height: 830,
  top: "0",
};
const AddressModal = ({
  isOpen,
  onClose,
  classes,
  address,
  onChange,
  onBlur,
  onSave,
  onFocus,
  errors = {},
}) => (
  <Modal isOpen={isOpen} setting={setting} className={classes.main}>
    <div>
      <div className={classes.text}>
        <Typography as="h3"> Change address </Typography>
        <CrossIcon onClick={onClose} onKeyDown={onClose} />
      </div>
      <div className={classes.inputs}>
        <AddressForm
          values={address}
          errors={errors}
          onClick={onSave}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          isModel
          buttonText="Update"
        />
      </div>
      <div className={classes.bottom}>
        <div className={classes.cancelDiv}>
          <Button onClick={onClose} className="cancel-button">
            Close
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);
AddressModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  address: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  onBlur: propTypes.func.isRequired,
  onFocus: propTypes.func.isRequired,
  errors: propTypes.object,
};

export default withStyles(styles)(AddressModal);
