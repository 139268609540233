import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import { useCallback, useMemo } from "react";
import styles from "./Pagination.module.css";

const Pagination = (props) => {
  const { currentPage, totalPages, pageRangeDisplayed = 5 } = props;

  const pagesArray = useMemo(() => {
    const amountOfPagesToDisplay =
      pageRangeDisplayed <= totalPages ? pageRangeDisplayed : totalPages;
    let start = currentPage - Math.floor(amountOfPagesToDisplay / 2);
    start = Math.max(start, 1);
    start = Math.min(start, 1 + totalPages - amountOfPagesToDisplay);
    return Array.from({ length: amountOfPagesToDisplay }, (el, i) => start + i);
  }, [totalPages, pageRangeDisplayed, currentPage]);

  const onChangeToPage = useCallback((event) => {
    event.stopPropagation();
    props.goToPage(event.target.id);
  }, []);

  useWhyDidYouUpdate("Pagination", props);
  return (
    <div className={styles.container}>
      {currentPage > 1 && (
        <>
          <a
            tabIndex={0}
            onClick={props.goToFirstPage}
            onKeyPress={props.goToFirstPage}
          >
            &#xab;
          </a>
          <a
            tabIndex={0}
            onClick={props.goToPreviousPage}
            onKeyPress={props.goToPreviousPage}
          >
            &#x2039;
          </a>
        </>
      )}
      {pagesArray.map((value) => (
        <a
          key={`page-${value}`}
          id={value}
          tabIndex={0}
          className={value == currentPage ? styles.active : ""}
          onKeyPress={onChangeToPage}
          onClick={onChangeToPage}
        >
          {value}
        </a>
      ))}
      {currentPage < totalPages && (
        <>
          <a
            tabIndex={0}
            onClick={props.goToNextPage}
            onKeyPress={props.goToNextPage}
          >
            &#x203A;
          </a>
          <a
            tabIndex={0}
            onClick={props.goToLastPage}
            onKeyPress={props.goToLastPage}
          >
            &#xbb;
          </a>
        </>
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: propTypes.number.isRequired,
  pageRangeDisplayed: propTypes.number,
  totalPages: propTypes.number.isRequired,
  goToFirstPage: propTypes.func.isRequired,
  goToPreviousPage: propTypes.func.isRequired,
  goToPage: propTypes.func.isRequired,
  goToNextPage: propTypes.func.isRequired,
  goToLastPage: propTypes.func.isRequired,
};

export default Pagination;
