import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, Input, Button, SimpleButton } from "components";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { styles } from "./styles";

const PostCodeModel = ({
  isOpen,
  onClose,
  classes,
  postcode,
  onChange,
  onSearch,
  newSearch,
  onFocus,
  error = {},
}) => (
  <Modal isOpen={isOpen}>
    <div>
      <div className={classes.text}>
        <Typography as="h3"> Change postcode </Typography>
        <CrossIcon onClick={onClose} onKeyDown={onClose} />
      </div>
      <div className={classes.inputs}>
        <div className={classes.inputDiv}>
          <Input
            error={error.postCode}
            name="postCode"
            value={postcode}
            onChange={onChange}
            onFocus={onFocus}
          />
        </div>
        <Button onClick={onSearch} className={classes.search}>
          Update
        </Button>
        <SimpleButton onClick={newSearch}>Start new search</SimpleButton>
      </div>
    </div>
  </Modal>
);
PostCodeModel.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  postcode: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  onSearch: propTypes.func.isRequired,
  newSearch: propTypes.func.isRequired,
  onFocus: propTypes.func.isRequired,
  error: propTypes.object,
};

export default withStyles(styles)(PostCodeModel);
