import React from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import { Links } from "components";
import { Typography } from "v2/components";
import { EmbeddingText } from "constants/AppConstants";
import { useStyles } from "./styles";

const Embedding = ({ theme, className = "" }) => {
  const { title, subTitle, email, emailSubject } = EmbeddingText;
  const classes = useStyles(theme);
  return (
    <div className={`${classes.main} ${className}`}>
      <div>
        <Typography as="h6" className="title">
          {title}
        </Typography>
      </div>
      <Typography as="subtitle" className="sub-title">
        {subTitle}{" "}
        <Links as="_blank" path={`mailto:${email}?subject=${emailSubject}`}>
          {email}
        </Links>
      </Typography>
    </div>
  );
};

Embedding.propTypes = {
  theme: propTypes.object.isRequired,
  className: propTypes.string,
};

export default withTheme(Embedding);
