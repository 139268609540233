export const styles = ({ colors, fonts, breakpoints }) => ({
  delete: {
    "& svg": {
      cursor: "pointer",
    },
    [breakpoints.MOBILE_DEVICE]: {
      padding: "0 !important",
      "& .contents": {
        width: "100%",
        margin: "0 !important",
        height: "100vh !important",
        top: "0",
      },
    },
  },
  text: {
    display: "flex",
    marginTop: "24px",
    "& p": {
      width: 552,
      height: 48,
      [breakpoints.MOBILE_DEVICE]: {
        height: "129px",
      },
    },
    "& svg": {
      cursor: "pointer",
      margin: "0 0 13px 16px",
      "&:focus": {
        outline: "none",
      },
    },
  },
  subTitle: {
    marginTop: "8px",
    marginBottom: "8px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: colors.darkGrey,
  },
  main: {
    background: colors.hexWhite,
    margin: "0 24px",
    fontFamily: fonts.primary,
    fontStyle: "normal",
    "& span": {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.darkGrey,
    },
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "35px",
    [breakpoints.RESPONSIVE]: {
      marginTop: "200px",
      justifyContent: "center",
    },
    "& button": {
      boxSizing: "border-box",
      borderRadius: "48px",
      width: "97px",
      height: "40px",
      marginLeft: "12px",
    },
    "& .cancel": {
      border: `1px solid ${colors.black}`,
      color: colors.black,
    },
    "& .delete": {
      border: `1px solid ${colors.error}`,
      color: colors.hexaWhite,
      background: colors.error,
    },
  },
});
