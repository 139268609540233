import React from "react";
import PropTypes from "prop-types";
import withStyle from "react-jss";
import { Typography } from "v2/components";
import { Styles } from "./Styles";

const Tooltip = ({ classes, text, content, className = "", props }) => (
  <div className={`${classes.tooltip} ${className}`}>
    {content}
    <span className="tooltiptext" {...props}>
      <Typography className="text">{text}</Typography>
    </span>
  </div>
);

Tooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  className: PropTypes.string,
};
export default withStyle(Styles)(Tooltip);
