import React from "react";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import styles from "./FileInput.module.css";
import Label from "../Label";

const FileInput = (props) => {
  const ref = React.useRef({});

  React.useEffect(() => {
    ref.current.value = "";
  }, [props.files]);

  useWhyDidYouUpdate(`Input ${props.name}`, props);
  return (
    <Label
      label={props.label}
      onInputTip={props.onInputTip}
      error={props.error}
      isClickable={true}
    >
      <input
        className={styles.fileInput}
        onChange={props.onClick}
        ref={ref}
        type="file"
        multiple
      />
    </Label>
  );
};

FileInput.propTypes = {
  label: propTypes.string,
  onInputTip: propTypes.func,
  type: propTypes.string,
  files: propTypes.array,
  placeholder: propTypes.string,
  name: propTypes.string,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  onKeyDown: propTypes.func,
  className: propTypes.string,
  readOnly: propTypes.bool,
  autocomplete: propTypes.bool,
};

FileInput.defaultProps = {
  type: "text",
  placeholder: "text",
  name: "name",
  classes: {},
  onChange: () => null,
  value: "",
  onBlur: () => null,
  onFocus: () => null,
  onKeyDown: () => null,
  className: "",
  readOnly: false,
  autocomplete: true,
  files: [],
};
export default FileInput;
