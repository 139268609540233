import styles from "./RiskModal.module.css";
import ModalV2 from "./Modal";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as Reminder } from "images/reminder.svg";
import { Button } from "v2";

const content = {
  heading: "What are the risks if landlords and agents don't comply?",
  list: [
    "• Fines up to £30,000",
    "• Criminal prosecution",
    "• Rent repayment order ",
    "• Rogue landlord and agent database",
  ],
};

export default function RiskModal(props) {
  return (
    <ModalV2 onClose={props.onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Reminder className="logo" />
          <Typography as="subtitle">{content.heading}</Typography>
          <CrossIcon onClick={props.onClose} onKeyDown={props.onClose} />
        </div>
        <div className={styles.lists}>
          {content.list.map((value) => (
            <Typography key={value}>{value}</Typography>
          ))}
        </div>
        <div className={styles.footerSection}>
          <Button
            variant="secondary"
            onClick={props.onClose}
            actionName="Close"
          />
        </div>
      </div>
    </ModalV2>
  );
}
