import Map from "v2/components/Map/Map";
import { usePropertyDetails } from "context/propertyContext/PropertyContext";
import { updatePropertyDetails } from "Apis";
import Styles from "./noUprnMapModal.module.css";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import Divider from "v2/components/Divider/Divider";
import { Links } from "v2/components";
import AddressList from "../../../../../v2/components/Form/Address/AddressList";
import { matchPropertyByCoordinate, updatePropertyAddress } from "Apis";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";

const NoUprnMapModal = (props) => {
  const propertyDetails = usePropertyDetails();
  const [addressToConfirm, setAddressToConfirm] = useState({
    ...initialAddressToConfirmState,
  });
  const [possibleMatchesFromServer, setPossibleMatchesFromServer] = useState(
    []
  );
  const [matches, setMatches] = useState();

  const initialAddressToConfirmState = {
    address: "",
    data: {
      iv: "",
      content: "",
    },
  };

  const history = useHistory();

  const push = () => {
    history.push(`/property/`);
  };
  const handleConfirmMatch = () => {
    //MAKE REQUEST TO SERVER TO UPDATE THE PROPERTY
    return updatePropertyAddress(addressToConfirm.data, propertyDetails.uuid);
  };

  const getAllPropertiesByLatLon = (newCoordinate) => {
    matchPropertyByCoordinate(newCoordinate).then((data) => setMatches(data));
  };

  useEffect(() => {
    matchPropertyByCoordinate(propertyDetails.preferredCoordinates).then(
      (data) => setMatches(data)
    );
  }, []);

  useEffect(() => {
    setPossibleMatchesFromServer(
      matches?.data.match.map((address, index) => {
        return {
          value: address.address,
          key: index,
          data: address.data,
        };
      })
    );
  }, [matches?.data.match]);

  const onNewCoordinate = ({ lat, lng }) => {
    updatePropertyDetails(propertyDetails.propertyId, {
      userLongitude: lng,
      userLatitude: lat,
    });
    let newCoordinate = {
      latitude: lat.toString(),
      longitude: lng.toString(),
    };
    getAllPropertiesByLatLon(newCoordinate);
  };

  return (
    <div className={Styles.container}>
      <span className={Styles.CrossIcon}>
        <CrossIcon onClick={props.closeModal} onKeyDown={props.closeModal} />
      </span>
      <div className={Styles.title}>
        <Typography as="h1">Please Confirm The Property Location</Typography>
      </div>
      <div className={Styles.subTitle}>
        <Typography as="Documents">
          We were unable to match this property to an exact UPRN, this could
          affect the accuracy of your report.
        </Typography>
      </div>
      <Map
        noUPRN={1000}
        onNewCoordinate={onNewCoordinate}
        latitude={propertyDetails.preferredCoordinates.latitude}
        longitude={propertyDetails.preferredCoordinates.longitude}
      />
      <div className={Styles.description}>
        {" "}
        <Typography as="Documents">
          Double click to place the marker on top of your property or the
          building it belongs to. You can drag the map above. Once the pin is
          correct please confirm location.
        </Typography>
      </div>
      <AddressList
        push={push}
        noIcon={true}
        possibleMatches={possibleMatchesFromServer}
        onChangeAddressToConfirm={setAddressToConfirm}
        noButton={true}
        noUPRN={true}
        onConfirmMatch={handleConfirmMatch}
        closeModal={props.closeModal}
      />
      <div className={Styles.bottom}>
        <Divider dividerText="Or" />
        <div className={Styles.start}>
          {" "}
          <Links
            as="link"
            path="/start-search"
            name="Search"
            description="Start new search"
          />
        </div>
      </div>
    </div>
  );
};

export default NoUprnMapModal;
