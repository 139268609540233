import React from "react";
import propTypes from "prop-types";
import styles from "./Flex.module.css";

const Flex = (props) => {
  return (
    <div className={`${styles.container} ${styles[props.direction]}`}>
      {props.children}
    </div>
  );
};

Flex.propTypes = {
  direction: propTypes.oneOf(["row", "column"]),
};

Flex.defaultProp = {
  direction: "row",
};

export default Flex;
