import React, { memo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import withStyle from "react-jss";
import { styles } from "./styles";

const Paginations = ({
  currentPage,
  classes,
  className = "",
  onChange,
  totalPage,
  pageRangeDisplayed = 5,
}) => {
  const history = useHistory();
  const lastPage = () => {
    onChange(totalPage);
  };
  const firstPage = () => {
    onChange(1);
  };
  const showPages = () => {
    if (pageRangeDisplayed > totalPage) pageRangeDisplayed = totalPage;
    let start = currentPage - Math.floor(pageRangeDisplayed / 2);
    start = Math.max(start, 1);
    start = Math.min(start, 1 + totalPage - pageRangeDisplayed);
    return Array.from({ length: pageRangeDisplayed }, (el, i) => start + i);
  };
  const previousPage = () => {
    const page = currentPage - 1;
    onChange(page);
  };
  const nextPage = () => {
    const page = currentPage + 1;
    onChange(page);
  };
  const changePage = (page) => {
    onChange(page);
  };
  return (
    <div className={`${classes.pagination} ${className}`}>
      {totalPage > 1 && (
        <>
          <a
            disabled={currentPage === totalPage}
            className={currentPage === 1 ? "disabled" : ""}
            role="button"
            tabIndex={0}
            onClick={firstPage}
            onKeyPress={firstPage}
          >
            &#xab;
          </a>
          <a
            disabled={currentPage === totalPage}
            className={currentPage === 1 ? "disabled" : ""}
            role="button"
            tabIndex={0}
            onClick={previousPage}
            onKeyPress={previousPage}
          >
            &#x2039;
          </a>
          {showPages().map((value) => (
            <a
              key={`page-${value}`}
              role="button"
              tabIndex={0}
              className={value === currentPage ? "active" : ""}
              onKeyPress={() => changePage(value)}
              onClick={() => changePage(value)}
            >
              {value}
            </a>
          ))}

          <a
            role="button"
            className={currentPage === totalPage ? "disabled" : ""}
            disabled={currentPage === totalPage}
            tabIndex={0}
            onClick={nextPage}
            onKeyPress={nextPage}
          >
            &#x203A;
          </a>
          <a
            role="button"
            className={currentPage === totalPage ? "disabled" : ""}
            disabled={currentPage === totalPage}
            tabIndex={0}
            onClick={lastPage}
            onKeyPress={lastPage}
          >
            &#xbb;
          </a>
        </>
      )}
    </div>
  );
};

Paginations.propTypes = {
  currentPage: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  pageRangeDisplayed: PropTypes.number,
  totalPage: PropTypes.number.isRequired,
};

export default withStyle(styles)(memo(Paginations));
