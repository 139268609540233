import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { Styles } from "./Styles";

const ProgressBar = ({ classes, progress }) => (
  <div className={classes.main}>
    <div className="data">
      <span className="text">File uploading...</span>
      <span className="text progress">{progress}%</span>
    </div>
    <div className="progress-info">
      <div className="progress-bar" progress={progress}>
        <div className="uploading" style={{ width: `${progress}%` }} />
      </div>
      <CrossIcon className="cross" />
    </div>
  </div>
);
ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
};
export default withStyles(Styles)(ProgressBar);
