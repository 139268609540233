export const hmoDefinition = `
# To determine what is an HMO we need to start with what ‘HMO’ stands for, ie. House in Multiple Occupancy.
<br/>
## England and Wales

The standard definition of a section 254 HMO is:
- 3 or more tenants
- More than 1 household
- Generally speaking that share facilities (kitchen, bathroom etc) though legally should meet standard test, self-contained flat test or converted building test HMO definition.
To classify as a 254 HMO a property does not need to be converted or adapted in any way.

Section 257 HMOs
Refers to buildings that:
- Have been converted into self-contained flats; and
- Less than two-thirds of the flats are owner-occupied; and
- The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply.
If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.

The full definition of all HMO’s can be found in the Housing Act 2004 Sections 254 to 260.
<br/>
<br/>
## Scotland

An HMO (House in Multiple Occupancy) is:

- Three or more individuals (“qualifying persons”) from more than two families reside in the property as their main residence and share some facilities, for example a kitchen and/or a bathroom.
- Payment of rent is not a prerequisite for a property to qualify as a HMO. Emergency accommodation, such as homeless units, can be classified as HMOs if the above criterion applies.

### Housing (Scotland) Act 2006.

Owner Occupiers:
Owner occupiers do not count as “qualifying persons” for the purposes of calculating whether a licence is required. Accommodation occupied by the owner and two other unrelated persons won’t be considered a HMO and will not require a HMO licence. A property occupied by the owner and three other unrelated persons will require a licence.

---
**Note**

Some councils operate their Additional licensing scheme (s257) if the property has less than 50% of the flats owner occupied.
The full definition of all HMO’s can be found in the [Housing Act 2004](https://www.legislation.gov.uk/ukpga/2004/34/part/7/crossheading/meaning-of-house-in-multiple-occupation) Sections 254 to 260.
`
export const licensingDefinition = `
# England and Wales
<br/>
Licensing
This is the Local Authority’s process of improving living standards in rental accommodation and proving that landlords, and property managers, are ‘fit and proper’ persons to operate the property. The type of licence a property requires varies depending on a number of different factors, including the number of tenants and the council your property is located in.
HMO is "House in Multiple Occupation".
<br/>
### Mandatory HMO licensing
<br/>
Generally applicable to (Section 254) HMOs with 5 or more tenants, from more than 1 household sharing facilities.
Additional HMO licensing

Any (Section 254) HMO that doesn't fall under the Mandatory scheme may need this licence and certain types of converted buildings (Section 257). This is not an England & Wales wide scheme and only specific boroughs have this in place.
<br/>
### Selective licensing
<br/>
If a property is in the selected area, it may require this licence, no matter the occupancy. This is not an England & Wales wide scheme and only specific boroughs have it in place.
Failure to have the correct licensing in place can result in fines of up to £30,000, criminal prosecution, rent repayment order and even being added to rogue landlord and agent database.
<br/>
<br/>
# Scotland
<br/>
HMO is "House in Multiple Occupation".
HMO licensing
Generally applies to all HMOs which in Scotland 3 people from 3 or more families, sharing facilities in the property.
`

export const planningDefinition = `
# Planning Permission
<br/>
## England and Wales
<br/>
### Planning permission
<br/>
This is the legal process of determining whether proposed development, or building change of use, should be permitted. In this process we are looking at two specific types of planning permission, SuI Generis and Article 4 (for small HMOs).
<br/>
### Article 4 Directive for (Section 254) HMOs
<br/>
For smaller (Section 254) HMOs (3 to 6 tenants) a change of use from a single dwelling house (use class C3) to a small HMO shared by 3 to 6 unrelated residents (use class C4) often does not need planning permission as change happens under permitted development.
However, local planning authorities have the power to bring in an Article 4 direction under the Town and Country Planning Order 2015. This removes the permitted development rights and therefore requires landlord to gain planning permission to convert their property from C3 to C4.
Article 4 can be applied borough wide or to specific areas/streets/wards within a borough and differs from council to council.
(This applies in England, Wales always requires a planning application for all change of use from C3 to C4 (Section 254) HMO's).
If you are already running an HMO before this directive comes in then you may not need to apply for Article 4 planning permission, though always consult an expert.
<br/>
### Immediate and non-immediate schemes
<br/>
Usually Planning departments consult them implement but they can implement immediately though this only lasts for 6 months then they must follow due process to extend. [view more](https://www.legislation.gov.uk/uksi/2015/596/schedule/3/made?view=plain)
<br/>
### Sui Generis planning permission
<br/>
This will generally always be required for large (Section 254) HMOs of 7 or more tenants that has had “material change of use”.
<br/>
<br/>
## Lawful Development (Lawful Use)
### What is a lawful development certificate?
There are 2 types of lawful development certificate. A local planning authority can grant a certificate confirming that:
- an existing use of land, or some operational development, or some activity being carried out in breach of a planning condition, is lawful for planning purposes under section 191 of the Town and Country Planning Act 1990; or
- a proposed use of buildings or other land, or some operations proposed to be carried out in, on, over or under land, would be lawful for planning purposes under section 192 of the Town and Country Planning Act 1990.

If you want to be certain that the existing use of a building is lawful for planning purposes or that your proposal does not require planning permission, you can apply for a 'Lawful Development Certificate' (LDC).

Example could be a property that is being ran as an HMO but has been running for a long time that means a LDC can be applied for and you do not need to apply for Article 4 or Sui Generis planning permission. Speak to an expert for specific advice to your property.
[view more](https://www.gov.uk/guidance/lawful-development-certificates)
<br/>
<br/>
# Scotland

Coming soon!
`