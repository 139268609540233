export const Styles = ({ colors }) => ({
  main: {
    display: "flex",
    width: "100%",
    backgroundColor: colors.listBackground,
    height: "40px",
    flexDirection: "column",
    "& .data": {
      marginBottom: 4,
      "& .text": {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "120%",
        color: colors.primary,
        marginLeft: 7,
      },
      "& .progress": {
        marginLeft: 91,
      },
    },
    "& .progress-info": {
      display: "flex",
      width: "100%",
      "& .cross": {
        width: "10px",
        height: "10px",
        margin: "-2px 6px 0px 0",
      },
      "& .progress-bar": {
        backgroundColor: colors.inputGrey,
        margin: "0 7px",
        height: "7px",
        borderRadius: "3px",
        width: "94%",
        "& .uploading": {
          height: "7px",
          backgroundColor: colors.primary,
          transition: "width .6s ease",
          borderRadius: "3px",
        },
      },
    },
  },
});
