import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import ButtonStyles from "./style";

export const Button = ({
  classes,
  onClick,
  children,
  className = "",
  disable = false,
  type = "button",
}) => (
  <button
    type={type || ""}
    className={`${classes.button} ${className}`}
    onClick={onClick}
    disabled={disable && disable}
  >
    {children}
  </button>
);

Button.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.any,
  disable: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};
Button.defaultProps = {
  children: "",
};

export default withStyles(ButtonStyles)(Button);
