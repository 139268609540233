import { useCallback } from "react";
import Typography from "../../../Typography/Typography";
import propTypes from "prop-types";
import { ReactComponent as Delete } from "images/deleteProperty.svg";
import FileInput from "../FileInput";
import styles from "./ListFileInput.module.css";

import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import Link from "../../../Link/Link";

const Item = (props) => {
  const onDelete = useCallback(() => {
    props.onDelete(props.file, props.index);
  }, [props]);
  return (
    <div className={styles.file}>
      <Link as="_blank" path={props.file.url}>
        <Typography as="list">{props.file.name}</Typography>
      </Link>
      <Delete onClick={onDelete} />
    </div>
  );
};

const ListFileInput = (props) => {
  useWhyDidYouUpdate("ListFileInput", props);
  return (
    <>
      <FileInput {...props.fileInputProps} />
      <div className={styles.container}>
        {props.files.map((file, index) => (
          <Item
            file={file}
            index={index}
            onDelete={props.onDelete}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

ListFileInput.propTypes = {
  fileInputProps: propTypes.shape(FileInput.propTypes),
  files: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      url: propTypes.string,
    })
  ),
};

export default ListFileInput;
