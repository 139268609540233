import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal } from "components";
import { Links, Typography } from "v2/components";
import { planningPopData } from "constants/AppConstants";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { useWindowDimensions } from "utils";
import theme from "theme";
import { styles } from "./styles";

const { colors } = theme;
const setting = {
  width: 640,
  height: 540,
  top: 80,
  color: colors.hexaWhite,
};
const { title, england, scotland } = planningPopData;
const Planning = ({ isOpen, onClose, classes }) => {
  const { width } = useWindowDimensions();
  setting.width = width > 800 ? 640 : "80%";
  const wrapperRef = useRef(null);
  const handleClick = ({ target, offsetX }) => {
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      if (offsetX <= target.clientWidth) {
        onClose();
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  return (
    <Modal
      isOpen={isOpen}
      setting={setting}
      ref={wrapperRef}
      className={classes.planning}
    >
      <div className={classes.main}>
        <div className={classes.exitButton}>
          <CrossIcon onClick={onClose} onKeyDown={onClose} />
        </div>
        <div className={classes.text}>
          <Typography as="subtitle" color={colors.black}>
            <Typography as="h6">{title}</Typography>
            <br />
          </Typography>
        </div>
        <div className={classes.section}>
          <>
            <div className={classes.header}>
              <Typography as="subtitle">{england.header}</Typography>
            </div>
            <div className="list-view">
              <div className={classes.header}>
                {england.sections.map((section) => (
                  <>
                    <Typography as="h4">{section.heading}</Typography>
                    <Typography as="p">
                      {section.paragraphs.map((paragraph) => (
                        <>
                          <span>{paragraph}</span>
                          <br />
                        </>
                      ))}
                    </Typography>
                    {!!section.links &&
                      section.links.map((link) => (
                        <Links as="_blank" path={link.path}>
                          {link.text}
                        </Links>
                      ))}
                    <br />
                  </>
                ))}
              </div>
            </div>
            <div className={classes.header}>
              <Typography as="subtitle">{scotland.header}</Typography>
            </div>
            <div className="list-view">
              <div className={classes.header}>
                {scotland.sections.map((section) => (
                  <>
                    <Typography as="h4">{section.heading}</Typography>
                    <Typography as="p">
                      {section.paragraphs.map((paragraph) => (
                        <>
                          <span>{paragraph}</span>
                          <br />
                        </>
                      )) || null}
                    </Typography>
                    <br />
                  </>
                ))}
              </div>
            </div>
          </>
        </div>
      </div>
    </Modal>
  );
};
Planning.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(Planning);
