import PropTypes from "prop-types";
import style from "./Row2Items.module.css";

const Row2Items = (props) => (
  <div className={style.container}>{props.children}</div>
);

Row2Items.propTypes = {
  children: PropTypes.any.isRequired,
};
export default Row2Items;
