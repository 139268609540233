export const headingText = (licenceType) => {
  switch (licenceType) {
    case "Mandatory":
      return "This property needs a Mandatory licence when running a Section 254 HMO.";
    case "Additional":
      return "This property needs an Additional HMO licence when running a Section 254 HMO.";
    case "Selective":
      return "This property needs a Selective licence.";
    default:
      return "";
  }
};

export const subHeaderText = (licenceType) => {
  switch (licenceType) {
    case "Mandatory":
      return `The English government have decided to exclude purpose built
            self-contained flats within a block comprising three or more
            self-contained flats from the mandatory HMO licensing
            scheme, this does not apply in Wales.`;
    default:
      return "";
  }
};
export const preLicenceListDescription = (licenceType) => {
  switch (licenceType) {
    case "Additional":
      return "Pre-application the freeholder will need the following documents.";

    default:
      return "";
  }
};

export const documentTypes = [
  { value: "Licence Application", label: "Licence Application" },
  { value: "Proposed/Draft Licence", label: "Proposed/Draft Licence" },
  { value: "Issued Licence", label: "Issued Licence" },
];
