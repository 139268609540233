export const useStyles = ({ colors }) => ({
  main: {
    background: colors.primary,
    width: "416",
    padding: "24px",
    "& .main-text": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.002em",
      color: colors.black,
    },
    "& .sub-text": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.002em",
      color: colors.black,
    },
    "& button": {
      width: 201,
      height: 32,
    },
  },
});
