import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { leftImage } from "constants/AppConstants";
import leftImageBlur from "images/home_img.svg";
import { useStyles } from "./style";

const LeftImage = memo(
  ({ imageUrl = leftImage, blurImage = leftImageBlur }) => {
    const [imgLoaded, setImgLoaded] = useState(false);
    useEffect(() => {
      loadImage();
    }, [imageUrl]);
    const loadImage = () => {
      const image = new Image();
      image.onload = () => {
        setImgLoaded(true);
      };
      image.src = imageUrl;
    };
    const classes = useStyles();
    return (
      <img
        src={!imgLoaded ? blurImage : imageUrl}
        alt="left-side"
        className={classes.leftImgae}
      />
    );
  }
);
LeftImage.propTypes = {
  imageUrl: PropTypes.string,
  blurImage: PropTypes.string,
};
export default LeftImage;
