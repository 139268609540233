import { approveWorkOrderURL, submitWorkOrderURL } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { mixPanel, POST } from "utils";

type ApproveWorkOrderData = { token: string; propertyUuid: string };
type SubmitWorkOrderData = { packageId: string; propertyUuid: string };

type PropertyWorkOrderActions = {
  approveWorkOrder: APIAction<ApproveWorkOrderData, boolean>;
  submitWorkOrder: APIAction<SubmitWorkOrderData, boolean>;
};

export default function useApiPropertyWorkOrderActions(): PropertyWorkOrderActions {
  const approveWorkOrderRequest = async (data: ApproveWorkOrderData) => {
    if (!!data) {
      mixPanel.track("Approve work order");
      return POST(approveWorkOrderURL(data.token, data.propertyUuid), data);
    }
  };
  const submitWorkOrderRequest = async (data: SubmitWorkOrderData) => {
    if (!!data) {
      mixPanel.track("Submit work order");
      return POST(submitWorkOrderURL(data.propertyUuid), {
        packageId: data.packageId,
      });
    }
  };

  const approveWorkOrderPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(approveWorkOrderRequest, false);

  const submitWorkOrderPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(submitWorkOrderRequest, false);

  return {
    approveWorkOrder: {
      trigger: approveWorkOrderPromise.execute,
      state: approveWorkOrderPromise.status === "pending" ? "loading" : "idle",
      error: approveWorkOrderPromise.error?.errorDetails,
      result: approveWorkOrderPromise.status === "success",
    },
    submitWorkOrder: {
      trigger: submitWorkOrderPromise.execute,
      state: submitWorkOrderPromise.status === "pending" ? "loading" : "idle",
      error: submitWorkOrderPromise.error?.errorDetails,
      result: submitWorkOrderPromise.status === "success",
    },
  };
}
