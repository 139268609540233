export const styles = ({ colors }) => ({
  main: {
    width: "100%",
    minHeight: "42px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    background: colors.skyBlue,
    alignItems: "center",
    "& .message": {
      marginLeft: "12px",
    },
  },
});
