import React from "react";

type UseModal<ModalDataType> = [
  boolean,
  (data: ModalDataType) => void,
  () => void,
  ModalDataType | null
];

export default function useModal<T>(isOpenDefault = false): UseModal<T> {
  const [modalData, setModalData] = React.useState<T | null>(null);
  const [isOpen, setIsOpen] = React.useState(isOpenDefault);

  const open = React.useCallback((modalData) => {
    setModalData(modalData);
    setIsOpen(true);
  }, []);

  const close = React.useCallback(() => {
    setIsOpen(false);
    setModalData(null);
  }, []);

  return [isOpen, open, close, modalData];
}
