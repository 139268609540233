import { useAsync } from "Hooks/useAsync";
import { GET } from "utils";
import { getAnalyticURL, getCachedAnalyticsURL } from "../../constants/urls";

type PropertyAnalytics = any;

type OverviewAnalyticsInput = {
  propertyUuid: string;
  uprn: string;
};
type CompareRentsAnalyticsInput = {
  propertyUuid: string;
  yields: string;
  bedrooms?: string;
};
type PropertyAnalyticsActions = {
  getCachedAnalytics: APIAction<number, PropertyAnalytics | null>;
  getOverviewAnalytics: APIAction<any, PropertyAnalytics | null>;
  getCompareRentsAnalytics: APIAction<number, PropertyAnalytics | null>;
  getEPC: APIAction<number, PropertyAnalytics | null>;
  getAirDnaAnalytics: APIAction<number, PropertyAnalytics | null>;
  getAnalytics: APIAction<
    { propertyId: string; analyticName: string },
    PropertyAnalytics | null
  >;
};

export default function useApiPropertyAnalytics(): PropertyAnalyticsActions {
  const getCachedAnalyticsRequest = async (propertyUuid: string) => {
    //TODO make the acutal request to the Yuno API
    return GET(getCachedAnalyticsURL(propertyUuid));
  };

  const getAnalyticsRequest = async (data: {
    propertyUuid: string;
    analyticName: string;
  }) => {
    return GET(getAnalyticURL(data.propertyUuid, data.analyticName));
  };

  const getOverviewAnalyticsRequest = async (data: OverviewAnalyticsInput) => {
    let uprnTitle;
    if (data.uprn) {
      uprnTitle = await GET(
        getAnalyticURL(data.propertyUuid, "uprn-title"),
        {}
      );
      if (uprnTitle.status === 200) {
        // TODO: Load all title numbers
        const titleData = uprnTitle.data.data.title_data;
        const titleNumber = titleData[0]["title_number"];
        await GET(getAnalyticURL(data.propertyUuid, "title-info"), {
          title: titleNumber,
        });
      }
    }
    return { data: true, meta: {} };
  };

  const getCompareRentsAnalyticsRequest = async (
    data: CompareRentsAnalyticsInput
  ) => {
    let results = [{}];
    results = await Promise.allSettled([
      GET(getAnalyticURL(data.propertyUuid, "yields"), {
        bedrooms: data.bedrooms,
      }),
      GET(getAnalyticURL(data.propertyUuid, "rents"), {
        bedrooms: data.bedrooms,
      }),
      GET(getAnalyticURL(data.propertyUuid, "rents-hmo")),
      GET(getAnalyticURL(data.propertyUuid, "lha-rate"), {
        bedrooms: data.bedrooms,
      }),
      GET(getAnalyticURL(data.propertyUuid, "rentalizer-summary")),
    ]);
    return { data: results, meta: {} };
  };

  const getEPCRequest = async (data: any) => {
    const epc = await GET(
      getAnalyticURL(data.propertyUuid, "epc-search-and-recommend")
    );
    if (epc.status === 200) {
      return {
        data: {
          current: epc.data.certificate["current-energy-efficiency"],
          potential: epc.data.certificate["potential-energy-efficiency"],
          current_impact: epc.data.certificate["environment-impact-current"],
          potential_impact:
            epc.data.certificate["environment-impact-potential"],
          valid_until: epc.data.certificate["validUntil"],
        },
      };
    }
    return epc;
  };

  const getAirDnaRequest = async (data: any) => {
    const airDnaAnalytics = await GET(
      getAnalyticURL(data.propertyId, "rentalizer-summary")
    );
    if (airDnaAnalytics.status === 200) {
      return {
        data: {
          rent: airDnaAnalytics.data,
        },
      };
    }
    return airDnaAnalytics;
  };

  const getCachedAnalyticsPromise = useAsync<
    APIActionResponse<PropertyAnalytics>,
    APIActionError
  >(getCachedAnalyticsRequest, false);

  const getAnalyticsPromise = useAsync<
    APIActionResponse<PropertyAnalytics>,
    APIActionError
  >(getAnalyticsRequest, false);

  const getOverviewAnalyticsPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(getOverviewAnalyticsRequest, false);

  const getCompareRentsAnalyticsPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(getCompareRentsAnalyticsRequest, false);

  const getEPCPromise = useAsync<APIActionResponse<any>, APIActionError>(
    getEPCRequest,
    false
  );

  const getAirDnaPromise = useAsync<APIActionResponse<any>, APIActionError>(
    getAirDnaRequest,
    false
  );

  return {
    getCachedAnalytics: {
      error: getCachedAnalyticsPromise.error?.errorDetails,
      result: getCachedAnalyticsPromise.value?.data,
      state:
        getCachedAnalyticsPromise.status === "pending" ? "loading" : "idle",
      trigger: getCachedAnalyticsPromise.execute,
    },
    getAnalytics: {
      error: getAnalyticsPromise.error?.errorDetails,
      result: getAnalyticsPromise.value?.data,
      state: getAnalyticsPromise.status === "pending" ? "loading" : "idle",
      trigger: getAnalyticsPromise.execute,
    },
    getOverviewAnalytics: {
      error: getOverviewAnalyticsPromise.error?.errorDetails,
      result: getOverviewAnalyticsPromise.value?.data,
      state:
        getOverviewAnalyticsPromise.status === "pending" ? "loading" : "idle",
      trigger: getOverviewAnalyticsPromise.execute,
    },
    getCompareRentsAnalytics: {
      error: getCompareRentsAnalyticsPromise.error?.errorDetails,
      result: getCompareRentsAnalyticsPromise.value?.data,
      state:
        getCompareRentsAnalyticsPromise.status === "pending"
          ? "loading"
          : "idle",
      trigger: getCompareRentsAnalyticsPromise.execute,
    },
    getEPC: {
      error: getEPCPromise.error?.errorDetails,
      result: getEPCPromise.value?.data,
      state: getEPCPromise.status === "pending" ? "loading" : "idle",
      trigger: getEPCPromise.execute,
    },
    getAirDnaAnalytics: {
      error: getAirDnaPromise.error?.errorDetails,
      result: getAirDnaPromise.value?.data,
      state: getAirDnaPromise.status === "pending" ? "loading" : "idle",
      trigger: getAirDnaPromise.execute,
    },
  };
}
