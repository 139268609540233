import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Styles } from "./Styles";

export const CheckBox = React.forwardRef(
  (
    {
      label,
      classes,
      name,
      onChange,
      value,
      onBlur,
      onFocus,
      className,
      error,
      disabled,
      ...props
    },
    ref
  ) => (
    <div className={classes.main}>
      <label className={`containers ${className}`}>
        {label}
        <input
          disabled={disabled}
          type="checkbox"
          name={name}
          className={error ? classes.error : ""}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          {...props}
          ref={ref}
        />
        <span className="checkmark" />
        {error && <span className={classes.errorText}>{error}</span>}
      </label>
    </div>
  )
);

CheckBox.propTypes = {
  name: propTypes.string,
  classes: propTypes.object,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  className: propTypes.string,
  disabled: propTypes.bool,
  label: propTypes.string,
};

CheckBox.defaultProps = {
  name: "name",
  classes: {},
  onChange: () => null,
  value: "",
  onBlur: () => null,
  onFocus: () => null,
  className: "",
  disabled: false,
  label: "",
};

export default withStyles(Styles)(CheckBox);
