import {
  addCertificateFilesURL,
  changeItemRequiredUrl,
  confirmEvidenceUploadURL,
  deleteCertificateFileURL,
  deleteCertificateURL,
  presignedEvidenceURL,
  updateCertificateOrdered,
  updateCertificateURL,
} from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { useEffect, useState } from "react";
import { DELETE, Patch, POST, PUT } from "utils";
import mixpanel from "../../utils/mixPanel";
import useUploadFilesToS3 from "./useUploadFilesToS3";

/*
 *TODO REfactor this file to use the global Types for API REsponse, Error and Action 

import { useAsync } from "Hooks/useAsync";
import { GET } from "utils";
type Example = {
  name: string
};

type PackagesActions = {
  getPackages: APIAction<string, Example>;
};

export const useApiExampleActions = (): PackagesActions => {
  const getExample = async (packageName) => {
    return GET(packagesURL(packageName));
  };

  const getExamplePromise = useAsync<
    APIActionResponse<Example>,
    APIActionError
  >(getExample, false);
  return {
    getExamples: {
      trigger: getExamplePromise.execute,
      state: getExamplePromise.status === "pending" ? "loading" : "idle",
      error: getExamplePromise.error?.errorDetails,
      result: getExamplePromise.value?.data || null,
    },
  };
}; */

type ErrorType = {
  errorDetails: {
    code: string;
    details: Array<{
      location: string;
      msg: string;
      param: string;
      value: string;
    }>;
    message: string;
  };
} | null;

type Action = {
  trigger: (data: any) => void;
  result: any;
  error:
    | {
        code: string;
        details: Array<{
          location: string;
          msg: string;
          param: string;
          value: string;
        }>;
        message: string;
      }
    | undefined;
};

type CreateData = {
  certificateId: string;
  issueDate: string;
  expiryDate: string;
  reference: string;
  files: Array<File>;
};

type CreateCertificateFile = {
  certificateId: string;
  files: Array<File>;
};

type UpdateData = {
  expiryDate: string;
  issueDate?: string;
  reference: string;
  files?: Array<File | { name: string; url: string; id: string }>;
  certificateId: string;
};

type CertificateOrderedData = {
  certificateId: string;
  value: boolean;
};

type ItemNotRequiredData = {
  certificateId: string;
  value: boolean;
};

type DeleteData = {
  certificateId: string;
};

type DeleteFileData = {
  certificateId: string;
  fileId: string;
};

type UsePropertyCertificationActionsProps = {
  propertyId: string;
};

const buildPresignedURLS = (
  presignedUrls: Array<{
    postData: { url: string; fields: { [key: string]: string } };
  }> = [],
  files: Array<{ name: string }>
) =>
  presignedUrls.map(({ postData }, index) => ({
    documentUrl: `${postData.url}/${postData.fields.key}`,
    name: files[index].name,
  }));

export default function useApiPropertyCertificateActions(
  props: UsePropertyCertificationActionsProps
): Array<Action> {
  const [onCreateData, setOnCreateData] = useState<CreateData>();
  const [onCreateFilesData, setOnCreateFilesData] =
    useState<CreateCertificateFile>();
  const [onDeleteData, setOnDeleteData] = useState<DeleteData>();
  const [onUpdateData, setOnUpdateData] = useState<UpdateData>();
  const [onUpdatedCertificateOrderData, setOnUpdatedCertificateOrderData] =
    useState<CertificateOrderedData>();
  const [onDeleteFileData, setOnDeleteFileData] = useState<DeleteFileData>();
  const [onItemNotRequired, setOnItemNotRequired] =
    useState<ItemNotRequiredData>();

  const [onUploadFilesToS3, presignedUrls, uploadToS3Error] =
    useUploadFilesToS3();

  const createRequest = async () => {
    if (onCreateData) {
      const { certificateId, reference, ...data } = onCreateData;
      mixpanel.track("Upload Certificate", {
        propertyId: props.propertyId,
        certificateId,
        reference,
      });
      return POST(confirmEvidenceUploadURL(props.propertyId, certificateId), {
        ...data,
        licenceRefNo: reference,
        uploadFiles: buildPresignedURLS(presignedUrls, data.files),
      });
    }
  };

  const createFilesRequest = async () => {
    if (onCreateFilesData) {
      const { certificateId, files } = onCreateFilesData;
      const newFiles = await Patch(
        addCertificateFilesURL(props.propertyId, certificateId),
        {
          uploadFiles: buildPresignedURLS(presignedUrls, files),
        }
      );
      return {
        certificateId,
        files: newFiles.data,
      };
    }
  };

  const updateItemRequest = async () => {
    if (onUpdateData) {
      const { expiryDate, issueDate, reference, certificateId } = onUpdateData;
      setOnCreateFilesData({
        certificateId,
        files: onUpdateData.files
          ? (onUpdateData.files.filter(
              (file) => file instanceof File
            ) as Array<File>)
          : [],
      });
      return PUT(updateCertificateURL(props.propertyId, certificateId), {
        licenceRefNo: reference,
        issueDate,
        expiryDate,
      });
    }
  };

  const updateOrderedCertificateRequest = async () => {
    if (onUpdatedCertificateOrderData) {
      const { certificateId } = onUpdatedCertificateOrderData;
      return Patch(updateCertificateOrdered(props.propertyId, certificateId), {
        ordered: onUpdatedCertificateOrderData.value,
      });
    }
  };

  const changeItemRequiredRequest = async () => {
    if (onItemNotRequired) {
      return Patch(
        changeItemRequiredUrl(
          props.propertyId,
          onItemNotRequired.certificateId
        ),
        {
          requireDeclarationChecked: onItemNotRequired.value,
        }
      );
    }
  };

  const deleteRequest = async () => {
    if (onDeleteData) {
      return DELETE(
        deleteCertificateURL(props.propertyId, onDeleteData.certificateId)
      );
    }
  };

  const deleteFileRequest = async () => {
    if (onDeleteFileData) {
      const { certificateId, fileId } = onDeleteFileData;
      return DELETE(
        deleteCertificateFileURL(props.propertyId, certificateId, fileId)
      );
    }
  };

  const createPromise = useAsync<any, ErrorType>(createRequest, false);
  const createFilesPromise = useAsync<any, ErrorType>(
    createFilesRequest,
    false
  );
  const updateItemPromise = useAsync<any, ErrorType>(updateItemRequest, false);
  const updateOrderedCertificatePromise = useAsync<any, ErrorType>(
    updateOrderedCertificateRequest,
    false
  );
  const changeItemRequiredPromise = useAsync<any, ErrorType>(
    changeItemRequiredRequest,
    false
  );
  const deletePromise = useAsync<any, ErrorType>(deleteRequest, false);
  const deleteFilePromise = useAsync<any, ErrorType>(deleteFileRequest, false);

  useEffect(() => {
    if (onCreateData) {
      if (onCreateData?.files.length) {
        onUploadFilesToS3({
          ...onCreateData,
          presignedURL: presignedEvidenceURL(
            props.propertyId,
            onCreateData.certificateId
          ),
        });
      } else {
        createPromise.execute();
        setOnCreateData(undefined);
      }
    }
  }, [onCreateData]);

  useEffect(() => {
    if (onCreateFilesData && onCreateFilesData.files.length) {
      onUploadFilesToS3({
        ...onCreateFilesData,
        presignedURL: presignedEvidenceURL(
          props.propertyId,
          onCreateFilesData.certificateId
        ),
      });
    }
  }, [onCreateFilesData]);

  useEffect(() => {
    if (presignedUrls && !uploadToS3Error) {
      if (onCreateData?.files.length) {
        createPromise.execute();
      }
      if (onCreateFilesData) {
        createFilesPromise.execute();
      }
      setOnCreateData(undefined);
      setOnCreateFilesData(undefined);
    }
  }, [presignedUrls]);

  useEffect(() => {
    if (onUpdateData) {
      updateItemPromise.execute();
    }
  }, [onUpdateData]);

  useEffect(() => {
    if (onUpdatedCertificateOrderData) {
      updateOrderedCertificatePromise.execute();
    }
  }, [onUpdatedCertificateOrderData]);

  useEffect(() => {
    if (onItemNotRequired) {
      changeItemRequiredPromise.execute();
    }
  }, [onItemNotRequired]);

  useEffect(() => {
    if (onDeleteData) {
      deletePromise.execute();
    }
  }, [onDeleteData]);

  useEffect(() => {
    if (onDeleteFileData) {
      deleteFilePromise.execute();
    }
  }, [onDeleteFileData]);

  //Keep the CRUD order. create first and delete last
  return [
    {
      trigger: setOnCreateData,
      error: createPromise.error?.errorDetails,
      result: createPromise.value?.data,
    },
    {
      trigger: setOnCreateFilesData,
      error: createFilesPromise.error?.errorDetails,
      result: createFilesPromise.value,
    },
    {
      trigger: setOnUpdateData,
      error: updateItemPromise.error?.errorDetails,
      result: updateItemPromise.status === "success" && onUpdateData,
    },
    {
      trigger: setOnUpdatedCertificateOrderData,
      error: updateOrderedCertificatePromise.error?.errorDetails,
      result:
        updateOrderedCertificatePromise.status === "success" &&
        onUpdatedCertificateOrderData,
    },
    {
      trigger: setOnItemNotRequired,
      error: changeItemRequiredPromise.error?.errorDetails,
      result:
        changeItemRequiredPromise.status === "success" && onItemNotRequired,
    },
    {
      trigger: setOnDeleteData,
      error: deletePromise.error?.errorDetails,
      result: deletePromise.status === "success" && onDeleteData,
    },
    {
      trigger: setOnDeleteFileData,
      error: deleteFilePromise.error?.errorDetails,
      result: deleteFilePromise.status === "success" && onDeleteFileData,
    },
  ];
}
