import { councilGuidelinesURL } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { GET } from "utils";

type ApiGuidelinesActions = {
  getGuidelines: APIAction<string, any>;
};

export default function useApiGuidelinesActions(): ApiGuidelinesActions {
  const getGuidelines = async (councilId: string) => {
    return GET(councilGuidelinesURL(councilId));
  };

  const getGuidelinesPromise = useAsync<APIActionResponse<any>, APIActionError>(
    getGuidelines,
    false
  );

  return {
    getGuidelines: {
      trigger: getGuidelinesPromise.execute,
      state: getGuidelinesPromise.status === "pending" ? "loading" : "idle",
      error: getGuidelinesPromise.error?.errorDetails,
      result: getGuidelinesPromise.value?.data,
    },
  };
}
