const grey = {
  grey1: "#6A7270",
  grayPrimary: "#DDDDDD",
  grey: "#414040",
  lightGrey: "#EDEDED",
  midGrey: "#C4C4C4",
  darkGrey: "#767676",
  grey2: "#A7A7A7",
  grey4: "#E9ECEC",
};

export const hamptons = {
  hamptonsPrimary: "#4F9E96",
  hamptonsNewPrimary: "#4EAEA5",
  hamptonsSecondary: "#F2C94C",
  hamptonsFocus: "#86BBD8",
  hamptonsGrey: "#C4C4C4",
  hamptonsNewDisabled: "#C4CDD5",
};

export default {
  alertBox: "#F4D100",
  primary: "#4F9E96",
  secondary: "#6dd8cd",
  black: "#1F1F1F",
  cmyk: "#d8d8d8",
  red: "#D50911",
  red2: "#FF7A79",
  error: "#ED5E5E",
  textLink: "#1A0DAB",
  orangeColor: "#fcebdb",
  lightBlue: "#E4F3F2",
  lightRed: "#FBE6E4",
  yelloNew: "rgba(255, 234, 138, 0.3)",
  yelloHexa: "#fff8da",
  white: [255, 255, 255],
  hexaWhite: "#ffffff",
  skyBlue: "#CAEDEA",
  backgroundColor: "#F4F4F4",
  accent: "#4EAEA5",
  lightAccent: "#DEF0EF",
  azure: "#ECF3F3",
  green: "#38DA33",
  paleSkyRBG: "rgba(106, 114, 112, 0.5)",
  grapeRGB: "rgba(65, 64, 64, 0.2)",
  bg1: "#FFF6EF",
  soildBg1: "#FBA364",
  bg2: "#F2F5FE",
  bg3: "#FEFAE5",
  bg5: "#ECECEC",
  bg4: "rgba(244, 209, 0, 0.1)",
  bg6: "#F0FBFA",
  bg7: "rgba(122, 157, 246, 0.1)",
  yunoActive: "rgba(244, 209, 0, 0.2)",
  label2: "#7A9DF6",
  inputGrey: "#DFE3E8",
  warningBox: "rgba(244, 209, 0, 0.15)",
  warningColor: "#F2C94C",
  listBackground: "#F9FAFB",
  errorRed: "#E2574C",
  activeInput: "#4EAEA5",
  labelColor: "#637381",
  leftImageColor: "#F0FBFA",
  placeholderColor: "#919EAB",
  accountLabel: "#939EAA",
  pureWhite: "#FFFFFF",
  warningDocumentColor: "#DBB131",
  workInProgressDocumentColor: "#F49342",
  borderColor: "#EBEEEF",
  unCompaintColor: "#e4574a",
  subTitleColor: "#313332",
  darkGreyToolTip: "#212B36",
  ...grey,
  ...hamptons,
};
