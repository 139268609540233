import React, { memo } from "react";
import withStyle from "react-jss";
import PropTypes from "prop-types";
import { Styles } from "./Styles";

export const CircleDynamic = memo(
  ({ classes, content, className, toolTip = false, toolTipText = "" }) => (
    <div className={`${classes.circleDynamic} ${className}`}>
      <div className="content">{content}</div>
      {toolTip && <div className="tooltiptext">{toolTipText}</div>}
    </div>
  )
);
CircleDynamic.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  toolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
  content: PropTypes.any.isRequired,
};
export default withStyle(Styles)(CircleDynamic);
