import { createUseStyles } from "react-jss";
import Theme from "theme";

const { breakpoints } = Theme;
export const useStyles = createUseStyles({
  main: {
    "& .title": {
      width: 432,
      minHeight: 64,
      [breakpoints.MOBILE_DEVICE]: {
        width: 327,
      },
    },
    "& .sub-title": {
      width: 432,
      minHeight: 64,
      marginTop: 24,
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: ({ colors: { hamptonsSecondary } }) => hamptonsSecondary,
      [breakpoints.MOBILE_DEVICE]: {
        width: 327,
      },
    },
  },
});
