import React from "react";
import { Redirect, Route } from "react-router-dom";
import { checkAuth } from "utils";

export const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

export const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Redirect
          to={{
            pathname: "/start-search",
            state: {
              page: "start-search",
            },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);
