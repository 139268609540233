import { MDXProvider } from "@mdx-js/react";
import propTypes from "prop-types";
import { useEffect, useState } from "react";
import Links from "../Link/Link";
import Typography from "../Typography/Typography";
import { compileMDXFunction, useMDXFunction } from "./utils";

const parseNodeRecursive = (children, tagName) => (
  <Typography as={tagName}>
    {Array.isArray(children) &&
      children.map(({ type, ...childData }) => {
        if (type === "element") {
          return parseNodeRecursive(childData.children, childData.tagName);
        }
      })}
    {children}
  </Typography>
);

const parseNode = (node, tagName) => {
  return parseNodeRecursive(node.children, tagName);
};

const CustomLink = (props) => (
  <Links as="_blank" path={props.href}>
    {props.children}
  </Links>
);

const Markdown = (props) => {
  const [code, setCode] = useState(null);
  useEffect(() => {
    async function compileAndConvertMDText() {
      //converts mdx string to JS
      const codeResult = await compileMDXFunction(props.information);
      setCode(codeResult);
    }
    compileAndConvertMDText();
  }, [props.information]);
  //converts JS into React component
  const MDXContent = useMDXFunction(code);
  if (!code) {
    return <div></div>;
  }
  return (
    <div>
      {/* props.components are the components supported in the MDX strings */}
      <MDXProvider
        components={{
          h1: (props) => parseNode(props, "h1"),
          h2: (props) => parseNode(props, "h2"),
          h3: (props) => parseNode(props, "h3"),
          h4: (props) => parseNode(props, "h4"),
          h5: (props) => parseNode(props, "h5"),
          h6: (props) => parseNode(props, "h6"),
          p: (props) => parseNode(props, "p"),
          a: CustomLink,
          ...props.components,
        }}
      >
        {/* passes props from above to the generated component */}
        <MDXContent {...props.contentProps} />
      </MDXProvider>
    </div>
  );
};

Markdown.propTypes = {
  information: propTypes.string.isRequired,
  components: propTypes.shape(propTypes.node),
  contentProps: propTypes.shape(propTypes.any),
};

Markdown.defaultProps = {
  contentProps: {},
  components: {},
};

export default Markdown;
