import useAirtableActions from "Apis/YunoApi/useAirtableActions";
import useSponsorActions from "Apis/YunoApi/useSponsorActions";
import { useCallback, useEffect } from "react";
import useModal from "./useModal";
import mixpanel from "utils/mixPanel";
type PickSponsorModalData = {
  propertyId: string;
  postcode: string;
  name: string;
};

type Props = {
  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
  propertyId?: string;
  onSuggestionSubmitted: () => void;
  onSponsorPicked: (sponsor: any) => void;
};

export default function usePickSponsor(props: Props) {
  const [
    isSponsorModalOpen,
    openSponsorModal,
    closeSponsorModal,
    sponsorModalData,
  ] = useModal<PickSponsorModalData>();
  const { getPropertySponsors, setPropertySponsor } = useSponsorActions();

  const { submitSponsorSuggestions } = useAirtableActions();

  useEffect(() => {
    if (submitSponsorSuggestions.result) {
      props.onSuggestionSubmitted();
      closeSponsorModal();
    }
  }, [submitSponsorSuggestions.result]);

  const onPickSponsorCallBack = (data: PickSponsorModalData): void => {
    openSponsorModal(data);
    getPropertySponsors.trigger(data.propertyId);
  };

  const onConfirmCallBack = useCallback(
    (data) => {
      setPropertySponsor.trigger({
        propertyId: sponsorModalData?.propertyId || "",
        sponsorData: data,
      });
      mixpanel.track("sponsor picked", {
        sponsorModalData,
        district: sponsorModalData?.postcode.split(" ")[0],
        propertyId: sponsorModalData?.propertyId,
        sponsorName: setPropertySponsor.result?.name,
      });
    },
    [sponsorModalData]
  );
  useEffect(() => {
    if (setPropertySponsor.result) {
      props.onSponsorPicked(setPropertySponsor.result);
      closeSponsorModal();
    }
  }, [setPropertySponsor.result]);

  useEffect(() => {
    if (setPropertySponsor.error) {
      closeSponsorModal();
    }
  }, [setPropertySponsor.error]);

  useEffect(() => {
    closeSponsorModal();
  }, [submitSponsorSuggestions.result]);

  const onSubmitSponsorSuggestionCallback = useCallback(
    (data) => {
      if (sponsorModalData?.propertyId) {
        submitSponsorSuggestions.trigger({
          ...data,
          propertyId: sponsorModalData.propertyId,
          user: props.user,
          postcode: sponsorModalData.postcode,
        });
      } else if (props.propertyId) {
        submitSponsorSuggestions.trigger({
          ...data,
          propertyId: props.propertyId,
          postcode: sponsorModalData?.postcode || "",
          user: props.user,
        });
      }
    },
    [sponsorModalData]
  );

  return [
    isSponsorModalOpen,
    onPickSponsorCallBack,
    getPropertySponsors.result,
    onConfirmCallBack,
    onSubmitSponsorSuggestionCallback,
    closeSponsorModal,
    sponsorModalData,
  ];
}
