import {
  usePropertyAllowedActions,
  usePropertyCertificateActions,
  usePropertyDetails,
  usePropertyGuidelines,
  usePropertyLicenceActions,
  usePropertyUpdate,
  usePropertySponsorContext,
} from "context/propertyContext/PropertyContext";
import propTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { AlertBox, Button, Typography } from "v2/components";
import LicensingView from "v2/components/LicensingView/LicensingView";
import Markdown from "v2/components/Markdown/Markdown";
import JourneyLayout from "../../JourneyLayout/JourneyLayout";
import {
  documentTypes,
  headingText,
  preLicenceListDescription,
  subHeaderText,
} from "./constants";
import styles from "./Licensing.module.css";
import LicencesList from "v2/containers/Licences";

const buildingWithoutLicensingMarkdown = {
  EnglandWales: `**England and Wales**  
<br/>
Great, it looks like there is no Additional (S257) licence required for this building.
<br/>
Just because you may not need licensing for the building doesn't mean there are no further safety works to proceed with, please review the full report for more details.  
<br/>
You may be running a Section 257 HMO  
<br/>
Refers to buildings that:  
- Have been converted into self-contained flats; and
- Less than two-thirds of the flats are owner-occupied; and
- The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply.  
<br/>
If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.  
<br/>
If this is the case the HMO Management Regulations 2007 applies to your property and you should ensure you comply as the council will still enforce this as well as the Regulatory Reform Order 2005 (where applicable) and much more.  
<br/>`,
  Scotland: `**Scotland**  
<br/>
If the Building is being ran as an HMO please, run the report again by selecting 'Property Type' as 'House'.`,
};

const flatNotHMOWithoutLicence = `
Great, it looks like there is no property/HMO licensing required for this flat.
<br/>

Just because you may not need licensing doesn't mean there are no further safety works to proceed with, please review the full report for more details.`;

const houseNotHMOWithoutLicence = `
Great, it looks like there is no property/HMO licensing required for this house. 
<br/>
Just because you may not need licensing doesn't mean there are no further safety works to proceed with, please review the full report for more details.`;

const flatHMOWithoutLicence = `
Great, it looks like there is no property/HMO licensing required for this flat.
<br/>

England and Wales:
Just because you may not need licensing doesn't mean there are no further safety works to proceed with as you may be running a Section 254 HMO
<br/>
A Section 254 HMO is;
- 3 or more tenants
- More than 1 household
- Generally speaking that share facilities (kitchen, bathroom etc) though legally should meet standard test, self-contained flat test or converted building test HMO definition.
<br/>
If this is the case the HMO Management Regulations 2006 applies to your property and you should ensure you comply as the council will still enforce this as well as HHSRS, the Regulatory Reform Order 2005 (where applicable) and much more.`;
const houseHMOWithoutLicence = `
Great, it looks like there is no property/HMO licensing required for this house.
<br/>
Just because you may not need licensing doesn't mean there are no further safety works to proceed with as you may be running a Section 254 HMO
<br/>
A Section 254 HMO is;
- 3 or more tenants
- More than 1 household
- Generally speaking that share facilities (kitchen, bathroom etc) though legally should meet standard test, self-contained flat test or converted building test HMO definition.
<br/>
If this is the case the HMO Management Regulations 2006 applies to your property and you should ensure you comply as the council will still enforce this as well as HHSRS, the Regulatory Reform Order 2005 (where applicable) and much more.`;

const Note = (props) => (
  <div className={styles.notes}>
    <span className={styles.heading}>
      <Typography as="noteTitle">{props.title}</Typography>
    </span>
    <span className={styles.noteText}>
      <Typography as="noteText">{props.text}</Typography>
    </span>
  </div>
);

function Licensing(props) {
  const { openSponsorModal } = usePropertySponsorContext();
  const formRef = useRef(null);
  const guidelines = usePropertyGuidelines();
  const propertyDetails = usePropertyDetails();
  const propertyLicenceActions = usePropertyLicenceActions();
  const propertyCertificateActions = usePropertyCertificateActions();
  const allowedPropertyActions = usePropertyAllowedActions();
  const { openPackageModal } = usePropertyUpdate();
  const [certificates, setCertificates] = useState([]);
  const [MarkdownText, setMarkdownText] = useState();

  useEffect(() => {
    if (propertyDetails.countryOnsCode) {
      if (
        propertyDetails.countryOnsCode[0] === "E" ||
        propertyDetails.countryOnsCode[0] === "W"
      ) {
        return setMarkdownText("EnglandWales");
      }
      if (propertyDetails.countryOnsCode[0] === "S") {
        return setMarkdownText("Scotland");
      }
    } else {
      return setMarkdownText("England");
    }
  }, [propertyDetails.countryOnsCode]);

  useEffect(() => {
    setCertificates(
      propertyDetails.licences.map((licence) => {
        return licence.certificates
          .map((prelicenceCertificateId) => {
            return propertyDetails.certificates.find(
              (certificate) => certificate.id === prelicenceCertificateId
            );
          })
          .filter((certificate) => !!certificate);
      })
    );
  }, [propertyDetails.certificates]);

  const headers = {
    columnNames: [
      { Header: "Scheme Description", accessor: "schemeDescription" },
      { Header: "Min Tenants", accessor: "minTenants" },
      { Header: "Min Households", accessor: "minHouseholds" },
      { Header: "Start Date", accessor: "startDate" },
      { Header: "End Date", accessor: "endDate" },
    ],
  };

  const schemesExist = propertyDetails.schemes;

  // Component and helper for CRM Licence Held View
  const ukDate = (date) =>
    date ? date.split("-").reverse().join("/") : "No date specified";

  const CrmUploadItem = (props) => (
    <div className={styles.crmItemContainer}>
      <div className={styles.crmItemLeft}>{props.title}</div>
      <div className={styles.crmItemRight}>{props.data}</div>
    </div>
  );

  const CRMProperty =
    propertyDetails.crmLicenceUpload &&
    propertyDetails.crmLicenceUpload.length > 0;

  const data = [
    {
      schemeDescription: "Mandatory",
      minTenants: 5,
      minHouseholds: 3,
      startDate: "2020-03-13",
      endDate: null,
    },
  ];

  // Hide 257 from licensing section if propertyType === "Flat"
  const filteredLicences =
    propertyDetails.propertyType === "Flat"
      ? propertyDetails.licences.filter((l) => !l.licenceType.includes("257"))
      : propertyDetails.licences;

  return (
    <JourneyLayout
      name={props.name}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      nextName={props.nextName}
    >
      <div className={styles.container}>
        {!propertyDetails.licences.length &&
          propertyDetails.propertyType === "Building" && (
            <Markdown
              information={buildingWithoutLicensingMarkdown.MarkdownText}
            />
          )}
        {!propertyDetails.licences.length &&
          propertyDetails.propertyType === "Flat" && (
            <>
              {!propertyDetails.isHMO && (
                <Markdown information={flatNotHMOWithoutLicence} />
              )}
              {propertyDetails.isHMO && (
                <Markdown information={flatHMOWithoutLicence} />
              )}
            </>
          )}
        {!propertyDetails.licences.length &&
          propertyDetails.propertyType === "House" && (
            <>
              {!propertyDetails.isHMO && (
                <Markdown information={houseNotHMOWithoutLicence} />
              )}
              {propertyDetails.isHMO && (
                <Markdown information={houseHMOWithoutLicence} />
              )}
            </>
          )}

        {propertyDetails.propertyType !== "Building" &&
          filteredLicences.filter((l) => l.licenceType !== "Landlord Register")
            .length > 1 && (
            <div className={styles.alertBox}>
              <AlertBox
                onClick={props.goHome}
                alertMessage={`Only one licence is required for each property. Below, you will find a list of all licences that may be necessary for this property. Please add occupancy details in the property information form to identify the exact licence that is required.`}
              >
                <Button
                  variant="padded"
                  actionName="Go to Property Information Form"
                />
              </AlertBox>
            </div>
          )}

        {/* // Display Property Applicable Licences  */}
        {filteredLicences.map((licence, licenceIndex) => (
          <LicensingView
            key={licenceIndex}
            title={licence.licenceType}
            propertyDetails={propertyDetails}
            package={propertyDetails.package}
            licence={{
              documents: licence.documents,
              id: licence.propertyItemId,
              actions: propertyLicenceActions,
              state: licence.state,
              startDate: licence.startDate,
              schemeTitle: licence.schemeTitle,
            }}
            headingText={headingText(licence.licenceType)}
            subHeadingText={subHeaderText(licence.licenceType)}
            guideline={licence.guideline}
            documents={licence.documents}
            documentTypes={documentTypes}
            preLicenceDescription={preLicenceListDescription(props.licenceType)}
            certificates={certificates[licenceIndex] || []}
            certificateActions={propertyCertificateActions}
            openPackageModal={openPackageModal}
            canUploadFiles={allowedPropertyActions.canViewPremiumData}
          />
        ))}

        {!!guidelines && (
          <LicencesList
            licensingPage={true}
            loading={!guidelines}
            propertyDetails={propertyDetails}
            formRef={formRef}
            licences={guidelines}
            canViewPremiumData={true}
            openSponsorModal={openSponsorModal}
          />
        )}

        {propertyDetails.propertyType === "Flat" && (
          <section>
            <Note
              title="Building"
              text="The Building this flat is in may need licensing or planning permission, if you want to run a full report on the building, start a new search. To simply find out more about building licensing and to notify the building owner just go to the 'Building Certification' section below."
            />
            <Note
              title="Note"
              text="As you have selected flat, in this section we have only checked the licensing for flats, if you believe your flat is in a property that could be a 254 or 257 HMO (see definitions) then please run the check again using House or Building retrospectively under 'Property Type' or if this is a complex/mixed-use property then please consult an expert for advice."
            />
          </section>
        )}
        {propertyDetails.propertyType === "Building" && (
          <>
            <Note
              title="Flats"
              text="The flats in this building may need individual licences or planning permission, if you want to run a full report on the flat then run the report again with the flat address and select 'Property Type' as 'Flat'. "
            />
            <Note
              title="Notify your fellow freeholders/leaseholders to ensure there is no risk of fines to you."
              text="It is the responsibility of the freeholder to ensure there is an up to date ‘suitable and sufficient’ Fire Risk Assessment in place at all times. It is now more important than ever to ensure all buildings and commercial premises have this in place, please review the full report for more details."
            />
            <Note
              title="Note"
              text="England and Wales:As you have selected Building, in this section we have only checked additional licensing against the 257 definition of an HMO, if you believe your property could be a 254 HMO (see definition) then please run the check again using 'House' under 'Property Type' or if this is a complex, or mixed use, property then please consult an expert for advice.
Scotland:As you have selected Building, if you believe your property could be an HMO (see definition) then please run the check again using 'House' under 'Property Type' or if this is a complex, or mixed use, property then please consult an expert for advice."
            />
          </>
        )}
        {/* // Show CRM Item Uploads (Licence Held Info) */}
        {CRMProperty ? (
          <Typography as="h2">CRM Licence Held Info: </Typography>
        ) : null}

        {CRMProperty ? (
          <div className={styles.crmUploads}>
            {propertyDetails.crmLicenceUpload.map((l, index) => (
              <>
                <div className={styles.crmUploadContainer}>
                  <CrmUploadItem
                    title="Licence Held Reference: "
                    data={l.licenceRefNo}
                  />
                  <br />
                  <CrmUploadItem title="Status: " data={l.status} />
                  <CrmUploadItem title="Licence Type: " data={l.licenceType} />
                  <br />
                  <CrmUploadItem
                    title="Application Date: "
                    data={ukDate(l.applicationDate)}
                  />
                  <CrmUploadItem
                    title="Granted Date: "
                    data={ukDate(l.issueDate)}
                  />
                  <CrmUploadItem
                    title="Expiry Date: "
                    data={ukDate(l.expiryDate)}
                  />
                  {l.maxTenants && (
                    <>
                      <br />
                      <CrmUploadItem
                        title="Max Tenants: "
                        data={l.maxTenants}
                      />
                      <CrmUploadItem
                        title="Max Households: "
                        data={l.maxHouseholds}
                      />
                    </>
                  )}
                </div>
                <br />
              </>
            ))}
          </div>
        ) : null}
      </div>
    </JourneyLayout>
  );
}

Licensing.propTypes = {
  onNext: propTypes.func,
  onPrevious: propTypes.func,
};

export default Licensing;
