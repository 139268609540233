import { domainInfoURL } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { GET } from "utils";
type DomainData = {
  sponsorable: boolean;
};

type DomainActions = {
  getDomainInfo: APIAction<any, DomainData | null>;
};

export const useDomainAction = (): DomainActions => {
  const getDomainInfo = async () => {
    return GET(domainInfoURL());
  };
  const getDomainInfoPromise = useAsync<
    APIActionResponse<DomainData>,
    APIActionError
  >(getDomainInfo, false);
  return {
    getDomainInfo: {
      trigger: getDomainInfoPromise.execute,
      state: getDomainInfoPromise.status === "pending" ? "loading" : "idle",
      error: getDomainInfoPromise.error?.errorDetails,
      result: getDomainInfoPromise.value?.data || null,
    },
  };
};
