export const breadcrumbText = {
  Flat: "Building Certification",
  Building: "Leaseholder Certification",
};
export const expireText = {
  1: "Actions required in the next few months, please update your documentation",
  2: "Your document(s) have expired. Submit a work order or upload documents to make this property compliant!",
};

export const freeHolderText = {
  Building: [
    {
      title: "Flats",
      subTitle: `The flats in this building may need individual licences or planning permission, if you want to run a full report on the flat please start a new search. To simply find out more about flat licensing and to notify the flat owner just go to the 'Leaseholder Certification' section below.`,
    },
    {
      title:
        "Notify your fellow free/leaseholders to ensure there is no risk of fines to you.",
      subTitle:
        "It is the responsibility of the freeholder to ensure there is an up to date ‘suitable and sufficient’ Fire Risk Assessment in place at all times. It is now more important than ever to ensure all buildings and commercial premisses have this in place, please review the full report for more details.",
    },
  ],
  Flat: [
    {
      title: `Notify your fellow free/leaseholders to ensure there is no risk of fines to you.`,
      subTitle: `It is the responsibility of the freeholder to ensure there is an up to date ‘suitable and sufficient’ Fire Risk Assessment in place at all times. It is now more important than ever to ensure all buildings and commercial premises have this in place and you need to see it.`,
      S257: "Pre-application the freeholder will need the following documents.",
      S257Rule1: "This building may need an Additional S257 Licence",
      S257Rule2:
        "It is the responsibility of the freeholder to get an Additional S257 licence.",
    },
  ],
};

export const notes = {
  Flat: [
    {
      title: "Note",
      subTitle:
        "In this section we have only checked the additional licensing against the 257 definition of an HMO, if you believe your property could be a 254 HMO then please run the check again using 'House' under 'Property Type' or if this is a complex, or mixed use, property then please consult an expert for advice.",
    },
  ],
};

export const totalContent = [
  "Planning",
  "Licensing",
  "Certification",
  {
    Flat: "Building Certification",
    Building: "Leaseholder Certification",
  },
];
export const compaintText = [
  `As part of the application process, the council will charge an application fee.
  The change of use fee is displayed here.`,
  ` As part of the application process,
  the council will charge an application fee.
  This fee will be calculated by the size of the property.
  For a rough guide, check out the property info tab.`,
];
export const planningText = {
  "Planning Permission": `There may be other planning permission required
  if you have any other modifications happening to the property or you
  are in a conservation area. You can book a call with a RPTI registered Town Planner to look into this for you.`,
  planning: (
    label
  ) => `We have checked Article 4 HMO and Sui Generis Planning permission and it looks like
   ${label} may be required. You can book a call with a RPTI registered Town Planner to look into this for you.`,
};

export const noSuiGenerisOrArticle4PlanningText = `England 
We have checked Article 4 HMO and Sui Generis Planning permission for HMOs and it is not required. 
There are many types of planning permission and we have only check the ones pertaining to HMOs, other exmaples could be, but not limited to, if you have made any modifications to the property or the property is a Listed Building or is in a Conservation Areas. 

In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors most notably the time it has been ran in its current form and if you can prove it.

If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.

If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building. 

Wales
We have checked HMO Planning permission and Sui Generis Planning permission for HMOs and it is not required. 
There are many types of planning permission and we have only check the ones pertaining to HMOs, other exmaples could be, but not limited to, if you have made any modifications to the property pr the property is a Listed Building or is in a Conservation Areas. 

In some cases Planning can be deemed to be a Lawful Development (Lawful Use) depending on many factors most notably the time it has been ran in its current form.

If looking for expert advice ensure you look for an RTPI registered Town Planner to look into this for you.

If you believe the property to be an 257 HMO, please run the report again with 'Property Type' set to 'Building. 

Scotland
You will only need planning permission for an HMO in Scotland if it is seen as a Material change of use.
There are many types of planning permission and we have only check the ones pertaining to HMOs, other exmaples could be, but not limited to, if you have made any modifications to the property or the property is a Listed Building or is in a Conservation Areas. `;
export const planningType = "Planning Permission";

export const documentTypes = [
  { value: "Planning Application", label: "Planning Application" },
  { value: "Planning Response", label: "Planning Response" },
  { value: "Issued Planning Permission", label: "Issued Planning Permission" },
];
