import PropTypes from "prop-types";
import styles from "./Button.module.css";

function ButtonV2(props) {
  return (
    <button
      type={props.type}
      className={`${styles.container} ${styles[props.role]}`}
      onClick={props.onClick}
      disabled={props.disable ? props.disable : false}
    >
      {props.children}
    </button>
  );
}

ButtonV2.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default ButtonV2;
