import useModal from "../../../Hooks/useModal";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { ReactComponent as UploadIcon } from "../../../images/uploads.svg";
import Modal from "../ModalV2/Modal";
import Typography from "../Typography/Typography";
import UploadLicence from "../Upload/UploadLicence/UploadLicence";
import styles from "./LicenceInfo.module.css";
import { ReactComponent as ActionRequiredYellow } from "images/actionRequiredYellow.svg";
import { ReactComponent as ActionRequiredRed } from "images/actionRequiredRed.svg";
import { ReactComponent as CheckIcon } from "images/checked.svg";

const EmptyDocumentInfo = (props) => (
  <div className={styles.wrap}>
    <Typography as="documentLabel">{props.emptyMessage}</Typography>
  </div>
);

const Document = (props) => {
  const current = new Date(Date.now());
  const docDate = new Date(props.expiryDate);

  const doc1Month = new Date(docDate);
  doc1Month.setMonth(docDate.getMonth() - 1);

  const doc4Months = new Date(doc1Month);
  doc4Months.setMonth(doc4Months.getMonth() - 3);

  const [redAction, setRedAction] = useState();
  const [yellowAction, setYellowAction] = useState();

  useEffect(() => {
    if (current >= doc1Month) {
      return setRedAction(true), setYellowAction(false);
    } else if (current < doc1Month && current >= doc4Months) {
      return setYellowAction(true), setRedAction(false);
    } else if (current < doc4Months) {
      return setYellowAction(false), setRedAction(false);
    }
  }, [props]);
  return (
    <div className={styles.wrap}>
      <span className={styles.documentName}>
        {props && redAction && <ActionRequiredRed />}
        {props && yellowAction && <ActionRequiredYellow />}
        {props && !yellowAction && !redAction && <CheckIcon />}
        <Typography as="documentLabel">
          {props.documentType.label}
          <Typography as="documentLabelRef"> Ref: {props.reference}</Typography>
        </Typography>
      </span>
      <span className={styles.documentData}>
        {props.expiryDate && (
          <Typography as="documentLabel">
            Expiry date: {docDate.toLocaleDateString()}
          </Typography>
        )}
        <span className={styles.editButton} onClick={props.onEdit}>
          <Typography as="documentEditBtn">Edit</Typography>
        </span>
      </span>
    </div>
  );
};

const LicenceInfo = (props) => {
  const [isModalOpen, openModal, closeModal, modalData] = useModal();

  const onSave = (data) => {
    closeModal();
    props.licence.actions.onCreate({
      ...data,
      licenceId: props.licence.id,
    });
  };
  const onUpdate = (data) => {
    closeModal();
    props.licence.actions.onUpdate(data);
  };

  const onAdd = React.useCallback(
    (data) => {
      openModal({
        name: props.documentName,
        itemUploadId: data.itemUploadId,
        issueDate: {
          value: props.issueDate?.value,
          error: props.isIssueDateRequired ? "Issue date is required" : "",
        },
        expiryDate: {
          value: document?.expiryDate,
          error: props.isExpiryDateRequired ? "Expiry date is required" : "",
        },
      });
    },
    [openModal, props.documentName]
  );

  const onEditLicence = React.useCallback(
    (document) => {
      openModal({
        ...document,
        issueDate: {
          value: document?.issueDate,
          error: props.isIssueDateRequired ? "Issue date is required" : "",
        },
        expiryDate: {
          value: document?.expiryDate,
          error: props.isExpiryDateRequired ? "Expiry date is required" : "",
        },
      });
    },
    [props]
  );
  useWhyDidYouUpdate("LicenceInfo", props);
  return (
    <div className={styles.container}>
      {!!props.licence.documents.length && (
        <div className={styles.toolTipTop}>
          <Typography as="licenseName">Documents</Typography>
          <span className={styles.toolTip}>
            <UploadIcon onClick={onAdd} />
            <Typography as="licenseName">
              <span className={styles.toolTipText}>Upload</span>
            </Typography>
          </span>
        </div>
      )}
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <UploadLicence
            documentTypes={props.documentTypes}
            onCreate={onSave}
            onUpdate={onUpdate}
            onClose={closeModal}
            onDeleteFile={props.licence.actions.onDeleteFile}
            isExpiryRequired={props.isExpiryDateRequired}
            {...modalData}
          />
        </Modal>
      )}
      {!props.licence.documents.length && (
        <div className={styles.toolTipTop}>
          <EmptyDocumentInfo onAdd={onAdd} emptyMessage={props.emptyMessage} />
          <span className={styles.toolTip}>
            {props.canUploadFiles && (
              <UploadIcon onClick={onAdd} id="Stonly_uploadButton" />
            )}
            <Typography as="licenseName">
              <span className={styles.toolTipText}>Upload</span>
            </Typography>
          </span>
        </div>
      )}
      {props.licence.documents.map((document, index) => {
        return (
          <Document
            key={index}
            {...document}
            onEdit={() => onEditLicence(document)}
          />
        );
      })}
    </div>
  );
};

LicenceInfo.propTypes = {
  isIssueDateRequired: propTypes.bool.isRequired,
  isExpiryDateRequired: propTypes.bool.isRequired,
  canUploadFiles: propTypes.bool,
  documentName: propTypes.string.isRequired,
  emptyMessage: propTypes.string.isRequired,
  licence: propTypes.shape({
    id: propTypes.string.isRequired,
    documents: propTypes.arrayOf(
      propTypes.shape({
        documentType: propTypes.object,
        reference: propTypes.string,
        issueDate: propTypes.string,
        expiryDate: propTypes.string,
        files: propTypes.arrayOf(
          propTypes.shape({
            name: propTypes.string,
            url: propTypes.string,
          })
        ),
      })
    ),
    actions: propTypes.shape({
      onCreate: propTypes.func,
      onUpdate: propTypes.func,
      onDeleteFile: propTypes.func,
    }),
  }),
  documentTypes: UploadLicence.propTypes.documentTypes,
};

LicenceInfo.defaultProps = {
  licence: {
    documents: [],
    actions: {
      onCreate: () => {},
      onUpdate: () => {},
      onDeleteFile: () => {},
    },
  },
};
export default LicenceInfo;
