import useInput from "Hooks/useInput";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import React from "react";
import ListFileInput from "../../Form/Input/ListFileInput/ListFileInput";
import Input from "../../Form/Input/Input";
import UploadLayout from "../UploadLayout";
import styles from "./UploadCertificate.module.css";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const UploadCertificate = (props) => {
  const [actionName, setActionName] = React.useState("");

  const [reference, referenceError, setReference, checkReference] = useInput(
    props.reference,
    "This field is required"
  );
  const [issueDate, issueDateError, setIssueDate, checkIssueDate] = useInput(
    props.issueDate
  );
  const [expiryDate, expiryDateError, setExpiryDate, checkExpiryDate] =
    useInput(props.expiryDate, "An expiry date is required");
  const [files, filesInputError, setFiles, checkFiles] = useInput(
    props.files,
    "",
    "files"
  );

  React.useEffect(() => {
    if (
      !actionName ||
      !!referenceError ||
      !!issueDateError ||
      !!expiryDateError ||
      !!filesInputError
    ) {
      setActionName("");
      return;
    }
    const createDataObj = {
      reference,
      files,
    };
    const updateDataObj = {
      certificateId: props.id,
      reference,
      files,
    };
    if (expiryDate) {
      createDataObj.expiryDate = expiryDate;
      updateDataObj.expiryDate = expiryDate;
    }
    if (issueDate) {
      createDataObj.issueDate = issueDate;
      updateDataObj.issueDate = issueDate;
    }
    switch (actionName) {
      case "create":
        props.onSave(createDataObj);
        break;
      case "update":
        props.onUpdate(updateDataObj);
        break;
    }
    setActionName("");
  }, [actionName]);

  const checkFormFields = React.useCallback(() => {
    checkReference();
    checkExpiryDate();
    checkIssueDate();
    checkFiles();
  }, [checkReference, checkIssueDate, checkExpiryDate, checkFiles]);

  const onSave = () => {
    checkFormFields();
    setActionName("create");
  };

  const onUpdate = () => {
    checkFormFields();
    setActionName("update");
  };

  const onDelete = (file, deletedIndex) => {
    if (!(file instanceof File)) {
      props.onDelete({
        ...props.document,
        certificateId: props.id,
        fileId: file.id,
      });
    }
    setFiles(files.filter((file, index) => deletedIndex !== index));
  };

  useWhyDidYouUpdate("Update Certificate", props);
  return (
    <UploadLayout
      title="Upload Certificate"
      isSave={!props.reference}
      onClose={props.onClose}
      onSave={onSave}
      onUpdate={onUpdate}
    >
      <div className={styles.row}>
        <Input label="Document type" value={props.name} readOnly={true} />
      </div>
      <div className={styles.row}>
        <Input
          error={referenceError}
          value={reference}
          onChange={setReference}
          label="Application reference number"
          name="applicationReference"
          placeholder="Enter your application reference number"
        />
      </div>
      <div className={styles.row}>
        <Input
          type="date"
          name="issueDate"
          label="Issue Date"
          value={issueDate}
          onChange={setIssueDate}
          max={new Date().toISOString().split("T")[0]}
          error={issueDateError}
        />
      </div>
      <div className={styles.row}>
        <Input
          type="date"
          name="expiryDate"
          label="Expiry Date"
          value={expiryDate}
          onChange={setExpiryDate}
          min={tomorrow.toISOString().split("T")[0]}
          error={expiryDateError}
        />
      </div>
      <div className={styles.chooseFile}>
        <ListFileInput
          fileInputProps={{
            label: "Choose file to upload",
            error: filesInputError,
            files: files,
            onClick: setFiles,
          }}
          onDelete={onDelete}
          files={files}
        />
      </div>
    </UploadLayout>
  );
};

UploadCertificate.propTypes = {
  name: propTypes.string.isRequired,
  reference: propTypes.string,
  issueDate: propTypes.string,
  expiryDate: propTypes.string,
  files: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      url: propTypes.string,
    })
  ),
  onClose: propTypes.func,
  onSave: propTypes.func,
  onUpdate: propTypes.func,
  onDelete: propTypes.func,
};

UploadCertificate.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  reference: "",
  issueDate: "",
  expiryDate: "",
  files: [],
};

export default UploadCertificate;
