import { LICENCE_LIST, LICENCE_ERROR, LOADING, APICALL } from "./constants";
import { initialState } from "./initialState";

export const reducer = (
  state = initialState,
  { type, payload, loading = false }
) => {
  switch (type) {
    case LICENCE_LIST:
      return { ...state, licenseList: { ...payload }, loading };
    case LICENCE_ERROR:
      return { ...state, apiError: { ...payload }, loading: false };
    case LOADING:
      return { ...state, loading: payload };
    case APICALL:
      return {
        ...state,
        loading: true,
        licenseList: {},
        apiError: {},
      };
    default:
      return state;
  }
};
