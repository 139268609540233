import Label from "../Label";
import styles from "./Select.module.css";

const SelectInput = ({
  name,
  variant,
  onChange,
  onBlur,
  onFocus,
  error,
  readOnly = false,
  isClearable = false,
  size,
  options = [],
  classes,
  inputRef,
  id,
  defaultValue,
  ...props
}) => {
  return (
    <label
      onInputTip={props.onInputTip}
      error={props.error}
      label={props.label}
      className={styles.label}
    >
      <select
        id={id}
        ref={inputRef}
        className={
          props.placeholder != defaultValue ? styles.selected : styles.select
        }
        defaultValue={defaultValue ? defaultValue : props.placeholder}
        isDisabled={readOnly}
        onChange={props.onOptionChange}
      >
        <option disabled className={styles.optionDisabled}>
          {props.placeholder}
        </option>
        {options.map((option) => (
          <option className={styles.option} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default SelectInput;
