export const Styles = ({ colors }) => ({
  longTooltip: {
    position: "relative",
    display: " inline-block",
    "&:hover > .tooltiptext": {
      visibility: "visible",
    },
    "& .tooltiptext": {
      visibility: "hidden",
      width: 155,
      minHeight: "30px",
      height: "30px",
      backgroundColor: colors.darkGreyToolTip,
      color: colors.hexaWhite,
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: 1,
      top: "42px",
      left: "-59px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.09)",
      "&:after": {
        content: '""',
        left: "72px",
        top: "-4px",
        width: "10px",
        height: "10px",
        position: "absolute",
        bottom: ({ bottom = "39px" }) => bottom,
        background: colors.darkGreyToolTip,
        transform: "rotate(45deg)",
      },
      "& .text": {
        margin: 12,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: `${colors.hexaWhite} !important`,
      },
    },
  },
});
