import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "./Input.module.css";

function PhoneNumberInput(props) {
  return (
    <PhoneInput
      name={props.name}
      className={`${styles.container} ${props.error ? styles.inputError : ""}`}
      placeholder="Enter phone number"
      defaultCountry="GB"
      error={props.error}
      value={props.value}
      onChange={(value) =>
        props.onChange({
          target: { value, name: props.name },
        })
      }
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
}

export default PhoneNumberInput;
