import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, SimpleButton } from "components";
import { Typography } from "v2/components";
import { ReactComponent as HelpIcon } from "images/help.svg";
import theme from "theme";
import { Styles } from "./Styles";

const { colors } = theme;
const setting = {
  width: 640,
  height: 450,
  top: "0",
  color: colors.backgroundColor,
};
const CompliantModel = ({
  isOpen,
  addDetails,
  classes,
  heading = "This property only needs 1 licence",
  subTitle = "Add more details to identify which licence this property needs.",
  buttonText = "Add details",
}) => (
  <Modal isOpen={isOpen} setting={setting} className={classes.compliant}>
    <div className={classes.main}>
      <div className={classes.content}>
        <div className="icon-check">
          <HelpIcon />
        </div>
        <div className="text-heading">
          <Typography as="h6">{heading}</Typography>
        </div>
        <div className="sub-heading">{subTitle}</div>
        <div className="button">
          <SimpleButton onClick={addDetails}>{buttonText}</SimpleButton>
        </div>
      </div>
    </div>
  </Modal>
);
CompliantModel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  addDetails: PropTypes.func.isRequired,
  heading: PropTypes.string,
  subTitle: PropTypes.string,
};

export default withStyles(Styles)(memo(CompliantModel));
