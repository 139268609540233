import styles from "./PropertyAction.module.css";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import { ReactComponent as ViewProperty } from "images/viewProperty.svg";
import { ReactComponent as DeleteProperty } from "images/deleteProperty.svg";

import { Button } from "../Form";
import mixpanel from "utils/mixPanel";

function PropertyAction(props) {
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(2);
  const onViewProperty = () => {
    props.onViewProperty(props.propertyUuid);
  };
  const onDeleteProperty = () => {
    props.onDeleteProperty(props.propertyUuid);
    setIsOpen(false);
  };
  const onActions = () => {
    setIsOpen(!isOpen);
    mixpanel.track("PropertyDashboardActions");
  };
  return (
    <div className={styles.container}>
      <Button
        {...buttonProps}
        variant="noBorder"
        onClick={onActions}
        actionName="•••"
      />
      {isOpen && (
        <div className={styles.menu} role="menu">
          <a className={styles.view} {...itemProps[0]} onClick={onViewProperty}>
            <ViewProperty />
            View Property
          </a>
          {props.onDeleteProperty && (
            <a
              className={styles.delete}
              {...itemProps[1]}
              onClick={onDeleteProperty}
            >
              <DeleteProperty />
              Delete Property
            </a>
          )}
        </div>
      )}
    </div>
  );
}

export default PropertyAction;
