import { businessURL } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { POST } from "utils";

type BusinessData = {
  name: string;
  logoUrl: string;
  address: string;
};

type DomainActions = {
  upgradeBusiness: APIAction<BusinessData, BusinessData | null>;
};

export const useApiBusinessActions = (): DomainActions => {
  const createBusiness = async (data: BusinessData) => {
    try {
      await POST(businessURL(), { data });

      return {
        data,
        meta: null,
      };
    } catch (error) {
      throw new Error("Business not created");
    }
  };

  const createBusinessPromise = useAsync<
    APIActionResponse<BusinessData>,
    APIActionError
  >(createBusiness, false);
  return {
    upgradeBusiness: {
      trigger: createBusinessPromise.execute,
      state: createBusinessPromise.status === "pending" ? "loading" : "idle",
      error: createBusinessPromise.error?.errorDetails,
      result: createBusinessPromise.value?.data || null,
    },
  };
};
