import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyle from "react-jss";
import { ReactComponent as DangerIcon } from "images/reminder.svg";
import { Styles } from "./Styles";

const DangerCircle = ({ classes, className = "" }) => (
  <div className={`${classes.dangerCircle} ${className}`}>
    <DangerIcon />
  </div>
);

DangerCircle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyle(Styles)(memo(DangerCircle));
