export const councils = (postCode) => `councils/${postCode}/guideline`;
export const councilGuidelinesURL = (councilId) =>
  `councils/${councilId}/guideline`;
export const documents = (postCode) =>
  `councils/${postCode}/guideline/documents`;
export const inActiveCouncils = "councils";
export const getDomainInfo = "customers/validate/domain";
export const saveDataAirTable = "search";
export const loginUrl = "/auth/login";
export const propertyURL = "properties";

export const propertyIdURL = (propertyId, token) => {
  if (!token) {
    return `/properties${propertyId ? `/${propertyId}` : ""}`;
  }
  return `/properties${propertyId ? `/${propertyId}` : ""}?token=${token}`;
};

export const addTenancyURL = (propertyUuid) => {
  return `/properties/${propertyUuid}/tenancies`;
};

export const updateTenancyURL = (propertyUuid, tenancyId) => {
  return `/properties/${propertyUuid}/tenancies/${tenancyId}`;
};

export const propertyAddVerifiedURL = "properties/verified";
export const propertyAddUnverifiedURL = "properties";
//export const updateProperty = "properties/update-property-verified";

export const updateProperty = (propertyId) =>
  `properties/verified/${propertyId}`;

export const propertyMatchURL = "properties/match";
export const propertyMatchCoordinate = "properties/match/coordinates";
export const propertyFiltersURL = "properties/filters";
export const startNumberVerification = "leads/actions/startPhoneVerification";
export const verifyNumberURL = "leads";

export const userPhoneNumberURL = () => `users/actions/update-phone-number`;
export const userLogoutURL = () => `users/logout`;

export const submitJobDetailsURL = () => "airtable/job-details";
export const submitSponsorSuggestionURL = () => "airtable/sponsor-suggestions";
export const sendQueryToCustomer = () => "email/send-rent-query-to-customer";
export const getPolygonsFromBoundingbox = () => "maps/bounding-polygons";

export const presignedEvidenceURL = (propertyId, evidenceId) =>
  `properties/${propertyId}/evidences/${evidenceId}/preAssignedUrl`;
export const confirmEvidenceUploadURL = (propertyId, evidenceId) =>
  `properties/${propertyId}/evidences/${evidenceId}/uploads`;
export const addCertificateFilesURL = (propertyId, evidenceId) =>
  `properties/${propertyId}/evidences/uploads/${evidenceId}/add-files`;
export const updateCertificateURL = (propertyId, certificateId) =>
  `/properties/${propertyId}/evidences/uploads/${certificateId}`;
export const updateCertificateOrdered = (propertyId, certificateId) =>
  `/properties/${propertyId}/evidences/${certificateId}`;
export const changeItemRequiredUrl = (propertyId, certificateId) =>
  `/properties/${propertyId}/evidences/${certificateId}`;
export const deleteCertificateURL = (propertyId, certificateId) =>
  `/properties/${propertyId}/evidences/uploads/${certificateId}`;
export const deleteCertificateFileURL = (propertyId, certificateId, fileId) =>
  `/properties/${propertyId}/evidences/uploads/${certificateId}/files/${fileId}`;

export const presignedItemURL = (propertyId, itemId) =>
  `properties/${propertyId}/items/${itemId}/preAssignedUrl`;
export const confirmItemUploadURL = (propertyId, itemId) =>
  `properties/${propertyId}/items/${itemId}/uploads`;
export const updateItemURL = (propertyId, propertyItemUploadId) =>
  `properties/${propertyId}/items/uploads/${propertyItemUploadId}`;
export const updateItemOrdered = (propertyId, itemId) =>
  `/properties/${propertyId}/items/${itemId}`;
export const addItemFilesURL = (propertyId, itemId) =>
  `properties/${propertyId}/items/uploads/${itemId}/add-files`;
export const deleteItemUploadURL = (propertyId, itemUploadId, fileId) =>
  `/properties/${propertyId}/items/uploads/${itemUploadId}/files/${fileId}`;

export const getPropertySponsorsURL = (propertyId) => `/business/${propertyId}`;
export const setPropertySponsorsURL = (propertyId) =>
  `/properties/${propertyId}/sponsor`;
export const sponsoredPropertiesURL = () => `/properties/sponsored`;
export const approveWorkOrderURL = (token, propertyId) =>
  `landlords/${token}/properties/${propertyId}/approve`;

export const submitWorkOrderURL = (propertyId) =>
  `properties/${propertyId}/work/order`;

export const businessURL = () => `/business`;

export const leadGenerationURL = () => `/leads`;
export const getLeadsURL = () => `/leads`;
export const propertyStatsURL = () => `properties/stats`;
export const domainInfoURL = () => `customers/validate/domain`;
export const packagesURL = () => `/packages`;
export const getApiAnalytics = (propertyId) =>
  `properties/${propertyId}/analytics`;
export const getCachedAnalyticsURL = (propertyId) =>
  `properties/${propertyId}/cached-analytics`;
export const getAnalyticURL = (propertyId, name) =>
  `properties/${propertyId}/analytics/${name}`;
export const submitWorkOrderUrl = (propertyId) =>
  `properties/${propertyId}/work/order`;

export const licencesURL = "licences";
export const certificatesURL = "certificates";
export const propertyStats = "properties/stats";
export const packages = "packages";
export const leadsUrl = "leads";
export const signupAgent = "users/customerUser/signup";

export const getS3Url = (
  propertyId,
  propertyCertificateId,
  type = "certificates"
) => `/properties/${propertyId}/${type}/${propertyCertificateId}/document`;

export const updateDocument = (
  propertyId,
  propertyCertificateId,
  type = "certificates"
) =>
  propertyCertificateId
    ? `/properties/${propertyId}/${type}/${propertyCertificateId}`
    : `/properties/${propertyId}/${type}`;

export const gets3UrlPlanning = (propertyId) =>
  `/properties/${propertyId}/planning/document`;

export const osApiURL = "address-lookup";
export const checkRequest = (propertyId) =>
  `properties/${propertyId}/manual/check/request`;
export const freeHolder = (propertyId) => `properties/${propertyId}/holders`;
export const freeHolderNotify = (propertyId) =>
  `properties/${propertyId}/notify/holders`;
export const forgetPasswordEmail = "users/actions/requestPasswordReset";
export const resetPassword = "users/actions/resetPassword";
export const emailTemplate = "emailTemplates/notify-landlord-for-approval";
export const notifyForApproval = (propertyId) =>
  `properties/${propertyId}/notifyForApproval`;
export const approveWorkOrder = ({ token, propertyId }) =>
  `landlords/${token}/properties/${propertyId}/approve`;
export const landlordInfo = (propertyId, landlodId) =>
  `/properties/${propertyId}/landlords${landlodId ? `/${landlodId}` : ""}`;
export const landlordSignup = "users/landlord/signup";
export const boundingPolygonsURL = "maps/bounding-polygons";
