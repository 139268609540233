import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { Styles } from "./Styles";

import { ReactComponent as WarningIcon } from "images/actionRequiredRed.svg";
import { ReactComponent as SuggestionIcon } from "images/actionRequiredYellow.svg";

import PasswordStrengthBar from 'react-password-strength-bar';

import { zxcvbnAsync, zxcvbnOptions } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import matcherPwnedFactory from '@zxcvbn-ts/matcher-pwned';

const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions);
zxcvbnOptions.addMatcher("pwned", matcherPwned);

const options = {
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  useLevenshteinDistance: true,
  translations: zxcvbnEnPackage.translations
};

zxcvbnOptions.setOptions(options);

const PasswordValidation = ({ password, className = "", classes }) => {
  const [validation, setValidation] = useState({
    oneNumber: false,
    oneLowerLetter: false,
    oneUpperLetter: false,
    onSpecialLetter: false,
    totalLength: false,
    warning: '',
    suggestions: [],
    score: 0
  });

  useEffect(() => { 
    const validPassword = { ...validation };

    zxcvbnAsync(password).then((response) => {
      validPassword.warning = response.feedback.warning;
      validPassword.suggestions = response.feedback.suggestions;
      validPassword.score = response.score;

      if (password.length >= 14) {
        validPassword.totalLength = true;
      } else {
        validPassword.totalLength = false;
      }
      if (/[^A-Za-z0-9]/.test(password)) {
        validPassword.onSpecialLetter = true;
      }
      if (/[a-z]/.test(password)) {
        validPassword.oneLowerLetter = true;
      }
      if (/[A-Z]/.test(password)) {
        validPassword.oneUpperLetter = true;
      }
      if (password.match(/\d+/g)) {
        validPassword.oneNumber = true;
      }
      if (!/[^A-Za-z0-9]/.test(password)) {
        validPassword.onSpecialLetter = false;
      }
      if (!/[a-z]/.test(password)) {
        validPassword.oneLowerLetter = false;
      }
      if (!/[A-Z]/.test(password)) {
        validPassword.oneUpperLetter = false;
      }
      if (!password.match(/\d+/g)) {
        validPassword.oneNumber = false;
      }

      setValidation({ ...validPassword });
    });

  }, [password]);

  return (
    <div className={`${className} ${classes.main}`}>
      <PasswordStrengthBar
        style={{"width": "100%", "marginTop": "0px"}}
        password={password}
        barColors={['#DDD', '#EF4836', '#F6B44D', '#4F9E96', '#37DA33']}
        scoreWords={['Very Weak', 'Weak', 'Okay', 'Good', 'Strong']}
        shortScoreWord="Too Short (Must be at least 14 characters)"
        minLength={14}
      />
      <div className="password-box">
        <div className="password-warnings">
          {validation.warning &&
            <div className="warning-text"><WarningIcon style={{"paddingRight": "5px"}}/>Warning: {validation.warning}</div>
          }
          {validation.suggestions.map(suggestion => (
            <div key={suggestion} className="suggestion-text"><SuggestionIcon style={{"paddingRight": "5px"}}/>Suggestion: {suggestion}</div>
          ))}
        </div>
      </div>
      <div className="passowrd-info">Your password needs to have at least...</div>
      <div className="password-box">
        <div className="password-group">
          <div
            className={`password-valid ${
              validation.onSpecialLetter ? "active" : ""
            }`}
          >
            <span className="dot" />
            One special character
          </div>
          <div
            className={`password-valid ${
              validation.oneLowerLetter ? "active" : ""
            }`}
          >
            <span className="dot" />
            One lowercase character
          </div>
          <div
            className={`password-valid  ${
              validation.oneUpperLetter ? "active" : ""
            }`}
          >
            <span className="dot" />
            One uppercase character
          </div>
        </div>
        <div className="password-group">
          <div
            className={`password-valid second ${
              validation.oneNumber ? "active" : ""
            }`}
          >
            <span className="dot" />
            One number
          </div>

          <div
            className={`password-valid ${
              validation.totalLength ? "active" : ""
            }`}
          >
            <span className="dot" />
            Minimum 14 characters
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordValidation.propTypes = {
  password: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(Styles)(memo(PasswordValidation));
