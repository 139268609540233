export const styles = ({ colors, breakpoints }) => ({
  council: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "55px",
    height: "auto",
    padding: "0 20px",
    [breakpoints.MOBILE_DEVICE]: {
      flexDirection: "column",
      padding: "11px",
      alignItems: "start",
      height: "auto",
      "& button": {
        margin: "12px",
      },
    },
    "& .right-button": {
      width: "145px",
      height: "40px",
      mixBlendMode: "normal",
      border: `1.2px solid ${colors.black}`,
      borderRadius: "4px",
      backgroundColor: colors.bg3,
      color: colors.black,
    },
  },
  yello: {
    backgroundColor: colors.bg3,
  },
  orange: {
    backgroundColor: colors.orangeColor,
  },
  green: {
    backgroundColor: colors.lightBlue,
  },
  red: {
    backgroundColor: colors.red2,
  },
  main: {
    backgroundColor: colors.yelloNew,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "62px",
    height: "auto",
    padding: "0 20px",
    [breakpoints.MOBILE_DEVICE]: {
      "& .home-icon": {
        display: "none",
      },
      flexDirection: "column",
      padding: "11px",
      alignItems: "start",
      height: "auto",
      "& button": {
        margin: "12px",
      },
    },
    "& .property-button": {
      width: "138px",
      color: colors.black,
      border: `1px solid ${colors.black}`,
      backgroundColor: colors.yelloHexa,
    },
  },
  buttonRow: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "7px",
  },
  confirmButton: {
    padding: "2px 6px",
    borderRadius: "4px",
    border: "1px solid white",
    backgroundColor: colors.secondary,
    cursor: "pointer",
  },
  headingDiv: {
    display: "flex",
    alignItem: "center",
    "& svg": {
      marginTop: 12,
      width: "22px",
      height: "22px",
    },
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    "& .text": {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "20px",
      color: colors.black,
    },
    "& .sub-heading": {
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
});
