import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Links, Modal } from "components";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import withStyles from "react-jss";
import { Button } from "v2/components";
import { Styles } from "./Styles";

const setting = {
  width: 494,
  height: 343,
  top: "10%",
};
const SignupConfirm = ({
  isOpen,
  classes,
  onClose,
  isRegister,
  goSignupPage,
}) => {
  const wrapperRef = useRef();
  const handleClick = ({ target, offsetX }) => {
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      if (offsetX <= target.clientWidth) {
        onClose();
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  return (
    <Modal
      ref={wrapperRef}
      isOpen={isOpen}
      setting={setting}
      className={classes.main}
    >
      <div>
        <div className="header">
          <Typography as="h6" className="heading">
            Attach document
          </Typography>
          <CrossIcon onClick={onClose} />
        </div>
        <div className="body">
          <Typography as="h6" className="body-heading">
            Sign up to be able to attach your documents and to get compliant
          </Typography>
          <div className="body-subheading">
            Are you ready to get compliant and avoid fines?
          </div>
        </div>
        <div className="footer">
          <Button onClick={onClose} className="close-button">
            {" "}
            Cancel
          </Button>
          <Links as="click" className="signup-button" onClick={goSignupPage}>
            {isRegister ? "Login" : "Sign up"}
          </Links>
        </div>
      </div>
    </Modal>
  );
};

SignupConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isRegister: PropTypes.bool.isRequired,
  goSignupPage: PropTypes.func.isRequired,
};

export default withStyles(Styles)(SignupConfirm);
