import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import ButtonStyles from "./style";

export const WhitePrimary = ({
  className = "",
  classes,
  onClick,
  children,
  disable = false,
  type = "button",
}) => (
  <button
    type={type}
    className={`${classes.whitePrimary} ${className}`}
    onClick={onClick}
    disabled={disable && disable}
  >
    {children}
  </button>
);

WhitePrimary.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  disable: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default withStyles(ButtonStyles)(WhitePrimary);
