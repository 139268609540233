import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal } from "components";
import { Typography } from "v2/components";
import { licensingPopData } from "constants/AppConstants";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { useWindowDimensions } from "utils";
import theme from "theme";
import { styles } from "./styles";

const { colors } = theme;
const setting = {
  width: 640,
  height: 520,
  top: 80,
  color: colors.hexaWhite,
};
const {
  headerText: { header, subHeader, firstText, secondText },
  subheading: { first, second, third },
  scotlandHeader: { title, subText },
} = licensingPopData;
const Licensing = ({ isOpen, onClose, classes }) => {
  const { width } = useWindowDimensions();
  setting.width = width > 800 ? 640 : "80%";
  const wrapperRef = useRef(null);
  const handleClick = ({ target, offsetX }) => {
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      if (offsetX <= target.clientWidth) {
        onClose();
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  return (
    <Modal
      isOpen={isOpen}
      setting={setting}
      className={classes.licensing}
      ref={wrapperRef}
    >
      <div className={classes.main}>
        <div className={classes.exitButton}>
          <CrossIcon onClick={onClose} onKeyDown={onClose} />
        </div>
        <div className={classes.text}>
          <Typography as="subtitle" color={colors.black}>
            <div className={classes.header}>
              <Typography as="h6">{header}</Typography>
            </div>
          </Typography>
        </div>
        <div className={classes.section}>
          <>
            <Typography as="p" fontWeight="600">
              {subHeader}
              <div className="list-view">
                <span>{firstText}</span>
                <br />
                <span>{secondText}</span>
                <br />
              </div>
            </Typography>
            <Typography as="p" fontWeight="600">
              {first.heading}
            </Typography>
            <div className="list-view">
              <span>{first.text}</span>
            </div>
            <Typography as="p" fontWeight="600">
              {second.heading}
            </Typography>
            <div className="list-view">
              <span>{second.text}</span>
            </div>
            <Typography as="p" fontWeight="600">
              {third.heading}
            </Typography>
            <div className="list-view">
              <div className={classes.header}>
                <span>{third.text}</span>
                <span>{third.extraText}</span>
              </div>
            </div>
            <Typography as="h6">
              {title}
              <br />
              <br />
            </Typography>
            <div className={classes.header}>
              {subText.map((scotlandText) => (
                <span>{scotlandText}</span>
              ))}
            </div>
          </>
        </div>
      </div>
    </Modal>
  );
};
Licensing.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(Licensing);
