import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, Typography, Links, WhiteButton } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { subdomain, useWindowDimensions } from "utils";
import theme from "theme";
import { styles } from "./Styles";
import { useOnClickOutside } from "Hooks/ClickOutside";

const { colors } = theme;
const setting = {
  width: 640,
  height: 465,
  top: 80,
  color: colors.hexaWhite,
};
const UPRNModal = ({ onClose, classes }) => {
  const { width } = useWindowDimensions();
  setting.width = width > 800 ? 640 : "80%";
  const openMail = () => {
    window.open(`mailto:${subdomain()}@goyuno.com`, "_blank");
  };

  const ref = useRef();
  useOnClickOutside(ref, onClose);

  return (
    <Modal isOpen={true} setting={setting} className={classes.hmo} ref={ref}>
      <div className={classes.main}>
        <div className={classes.text}>
          <Typography as="subtitle" color={colors.black}>
            What is a UPRN Number?
          </Typography>
          <CrossIcon onClick={() => onClose()} onKeyDown={() => onClose()} />
        </div>
        <div className={classes.section}>
          <div className="list-view">
            <div>
              UPRN stands for Unique Property Reference Number and was created
              by the Ordnance Survey (OS). Local governments in the UK have
              allocated a unique number for each land or property.
            </div>
            <br />
            <div>
              We require a UPRN number so that we can accurately report the
              licensing requirement for your property
            </div>
          </div>
        </div>
        <div className={classes.bottomSection}>
          <Typography as="subtitle" color={colors.black}>
            <span className={classes.secondHeading}>
              How do I find the UPRN Number?
            </span>
          </Typography>
          <div>
            <div>
              Contact your local authority responsible for maintaining addresses
              to get a UPRN, or use the government local council checker.
            </div>
            <br />
            <div>
              A data to retrieve UPRN API is available from Ordnance Survey's
              AddressBase
            </div>
            <br />
          </div>
        </div>
        <div
          role="button"
          tabIndex="0"
          onClick={openMail}
          onKeyPress={openMail}
        >
          If you cannot find it - please{" "}
          <Links as="click" className="link">
            contact us
          </Links>
        </div>
        <div className={classes.footerSection}>
          <WhiteButton
            as="click"
            className={classes.whiteButton}
            onClick={() => onClose()}
          >
            Close
          </WhiteButton>
        </div>
      </div>
    </Modal>
  );
};
UPRNModal.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(UPRNModal);
