export const propertyFormTooltipData = {
  propertyType: `
    # Property type
    <br/>
    ## England, Wales and Scotland     
      
    #### What is a Flat?

    A Flat is a self contained housing unit, that occupies only part of a building.

    #### What is a House?

    A House is a single residential unit.

    #### What is a Building?

    A Building can contain multiple housing or commercial units.`,
  numberOfStoreys: `
    # Number of storeys
    <br/>
    #### What is a "storey"?

    A storey is any floor that is in use for living purposes and can include attics, cellars and mezzanines if used for living purposes.
    <br/>
    #### How to count storeys by Property Type
    <br/>
    ##### Flat
    These are the storeys internal to the flat. For licensing purposes bear in mind that if there is a commercial premises in the building then you need to add this on as a storey too (We have not added this storey on for you in the commercial premises question).
    <br/>
    ##### House
    The total amount of Storeys in the house.
    <br/>
    ##### Building
    These are total number of Storeys within the whole building. You must also include commercial premises such as shops and restaurants, if located in the building.
    <br/>
    
    Please review the legislation or speak to an expert if you are unsure <a target="_blank" href="https://www.legislation.gov.uk/uksi/2006/371/made">here</a>
    <br/>
    #### FAQ
    <br/>
    ##### Does an external terrace count as a storey?<br/>
    No not as a storey, unless its adapted for use as living accommodation.
    <br/>
    ##### What if the commercial premises is not directly above, or below, the flat but in the same building, does it count or not?<br/>
    Yes it does.`,
  commercialPremises: `
    # Commercial premises

    Does the building have a commercial unit on the premises?
    
    e.g. a café/restaurant/gym on any floor of the building, including ground floor.`,
  tenancyType: `
    # What is an Individual Tenancy?
    Individual tenancies are where the property is let on a room by room basis and the landlord has an individual agreement with each tenant.
    # What is a Shared Tenancy (AST)?
    A shared tenancy, or single AST, is where the whole property is let to all tenants on the same agreement, normally to a family or group of friends living together.
    # What is a Corporate Tenancy?
    A corporate tenancy is when a company takes on a residential tenancy agreement as the tenant, rather than an individual.`,
  propertyCategory: `
    # What is a Single Dwelling?
    A family or tenants sharing accommodation that do not form an HMO.
    # What does HMO stand for?
    House in Multiple Occupancy or House of Multiple Occupation.
    # What is a Section S254 HMO?
    #### The standard definition of a section 254 HMO is:
    - 3 or more tenants
    - More than 1 household
    - Generally speaking that share facilities (kitchen, bathroom etc) though legally should meet standard test, self-contained flat test or converted building test HMO definition.
    # What is a Section S257 HMO?
    #### Refers to buildings that:
    <Link text="The full definition of all HMO’s can be found in the " clickableText="Housing Act 2004 Sections 254 to 260" to="[[https://www.legislation.gov.uk/ukpga/2004/34/part/7/crossheading/meaning-of-house-in-multiple-occupation](https://www.legislation.gov.uk/ukpga/2004/34/part/7/crossheading/meaning-of-house-in-multiple-occupation)](https://www.legislation.gov.uk/ukpga/2004/34/part/7/crossheading/meaning-of-house-in-multiple-occupation](https://www.legislation.gov.uk/ukpga/2004/34/part/7/crossheading/meaning-of-house-in-multiple-occupation))"/>
    - Have been converted into self-contained flats; and
    - Less than two-thirds of the flats are owner-occupied; and
    - The conversion did not comply with the relevant building regulations in force at that time and still does not comply. If the conversion was completed before 1 June 1992, it should comply with building regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the building regulations in force at the time of the conversion.
    #### What is a Licensable HMO?
    If you know the property is an HMO and requires a licence`,
  numberOfTenants: `
    # Number of people
    How many people live in the property as their primary residence? This can also include children, carers and domestic staff in the property.`,
  roomsOrUnits: `
    # Number of bedrooms
    If the property is a flat/house, Bedrooms are the number rooms used for sleeping within the property.

    If the property is a building, Units are the number of self-contained flats within the building.`,
  numberOfHouseholds: `
  # What is a “household”?
    A single person, or members of the same family who live together. Each tenant from a separate family is classed as a separate household.
    # What constitutes a “family”?
    Tenants who are married or living together as a couple, form one family. This includes couples in same-sex relationships and their children.
    
    All relatives & half-relatives count as the same family e.g. grandparents, step-parents, aunts, uncles, siblings & step-siblings. A half-blood relationship is treated the same as full blood and a stepchild is treated the same as a child.
    
    Any household domestic staff are included in the household if they do not pay rent while living with the person(s) they are working for, this may also depend on their contract terms.`,
  buildingConstructionType: `
    # Purpose-built buildings
    Purpose-built buildings are dwellings that were constructed with the sole purpose of being flats from the beginning, whereas, for example an old house that has been split into to different homes is Converted Building.`,
};
