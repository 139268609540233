import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as Logo } from "images/yunologo.svg";
import propTypes from "prop-types";

import Button from "../../Form/Button/Button";
import Typography from "../../Typography/Typography";
import styles from "./SubmitJobDetailsLayout.module.css";

const SubmitJobDetailsLayout = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography as="h6License">{props.title}</Typography>
        <Logo className={styles.logo}></Logo>
        <CrossIcon
          className={styles.cross}
          onClick={props.onClose}
          onKeyDown={props.onClose}
        />
      </div>
      <div className={styles.content}>
        {props.children}
        <div className={styles.button}>
          <Button
            actionName="Cancel"
            variant="secondary"
            onClick={props.onClose}
          />

          <Button
            actionName="Submit"
            onClick={props.onSave}
            disabled={props.disabled}
          />
        </div>
      </div>
    </div>
  );
};

SubmitJobDetailsLayout.propTypes = {
  title: propTypes.string.isRequired,
  isSave: propTypes.string.isRequired,
  onClose: propTypes.func,
  onSave: propTypes.func,
  onUpdate: propTypes.func,
};

SubmitJobDetailsLayout.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  onUpdate: () => {},
};

export default SubmitJobDetailsLayout;
