import React from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import { Typography } from "v2/components";
import { ReactComponent as ErrorIcon } from "images/triangle.svg";
import { useStyles } from "./styles";

const ErrorMessage = ({ theme, message, subTitle = "", className = "" }) => {
  const classes = useStyles(theme);
  return (
    <div className={`${classes.main} ${className}`}>
      <div className="title">
        <ErrorIcon />
        <Typography>{message}</Typography>
      </div>
      <Typography>{subTitle}</Typography>
    </div>
  );
};

ErrorMessage.propTypes = {
  theme: propTypes.object.isRequired,
  className: propTypes.string,
  message: propTypes.string.isRequired,
  subTitle: propTypes.string,
};

export default withTheme(ErrorMessage);
