import { TileLayer } from "leaflet";
import {
  createTileLayerComponent,
  updateGridLayer,
  withPane,
} from "@react-leaflet/core";

async function fetchImage(url, callback, headers, abort) {
  const controller = new AbortController();
  const signal = controller.signal;
  if (abort) {
    abort.subscribe(() => {
      controller.abort();
    });
  }
  const f = await fetch(url, {
    method: "GET",
    headers: headers,
    mode: "cors",
    signal: signal,
  });
  const blob = await f.blob();
  callback(blob);
}

var LTileLayerWithHeader = TileLayer.extend({
  initialize: function (url, options) {
    const { headers, abort, results, ...props } = options;
    TileLayer.prototype.initialize.call(this, url, props);
    this.headers = headers;
    this.abort = abort;
    this.results = results;
  },
  createTile(coords, done) {
    const { x, y, z } = coords;
    const url = `${process.env.REACT_APP_API_URL}map?x=${x}&y=${y}&z=${z}`;
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");

    fetchImage(
      url,
      (resp) => {
        const reader = new FileReader();
        reader.onload = () => {
          img.src = reader.result;
          if (this.results) {
            this.results.next(reader.result);
          }
        };
        reader.readAsDataURL(resp);
        done(null, img);
      },
      this.headers,
      this.abort
    );
    return img;
  },
});

const TileLayerWithHeader = createTileLayerComponent(
  function createWMSTileLayer({ params = {}, url, ...options }, context) {
    return {
      instance: new LTileLayerWithHeader(url, {
        ...params,
        ...withPane(options, context),
      }),
      context,
    };
  },
  function updateWMSTileLayer(layer, props, prevProps) {
    updateGridLayer(layer, props, prevProps);

    if (props.params != null && props.params !== prevProps.params) {
      layer.setParams(props.params);
    }
  }
);

export default TileLayerWithHeader;
