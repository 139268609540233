import { Details, LocationPin } from "images";
import React, { memo } from "react";
import withStyles from "react-jss";
import { Image, WhiteButton, WhitePrimary } from "v2/components";
import Label from "v2/components/Form/Label/index";
import useModal from "Hooks/useModal";
import { formatter } from "yuno-formatting";
import { Styles } from "./Styles";
import { tooltipConstants } from "./constants";
import Links from "v2/components/Link/Link";
import Markdown from "v2/components/Markdown/Markdown";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import TooltipModal from "v2/components/ModalV2/TooltipModal/TooltipModal";
import { styles } from "components/SelectedAddress/styles";
import { ReactComponent as NoUPRN } from "images/noUPRN.svg";
import { ReactComponent as ActionsRequiredWarning } from "images/actionsRequiredWarning.svg";
import { ReactComponent as ActionsRequiredCritical } from "images/actionsRequiredCritical.svg";
import { Button } from "../../../v2/components/Form";
import { useHistory, useRouteMatch } from "react-router-dom";

const ViewDetails = ({ classes, handleClose, handleEdit, propertyDetails }) => {
  //mockdata
  // const actionsRequired = {
  //   actionsRequired: true,
  //   urgentActionsRequired: true,
  // };

  //end mockdata
  const { url } = useRouteMatch();
  const history = useHistory();
  const [
    isTooltipModalOpen,
    openToolTipModal,
    closeToolTipModal,
    tooltipModalData,
  ] = useModal();

  const viewActions = () => {
    history.push(`${url}/journey/documentation-and-certification`);
    handleClose(false);
  };

  return (
    <div className={classes.detailsPopup}>
      {isTooltipModalOpen && (
        <TooltipModal
          onClose={closeToolTipModal}
          content={tooltipConstants[tooltipModalData.field]}
        />
      )}
      <div>
        <div className="detailsMain">
          <div className="detailsSection">
            <div className="detailsHeading">
              <Image src={LocationPin} alt="Location" />
              Location
            </div>
            <div className="closeButton">
              <WhiteButton
                as="click"
                className={classes.whiteButton}
                onClick={() => handleClose(false)}
              >
                Close
              </WhiteButton>
            </div>
          </div>
          <div className="detailsItemsWrap">
            <div className="detailsItems">
              <div className="detailsItem">
                <span className="detailLabel">
                  <Label
                    onInputTip={() => openToolTipModal({ field: "whatIsUPRN" })}
                    label="UPRN Number"
                  ></Label>
                </span>
                <span className="detailValue">{propertyDetails.UPRN}</span>
              </div>
              <div className="detailsItem">
                <span className="detailLabel">Address</span>
                <span className="detailValue">
                  <div className="addressSection">
                    {formatter.formatShortAddress(propertyDetails)}
                  </div>
                </span>
              </div>
              <div className="detailsItem">
                <span className="detailLabel">Council</span>
                <span className="detailValue">
                  <div className="addressSection">
                    {propertyDetails.councilName}
                  </div>
                </span>
              </div>
            </div>

            <div className="detailsItems">
              <div className="detailsItem">
                <span className="detailLabel">Status</span>
                <span className="detailValue">{propertyDetails.status}</span>
              </div>
              {propertyDetails.longitude && (
                <>
                  <div className="detailsItem">
                    <span className="detailLabel">Latitude</span>
                    <span className="detailValue">
                      {propertyDetails.latitude}
                    </span>
                  </div>
                  <div className="detailsItem">
                    <span className="detailLabel">Longitude</span>
                    <span className="detailValue">
                      {propertyDetails.longitude}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* {actionsRequired.urgentActionsRequired && (
            <div className="actionsRequiredCritical">
              <div className="leftSide">
                <ActionsRequiredCritical />
                <span className="warningText">
                  Actions is required urgently, please update your
                  documentation.
                </span>
              </div>
              <div className="rightSide">
                <Button
                  variant="verifyAddress"
                  actionName="View Actions"
                  size="small"
                  onClick={viewActions}
                />
              </div>
            </div>
          )}
          {actionsRequired.actionsRequired &&
            !actionsRequired.urgentActionsRequired && (
              <div className="actionsRequired">
                <div className="leftSide">
                  <ActionsRequiredWarning />
                  <span className="warningText">
                    Actions required in the next few months, please update your
                    documentation.
                  </span>
                </div>
                <div className="rightSide">
                  <Button
                    variant="verifyAddress"
                    actionName="View Actions"
                    size="small"
                    onClick={viewActions}
                  />
                </div>
              </div>
            )} */}

          <div className="topDetailsItems">
            {propertyDetails.parentUprn && (
              <div className="detailsItem">
                <span className="detailLabel">Parent UPRN Number</span>
                <span className="detailValue">
                  {propertyDetails.parentUprn}
                </span>
              </div>
            )}
            {propertyDetails.userLongitude && (
              <>
                <div className="detailsItem">
                  <span className="detailLabel">User Latitude</span>
                  <span className="detailValue">
                    {propertyDetails.userLatitude}
                  </span>
                </div>
                <div className="detailsItem">
                  <span className="detailLabel">User Longitude</span>
                  <span className="detailValue">
                    {propertyDetails.userLongitude}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="detailsBottomSection">
            <div className="detailsHeading">
              <Image src={Details} alt="Details" />
              Details
            </div>
          </div>
          <div className="detailsItemsWrap">
            <div className="detailsDiv">
              <div className="detailsDivStyle">
                <div className="detailsItems topSection">
                  <div className="detailsItem">
                    <span className="detailLabel">Property type</span>
                    <span className="detailValue">
                      {propertyDetails.propertyType || "-"}
                    </span>
                  </div>
                  <div className="detailsItem">
                    <span className="detailLabel">Property category</span>
                    <span className="detailValue">
                      {propertyDetails.propertyType || "-"}
                    </span>
                  </div>
                  <div className="detailsItem">
                    <span className="detailLabel">
                      Commercial premises in building?
                    </span>
                    <span className="detailValue">
                      {propertyDetails.commercialPremises || "-"}
                    </span>
                  </div>
                </div>
                <div className="detailsItems">
                  <div className="detailsItem">
                    <span className="detailLabel">Tenancy Type</span>
                    <span className="detailValue">
                      {propertyDetails.tenancyType || "-"}
                    </span>
                  </div>
                  <div className="detailsItem">
                    <span className="detailLabel">Storeys</span>
                    <span className="detailValue">
                      {propertyDetails.numberOfStoreys || "-"}
                    </span>
                  </div>
                  <div className="detailsItem">
                    <span className="detailLabel">People</span>
                    <span className="detailValue">
                      {propertyDetails.numberOfTenants || "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="detailsItems bottomSection detailsDivStyle">
                <div className="detailsItem bottomDiv">
                  <span className="detailLabel">Households</span>
                  <span className="detailValue">
                    {propertyDetails.numberOfHouseholds || "-"}
                  </span>
                </div>
                <div className="detailsItem rightDiv">
                  <span className="detailLabel">Rooms/Units</span>
                  <span className="detailValue">
                    {propertyDetails.roomsOrUnits || "-"}
                  </span>
                </div>
                <div className="detailsItem rightDiv">
                  <span className="detailLabel">Construction Type</span>
                  <span className="detailValue">
                    {propertyDetails.buildingConstructionType || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(Styles)(memo(ViewDetails));
