export const tooltipConstants = {
  whatIsUPRN: `
    # What is a UPRN?
    <br/>
    UPRN stands for Unique Property Reference Number, local governments in the UK have allocated a unique number for each property, including House, Flats and Buildings.
    <br/>
    In the case of buildings with flats, Buildings are labelled as Parent UPRNs and Flats as child UPRNs.
    <br/>
    In this system we us an API through our partnership with Ordnance Survey (OS) to find your URPN.
    <br/>
    We require a UPRN number so that we can accurately report the compliance requirements for your property.`,
};
