import propTypes from "prop-types";
import { ReactComponent as HelpIcon } from "images/help.svg";
import { Error } from "images";
import Image from "../../Image";
import styles from "./Label.module.css";
const Label = (props) => {
  const onLabelClick = (event) => {
    if (props.shouldPreventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <label
      id={props.id}
      onClick={onLabelClick}
      className={`${styles.container} ${
        props.noSponsor ? styles.noSponsor : ""
      } ${props.isClickable ? styles.clickable : styles.notClickable}`}
    >
      <span className={styles.label}>
        {props.label}
        {props.onInputTip && <HelpIcon onClick={props.onInputTip} />}
      </span>
      {props.error && (
        <span className={styles.errorMessage}>
          <Image src={Error} alt="error" />
          <strong>{props.error}</strong>
        </span>
      )}
      {props.children}
    </label>
  );
};

Label.propTypes = {
  label: propTypes.string,
  onInputTip: propTypes.func,
  error: propTypes.string,
  isClickable: propTypes.bool,
  shouldPreventDefault: propTypes.bool,
};

Label.defaultProps = {
  isClickable: false,
  shouldPreventDefault: false,
};

export default Label;
