import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  "@keyframes placeHolderShimmer": {
    "0%": {
      backgroundPosition: "-468px 0",
    },
    "100%": {
      backgroundPosition: "468px 0",
    },
  },
  "@keyframes sk-bounce": {
    "0%, 100%": {
      transform: "scale(0.0)",
      WebkitTransform: "scale(0.0)",
    },
    "50%": {
      transform: "scale(1.0)",
      WebkitTransform: "scale(1.0)",
    },
  },
  "@-webkit-keyframes sk-bounce": {
    "0%, 100%": {
      WebkitTransform: "scale(0.0)",
    },
    "50%": {
      WebkitTransform: "scale(1.0)",
    },
  },
  animation: {
    animationDuration: "2.25s",
    animationFillMode: "forwards",
    animationIterationCount: "infinite",
    animationName: "$placeHolderShimmer",
    animationTimingFunction: "linear",
    background:
      "linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%)",
    backgroundSize: "800px 104px",
    position: "relative",
    height: ({ height }) => height || "10px",
    width: ({ width }) => width || "60%",
  },
  spinner: {
    width: "100%",
    height: "8%",
    position: "fixed",
    maxHeight: "100vh",
    zIndex: "999",
    left: "0",
    top: "0",
    overflow: "auto",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    paddingTop: "300px",
  },
});
