import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import { store } from "./store";
import "./index.css";
import App from "./views/App";

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <App />
    </Provider>
  </HttpsRedirect>,
  document.getElementById("root")
);
