import React from "react";
import PropTypes from "prop-types";
import withStyle from "react-jss";
import { styles } from "./styles";

const Card = ({ classes, text, count, children, className = "" }) => (
  <div className={classes.main}>
    {children && (
      <div>
        <span className={`logo ${className}`}>{children}</span>
      </div>
    )}
    <div className="text">
      <span className="heading">{text}</span>
      <span className="counts">{count}</span>
    </div>
  </div>
);

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.any.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default withStyle(styles)(Card);
