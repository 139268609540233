import { WebHook } from "Hooks";
import { Header, IframeHeader } from "components";
import styles from "./Layout.module.css";
import { useHistory } from "react-router";
import { usePropertyDetails } from "context/propertyContext/PropertyContext";
import { useIsInIframeCheck } from "context/environmentContext/EnvironmentContext";

const Layout = (props) => {
  const [{ loading, logo }] = WebHook(false);
  const { location } = useHistory();
  const propertyDetails = usePropertyDetails();
  const isInIframe = useIsInIframeCheck();

  return (
    <div className={styles.container}>
      <div id="header" className={styles.header}>
        {isInIframe ? (
          <IframeHeader
            currentPath={location.pathname}
            propertyDetails={propertyDetails}
          />
        ) : (
          <Header
            loading={loading}
            logo={logo}
            currentPath={location.pathname}
            propertyDetails={propertyDetails}
          />
        )}
      </div>
      <div className={styles.mainContainer}>{props.children}</div>
    </div>
  );
};

Layout.propTypes = {};
export default Layout;
