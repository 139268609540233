import LeaseholderCertificationForm from "components/Form/LeaseholderCertificationForm";
import { usePropertyDetails } from "context/propertyContext/PropertyContext";
import propTypes from "prop-types";
import { useState, useEffect } from "react";
import Markdown from "v2/components/Markdown/Markdown";
import Typography from "v2/components/Typography/Typography";
import JourneyLayout from "../../JourneyLayout/JourneyLayout";
import styles from "./BuildingCertification.module.css";
import { Button } from "v2";
import usePropertyActions from "Apis/YunoApi/usePropertyActions";
import CreateBuildingReport from "./CreateBuildingReport";
import { HMO, Licensing, Links, Planning } from "components";

const leaseHolderMarkdown = {
  EnglandWales: `
  **England and Wales**
<br/>
The flats in this building may need Mandatory, Additional or Selective licensing.
<br/>
It is the responsibility of the leaseholder to get a licence, to find out if your flat needs a licence please run the check again using 'Flat'  under 'Property Type' or if this is a complex, or mixed use, property then please consult an expert for advice.
<br/>
Note:

It is the responsibility of the freeholder to ensure there is an up to date ‘suitable and sufficient’ Fire Risk Assessment in place at all times. It is now more important than ever to ensure all buildings and commercial premises have this in place, please review the full report for more details.
<br/>
  `,
  Scotland: `
  **Scotland**
<br/>
As you have selected Building, if you believe your property could be an HMO (see definition) then please run the check again using 'House' under 'Property Type' or if this is a complex, or mixed use, property then please consult an expert for advice.
  `,
};
const buildingCertificationWithoutLicence = {
  EnglandWales: `
  **England and Wales**
<br/>
Great, it looks like there is no Additional (S257) licence required for this building.
<br/>
Just because you may not need licensing for the building doesn't mean there are no further safety works to proceed with, please review the full report for more details.
<br/>
You may be running a Section 257 HMO
<br/>
Refers to buildings that:
- Have been converted into self-contained flats; and
- Less than two-thirds of the flats are owner-occupied; and
- The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply. 
<br/>
If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.
<br/>
If this is the case the HMO Management Regulations 2007 applies to your property and you should ensure you comply as the council will still enforce this as well as the Regulatory Reform Order 2005 (where applicable) and much more.
<br/>
  `,
  Scotland: `**Scotland**

If the Building is being ran as an HMO please, run the report again by selecting 'Property Type' as 'House'.`,
};

const buildingCertificationWithLicence = `
This building needs an Additional (S257) licence if you are running a Section 257 HMO.
<br/>
257 HMO refers to buildings that:
- Have been converted into self-contained flats; and
- Less than two-thirds of the flats are owner-occupied; and
- The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply. 
<br/>
If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.
<br/>
If this is the case the HMO Management Regulations 2007 applies to your property and you should ensure you comply as the council will still enforce this as well as the Regulatory Reform Order 2005 (where applicable) and much more.`;

const flatBuildingCertification = {
  EnglandWales: `
**England and Wales**<br/>
Great, it looks like there may be no Additional (S257) licence required for this building.
<br/>
Just because you may not need licensing for the building doesn't mean there are no further safety works to proceed with, please review the full report for more details.
<br/>
You may be running a Section 257 HMO
<br/>
Refers to buildings that:<br/>
• Have been converted into self-contained flats; and<br/>
• Less than two-thirds of the flats are owner-occupied; and<br/>
• The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply. <br/><br/>
If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.
<br/>
If this is the case the HMO Management Regulations 2007 applies to your property and you should ensure you comply as the council will still enforce this as well as the Regulatory Reform Order 2005 (where applicable) and much more.
<br/><br/>`,
  Scotland: `**Scotland**
<br/>
If the Building is being ran as an HMO please, run the report again by selecting 'Property Type' as 'House'.`,
};

const flatBuildingCertificationDefault = `
This building needs an Additional (S257) licence if you are running a Section 257 HMO.
<br/>
257 HMO refers to buildings that:<br/>
• Have been converted into self-contained flats; and<br/>
• Less than two-thirds of the flats are owner-occupied; and<br/>
• The conversion did not comply with the relevant Building Regulations in force at that time and still does not comply. <br/>
<br/>
If the conversion was completed before 1 June 1992, it should comply with Building Regulations in force as of 1 June 1992. If the building was converted after 1 June 1992, it should comply with the Building Regulations in force at the time of the conversion.
<br/>
If this is the case the HMO Management Regulations 2007 applies to your property and you should ensure you comply as the council will still enforce this as well as the Regulatory Reform Order 2005 (where applicable) and much more.`;

const Note = (props) => (
  <>
    <span className={styles.heading}>
      <Typography as="noteTitle">{props.title}</Typography>
    </span>
    <span className={styles.noteText}>
      <Typography as="noteText">{props.text}</Typography>
    </span>
  </>
);

const QuestionLinks = (props) => {
  const [state, setState] = useState({
    showHmo: false,
    showLicensing: false,
    showPlanning: false,
  });
  const { showHmo, showLicensing, showPlanning } = state;
  const handleModal = (modal, value) => {
    setState((state) => ({ ...state, [modal]: !value }));
  };
  return (
    <>
      <HMO isOpen={showHmo} onClose={() => handleModal("showHmo", showHmo)} />
      <Licensing
        isOpen={showLicensing}
        onClose={() => handleModal("showLicensing", showLicensing)}
      />
      <Planning
        isOpen={showPlanning}
        onClose={() => handleModal("showPlanning", showPlanning)}
      />
      <div className={styles.modalContainer}>
        <Links as="click" onClick={() => handleModal("showHmo", showHmo)}>
          What is an HMO?
        </Links>
        <Links
          as="click"
          onClick={() => handleModal("showLicensing", showLicensing)}
        >
          What is Licensing?
        </Links>
      </div>
    </>
  );
};

function BuildingCertification(props) {
  const propertyDetails = usePropertyDetails();

  const [MarkdownText, setMarkdownText] = useState();

  useEffect(() => {
    if (propertyDetails.countryOnsCode) {
      if (
        propertyDetails.countryOnsCode[0] === "E" ||
        propertyDetails.countryOnsCode[0] === "W"
      ) {
        return setMarkdownText("EnglandWales");
      }
      if (propertyDetails.countryOnsCode[0] === "S") {
        return setMarkdownText("Scotland");
      }
    } else {
      return setMarkdownText("England");
    }
  }, [propertyDetails.countryOnsCode]);

  if (propertyDetails.propertyType === "Building") {
    return (
      <JourneyLayout
        name={props.name}
        onNext={props.onNext}
        onPrevious={props.onPrevious}
        nextName={props.nextName}
      >
        <div className={styles.requirementList}>
          <Markdown information={leaseHolderMarkdown[MarkdownText]} />

          <QuestionLinks />

          <LeaseholderCertificationForm
            propertyId={propertyDetails.propertyId}
            propertyType={propertyDetails.propertyType}
          />
        </div>
      </JourneyLayout>
    );
  }

  if (
    propertyDetails.propertyType === "Flat" &&
    propertyDetails.buildingConstructionType === "Purpose built"
  ) {
    return (
      <JourneyLayout
        name={props.name}
        onNext={props.onNext}
        onPrevious={props.onPrevious}
        nextName={props.nextName}
      >
        <div className={styles.requirementList}>
          <Markdown information={flatBuildingCertification[MarkdownText]} />

          <QuestionLinks />

          <LeaseholderCertificationForm
            propertyId={propertyDetails.propertyId}
            propertyType={propertyDetails.propertyType}
          />
          <CreateBuildingReport propertyDetails={propertyDetails} />
        </div>
      </JourneyLayout>
    );
  } else if (propertyDetails.propertyType === "Flat") {
    return (
      <JourneyLayout
        name={props.name}
        onNext={props.onNext}
        onPrevious={props.onPrevious}
        nextName={props.nextName}
      >
        <div className={styles.requirementList}>
          <Markdown information={flatBuildingCertificationDefault} />

          <QuestionLinks />

          <LeaseholderCertificationForm
            propertyId={propertyDetails.propertyId}
            propertyType={propertyDetails.propertyType}
          />
          <CreateBuildingReport propertyDetails={propertyDetails} />
        </div>
      </JourneyLayout>
    );
  }

  if (!propertyDetails.buildingLicence) {
    return (
      <JourneyLayout
        name={props.name}
        onNext={props.onNext}
        onPrevious={props.onPrevious}
        nextName={props.nextName}
      >
        <div className={styles.requirementList}>
          <Markdown
            information={buildingCertificationWithoutLicence[MarkdownText]}
          />

          <QuestionLinks />

          <LeaseholderCertificationForm
            propertyId={propertyDetails.propertyId}
            propertyType={propertyDetails.propertyType}
          />
          <Note
            title="Flats"
            text="The flats in this building may need individual licences or planning permission, please check the licensing section."
          />
          <Note
            title="Notify your fellow freeholders/leaseholders to ensure there is no risk of fines to you."
            text="It is the responsibility of the freeholder to ensure there is an up to date ‘suitable and sufficient’ Fire Risk Assessment in place at all times. It is now more important than ever to ensure all buildings and commercial premises have this in place, please review the full report for more details."
          />
          <Note
            title="Note"
            text="England and Wales:As you have selected Flat, in this section we have only checked additional licensing against the 257 definition of an HMO, if you believe your property could be a 254 HMO (see definition) then please run the check again using 'House' under 'Property Type' or if this is a complex, or mixed use, property then please consult an expert for advice."
          />
          <CreateBuildingReport propertyDetails={propertyDetails} />
        </div>
      </JourneyLayout>
    );
  }

  return (
    <JourneyLayout
      name={props.name}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      nextName={props.nextName}
    >
      <div className={styles.requirementList}>
        <Markdown information={buildingCertificationWithLicence} />

        <QuestionLinks />

        {propertyDetails.buildingLicence.guideline.map((rule, keys) =>
          rule.split(";").map((ruleDescription, index) => {
            if (index === 0) {
              return (
                <Typography className="heading-text">
                  {ruleDescription}
                </Typography>
              );
            }
            return (
              <Typography key={keys} className="heading-text">
                {`• ${ruleDescription}`}
              </Typography>
            );
          })
        )}
        <LeaseholderCertificationForm
          propertyId={propertyDetails.propertyId}
          propertyType={propertyDetails.propertyType}
        />
        <Note
          title="Flats"
          text="The flats in this building may need individual licences or planning permission, please check the licensing section."
        />
        <Note
          title="Notify your fellow freeholders/leaseholders to ensure there is no risk of fines to you."
          text="It is the responsibility of the freeholder to ensure there is an up to date ‘suitable and sufficient’ Fire Risk Assessment in place at all times. It is now more important than ever to ensure all buildings and commercial premises have this in place, please review the full report for more details."
        />
        <Note
          title="Note"
          text="England and Wales:As you have selected Flat, in this section we have only checked additional licensing against the 257 definition of an HMO, if you believe your property could be a 254 HMO (see definition) then please run the check again using 'House' under 'Property Type' or if this is a complex, or mixed use, property then please consult an expert for advice."
        />
        <CreateBuildingReport propertyDetails={propertyDetails} />
      </div>
    </JourneyLayout>
  );
}

BuildingCertification.propTypes = {
  onNext: propTypes.func,
  onPrevious: propTypes.func,
};

export default BuildingCertification;
