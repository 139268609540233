import Rollbar from "rollbar";

const environment = process.env.REACT_APP_ENVIRONMENT;
export const RollbarErrorTracking = (() => {
  const RollbarObj = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ENVIRONMENT,
    },
    ignoredMessages: ["Request failed with status code 400"],
  });

  const logErroInfo = (info) => {
    if (environment !== "local") {
      RollbarObj.info(info);
    }
  };

  const logErrorInRollbar = (error) => {
    if (environment !== "local") {
      RollbarObj.error(error);
    }
  };

  return {
    logErroInfo,
    logErrorInRollbar,
  };
})();

export default RollbarErrorTracking;
