import React, { memo } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, Select } from "components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import theme from "theme";
import { Styles } from "./Styles";

const { colors } = theme;
const setting = {
  width: "110px",
  height: "200px",
  top: 0,
  color: colors.hexaWhite,
};
const MobilePackages = ({
  isOpen,
  onClose,
  classes,
  packagesList,
  selectPackage = () => {},
  data: { packages = [] },
  currentPackage = {},
}) => (
  <>
    <Modal isOpen={isOpen} setting={setting} className={classes.mobilePackages}>
      <div className="close-icon">
        <CrossIcon onClick={onClose} onKeyDown={onClose} />
      </div>
      <div className="content-section">
        <div className="mobile-package-heading">
          <div className="mobile-package-title">Yuno Packages</div>
        </div>
        <div className="mobile-details">
          {currentPackage.packageId === undefined ? (
            <div />
          ) : (
            <div className="all-contents">
              <div className="input-div">
                <Select
                  onChange={selectPackage}
                  onBlur={() => null}
                  onFocus={() => null}
                  options={packages}
                  selected={currentPackage.packageId}
                  isPackage
                />
              </div>
              <div className="package-details">
                <div className="package-label">{currentPackage.name}</div>
                {currentPackage.packageId === 2 ||
                currentPackage.packageId === "2" ? (
                  <div className="package-type">
                    <span>Recommended</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="package-info">
                {packagesList[currentPackage.packageId].desc}
              </div>
              <div className="price-section">
                <div className="border-div">
                  <span>London</span>
                  <span>
                    £{packagesList[currentPackage.packageId].priceInLondon}
                  </span>
                </div>
                <div>
                  <span>Rest of UK</span>
                  <span>
                    £{packagesList[currentPackage.packageId].priceRestOfUk}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  </>
);
MobilePackages.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  packagesList: propTypes.array,
};

export default withStyles(Styles)(memo(MobilePackages));
