import useInput from "Hooks/useInput";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import React from "react";
import ListFileInput from "../../Form/Input/ListFileInput/ListFileInput";
import Input from "../../Form/Input/Input";
import SelectInput from "../../Form/SelectInput/SelectInput";
import UpdateLayout from "../UploadLayout";
import styles from "./UploadLicence.module.css";

const UploadLicence = (props) => {
  const [actionName, setActionName] = React.useState("");
  const [documentType, documentTypeError, setDocumentType, checkDocumentType] =
    useInput(
      props.documentType || props.documentTypes[0],
      "This field is required",
      "select"
    );
  const [reference, referenceError, setReference, checkReference] = useInput(
    props.reference,
    "This field is required"
  );
  const [issueDate, issueDateError, setIssueDate, checkIssueDate] = useInput(
    props.issueDate?.value || "",
    props.issueDate?.error
  );
  const [expiryDate, expiryDateError, setExpiryDate, checkExpiryDate] =
    useInput(props.expiryDate?.value || "", props.expiryDate?.error);

  const [files, fileInputError, setFiles, checkFiles] = useInput(
    props.files,
    "",
    "files"
  );
  let expiryRequired = true;
  if (props.isExpiryRequired === false) expiryRequired = false;

  const deleteFile = (file, indexToDelete) => {
    if (!(file instanceof File)) {
      props.onDeleteFile(
        {
          itemId: props.itemId,
          fileId: file.id,
          ...file,
        },
        indexToDelete
      );
    }
    setFiles(files.filter((e, index) => indexToDelete !== index));
  };

  const checkFormFields = React.useCallback(() => {
    checkReference();
    checkExpiryDate();
    checkDocumentType();
    checkIssueDate();
    checkFiles();
  }, [
    checkReference,
    checkExpiryDate,
    checkDocumentType,
    checkIssueDate,
    checkFiles,
  ]);

  React.useEffect(() => {
    if (
      !actionName ||
      !!referenceError ||
      !!issueDateError ||
      !!expiryDateError ||
      !!fileInputError ||
      !!documentTypeError
    ) {
      setActionName("");
      return;
    }
    const createDataObj = {
      reference,
      documentType: documentType.label,
      files,
    };
    const updateDataObj = {
      itemId: props.itemId,
      documentType: documentType.label,
      reference,
      files,
    };
    if (expiryDate) {
      createDataObj.expiryDate = expiryDate;
      updateDataObj.expiryDate = expiryDate;
    }
    if (issueDate) {
      createDataObj.issueDate = issueDate;
      updateDataObj.issueDate = issueDate;
    }
    switch (actionName) {
      case "create":
        props.onCreate(createDataObj);
        break;
      case "update":
        props.onUpdate(updateDataObj);
        break;
    }
    setActionName("");
  }, [actionName]);

  const onSave = () => {
    checkFormFields();
    setActionName("create");
  };

  const onUpdate = () => {
    checkFormFields();
    setActionName("update");
  };
  useWhyDidYouUpdate("Upload Document", props);
  return (
    <UpdateLayout
      title="Upload Documents"
      isSave={!props.reference}
      onClose={props.onClose}
      onSave={onSave}
      onUpdate={onUpdate}
    >
      <span className={styles.container}>
        <Input label="Licence Document" value={props.name} readOnly={true} />
        <SelectInput
          label="Document type"
          defaultValue={documentType}
          options={props.documentTypes}
          onOptionChange={setDocumentType}
          error={documentTypeError}
        />
        <Input
          error={referenceError}
          value={reference}
          onChange={setReference}
          label="Reference"
          placeholder="Enter your reference"
        />
        <Input
          type="date"
          name="issueDate"
          label="Issue Date"
          value={issueDate}
          onChange={setIssueDate}
          max={new Date().toISOString().split("T")[0]}
          error={issueDateError}
        />
        {expiryRequired && (
          <Input
            type="date"
            name="expiryDate"
            label="Expiry Date"
            value={expiryDate}
            onChange={setExpiryDate}
            min={new Date().toISOString().split("T")[0]}
            error={expiryDateError}
          />
        )}
        <div className={styles.chooseFile}>
          <ListFileInput
            fileInputProps={{
              label: "Choose file to upload",
              error: fileInputError,
              files: files,
              onClick: setFiles,
            }}
            onDelete={deleteFile}
            files={files}
          />
        </div>
      </span>
    </UpdateLayout>
  );
};

UploadLicence.propTypes = {
  onClose: propTypes.func,
  onCreate: propTypes.func,
  onUpdate: propTypes.func,
  onDeleteFile: propTypes.func,
  name: propTypes.string.isRequired,
  documentType: propTypes.shape({
    value: propTypes.string,
    label: propTypes.string,
  }),
  documentTypes: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.string,
      label: propTypes.string,
    })
  ).isRequired,
  issueDate: propTypes.shape({
    value: propTypes.string,
    error: propTypes.string,
  }),
  expiryDate: propTypes.shape({
    value: propTypes.string,
    error: propTypes.string,
  }),
  files: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      url: propTypes.string,
    })
  ),
};

UploadLicence.defaultProps = {
  onClose: () => {},
  onCreate: () => {},
  onUpdate: () => {},
  onDeleteFile: () => {},
  documentType: null,
  reference: "",
  issueDate: "",
  expiryDate: "",
  files: [],
};

export default UploadLicence;
