import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";
import styles from "./Input.module.css";
import Label from "../Label";

const Input = (props) => {
  useWhyDidYouUpdate(`Input ${props.name}`, props);
  return (
    <Label
      label={props.label}
      onInputTip={props.onInputTip}
      error={props.error}
      noSponsor={props.noSponsor}
    >
      <span
        className={`${props.error ? styles.inputError : ""} ${
          props.readOnly ? styles.readOnly : styles.container
        }`}
      >
        <input
          autoComplete={props.autocomplete ? "on" : "off"}
          id={props.id || ""}
          readOnly={props.readOnly}
          type={props.type}
          placeholder={props.placeholder}
          name={props.name}
          className={`${props.variant ? styles[props.variant] : styles.default} 
          ${props.error ? styles.inputError : ""}
          `}
          value={props.value}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
        />
      </span>
    </Label>
  );
};

Input.propTypes = {
  label: propTypes.string,
  onInputTip: propTypes.func,
  type: propTypes.string,
  placeholder: propTypes.string,
  name: propTypes.string,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  onKeyDown: propTypes.func,
  className: propTypes.string,
  readOnly: propTypes.bool,
  autocomplete: propTypes.bool,
};

Input.defaultProps = {
  type: "text",
  placeholder: "text",
  name: "name",
  classes: {},
  onChange: () => null,
  value: "",
  onBlur: () => null,
  onFocus: () => null,
  onKeyDown: () => null,
  className: "",
  readOnly: false,
  autocomplete: true,
  min: "",
  max: "",
};
export default Input;
