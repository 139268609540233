import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { SimpleButton, Image } from "components";
import { HomeIcon } from "images";
import { styles } from "./styles";

const SelectedAddress = ({ postcode, classes = "", onClick }) => (
  <div className={classes.main}>
    <div className={classes.logo}>
      <Image src={HomeIcon} alt="homeicon" />
      <span>{postcode}</span>
    </div>
    <div className={classes.button}>
      <SimpleButton onClick={onClick}>Change</SimpleButton>
    </div>
  </div>
);

SelectedAddress.propTypes = {
  postcode: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
  onClick: propTypes.func.isRequired,
};

export default withStyles(styles)(SelectedAddress);
