export const styles = ({ colors, fonts }) => ({
  main: {
    fontFamily: fonts.primary,
    fontStyle: "normal",
    fontWeight: "600",
    background: colors.hexaWhite,
    "& .text-click": {
      outline: "none",
      cursor: "pointer",
    },
    "& svg": {
      cursor: "pointer",
    },
    "& .heading": {
      minHeight: 48,
      height: "auto",
      width: "auto",
      display: "flex",
      justifyContent: "space-between",
      alginItem: "center",
      background: colors.grey4,
      alignItems: "center",
      padding: "0 20px",
      "& svg > path": {
        stroke: colors.black,
      },
      "& p": {
        color: colors.black,
      },
    },
    "& .details": {
      width: "auto",
      minHeight: 200,
      height: "auto",
      background: colors.hexaWhite,
    },
  },
});
