import React from "react";
import propTypes from "prop-types";

const Image = ({
  src,
  className = "",
  alt,
  style,
  onClick = () => null,
  onClose = () => null,
  id = null,
}) => (
  <img
    id={id}
    src={src}
    className={className}
    style={style}
    onKeyDown={onClose}
    role="presentation"
    onClick={onClick}
    alt={alt}
  />
);

Image.propTypes = {
  src: propTypes.string.isRequired,
  className: propTypes.string,
  alt: propTypes.string,
  onClick: propTypes.func,
  onClose: propTypes.func,
  id: propTypes.string,
};

export default Image;
