import { sendQueryToCustomer } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { POST } from "utils";

type RentalQuery = {
  firstName: string;
  lastName: string;
  email: string;
  number: string;
  details: string;
  propertyUuid: string;
};

type EmailActions = {
  sendRentalQuery: APIAction<RentalQuery, boolean>;
};

export default function useApiEmailActions(): EmailActions {
  const sendRentalQueryToCustomer = async (onSendRentalQuery: RentalQuery) => {
    if (onSendRentalQuery) {
      return POST(sendQueryToCustomer(), {
        firstName: onSendRentalQuery.firstName,
        lastName: onSendRentalQuery.lastName,
        email: onSendRentalQuery.email,
        number: onSendRentalQuery.number,
        details: onSendRentalQuery.details,
        propertyUuid: onSendRentalQuery.propertyUuid,
      });
    }
  };

  const sendRentalQueryPromise = useAsync<
    APIActionResponse<RentalQuery>,
    APIActionError
  >(sendRentalQueryToCustomer, false);

  return {
    sendRentalQuery: {
      trigger: sendRentalQueryPromise.execute,
      state: sendRentalQueryPromise.status === "pending" ? "loading" : "idle",
      error: sendRentalQueryPromise.error?.errorDetails,
      result: sendRentalQueryPromise.status === "success",
    },
  };
}
