import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { Modal, Form, Select, Links, ProgressBar } from "components";
import Button from "../../../v2/components/Form/Button/Button";
import { uploadFileS3Bucket, getS3details } from "Apis";
import Alert from "sweetalert";
import { Input, Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { checkRequiredField, checkAllRequiredFieldsWithKey } from "utils";
import styles from "./UploadDocument.module.css";
import { ReactComponent as Logo } from "../../../images/yunologo.svg";

import {
  setting,
  field,
  acceptFile,
  errorFields,
  documentType,
} from "./constants";

const SelectCertificates = ({
  isOpen,
  onClose,
  classes,
  onSubmit,
  currentCertificate: { id, dbId, type },
  certificates,
  propertyId,
}) => {
  //setting.height = type === documentType.planning ? '556px' : '672px';
  const hiddenInput = useRef(null);
  const [certificateForm, setCertificatesForm] = useState(field);
  const [startUploading, setStartUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(errorFields[type]);
  useEffect(() => {
    setCertificatesForm({ ...field, id });
  }, [id]);

  const checkRequired = ({ target: { name, value } }) => {
    if (Object.prototype.hasOwnProperty.call(errorFields[type], name)) {
      setError({ ...error, ...checkRequiredField(name, value) });
    }
  };
  const removeError = ({ target: { name } }) => {
    setError({ ...error, [name]: "" });
  };
  const handleIuput = ({ target: { name, value } }) => {
    setCertificatesForm({ ...certificateForm, [name]: value });
  };
  const handleFile = ({ target: { name, files } }) => {
    setCertificatesForm({ ...certificateForm, [name]: files[0] });
    setError({ ...error, [name]: "" });
  };
  const removeFile = () => {
    setCertificatesForm({ ...certificateForm, document: "" });
  };
  const selectFile = () => {
    hiddenInput.current.click();
  };
  const checkAllFields = () => {
    const errors = checkAllRequiredFieldsWithKey(
      errorFields[type],
      certificateForm
    );
    setError({ ...error, ...errors });
    return Object.values(errors).every((value) => !value);
  };
  const uploadS3 = (data, document, onUploadProgress) => {
    const formData = new FormData();
    if (data && data.fields) {
      Object.keys(data.fields).forEach((key) => {
        if (key !== "bucket_name") {
          formData.append(key, data.fields[key]);
        }
      });
    }
    formData.append("file", document);
    const options = {
      headers: {
        "Content-Type": document.type,
      },
      onUploadProgress,
    };
    return uploadFileS3Bucket(data.url, formData, options);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (checkAllFields()) {
      try {
        let docUrl = "";
        if (certificateForm.document) {
          setStartUploading(true);
          const s3Info = await getS3details(propertyId, dbId, type);
          const { postData, url } = s3Info.data;
          docUrl = url;
          await uploadS3(postData, certificateForm.document, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
          });
          setStartUploading(false);
        }
        onSubmit({ ...certificateForm, url: docUrl, dbId });
        setCertificatesForm({ ...field, id: "0" });
      } catch (err) {
        setStartUploading(false);
        setProgress(0);
        Alert("error", "Something went wrong", "error");
      }
    }
  };
  return (
    <Modal isOpen={isOpen} setting={setting}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography as="h6License">Upload Documents</Typography>
          <Logo className={styles.logo}></Logo>
          <CrossIcon
            className={styles.cross}
            onClick={onClose}
            onKeyDown={onClose}
          ></CrossIcon>
        </div>
        <div className={styles.Wrap}>
          <Form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <Typography as="UploadDocuments">
                Select the document you’d like to upload
              </Typography>
              <Select
                name="id"
                options={certificates}
                selected={id.toString()}
                error={error.name}
                type="text"
                placeholder="Name"
                disabled
                onBlur={checkRequired}
                onFocus={removeError}
                onChange={handleIuput}
              />
            </div>

            <div className={styles.row}>
              <Typography as="UploadDocuments">
                {type === documentType.planning
                  ? "Planning Permission Reference Number"
                  : "Enter document reference number"}
              </Typography>
              <Input
                name="licenceRefNo"
                value={certificateForm.licenceRefNo}
                error={error.licenceRefNo}
                type="text"
                onBlur={checkRequired}
                onFocus={removeError}
                onChange={handleIuput}
                placeholder={
                  type === documentType.planning
                    ? "Enter Planning Permission Reference Number"
                    : "Enter document reference number"
                }
              />
            </div>
            <div className={styles.row}>
              <Typography as="label">Issue date</Typography>
              <Input
                max={new Date().toISOString().split("T")[0]}
                name="issueDate"
                value={certificateForm.issueDate}
                error={error.issueDate}
                type="date"
                placeholder="DD/MM/YY"
                onChange={handleIuput}
                onBlur={checkRequired}
                onFocus={removeError}
              />
            </div>

            {type !== documentType.planning && (
              <div className="form-control">
                <Typography as="label">Expiry date</Typography>
                <Input
                  min={
                    certificateForm.issueDate ||
                    new Date().toISOString().split("T")[0]
                  }
                  name="expiryData"
                  value={certificateForm.expiryData}
                  error={error.expiryData}
                  type="date"
                  placeholder="DD/MM/YY"
                  onBlur={checkRequired}
                  onFocus={removeError}
                  onChange={handleIuput}
                />
              </div>
            )}
            <div className={styles.row}>
              {!startUploading ? (
                certificateForm.document ? (
                  <div className="selected-file">
                    <div className="file-name">
                      {certificateForm.document.name}
                    </div>
                    <CrossIcon className="file-remove" onClick={removeFile} />
                  </div>
                ) : (
                  <Links
                    as="click"
                    onClick={selectFile}
                    className="select-file-link"
                  >
                    Choose File
                  </Links>
                )
              ) : (
                <ProgressBar progress={progress} />
              )}
              <Input
                type="file"
                className={styles.chooseFile}
                accept={acceptFile}
                name="document"
                ref={hiddenInput}
                error={error.document}
                onBlur={checkRequired}
                onFocus={removeError}
                onChange={handleFile}
              />
            </div>

            <div className={styles.button}>
              <Button
                disable={startUploading}
                onClick={onClose}
                actionName="Cancel"
              />
              <Button
                disable={!startUploading}
                className="submit-button"
                onClick={() => {}}
                type="submit"
                actionName="Upload"
              />
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
SelectCertificates.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentCertificate: PropTypes.any.isRequired,
  certificates: PropTypes.array.isRequired,
  propertyId: PropTypes.number.isRequired,
};

export default SelectCertificates;
