import { Image, Typography } from "v2/components";
import { AirDNA } from "images";
import AlertSponsorBox from "v2/components/AlertSponsorBox/AlertSponsorBox";
import Flex from "v2/components/Layout/Flex";
import styles from "./CompareRents.module.css";
export const CompareRents = (props) => {
  const bedrooms = props.propertyDetails.roomsOrUnits;
  const localHousingBedrooms = bedrooms > 4 ? 4 : bedrooms;
  const lettingBedrooms = bedrooms > 5 ? 5 : bedrooms;

  const rents = props.analytics["rents"].filter(
    (r) => r.parameters.bedrooms == lettingBedrooms
  );
  const yields = props.analytics["yields"].filter(
    (y) => y.parameters.bedrooms == lettingBedrooms
  );
  const localHousingAuthorityRates = props.analytics["lha-rate"].filter(
    (l) => l.parameters.bedrooms == localHousingBedrooms
  );
  const rentalizer = props.analytics["rentalizer-summary"].filter(
    (y) => y.data.property_details?.bedrooms == bedrooms
  );
  const rentsHMO = props.analytics["rents-hmo"];
  return (
    <>
      <span className={styles.title}>
        <Typography as="h3">Compare Rents by Letting Type</Typography>
      </span>
      {!!props.canViewPremiumData && (
        <>
          {!!yields.length && (
            <Flex direction={"column"}>
              <Typography as="propertyInfoAnalytics">
                {" Long Let Yields: "}
              </Typography>
              {yields.map((yieldsObject) => (
                <Flex direction={"column"}>
                  <Typography as="propertyInfoAnalyticsData">{`${
                    yieldsObject.data.data.long_let.gross_yield
                  } based on ${
                    yieldsObject.parameters.bedrooms || "0"
                  } bedrooms`}</Typography>
                </Flex>
              ))}
            </Flex>
          )}
          {!!rents.length && (
            <Flex direction={"column"}>
              <Typography as="propertyInfoAnalytics">{" Long Let:"}</Typography>
              {rents.map((rent) => (
                <Flex direction={"column"}>
                  {Object.keys(rent.data.data).map((key) => {
                    return rent.data.data[key]?.average ? (
                      <Flex direction={"column"}>
                        {/* <Typography as="propertyInfoAnalytics">
                        {`${key.split("_").join(" ")}`}:
                      </Typography> */}
                        <Typography as="propertyInfoAnalyticsData">
                          £
                          {`${rent.data.data[key]?.average}/week based on ${
                            rent.parameters.bedrooms || "0"
                          } bedrooms`}
                        </Typography>
                      </Flex>
                    ) : null;
                  })}
                </Flex>
              ))}
              <br />
            </Flex>
          )}
          {!!rentsHMO.length && (
            <Typography as="propertyInfoAnalytics">{`Local Room Rates`}</Typography>
          )}
          {!!rentsHMO.length &&
            rentsHMO.map((rentHMO) => (
              <Flex direction={"column"}>
                {Object.keys(rentHMO.data.data).map((key) => {
                  return rentHMO.data.data[key]?.average ? (
                    <Typography as="propertyInfoAnalyticsData">
                      {`${key.split("-").join(" ")}`}: £
                      {`${rentHMO.data.data[key]?.average}/week`}
                    </Typography>
                  ) : null;
                })}
              </Flex>
            ))}
          {!!localHousingAuthorityRates.length && (
            <Typography as="propertyInfoAnalytics">
              {`Local Housing Authority Rates:`}
            </Typography>
          )}
          <Flex direction={"column"}>
            {localHousingAuthorityRates.map((localHousingAuthorityRate) => (
              <Typography as="propertyInfoAnalyticsData">
                £
                {`${localHousingAuthorityRate.data.data.rate}${
                  localHousingAuthorityRate.data.data.rate_unit ===
                  "gbp_per_week"
                    ? "/week"
                    : ""
                }  based on ${
                  localHousingAuthorityRate.parameters.bedrooms
                } bedrooms`}
              </Typography>
            ))}
          </Flex>
          <Flex direction={"column"}>
            {!!rentalizer.length && (
              <Flex direction={"row"}>
                <Typography as="propertyInfoAnalytics">
                  {"Serviced Accommodation:"}
                </Typography>
                <Image
                  src={AirDNA}
                  style={{ height: "0.9rem", paddingLeft: "6rem" }}
                ></Image>
              </Flex>
            )}
            {
              rentalizer.map((rentalizerObject) => (
                <Flex direction={"column"}>
                  <br></br>
                  <Typography as="propertyInfoAnalyticsData">
                    {"Based on " +
                      rentalizerObject.data.property_details.bedrooms +
                      " bedrooms:"}
                  </Typography>
                  {Object.keys(rentalizerObject.data.property_stats).map(
                    (item) => {
                      if (item === "adr")
                        return (
                          <Typography as="propertyInfoAnalyticsData">
                            Daily Rate: £
                            {rentalizerObject.data.property_stats[item].ltm}
                          </Typography>
                        );
                      if (item === "occupancy")
                        return (
                          <Typography as="propertyInfoAnalyticsData">
                            {`Occupancy Rate: `}
                            {(
                              rentalizerObject.data.property_stats[item].ltm *
                              100
                            )
                              .toString()
                              .substring(0, 5)}
                            %
                          </Typography>
                        );
                      if (item === "revenue")
                        return (
                          <Typography as="propertyInfoAnalyticsData">
                            Estimate Annual Revenue: £
                            {rentalizerObject.data.property_stats[item].ltm}
                          </Typography>
                        );
                      return null;
                    }
                  )}
                </Flex>
              ))[0]
            }
          </Flex>
          {/* Hidden for now */}
          {/* <Flex direction={"column"}>
            <Typography as="propertyInfoAnalytics">
              {`Long Let Rental Demand`}
            </Typography>
            <Typography as="propertyInfoAnalyticsData">
              {"Coming Soon!"}
            </Typography>
          </Flex> */}
        </>
      )}
      {!props.canViewPremiumData && (
        <AlertSponsorBox onClick={props.openSponsorModal} />
      )}
    </>
  );
};
