import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { Styles } from "./Styles";

const TickMark = ({ classes, className = "" }) => (
  <div className={`${classes.tickmark} ${className}`} />
);
TickMark.propType = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};
export default withStyles(Styles)(TickMark);
