import styles from "./ConfirmActionModal.module.css";
import ModalV2 from "../Modal";

export default function ConfirmActionModal(props) {
  return (
    <ModalV2 onClose={props.onClose}>
      <div className={styles.container}>
        <header>
          {props.title}
          <div className={styles.close} onClick={props.onClose}>
            {props.closeIcon}
          </div>
        </header>
        <section>{props.message}</section>
        <footer>{props.confirmAction}</footer>
      </div>
    </ModalV2>
  );
}
