import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyle from "react-jss";
import { Styles } from "./Styles";

const Checked = ({
  classes,
  className = "",
  toolTip = false,
  toolTipText = "",
}) => (
  <div className={`${classes.tickbg} ${className}`}>
    <div className={`${classes.tick} tick-color`} />
    {toolTip && <div className="tooltiptext">{toolTipText}</div>}
  </div>
);

Checked.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  toolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
};

export default withStyle(Styles)(memo(Checked));
