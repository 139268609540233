export const Warnings = {
  Red: "red",
  Yellow: "yellow",
  Green: "green",
  Grey: "grey",
};

export const Actions = {
  MissingUPRN: "missing uprn",
  ConfirmPin: "confirm pin location",
  LicenceRequired: "licence required",
  LicenceExpiring: "licence expiring soon",
  LicenceExpired: "licence expired",
  PlanningRequired: "planning required",
  PlanningExpired: "planning expired",
  DocumentsExpiring: "documents expiring soon",
  DocumentsExpired: "documents expired",
  OverMaxLicenceTenants: "too many tenants for licence",
  OverMaxLicenceHouseholds: "too many households for licence",
  OverMaxPlanningTenants: "too many tenants for planning",
  OverMaxPlanningHouseholds: "too many households for planning",
  MissingPropertyData: "missing property data",
};
