import propTypes from "prop-types";
import { Typography } from "v2/components";
import { Links } from "v2/components";
import { ReactComponent as YunoLogos } from "images/yunologo.svg";
import styles from "./FooterLogo.module.css";

const FooterLogo = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.poweredBy}>
        <Typography as="label">Powered by</Typography>
        <Links as="_blank" path={props.link}>
          <YunoLogos />
        </Links>
      </div>
    </div>
  );
};

FooterLogo.propTypes = {
  link: propTypes.string,
  alert: propTypes.bool,
};

FooterLogo.defaultProps = {
  link: "https://goyuno.com",
  alert: false,
};

export default FooterLogo;
