const ButtonStyles = ({ colors, fonts }) => {
  const button = {
    textAlign: "center",
    fontStyle: "normal",
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "600",
    fontFamily: fonts.primary,
    width: ({ width }) => width || "160px",
    justifyContent: ({ align }) => align && align,
    cursor: "pointer",
    outline: "none",
    background: "none",
    border: "none",
    padding: "12px 16px",
    fontSize: ({ fontSize }) => fontSize || 16,
  };
  const simpleButton = {
    textAlign: "center",
    fontStyle: "normal",
    color: colors.hamptonsSecondary,
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "600",
    fontFamily: fonts.primary,
    width: ({ width }) => width || "91px",
    justifyContent: ({ align }) => align && align,
    cursor: "pointer",
    outline: "none",
    background: "none",
    border: "none",
    padding: "8px 16px",
    fontSize: ({ fontSize }) => fontSize || 14,
    "&:disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
      pointerEvents: "none",
    },
  };
  return {
    button: {
      ...button,
      height: "48px",
      borderRadius: "48px",
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || `${colors.primary}`,
      color: ({ color }) => color || `rgb(${colors.white})`,
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
        pointerEvents: "none",
      },
    },
    simpleButton: {
      ...simpleButton,
      height: "32px",
      padding: "0px",
      backgroundColor: ({ color }) => color || `rgb(${colors.white})`,
    },
  };
};

export default ButtonStyles;
