import { createUseStyles } from "react-jss";
import Theme from "theme";

const { breakpoints } = Theme;
export const useStyles = createUseStyles({
  main: {
    width: "416px",
    [breakpoints.RESPONSIVE]: {
      width: "100%",
    },
    minHeight: "40px",
    height: "auto",
    "&  .content": {
      padding: "27px 0 0 24px",
    },
  },
  locationIcon: {
    margin: "27.67px 0 0 26.05px",
    "& svg": {
      height: "16.67",
      width: "15",
    },
    "& path": {
      stroke: ({ colors: { black } }) => black,
    },
    "& rect": {
      stroke: ({ colors: { black } }) => black,
    },
  },
  heading: {
    margin: "24px 0 0 18.05px",
    paddingBottom: "24px",
    display: "flex",
    "& .heading-subtitle": {
      width: 296,
      height: 24,
    },
    [breakpoints.RESPONSIVE]: {
      width: "100%",
      justifyContent: "space-between",
    },
    [breakpoints.MOBILE_DEVICE]: {
      width: "87%",
    },
    "& path": {
      stroke: ({ colors: { black } }) => black,
    },
    "& rect": {
      stroke: ({ colors: { black } }) => black,
    },
    "& svg": {
      cursor: "pointer",
      marginLeft: "11px",
      [breakpoints.MOBILE_DEVICE]: {
        marginRight: "44px",
      },
      [breakpoints.IPAD]: {
        marginRight: "29px",
        marginLeft: "0px",
      },
      "&:focus": {
        outline: "none",
      },
    },
  },
  headingDiv: {
    display: "flex",
  },
  buttonDiv: {
    paddingBottom: "24px",
    "& button": {
      border: ({ colors: { hamptonsSecondary } }) =>
        `1px solid ${hamptonsSecondary}`,
    },
  },
  propertyDetails: {
    marginLeft: "24px",
    "& .details": {
      [breakpoints.MOBILE_DEVICE]: {
        width: "95%",
      },
      "& p": {
        width: "144px",
        minHeight: "20px",
        height: "auto",
        margin: "10px 16px 10px 0",
        fontSize: "14px",
        lineHeight: "20px",
      },
      display: "flex",
      minHeight: "40px",
      height: "auto",
      width: "368px",
      boxShadow: ({ colors: { lightGrey } }) =>
        `inset 0px -1px 0px ${lightGrey}`,
    },
    "& .last-div": {
      boxShadow: "none !important",
      marginBottom: "26px",
    },
  },
});
