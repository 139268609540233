import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyle from "react-jss";
import { ReactComponent as WarningIcon } from "images/help.svg";
import { Styles } from "./Styles";

const WarningCircle = ({ classes, className = "" }) => (
  <div className={`${classes.warningCircle} ${className}`}>
    <WarningIcon />
  </div>
);

WarningCircle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyle(Styles)(memo(WarningCircle));
