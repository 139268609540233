import React from "react";
import propTypes from "prop-types";
import WithStyles from "react-jss";
import { Styles } from "./styles";

const Column = ({ children, className, classes }) => (
  <div className={`${className} ${classes.col}`}>{children}</div>
);

Column.propTypes = {
  children: propTypes.any.isRequired,
  className: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
};

export default WithStyles(Styles)(Column);
