export const Styles = ({ colors, fonts }) => ({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    "& thead": {
      background: colors.hexaWhite,
    },
    "& thead > tr": {
      boxShadow: `inset 0px -1px 0px ${colors.lightGrey}`,
      height: "40px",
      textAlign: "left",
      fontFamily: fonts.primay,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.002em",
      textTransform: "capitalize",
      color: colors.grey2,
    },
    "& thead > tr > th": {
      paddingLeft: 10,
    },
    "& tbody": {
      background: colors.hexaWhite,
    },
    "& tbody > tr": {
      height: 56,
      fontFamily: fonts.primay,
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.black,
    },
    "& tbody > tr > td": {
      paddingLeft: 10,
    },
  },
});
