import { getWebInfo, checkWebInIframe } from "utils";

const { name } = getWebInfo();
const Styles = ({ colors, fonts, breakpoints }) => ({
  form: {
    width: "100%",
    display: "grid",
    gap: "1rem",
    marginTop: "32px",
    "& .line-divide": {
      marginBottom: "40px",
      color: colors.inputGrey,
    },
    "& .error-input": {
      width: "330px",
    },
    "& .list": {
      width: "100% !important",
      marginTop: "-7px",
      paddingTop: "2px",
    },
    "& .input-model": {
      "& label": {
        marginBottom: "10px",
      },
      "& input": {
        width: "272px",
      },
    },
    "& .input-div": {
      boxSizing: "border-box",
      "& input": {
        height: "48px",
        padding: 0,
        paddingLeft: "12px",
      },
      "& .input-div-inactive": {
        width: "100%",
        "& input": {
          height: "44px",
          padding: 0,
          paddingLeft: "12px",
        },
      },
      [breakpoints.MOBILE_DEVICE]: {
        width: "100%",
        padding: "0",
      },
    },
    "& .d-flex": {
      display: "flex",
      "& svg > path": {
        stroke: colors.hamptonsSecondary,
      },
    },
    "& .center-div": {
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: "26px",
      fontWeight: "500",
      marginLeft: "12px",
      color: colors.hamptonsSecondary,
      fontSize: "15px",
      cursor: "pointer",
      outline: "none",
    },
    "& button": {
      float: "right",
      marginLeft: "auto",
      background: colors.hexaWhite,
      color: colors.primary,
      width: "180px",
      border: `2px solid ${colors.primary}`,
      borderRadius: "4px",
    },
    "& .modal-button": {
      [breakpoints.RESPONSIVE]: {
        width: "100%",
        bottom: checkWebInIframe ? "0" : "65px",
      },
    },
  },
  labelDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "& .help-icon": {
      cursor: "pointer",
      margin: "0 0 6px 12px",
      fill: name === "sprift" ? colors.hamptonsSecondary : "none",
      "& .question-stock": {
        stroke: name === "sprift" ? colors.hamptonsSecondary : "",
      },
    },
  },
  smallInput: {
    "& .info": {
      marginRight: "24px",
      [breakpoints.MOBILE_DEVICE]: {
        marginRight: "17px",
      },
    },
  },
  select: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    [breakpoints.MOBILE_DEVICE]: {
      "& .long-label": {
        width: "auto",
        maxWidth: "272px",
      },
    },
    "& select": {
      width: "100%",
      [breakpoints.MOBILE_DEVICE]: {
        width: "100%",
      },
    },
    "& input": {
      width: "100%",
      [breakpoints.MOBILE_DEVICE]: {
        width: "100%",
      },
    },
  },
  label: {
    fontStyle: "normal",
    display: "flex",
    letterSpacing: ({ spacing = "0.01em" }) => spacing,
    fontWeight: ({ weight }) => weight || "500",
    fontFamily: fonts.primary,
    fontSize: ({ fontSize }) => fontSize || "16px",
    lineHeight: ({ lineHeight }) => lineHeight || "16px",
    marginBottom: "8px",
    color: colors.black,
    "& .required-start": {
      fontSize: "18px",
      marginLeft: "2px",
      color: colors.red,
    },
  },
  button: {
    display: "flex",
    width: "100%",

    justifyContent: "flex-start",
    [breakpoints.MOBILE_DEVICE]: {
      justifyContent: "none",
      height: "64px",

      width: "100%",
      left: "0",
      right: "0",
      bottom: "65px",
      "& button": {
        width: "100%",
        margin: "0",
      },
    },
  },
  radio: {
    display: "flex",
    marginTop: "24px",
  },
});

export default Styles;
