export const Styles = ({ colors }) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    "& .password-info": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
    },
    "& .password-box": {
      display: "flex",
      "& .password-group": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        marginTop: "6px",
        width: "170px",
        "& .dot": {
          height: 6,
          width: 6,
          backgroundColor: colors.labelColor,
          borderRadius: "50%",
          display: "inline-block",
          marginRight: 5,
        },
        "& .password-valid": {
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "13px",
          lineHeight: "24px",
          display: "flex",
          alignItems: "center",
          color: colors.labelColor,
        },
        "& .active > .dot": {
          backgroundColor: colors.primary,
          textDecorationLine: "line-through",
        },
        "& .active": {
          textDecorationLine: "line-through",
          color: colors.primary,
        },
      },
      "& .password-warnings": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        marginTop: "6px",
        marginBottom: "6px",
        width: "100%",
        "& .warning-text": {
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "13px",
          lineHeight: "24px",
          display: "flex",
          alignItems: "center",
          color: colors.red,
          paddingLeft: "0px"
        },
        "& .suggestion-text": {
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "13px",
          lineHeight: "24px",
          display: "flex",
          alignItems: "center",
          color: colors.labelColor,
          paddingLeft: "0px"
        },
      },
    },
  },
});
