import useAirtableActions from "Apis/YunoApi/useAirtableActions";
import { useUserData } from "context/environmentContext/EnvironmentContext";
import { usePropertyDetails } from "context/propertyContext/PropertyContext";
import useModal from "Hooks/useModal";
import propTypes from "prop-types";
import { useCallback } from "react";
import mixpanel from "utils/mixPanel";
import { Image, ModalV2, Typography } from "v2/components";
import SubmitJobDetails from "v2/components/Banner/SubmitJobDetails/SubmitJobDetails";
import Button from "v2/components/Form/Button/Button";
import { Iframe } from "v2/components/Iframe/Iframe";
import styles from "./Providers.module.css";
import { useCustomer } from "context/environmentContext/EnvironmentContext";

const MainProvider = (props) => {
  const customerInfo = useCustomer();
  const onClickCallback = (data) => {
    mixpanel.track("Contact Main Provider", { ...props });
    props.onClick({ ...data, partnerName: props.name });
  };
  if (props.description)
    if (props.iframe)
      return (
        <div className={styles.mainProviderContainer}>
          <header>
            <Typography as="h6">{props.name}</Typography>
            <Image src={props.logoUrl} />
          </header>
          <Typography as="noteText">{props.description}</Typography>
          <div className={styles.iframe}>
            <Iframe src={props.iframe}></Iframe>
          </div>
        </div>
      );
    else
      return (
        <div className={styles.mainProviderContainer}>
          <header>
            <Typography as="h6">{props.name}</Typography>
            <Image src={props.logoUrl} />
          </header>
          <Typography as="noteText">{props.description}</Typography>
          <Button
            id="Stonly_GetInContactButton"
            actionName="Get in contact"
            onClick={onClickCallback}
          />
        </div>
      );
  else return <Button actionName="Get in contact" onClick={onClickCallback} />;
};

const Provider = (props) => {
  const onClickCallback = () => {
    mixpanel.track("Contact Provider", { ...props });
    props.onClick({ ...props, partnerName: props.name });
  };

  return (
    <div className={styles.providerContainer}>
      <span className={styles.providerName}>
        <Typography as="h6">{props.name}</Typography>
        <Typography as="noteText">{props.description}</Typography>
        <Button actionName="Get in contact" onClick={onClickCallback} />
      </span>
    </div>
  );
};

function Providers(props) {
  const [mainProvider, ...secondaryProviders] = props.providers;
  const [isModalOpen, openModal, closeModal, modalData] = useModal();
  const user = useUserData();
  const { submitJobDetails } = useAirtableActions();
  const propertyDetails = usePropertyDetails();

  const submitJobDetailsCallback = useCallback(
    (data) => {
      submitJobDetails.trigger({
        ...data,
        ...user,
        propertyUuid: propertyDetails.uuid,
        details: `${modalData.partnerName}
        ${data.details}`,
        partnerName: props.providers[0].name,
        serviceName: props.providers[0].title,
      });
    },
    [submitJobDetails, modalData]
  );

  return (
    <div className={styles.container}>
      {isModalOpen && (
        <ModalV2 onClose={closeModal}>
          <SubmitJobDetails
            {...user}
            title="Contact our partner"
            onClose={closeModal}
            onSave={submitJobDetailsCallback}
          />
        </ModalV2>
      )}
      {mainProvider && <MainProvider {...mainProvider} onClick={openModal} />}
      {secondaryProviders && (
        <div className={styles.secondaryProviders}>
          {secondaryProviders.map((provider, index) => {
            return <Provider key={index} {...provider} onClick={openModal} />;
          })}
        </div>
      )}
    </div>
  );
}
Providers.propTypes = {
  providers: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
    })
  ),
};
Providers.defaultProps = {
  providers: [],
};
export default Providers;
