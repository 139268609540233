import { ReactComponent as NoResultsIcon } from "images/noResults.svg";
import propTypes from "prop-types";

import { useTable } from "react-table";
import Typography from "../Typography/Typography";
import styles from "./ValuationTable.module.css";

const NoResults = (props) => (
  <td colSpan={5} className={styles.noResultCell}>
    <div className={styles.noResult}>
      <NoResultsIcon />
      <Typography as="h3">No results found</Typography>
      {props.message && <Typography as="subtitle">{props.message}</Typography>}
    </div>
  </td>
);

const Table = (props) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: props.headers.columnNames,
      data: props.data,
      initialState: {
        properties: "NO DATA",
      },
    });

  // Render the UI for your table
  return (
    <table className={styles.container} {...getTableProps()}>
      <thead className={styles.header}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <th {...column.getHeaderProps()} className={column.className}>
                  <Typography as="h3">{column.render("Header")}</Typography>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={styles.tbody}>
        {!rows.length && (
          <tr>
            <NoResults message={props.noDataMessage} />
          </tr>
        )}
        {!!rows.length &&
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={row.original?.className}>
                {row.cells.map((cell) => {
                  const cellProps = row.original.cellProps
                    ? row.original.cellProps[cell.column.id] || {}
                    : {};
                  if (cellProps.hide) {
                    return null;
                  }
                  return (
                    <td {...cell.getCellProps(cellProps)} id={cell.column.id}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
Table.propTypes = {
  columnNames: propTypes.arrayOf(
    propTypes.shape({
      Header: propTypes.string,
    })
  ),
  noDataMessage: propTypes.string,
  data: propTypes.array,
};

export default Table;
