import usePropertyActions from "Apis/YunoApi/usePropertyActions";
import { Button, Typography } from "v2/components";
import styles from "./CreateBuildingReport.module.css";
import { useEffect } from "react";
import { useHistory } from "react-router";

type CreateBuildingReportProps = {
  propertyDetails: object;
};

const CreateBuildingReport = (props: CreateBuildingReportProps) => {
  const { propertyDetails } = props;
  const { createBuildingReport } = usePropertyActions(undefined);
  const history = useHistory();
  const buildingReportStatus =
    createBuildingReport.state === "pending"
      ? "Creating Property..."
      : createBuildingReport.state === "success"
      ? "Taking you there now..."
      : "Create Full Property Report";

  const parsedPropertyDetails = Object.fromEntries(
    Object.entries(propertyDetails).filter(([_, v]) => v != null) // Clearing out null values
  );
  delete parsedPropertyDetails.CRMUniqueIdentifier; // Can't create property with same CRMID

  const handleCreateBuildingReport = () => {
    createBuildingReport.trigger({
      ...parsedPropertyDetails,
      propertyType: "Building",
      knowWhoLiveInProperty:
        parsedPropertyDetails.knowWhoLiveInProperty === "Yes" ? true : false,
    });
  };

  useEffect(() => {
    if (createBuildingReport.result) {
      const { uuid } = createBuildingReport.result;
      if (uuid) {
        window.location.href = `${window.location.origin}/property/${uuid}/journey/licensing`;
      }
    }
  }, [createBuildingReport.result]);

  return (
    <div className={styles.buildingReportContainer}>
      <Typography>
        To create a full building report for this property please press below:
      </Typography>
      <Typography as="noteText">
        A full building report will:
        <ul>
          <li>Recreate this report as a building</li>
          <li>
            Contain the same information that you have given us for this
            property
          </li>
          <li>Create you a full building compliance report</li>
        </ul>
      </Typography>
      <Button
        actionName={buildingReportStatus}
        onClick={handleCreateBuildingReport}
      />
    </div>
  );
};

export default CreateBuildingReport;
