import styles from "./NetworkError.module.css";

import { ReactComponent as Wave } from "images/wave.svg";
import Typography from "../Typography/Typography";

function NetworkError(props) {
  return (
    <div className={styles.container}>
      <div className={styles.backgroundWave}>
        <Wave />
      </div>

      <div className={styles.networkMessage}>
        <div className={styles.message}>
          <Typography as="h1">{props.message}</Typography>
          <Typography as="h3">
            Please ensure that you have a stable internet connection and refresh
            the page.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default NetworkError;
