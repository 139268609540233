import { Modal, WhiteBox } from "components";
import { style } from "components/Form/Input/style";
import { TickIcon } from "images";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as WhiteCircleCheck } from "images/white-circle-check.svg";
import propTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";
import withStyles from "react-jss";
import theme from "theme";
import { Button, Links } from "v2/components";
import styles from "./packages.module.css";
import { SelectInput } from "../../../v2";
import { useWindowDimensions } from "utils";

const setting = {
  width: "100%",
  top: 0,
  // color: colors.backgroundColor,
};

const Packages = ({
  isOpen,
  onClose,
  classes,
  currentPackage = {},
  data: { packages = [], services = [] },
  selectPackage = () => {},
  isLondonCouncil = false,
  isLogout = false,
}) => {
  const openMail = () => {
    window.open("mailto:support@goyuno.com", "_blank");
  };
  const ref = useRef();
  const [selectLocalPackage, setSelectLocalPackage] = useState({
    ...currentPackage,
  });
  const selectedData = () => {
    selectPackage(selectLocalPackage);
    onClose();
  };
  const onClickPackage = (value) => {
    setSelectLocalPackage(value);
  };
  useEffect(() => {
    setSelectLocalPackage({ ...currentPackage });
  }, []);

  const { width } = useWindowDimensions();

  return (
    <Modal
      isOpen={isOpen}
      setting={setting}
      className={styles.packages}
      ref={ref}
    >
      {width < 1024 && (
        <div className={styles.wrapper}>
          <div className={styles.mobileContainer}>
            <div className={styles.mobilePackages}>
              HMO Services Packages
              <CrossIcon onClick={onClose} onKeyDown={onClose} />
            </div>
            <div className={styles.mobileSelect}>
              <SelectInput
                variant="secondary"
                key={selectLocalPackage.name}
                className={styles.filter}
                placeholder={selectLocalPackage.name}
                options={packages.map((value) => {
                  return { value: value, label: value.name };
                })}
                onOptionChange={(e) => onClickPackage(e.value)}
              />
            </div>
            <div className={styles.mobileDescription}>
              <div className={styles.mobileDescriptionTitle}>
                {selectLocalPackage.name}
                {selectLocalPackage.name === "Platinum" && (
                  <span> Recommended</span>
                )}
              </div>
              <div className={styles.mobileDescriptionText}>
                <WhiteBox className={styles.packageContent}>
                  {services.map((value, key) => (
                    <div
                      className={styles.mobilePackageList}
                      key={`${key}-${value.name}`}
                    >
                      <div className={styles.packageTitle}>{value.name}</div>

                      {Array.isArray(value.packages) &&
                        value.packages.map((val, key) =>
                          val.tag !== null &&
                          val.packageName === selectLocalPackage.name ? (
                            <div className={styles.tickIcon}>{val.tag}</div>
                          ) : (
                            val.enabled &&
                            val.packageName === selectLocalPackage.name && (
                              <div className={styles.tickIcon}>
                                <TickIcon />
                              </div>
                            )
                          )
                        )}
                    </div>
                  ))}
                </WhiteBox>
                {selectLocalPackage.name === "Diamond" && <span>Diamond</span>}
              </div>
            </div>
            <div className={styles.mobilePrice}>
              <span>{isLondonCouncil ? "London" : "Outside of London"}</span>
              <span>
                £{" "}
                {selectLocalPackage.price
                  ? selectLocalPackage.price
                  : isLondonCouncil
                  ? selectLocalPackage.priceInLondon
                  : selectLocalPackage.priceRestOfUk}
              </span>
            </div>
            <div className={styles.mobileSelectedPackage}>
              <span className={styles.selectPackage}>
                <span>Current selection:</span>{" "}
                <span className={styles.selectedPackageName}>
                  {selectLocalPackage.name} - £
                  {selectLocalPackage.price
                    ? selectLocalPackage.price
                    : isLondonCouncil
                    ? selectLocalPackage.priceInLondon
                    : selectLocalPackage.priceRestOfUk}
                </span>
              </span>
              <span>
                <Button
                  disabled={isLogout}
                  onClick={selectedData}
                  actionName="Save"
                />
              </span>
            </div>
          </div>
          <div className={styles.viewDetails} id="viewDetailsPDF">
            View details in PDF
          </div>
        </div>
      )}
      {width > 1023 && (
        <>
          <div className={styles.crossIcon}>
            <CrossIcon onClick={onClose} onKeyDown={onClose} />
          </div>
          <div className={styles.main}>
            <div className={styles.popular}>
              <div className={styles.packageTitle} />
              {packages.length === 3 && (
                <div className={styles.packageCommon} />
              )}
              {packages.length === 5 && (
                <>
                  <div className={styles.packageCommon} />
                  <div className={styles.packageCommon} />
                  <div className={styles.packageCommon} />
                </>
              )}
              <div className={styles.packageCommon}>
                <span className={styles.mostPopular}>Recommended</span>
              </div>
              <div className={styles.packageCommon} />
            </div>
            <div className={styles.packageList}>
              <div className={styles.packageHeading}>
                <div className={styles.packageTitle}>HMO Services Packages</div>
                {packages.map((value) => (
                  <div
                    className={`${styles.packageCommon} ${
                      selectLocalPackage.packageId === value.packageId
                        ? styles.selected
                        : ""
                    }`}
                    key={value.packageId}
                  >
                    <span className={styles.packageName}>{value.name}</span>
                    <Links
                      className={`${styles.packageLink} ${
                        isLogout ? "disabled" : ""
                      } 
                  ${
                    selectLocalPackage.packageId === value.packageId
                      ? "selected-text"
                      : ""
                  }`}
                      as="click"
                      onClick={() => onClickPackage(value)}
                    >
                      {selectLocalPackage.packageId === value.packageId ? (
                        <span className={styles.selectedPackage}>
                          <WhiteCircleCheck />
                          Selected
                        </span>
                      ) : (
                        "Select package"
                      )}
                    </Links>
                  </div>
                ))}
              </div>
              <WhiteBox className={styles.packageContent}>
                {services.map((value, key) => (
                  <div
                    className={styles.packageList}
                    key={`${key}-${value.name}`}
                  >
                    <div className={styles.packageTitle}>{value.name}</div>
                    {Array.isArray(value.packages) &&
                      value.packages.map((val, key) => (
                        <div
                          className={`${styles.packageCommon} ${
                            selectLocalPackage.name === val.packageName
                              ? styles.packageSelectedList
                              : ""
                          }`}
                          key={`is-${key}`}
                        >
                          {val.tag !== null ? (
                            <span className={styles.tagValue}>{val.tag}</span>
                          ) : (
                            val.enabled && <TickIcon />
                          )}
                        </div>
                      ))}
                  </div>
                ))}
              </WhiteBox>
              <div className={styles.packageFooter}>
                <div className={styles.locationName}>
                  {isLondonCouncil ? "London" : "Outside of London"}
                </div>
                {packages.map((value, key) => (
                  <div
                    className={styles.packageCommon}
                    key={`${key}${value.packageId}`}
                  >
                    £
                    {isLondonCouncil
                      ? value.priceInLondon
                      : value.priceRestOfUk}
                  </div>
                ))}
              </div>
              <div className={styles.packageActions}>
                <div>
                  <span className={styles.packageHelp}>
                    Need help getting everything sorted?
                  </span>
                  <span className={styles.packageSupport}>
                    Send us your inquiry to
                  </span>
                  <span
                    className={styles.packageSupportEmail}
                    role="button"
                    tabIndex="0"
                    onClick={openMail}
                    onKeyPress={openMail}
                  >
                    support@goyuno.com
                  </span>
                </div>
                <div className={styles.button}>
                  <Button
                    variant="secondary"
                    className={styles.whiteButton}
                    onClick={onClose}
                    actionName="Close"
                  />
                  <Button
                    disabled={isLogout}
                    onClick={selectedData}
                    actionName="Save"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
Packages.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object,
  data: propTypes.any.isRequired,
  selectPackage: propTypes.func,
  currentPackage: propTypes.object,
  isLondonCouncil: propTypes.bool,
  isLogout: propTypes.bool,
};

export default withStyles(styles)(memo(Packages));
