export const formErrorForm = {
  type: "",
  email: "",
  fullName: "",
  contactNumber: "",
};
export const options = [
  {
    value: "free holder",
    label: "Freeholder",
  },
  {
    value: "lease holder",
    label: "Leaseholder",
  },
  {
    value: "Commercial premises",
    label: "Commercial Premises",
  },
];
