export const Styles = ({ colors }) => ({
  tooltip: {
    position: "relative",
    display: " inline-block",
    "&:hover > .tooltiptext": {
      visibility: "visible",
    },
    "& .tooltiptext": {
      visibility: "hidden",
      width: 211,
      minHeight: "85px",
      height: "auto",
      backgroundColor: colors.hexaWhite,
      color: colors.black,
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: 1,
      top: "-39px",
      left: "31px",
      alginText: "center",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.09)",
      "&:after": {
        content: '""',
        position: "absolute",
        borderStyle: "solid",
        width: "11px",
        height: "11px",
        left: "-8px",
        bottom: ({ bottom = "39px" }) => bottom,
        background: colors.hexaWhite,
        transform: "rotate(45deg)",
        borderColor: "transparent transparent transparent transparent",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.09)",
      },
      "& .text": {
        margin: 12,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  },
});
