import React, { useCallback, useState, useEffect } from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import { useWindowDimensions, replaceAll } from "utils";
import { YunoLogos } from "images";
import { goYunoLink } from "constants/AppConstants";
import {
  Links,
  Image,
  ViewDetails as AddressInfo,
  AddressDetails,
} from "components";
import styles from "./IframeHeader.module.css";
import { useToggle } from "Hooks/useToggle";
import { useHistory, useRouteMatch } from "react-router";
import { tabList } from "containers/PropertyDetails/constants";
import { Typography } from "v2/components";

const Header = React.memo(
  ({
    theme,
    currentPath,
    isLogout = false,
    link = goYunoLink,
    propertyDetails,
  }) => {
    const { width } = useWindowDimensions();
    const history = useHistory();
    const { url } = useRouteMatch();
    const [isOpen, toggle] = useToggle(false);

    const handleEditClick = () => {
      history.push({
        pathname: `${url}/property-info`,
        state: { tabName: tabList.property },
      });
      toggle();
    };
    return (
      <>
        <div className={styles.header}>
          <div className={styles.logo}>
            {propertyDetails && (
              <AddressDetails
                onView={toggle}
                isOpen={isOpen}
                propertyDetails={propertyDetails}
              />
            )}
          </div>
          <div className={styles.navbar}>
            <Links
              as="_blank"
              path={
                "https://stonly.com/guide/en/benefits-of-yuno-aMOlZSdfRu/Steps/1709353"
              }
              className={styles.links}
            >
              <Typography>Why choose </Typography>
              <Image src={YunoLogos} alt="logo" className="logo-image" />
              <Typography>?</Typography>
            </Links>
          </div>
        </div>
        {isOpen && propertyDetails && (
          <AddressInfo
            handleClose={toggle}
            handleEdit={handleEditClick}
            propertyDetails={propertyDetails}
          />
        )}
      </>
    );
  }
);

Header.propTypes = {
  theme: propTypes.object.isRequired,
  currentPath: propTypes.string.isRequired,
  link: propTypes.string,
  isLogout: propTypes.bool,
};

export default withTheme(Header);
