import { useState } from "react";
import propTypes from "prop-types";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { Typography } from "v2/components";
import styles from "./List.module.css";

const List = (props) => {
  const [selectedListItemIndex, setSelectedListItemIndex] = useState(-1);

  const onClick = (event) => {
    props.onClick(event.target.value);
    setSelectedListItemIndex(event.target.value);
    event.stopPropagation();
  };

  return (
    <div className={styles.container}>
      <ul
        {...props}
        role="menu"
        defaultValue={props.selected}
        className={styles.list}
      >
        {!props.isLoading &&
          props.options.map((option, index) => (
            <Typography as="list" key={index}>
              <li
                className={`${styles.listItem} ${
                  index === selectedListItemIndex ? styles.selectedListItem : ""
                }`}
                onClick={onClick}
                onKeyPress={onClick}
                key={index}
                value={index}
              >
                {option.value}
              </li>
            </Typography>
          ))}
        {props.isLoading && (
          <li>
            <Typography className="list" as="subtitle">
              Loading...
            </Typography>
          </li>
        )}
        {!props.isLoading && props.noResults && (
          <li>
            <Typography className="list" as="subtitle">
              No results found.
            </Typography>
          </li>
        )}
      </ul>
      {props.error && <span className={styles.errorText}>{props.error}</span>}
      {!props.noIcon && <ArrowDown className={styles.downArrow} />}
    </div>
  );
};

List.propTypes = {
  type: propTypes.string,
  selected: propTypes.any,
  name: propTypes.string,
  classes: propTypes.object,
  options: propTypes.array,
  onClick: propTypes.func,
  selectedValue: propTypes.string,
  className: propTypes.string,
};

List.defaultProps = {
  selected: null,
  options: [],
  isLoading: false,
  noResults: false,
  name: "name",
  classes: {},
  onChange: () => null,
  className: "",
};

export default List;
