export const Styles = ({ colors, fonts, breakpoints }) => {
  const titles = {
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "32px",
    width: "250px",
    marginLeft: "18px",
    [breakpoints.RESPONSIVE]: {
      width: "60px",
      fontSize: "10px",
      lineHeight: "unset",
      marginLeft: "6rem",
    },
  };
  const common = {
    display: "flex",
    width: "151px",
    alignItems: "center",
    lineHeight: "125%",
    [breakpoints.RESPONSIVE]: {
      width: "60px",
      fontSize: "10px",
    },
  };
  const heading = {
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [breakpoints.RESPONSIVE]: {
      marginLeft: "3rem",
    },
  };
  return {
    "@global body": {
      overflow: ({ isOpen }) => (isOpen ? "hidden" : "auto"),
    },
    footer: {
      marginTop: "10px",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      color: colors.grey1,
    },
    packages: {
      padding: 0,
      "& .contents": {
        marginTop: "1rem",
        paddingBottom: "1rem",
        display: "flex",
        justifyContent: "center",
        height: "fit-content",
        background: colors.pureWhite,
        "& .logo": {
          display: "flex",
          justifyContent: "flex-end !important",
          "& svg": {
            position: "absolute",
            padding: "19px",
          },
        },
      },
      "& svg": {
        cursor: "pointer",
      },
    },
    mobilePackages: {
      "& .content-section": {
        margin: "16px",
      },
      "& .close-icon": {
        display: "flex",
        justifyContent: "flex-end",
      },
      "& svg": {
        cursor: "pointer",
        display: "flex",
        margin: "52px 19px 0px 0px",
        "&:focus": {
          outline: "none",
        },
      },
      "& .mobile-package-heading": {
        background: colors.bg6,
        display: "flex",
        justifyContent: "flex-start",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "32px",
        width: "343px",
        height: "45px",
        "& .mobile-package-title": {
          marginLeft: "16px",
          marginTop: "10px",
          fontSize: "20px",
          lineHeight: "24px",
        },
      },
      "& .mobile-details": {
        width: "340px",
        height: "520px",
        background: colors.hexWhite,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.09)",
        borderRadius: "4px",
        "& .all-contents": {
          display: "flex",
          flexDirection: "column",
          width: "310px",
          marginLeft: "16px",
          "& .input-div": {
            position: "relative",
            "& select": {
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              marginTop: "20px",
              fontSize: "16px",
              lineHeight: "24px",
              border: "2px solid #DFE3E8",
              boxSizing: "border-box",
              borderRadius: "4px",
              textTransform: "uppercase",
              "& option": {
                width: "310px !important",
                fontFamily: fonts.primary,
                fontWeight: 1,
                fontSize: "1rem",
                lineHeight: "1.5",
                padding: 0,
              },
              [breakpoints.MOBILE_DEVICE]: {
                width: "310px",
              },
            },
            "& svg": {
              top: "-19px",
              right: "20px",
              cursor: "pointer",
              outline: "none",
            },
            "& .info-second": {
              position: "relative",
            },
          },
          "& .package-details": {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "14px",
            "& .package-label": {
              fontWeight: "bold",
              fontSize: "16px",
              lineHeight: "125%",
              color: "#4EAEA5",
            },
            "& .package-type": {
              width: "117px",
              height: "19px",
              backgroundColor: colors.yelloHexa,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& span": {
                fontWeight: 600,
                fontSize: "11px",
                lineHeight: "11px",
                letterSpacing: "0.1em",
                textTransform: "uppercase",
                color: colors.hamptonsSecondary,
              },
            },
          },
          "& .package-info": {
            marginTop: "8px",
            fontSize: "15px",
            lineHeight: "24px",
            color: colors.grey1,
          },
          "& .price-section": {
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
            "& .border-div": {
              borderBottom: `1px solid ${colors.hamptonsNewDisabled}`,
              marginBottom: "18px",
              "& span": {
                marginBottom: "18px",
              },
            },
            "& div": {
              display: "flex",
              justifyContent: "space-between",
              "& span": {
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                letterSpacing: "0.002em",
                color: colors.black,
              },
            },
          },
        },
      },
      [breakpoints.MOBILE_DEVICE]: {
        padding: "0 !important",
        "& .contents": {
          width: "100%",
          margin: "0 !important",
          height: "100vh !important",
          top: "0",
        },
      },
    },
    select: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      marginLeft: "16px",
      marginTop: "20px",
      border: "2px solid #DFE3E8",
      boxSizing: "border-box",
      borderRadius: "4px",
      [breakpoints.MOBILE_DEVICE]: {
        "& .long-label": {
          width: "auto",
          maxWidth: "272px",
        },
        "& .last-select > svg": {
          top: "53px",
        },
      },
      "& select": {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        marginLeft: "16px",
        marginTop: "20px",
        border: "2px solid #DFE3E8",
        boxSizing: "border-box",
        borderRadius: "4px",
        [breakpoints.MOBILE_DEVICE]: {
          width: "285px",
        },
      },
      "& input": {
        width: "364px",
        [breakpoints.MOBILE_DEVICE]: {
          width: "310px",
        },
      },
    },

    main: {
      background: colors.hexWhite,
      margin: "0 48px",
      fontFamily: fonts.primary,
      fontStyle: "normal",
      padding: 0,

      "& .tag-value": {
        fontWeight: "bold !important",
      },
    },
    popular: {
      ...heading,
      marginTop: "15px",
      "& .package-heading": {
        ...titles,
      },
      "& .package-common": {
        ...common,
      },

      "& .most-popular": {
        background: colors.hamptonsSecondary,
        borderRadius: "6px 6px 0px 0px",
        color: colors.pureWhite,
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "24px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 32,
      },
    },
    packageList: {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
      width: "100%",
      flexDirection: "column",
      "& .package-actions": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "21px",
        [breakpoints.RESPONSIVE]: {
          marginBottom: "6rem",
          marginLeft: "9rem",
        },
        "& .package-help": {
          fontSize: "18px !important",
          fontWeight: 500,
          lineHeight: "24px",
        },
        "& .package-support": {
          fontSize: "18px",
          fontWeight: 500,
          marginLeft: "0.5rem",
          lineHeight: "24px",
          color: colors.primary,
        },
        "& .package-support-email": {
          fontSize: "18px",
          fontWeight: 600,
          marginLeft: "3px",
          color: colors.primary,
          textDecoration: "underline",
          cursor: "pointer",
        },
        "& .button ": {
          display: "flex",
          "& :nth-child(2)": {
            marginLeft: "1rem",
          },
        },
      },
      "& .package-heading": {
        background: colors.bg6,
        minHeight: "76px",
        "& .selected": {
          background: colors.primary,
          minHeight: "76px",
          justifyContent: "space-between !important",
          "& span": {
            color: colors.hexaWhite,
          },
        },
        ...heading,
        "& .package-title": {
          ...titles,
        },
        "& .package-common": {
          display: "flex",
          flexDirection: "column",
          minHeight: "78px",
          justifyContent: "space-around !important",
          "& .selected-package": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& :nth-child(1)": {
              marginRight: "0.5rem",
            },
          },
          "& .selected-text": {
            color: `${colors.pureWhite} !important`,
            "& svg": {
              width: "14px",
              marginRight: "3px",
            },
          },
          ...common,
          "& .package-name": {
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "125%",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            textTransform: "uppercase",
            marginTop: "7px",
            [breakpoints.RESPONSIVE]: {
              fontSize: "10px",
            },
          },
          "& .disabled": {
            pointerEvents: "none",
          },
          "& .package-link": {
            color: colors.primary,
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "125%",
          },
        },
      },
      "& .package-footer": {
        background: colors.grey4,
        minHeight: "38px",
        ...heading,
        "& .location-name": {
          fontSize: "14px",
          fontWeight: "bold",
          marginLeft: "18px",
          width: "250px",
        },
        "& .package-title": {
          ...titles,
        },
        "& .package-common": {
          fontWeight: "bold",
          justifyContent: "center !important",
          ...common,
        },
      },
      "& .package-content": {
        minHeight: "50px",
        width: "100%",
        "& .package-list": {
          ...heading,
          height: "auto",
          boxShadow: `inset 0px -1px 0px ${colors.grey4}`,
          "& .package-selected-list": {
            background: colors.bg6,
            boxShadow: `inset 0px -1px 0px ${colors.grey4}`,
          },
          "& .package-title": {
            ...titles,
            fontSize: "14px",
            lineHeight: "20px",
            height: "auto",
            padding: "0",
            alignItems: "center",
            color: colors.grey1,
          },
          "& .package-common": {
            ...common,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            minHeight: "36px",
          },
        },
      },
    },
    button: {
      width: "160px",
      marginRight: "1rem",
      display: "flex",
      justifyContent: "space-between",
    },
  };
};
