import React, { memo } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, SimpleButton } from "components";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as Email } from "images/email.svg";
import { Styles } from "./Styles";

const setting = {
  width: 640,
  height: 426,
  top: "0%",
};
const SubmitWork = ({ isOpen, onClose, classes }) => (
  <Modal isOpen={isOpen} setting={setting} className={classes.submitWork}>
    <div className={classes.main}>
      <div className={classes.logo}>
        <CrossIcon onClick={onClose} onKeyDown={onClose} />
      </div>
      <div className={classes.content}>
        <div className="icon-check">
          <Email />
        </div>
        <div className="text-heading">
          <Typography as="h6" className="heading">
            Thank you!
          </Typography>
          <Typography as="h6" className="heading">
            The work order has been submitted.
          </Typography>
        </div>
        <div className="sub-heading">
          We will be in touch soon. Please feel free to reach out if you have
          any further questions.
        </div>
        <div className="button">
          <SimpleButton onClick={() => onClose()}>OK, Thanks</SimpleButton>
        </div>
      </div>
    </div>
  </Modal>
);
SubmitWork.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
};

export default withStyles(Styles)(memo(SubmitWork));
