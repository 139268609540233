import { useEffect } from "react";
import propTypes from "prop-types";
import styles from "./JourneyLayout.module.css";
import { Typography } from "v2/components";
import Button from "v2/components/Form/Button/Button";

const JourneyLayout = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(window.scrollTo(0, 0), 100);
  }, []);

  return (
    <>
      <div id="container" className={styles.container}>
        {props.name && (
          <div className={styles.title}>
            <header
              className={styles.h6PropertyBox}
              id={props.name.replace(/\s/g, "")}
            >
              <Typography as="h6">{props.name}</Typography>
            </header>
            <Button
              actionName={"Continue to " + props.nextName + " ➔"}
              onClick={props.onNext}
            />
          </div>
        )}
        {props.children}
        <div className={styles.button}>
          <Button
            actionName="Back"
            variant="secondary"
            onClick={props.onPrevious}
          />
          <Button
            actionName={"Continue to " + props.nextName + " ➔"}
            onClick={props.onNext}
          />
        </div>
      </div>
    </>
  );
};

JourneyLayout.propTypes = {
  name: propTypes.string,
  children: propTypes.node.isRequired,
};

export default JourneyLayout;
