import {
  privacyPolicyURL,
  termsAndConditionsURL,
} from "constants/AppConstants";
import propTypes from "prop-types";

import Link from "../Link/Link";
import Typography from "../Typography/Typography";
import styles from "./TermsAndConditions.module.css";

const TermsAndConditions = (props) => {
  return (
    <div className={styles.container}>
      <Typography as="termsAndConditions">
        By clicking {props.actionName}, you agree to Yuno’s
        <Link as="termsAndConditions" path={termsAndConditionsURL}>
          {" Terms"}
        </Link>
        {" and "}
        <Link as="termsAndConditions" path={privacyPolicyURL}>
          Privacy Policy.
        </Link>
      </Typography>
    </div>
  );
};

TermsAndConditions.propTypes = {
  actionName: propTypes.string,
};

export default TermsAndConditions;
