import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import propTypes from "prop-types";

import Typography from "../Typography/Typography";
import styles from "./PreLicenceList.module.css";
import Certificate from "./PreLicense/Certificate";

const PreLicenseCertificateList = (props) => {
  useWhyDidYouUpdate("Certificate List", props);
  return (
    <div className={styles.container}>
      <Typography as="h6License">Pre-Application</Typography>
      <Typography as="documentInfoGrey">{props.description}</Typography>

      <span className={styles.section}>
        <Typography as="pTag">
          The following certification and documentation has been collected from
          the local council. Sometimes the council will accept an application
          without all the items listed below, however this is rare and you would
          need expert advice before applying without these documents as this can
          be seen as an incomplete application and may be refused. Please also
          note that you may not need a certificate if you don't already have the
          item installed, a common example is Emergency Lighting Certification.
        </Typography>
      </span>
      <span className={styles.section}>
        {props.certificates.map((certificate) => (
          <Certificate
            key={certificate.id}
            certificate={certificate}
            actions={props.actions}
            canUploadFiles={props.canUploadFiles}
          />
        ))}
      </span>
    </div>
  );
};

PreLicenseCertificateList.propTypes = {
  description: propTypes.string,
  certificates: propTypes.arrayOf(Certificate.propTypes.certificate).isRequired,
  actions: propTypes.shape(Certificate.propTypes.actions).isRequired,
  canUploadFiles: propTypes.bool,
};

PreLicenseCertificateList.defaultProps = {
  description: "",
};

export default PreLicenseCertificateList;
