import React, { useState, memo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Typography, Checkbox } from "v2/components";
import withStyle from "react-jss";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { Styles } from "./Styles";

const FilterBox = ({
  name,
  classes,
  onChange,
  list = [],
  heading,
  isSearch = false,
  placeholderText = "Search councils",
  isView,
}) => {
  const [showDetails, setShowDetails] = useState(isView);
  const [search, setSearch] = useState("");
  const [listData, setListData] = useState([...list]);
  useEffect(() => {
    if (search.length === 0) {
      setListData([...list]);
    }
  }, [list, search]);
  const onUpdate = ({ checked, name, key, value }) => {
    let newKey = key;
    const newListData = [...listData];
    if (isSearch) {
      const parseValue = parseInt(value);
      newKey = list.findIndex((val) => val.key === parseValue);
    }
    newListData[key].checked = checked;
    setListData([...newListData]);
    onChange({ checked, key: newKey, name });
  };
  const onSearch = useCallback(
    (value) => {
      setSearch(value);
      const newArray = [...list];
      setListData(value);
      if (value) {
        return setListData([
          ...newArray.filter((val) =>
            val.value.toLowerCase().includes(value.toLowerCase())
          ),
        ]);
      }
      setListData([...list]);
    },
    [setListData, list]
  );
  return (
    <div className={classes.main}>
      <div className="heading">
        <Typography as="h6" className="filter-heading">
          {heading}
        </Typography>
        {showDetails && (
          <ArrowUp onClick={() => setShowDetails(!showDetails)} />
        )}
        {!showDetails && (
          <ArrowDown onClick={() => setShowDetails(!showDetails)} />
        )}
      </div>
      {showDetails && (
        <>
          {isSearch && (
            <div className="search-input">
              <Input
                onChange={onSearch}
                value={search}
                type="text"
                placeholder={placeholderText}
              />
            </div>
          )}

          <div className="list-data">
            {listData.length > 0 &&
              listData.map((value, key) => (
                <div className="list-info" key={key}>
                  <Checkbox
                    value={value.key}
                    onChange={({ target: { checked, value } }) =>
                      onUpdate({
                        checked,
                        key,
                        name,
                        value,
                      })
                    }
                    checked={value.checked}
                    label={value.value}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

FilterBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  isSearch: PropTypes.bool,
  placeholderText: PropTypes.string,
  heading: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isView: PropTypes.bool.isRequired,
};
export default withStyle(Styles)(memo(FilterBox));
