import styles from "./MapModalGuide.module.css";
import { Typography } from "v2/components";
import ClickMap from "./mapModalImages/click.png";
import DoubleClickMap from "./mapModalImages/doubleclick.png";
import MovedMap from "./mapModalImages/moved.png";
import { ReactComponent as CrossIcon } from "../../../../../images/crossIcon.svg";
const MapGuideModal = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.closeIcon}>
        <CrossIcon onClick={props.onClose} />
      </div>
      <div className={styles.WrapSteps}>
        <div className={styles.steps}>
          <Typography as="list">
            Double click the pin to make it movable.
          </Typography>
          <img src={ClickMap} alt="Logo" />
        </div>

        <div className={styles.steps}>
          <Typography as="list">
            Move the Pin to the desired location within the circle.
          </Typography>
          <img src={DoubleClickMap} alt="Logo" />
        </div>
        <div className={styles.steps}>
          <Typography as="list">
            Release the pin to set the new location
          </Typography>
          <img src={MovedMap} alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default MapGuideModal;
