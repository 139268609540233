import React from "react";
import { InputDebounce } from "v2/components";
import PropTypes from "prop-types";

const OSAPIAutoComplete = (props) => {
  return (
    <InputDebounce
      name={props.name}
      placeholder={props.placeholder}
      delay={1000}
      value={props.value}
      onChange={props.onChange}
      autocomplete={false}
    />
  );
};

OSAPIAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

OSAPIAutoComplete.defaultProps = {
  value: "",
};

export default OSAPIAutoComplete;
