import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { SimpleButton } from "components";
import { Typography } from "v2/components";
import { mailObject } from "utils";
import { useStyles } from "./styles";

const ContactYuno = ({ emailData = {}, classes, className = "" }) => {
  const sendEmail = () => {
    window.open(mailObject(emailData), "_blank");
  };
  return (
    <div className={`${classes.main} ${className}`}>
      <div className="text-div">
        <Typography as="subtitle" className="main-text">
          Need help getting everything sorted?
        </Typography>
        <Typography className="sub-text">Talk to one of our experts</Typography>
      </div>
      <div>
        <SimpleButton onClick={sendEmail}>Get in touch</SimpleButton>
      </div>
    </div>
  );
};

ContactYuno.propTypes = {
  emailData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(useStyles)(memo(ContactYuno));
