import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  modal: {
    width: "100%",
    height: "100%",
    position: "fixed",
    maxHeight: "100vh",
    zIndex: "999",
    left: "0",
    top: "0",
    overflow: "auto",
    margin: "0 auto",
    background: "rgba(0, 0, 0)",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    paddingTop: ({ setting: { top } }) => top || "5rem",
  },
  content: {
    backgroundColor: ({ setting, theme: { colors } }) =>
      setting.color || colors.backgroundColor,
    maxHeight: "100vh",
    height: ({ setting: { height } }) => height,
    width: ({ setting: { width } }) => width,
    overflowY: "auto",
    borderRadius: 4,
  },
});
