export const style = ({ colors, fonts, breakpoints }) => {
  const inputField = {
    display: "flex",
    color: colors.hexaWhite,
    fontFamily: fonts.primary,
    fontWeight: 300,
    outline: "none",
    "-moz-appearance": "textfield",
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    [breakpoints.MOBILE_DEVICE]: {
      marginRight: 0,
      fontSize: 14,
    },
    "&::-webkit-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-moz-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-ms-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 16,
      fontWeight: "normal",
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "& :hover": {
      color: colors.skyBlue,
    },
  };
  return {
    inputField: {
      ...inputField,
      width: "432px",
      padding: "12px 0 12px 16px",
      fontSize: 16,
      fontWeight: "normal",
      border: "none",
      lineHeight: "24px",
      fontStyle: "normal",
      fontFamily: fonts.primary,
      color: colors.black,
      borderRadius: "4px",
      "&:read-only": {
        background: colors.cmyk,
      },
    },
    error: {
      border: `2px solid ${colors.red} !important`,
    },
    errorText: {
      color: colors.red,
      paddingTop: "6px",
      width: "436px",
      [breakpoints.MOBILE_DEVICE]: {
        width: "339px",
      },
    },
  };
};
