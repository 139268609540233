import { getWebInfo } from "Apis";
import { hamptons } from "theme/colors";
import { ADDWEBINFO } from "./constants";

export const getDomainInfo = () => (dispatch) => {
  const { hamptonsSecondary, primary } = hamptons;
  return new Promise((Resolve, Rejected) => {
    getWebInfo()
      .then(({ data }) => {
        if (data) {
          const {
            secondaryColour = hamptonsSecondary,
            primaryColour = primary,
            faviconUrl,
            logoUrl,
            name,
            embedded = false,
            leadgen = false,
            yunoDomain,
          } = data;
          const payload = {
            secondaryColour: secondaryColour || hamptonsSecondary,
            primaryColour: primaryColour || primary,
            faviconUrl,
            logoUrl,
            name,
            embedded,
            leadgen,
            yunoDomain,
          };
          dispatch({ type: ADDWEBINFO, payload });
          Resolve(payload);
        }
        Rejected(false);
      })
      .catch((err) => Rejected(err));
  });
};
