import { deleteLandlordInfo, getEmailTemplate, getLandlordInfo } from "Apis";
import { AnimationLoading, Checked, Modal } from "components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { ReactComponent as RemoveIcon } from "images/deleteProperty.svg";
import { ReactComponent as Pancel } from "images/pancel.svg";
import { ReactComponent as PlusIcon } from "images/plus.svg";
import { ReactComponent as EyesShow } from "images/show.svg";
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";
import withStyles from "react-jss";
import {
  checkAllRequiredFieldsWithKey,
  checkRequiredField,
  getLoginInfo,
  validateEmail,
} from "utils";
import { Button, Form, Input, Typography } from "v2/components";
import { formValues, setting } from "./constants";
import { Styles } from "./Styles";

const ApprovalModal = ({
  classes,
  onClose,
  onSuccess,
  isOpen,
  listDocument,
  className = "",
  total = "345",
  propertyId,
}) => {
  const { firstName = "", lastName = "" } = getLoginInfo();
  const [selectedList, setSelectedList] = useState([]);
  const [emailTemplates, setEmailTemplate] = useState({
    emailBody: "",
    subject: "",
  });
  const [form, setForm] = useState(formValues);
  const [formError, setFormError] = useState(formValues);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    const { certificates, licences, planning } = listDocument;
    const list = [];
    getLandlordInfo(propertyId)
      .then(({ data }) => {
        if (data && data.length > 0) {
          setFormError([...data.map(() => formValues[0])]);
          setForm([...data]);
        }
      })
      .catch();
    getEmailTemplate()
      .then(({ data: { template = "", subject = "" } }) => {
        setEmailTemplate({ emailBody: template, subject });
      })
      .finally(() => {
        setLoading(false);
      });
    licences.forEach((obj) => {
      if (obj.ordered) {
        list.push(`${obj.licenceType} Licence`);
      }
    });
    planning.forEach((obj) => {
      if (obj.ordered) {
        list.push(`${obj.label} Planning discovery`);
      }
    });
    certificates.forEach((obj) => {
      if (obj.isSelected) {
        list.push(obj.name);
      }
    });
    setSelectedList([...list]);
  }, [listDocument, setSelectedList, setEmailTemplate]);
  const addMore = (isAdd = true, index, id = 0) => {
    const existingForm = JSON.parse(JSON.stringify(form));
    const errorFormArray = JSON.parse(JSON.stringify(formError));
    if (isAdd) {
      existingForm.push(formValues[0]);
      errorFormArray.push(formValues[0]);
    } else {
      if (id !== 0) deleteLandlordInfo(propertyId, id).catch();
      existingForm.splice(index, 1);
      errorFormArray.splice(index, 1);
    }
    setForm([...existingForm]);
    setFormError([...errorFormArray]);
  };
  const handleInput = useCallback(
    (name, value, index) => {
      const formValue = JSON.parse(JSON.stringify(form));
      formValue[index][name] = value;
      setForm([...formValue]);
    },
    [form, setForm]
  );
  const checkError = (name, value, index) => {
    const formErrors = JSON.parse(JSON.stringify(formError));
    const checkError = { ...formErrors[index] };
    switch (name) {
      case "email":
        Object.assign(checkError, validateEmail(name, value));
        break;
      default:
        Object.assign(checkError, checkRequiredField(name, value));
        break;
    }
    formErrors[index] = checkError;
    setFormError([...formErrors]);
  };
  const removeError = (name, index) => {
    const formErrors = JSON.parse(JSON.stringify(formError));
    const checkError = { ...formErrors[index] };
    checkError[name] = "";
    formErrors[index] = { ...checkError };
    setFormError([...formErrors]);
  };
  const checkAllError = () => {
    const formValue = JSON.parse(JSON.stringify(form));
    const allErrors = [];
    let isError = true;
    formValue.forEach((value) => {
      const errorObject = {
        ...checkAllRequiredFieldsWithKey(formValues[0], value),
      };
      allErrors.push(errorObject);
      if (isError) {
        isError = Object.values(errorObject).every((errorMsg) => !errorMsg);
      }
    });
    setFormError([...allErrors]);
    return isError;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkAllError()) {
      onSuccess({
        landlords: [...form],
        ...emailTemplates,
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      setting={setting}
      className={`${classes.main} ${className}`}
    >
      <div className="approval-modal">
        <div className="header-div">
          <div className="text-heading"> Share property requirements</div>
          <CrossIcon onClick={onClose} onKeyDown={onClose} />
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="form">
            <Typography as="h6" className="form-heading">
              Who do you want to share this property with?
            </Typography>
            {form.map((val, key) => (
              <div key={`${key}${val}`}>
                {key > 0 && form.length !== key && (
                  <div>
                    <div
                      className="remove-form"
                      onClick={() => addMore(false, key, val.landlordId || 0)}
                      tabIndex="0"
                      onKeyPress={() => addMore(false, key)}
                      role="button"
                    >
                      <RemoveIcon className="icon" />
                      Remove
                    </div>
                    <hr className="line" />
                  </div>
                )}
                <div className="form-input">
                  <div className="input">
                    <Typography as="label" className="label">
                      First name
                    </Typography>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      error={formError[key].firstName}
                      value={val.firstName}
                      onChange={({ target: { name, value } }) =>
                        handleInput(name, value, key)
                      }
                      onFocus={({ target: { name } }) => removeError(name, key)}
                      onBlur={({ target: { name, value } }) =>
                        checkError(name, value, key)
                      }
                    />
                  </div>
                  <div className="input-right">
                    <Typography as="label" className="label">
                      Last name
                    </Typography>
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      error={formError[key].lastName}
                      value={val.lastName}
                      onChange={({ target: { name, value } }) =>
                        handleInput(name, value, key)
                      }
                      onFocus={({ target: { name } }) => removeError(name, key)}
                      onBlur={({ target: { name, value } }) =>
                        checkError(name, value, key)
                      }
                    />
                  </div>
                </div>
                <div className="input-full">
                  <Typography as="label" className="label">
                    Email Address
                  </Typography>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    error={formError[key].email}
                    value={val.email}
                    onChange={({ target: { name, value } }) =>
                      handleInput(name, value, key)
                    }
                    onFocus={({ target: { name } }) => removeError(name, key)}
                    onBlur={({ target: { name, value } }) =>
                      checkError(name, value, key)
                    }
                  />
                </div>
              </div>
            ))}

            <div
              className="add-more"
              onClick={addMore}
              role="button"
              onKeyPress={addMore}
              tabIndex="0"
            >
              <PlusIcon className="icon" />
              <span className="add-more-text"> Add another</span>
            </div>
          </div>
          <div className="email-preview">
            <div className="email-preview-edit">
              <Typography as="h6" className="email-preview-text">
                Email preview
              </Typography>
              <div
                className="edit-div"
                role="button"
                tabIndex="0"
                onKeyPress={() => setIsEdit(!isEdit)}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? <div className="tick" /> : <Pancel />}
                <span className="edit">
                  {isEdit ? "Save changes" : "Edit message"}
                </span>
              </div>
            </div>
            <div className="email-content">
              <div className="name">{`Hello ${form[0].firstName},`}</div>

              <div className="dynamic-data">
                {loading ? (
                  <>
                    <AnimationLoading
                      height="15px"
                      width="500px"
                      className="animation"
                    />
                    <AnimationLoading
                      height="15px"
                      width="300px"
                      className="animation"
                    />
                  </>
                ) : (
                  <textarea
                    value={emailTemplates.emailBody}
                    disabled={!isEdit}
                    onChange={({ target: { value } }) =>
                      setEmailTemplate({ ...emailTemplates, emailBody: value })
                    }
                  />
                )}
              </div>
              <div className="list">
                {selectedList.map((val, key) => (
                  <div className="data" key={key}>
                    {`- ${val}`}
                  </div>
                ))}
              </div>
              <div className="total-list">
                This will cost <span className="price">{total}</span>
                incl. VAT
              </div>
              <div className="buttons">
                <div className="email-button left-button">
                  <Checked className="icon-button" />
                  Approve work order
                </div>
                <div className="email-button">
                  <EyesShow className="icon-button" />
                  View details
                </div>
              </div>
              <div className="footer">
                <div>
                  If you have any questions, please don’t hesitate to repond to
                  this email{" "}
                </div>
                <div>Kind regards, </div>
                <div>{`${firstName} ${lastName}`}</div>
              </div>
            </div>
          </div>
          <div className="footer-text">
            <div className="footer-heading">
              You will receive a copy of this email when you send it.
            </div>

            <div className="footer-subheading">
              Once you share this property, the person you share it with will be
              able to view all property details and requirements for this
              specific property and register to approve work orders.
            </div>
          </div>
          <div className="button-group">
            <Button onClick={onClose} variant="secondary" actionName="Cancel" />
            <Button type="submit" disabled={isEdit} actionName="Send email" />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

ApprovalModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  total: PropTypes.string,
  listDocument: PropTypes.object.isRequired,
  propertyId: PropTypes.number.isRequired,
};

export default withStyles(Styles)(memo(ApprovalModal));
