export const style = ({ colors, fonts, breakpoints }) => {
  const inputField = {
    display: "flex",
    color: colors.hexaWhite,
    fontFamily: fonts.primary,
    fontWeight: 300,
    outline: "none",
    [breakpoints.MOBILE_DEVICE]: {
      width: "20px",
      marginRight: 0,
      fontSize: 14,
    },
    "&::-webkit-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-moz-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-ms-input-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 18,
      fontWeight: 300,
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "&::-placeholder": {
      color: colors.grey40,
      fontFamily: fonts.primary,
      fontSize: 16,
      fontWeight: "normal",
      [breakpoints.MOBILE_DEVICE]: {
        fontSize: 14,
      },
    },
    "& :hover": {
      color: colors.skyBlue,
    },
  };
  return {
    inputField: {
      ...inputField,
      width: "20px",
      padding: "12px 0 12px 16px",
      fontSize: 16,
      fontWeight: "normal",
      border: "none",
      lineHeight: "24px",
      fontStyle: "normal",
      fontFamily: fonts.primary,
      color: colors.black,
    },
    error: {
      border: `2px solid ${colors.red} !important`,
    },
    errorText: {
      color: colors.red,
      paddingTop: "6px",
      width: "20px",
      [breakpoints.MOBILE_DEVICE]: {
        width: "339px",
      },
    },
    disabled: {
      pointerEvents: "none",
      background: colors.cmyk,
    },
    radioDiv: {
      display: "flex",
      position: "relative",
      "& .checkmark": {
        display: "block",
        position: "absolute",
        border: "2px solid #AAAAAA",
        borderRadius: "100px",
        height: "16px",
        width: "16px",
        zIndex: "5",
        transition: "border .25s linear",
        "-webkit-transition": "border .25s linear",
        "&::before": {
          display: "block",
          position: "absolute",
          content: "no-close-quote",
          borderRadius: "100px",
          height: "10px",
          width: "10px",
          top: "3px",
          left: "3px",
          margin: "auto",
          transition: " background 0.25s linear",
          "-webkit-transition": "background 0.25s linear",
        },
      },
      "& input": {
        position: "absolute",
        opacity: 0,
        top: "-7px",
        zIndex: "9",
        cursor: "pointer",
        "&:read-only": {
          background: colors.cmyk,
        },
        "&:checked ~.checkmark": {
          border: `2px solid ${colors.primary}`,
          "&:before": {
            background: colors.primary,
          },
        },
      },
      "& label": {
        display: "block",
        position: "relative",
        cursor: "pointer",
        "-webkit-transition": "all 0.25s linear",
        margin: "0 42px 0 33px",
        lineHeight: "20px",
        fontStyle: "normal",
        fontFamily: fonts.primary,
        fontWeight: "normal",
        fontSize: "16px",
        "&:hover": {
          color: colors.primary,
        },
      },
    },
  };
};
