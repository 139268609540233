import { createUseStyles } from "react-jss";
import Theme from "theme";
import { checkWebInIframe } from "utils";

const { breakpoints } = Theme;
export const useStyles = createUseStyles({
  licences: {
    "& .license": {
      marginBottom: "2rem",
      padding: "2rem",
      [breakpoints.RESPONSIVE]: {
        padding: "2rem 1rem",
      },
    },
    "& .subtitle": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "22px",
      color: ({ colors: { accountLabel } }) => accountLabel,
    },
    "& .heading-text": {
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "18px",
      lineHeight: "26px",
      "margin-bottom": "1rem",
      color: ({ colors: { black } }) => black,
      [breakpoints.RESPONSIVE]: {
        "& svg > path": {
          stroke: ({ colors: { black } }) => black,
        },
        width: "100%",
      },
    },
    [breakpoints.RESPONSIVE]: {
      height: "auto",
    },
    "& .whiteBox": {
      padding: "2rem",
    },
  },

  list: {
    display: "flex",
    flexDirection: "column",
    whiteSpace: "break-spaces",
    color: ({ colors: { labelColor } }) => labelColor,
    "margin-bottom": "1rem",
    "& .licence-contents": {
      display: "flex",
      flexDirection: "row",
      fontSize: "16px",
      lineHeight: "24px",
      fontStyle: "normal",
    },
    "& span": {
      minHeight: "20px",
      height: "auto",
      marginRight: "16px",
      border: ({ colors: { midGrey } }) => `2px solid ${midGrey}`,
    },
  },
  links: {
    "& .modalContainer": {
      display: "flex",
      flexDirection: checkWebInIframe ? "column" : "row",
    },
    "& span": {
      display: "flex",
      width: checkWebInIframe ? "104px" : "150px",
      flexWrap: checkWebInIframe ? "wrap" : "",
      whiteSpace: "nowrap",
      color: ({ colors: { primary } }) => primary,
    },
  },
  selectSponsor: {
    display: "flex",
    width: "100%",
    padding: "1rem",
    marginBottom: "2rem",
    border: "1px solid",
    borderColor: ({ colors: { primary } }) => primary,
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    [breakpoints.MOBILE_DEVICE]: {
      height: "64px",
      zIndex: "2",
      width: "100%",
      position: "fixed",
      left: "0",
      right: "0",
      bottom: "65px",
      "& button": {
        width: "100%",
        margin: "8px 16px",
      },
    },
  },
  mainDiv: {
    [breakpoints.MOBILE_DEVICE]: {
      padding: "24px",
      boxSizing: " border-box",
    },
    [breakpoints.IPAD]: {
      padding: "24px",
      boxSizing: " border-box",
    },
  },
  error: {
    paddingTop: "30px",
    marginLeft: "32px",
    minHeight: "120px",
    height: "auto",
  },
});
