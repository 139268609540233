import { userPhoneNumberURL, userLogoutURL } from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { POST } from "utils";

type UserActions = {
  updatePhoneNumber: APIAction<string, boolean>;
  logoutUserRequest: APIAction<string, boolean>;
};

type UserAuthentication = {
  phoneNumber: string | null;
};

export const useApiUserActions = (): UserActions => {
  const updatePhoneNumberRequest = async (phoneNumber: string) => {
    const response = await POST(userPhoneNumberURL(), { phoneNumber });
    if (!response) {
      const customer = JSON.parse(
        localStorage.getItem("authUser") || ""
      ) as UserAuthentication;
      customer.phoneNumber = phoneNumber;
      localStorage.setItem("authUser", JSON.stringify(customer));
    }
    return response;
  };

  const logoutUserRequest = async () => {
    const response = await POST(userLogoutURL());
    return response;
  };

  const updatePhoneNumberPromise = useAsync<
    APIActionResponse<string>,
    APIActionError
  >(updatePhoneNumberRequest, false);

  const logoutUserPromise = useAsync<APIActionResponse<string>, APIActionError>(
    logoutUserRequest,
    false
  );

  return {
    updatePhoneNumber: {
      trigger: updatePhoneNumberPromise.execute,
      state: updatePhoneNumberPromise.status === "pending" ? "loading" : "idle",
      error: updatePhoneNumberPromise.error?.errorDetails,
      result: updatePhoneNumberPromise.status === "success",
    },
    logoutUserRequest: {
      trigger: logoutUserPromise.execute,
      state: logoutUserPromise.status === "pending" ? "loading" : "idle",
      error: logoutUserPromise.error?.errorDetails,
      result: logoutUserPromise.status === "success",
    },
  };
};
