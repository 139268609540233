import { AnimationLoading, ErrorMessage, Links, WhiteBox } from "components";
import useModal from "Hooks/useModal";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import propTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { withTheme } from "react-jss";
import { emptyObject, minDeskTopWidth, useWindowDimensions } from "utils";
import { Typography } from "v2/components";
import AlertSponsorBox from "v2/components/AlertSponsorBox/AlertSponsorBox";
import Markdown from "v2/components/Markdown/Markdown";
import TooltipModal from "v2/components/ModalV2/TooltipModal/TooltipModal";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  hmoDefinition,
  licensingDefinition,
  planningDefinition,
} from "./constants";
import { useStyles } from "./Styles";
const LicencesList = ({
  theme,
  licences,
  apiError,
  loading,
  canViewPremiumData,
  openSponsorModal,
  formRef,
  propertyDetails,
  planningPage,
  licensingPage,
  ...props
}) => {
  const { width } = useWindowDimensions();
  const classes = useStyles(theme);
  const history = useHistory();
  const { url } = useRouteMatch();

  const [isHMOModalOpen, openHMOModal, closeHMOModal] = useModal();
  const [isLicensingModalOpen, openLicensingModal, closeLicensingModal] =
    useModal();
  const [isPlanningModalOpen, openPlanningModal, closePlanningModal] =
    useModal();

  const goToLicensing = () => {
    if (!propertyDetails.propertyType) {
      formRef.current.onMenuOpen();
      return;
    }
    history.push(`${url.replace("/property-info", "")}/journey/licensing`);
  };
  const { councilGuidelines = [] } = licences;
  const [licencesList, setLicencesList] = useState(councilGuidelines);
  useEffect(() => {
    setLicencesList(licencesList);
  }, [licences]);
  const showData = (index, show) => {
    const updateKey = [...councilGuidelines];
    updateKey[index].showData = show;
    setLicencesList([...updateKey]);
  };

  return (
    <div className={classes.licences}>
      {isHMOModalOpen && (
        <TooltipModal content={hmoDefinition} onClose={closeHMOModal} />
      )}
      {isLicensingModalOpen && (
        <TooltipModal
          content={licensingDefinition}
          onClose={closeLicensingModal}
        />
      )}
      {isPlanningModalOpen && (
        <TooltipModal
          content={planningDefinition}
          onClose={closePlanningModal}
        />
      )}

      {licensingPage && (
        <>
          <div className={classes.list}>
            {Object.prototype.hasOwnProperty.call(
              licencesList[0],
              "guidelineMarkdown"
            ) &&
              canViewPremiumData && (
                <Markdown information={licencesList[0].guidelineMarkdown} />
              )}
          </div>

          <div className={classes.links}>
            {!canViewPremiumData && (
              <AlertSponsorBox onClick={openSponsorModal} />
            )}
            <div className="modalContainer">
              <Links as="click" onClick={openHMOModal}>
                What is an HMO?
              </Links>
              <Links as="click" onClick={openLicensingModal}>
                What is Licensing?
              </Links>
            </div>
            <div className="modalContainer">
              <Links as="click" onClick={goToLicensing}>
                Which licence does my property need?
              </Links>
            </div>
          </div>
        </>
      )}
      {planningPage && (
        <>
          <div className={classes.list}>
            {Object.prototype.hasOwnProperty.call(
              licencesList[1],
              "guidelineMarkdown"
            ) &&
              canViewPremiumData && (
                <Markdown information={licencesList[1].guidelineMarkdown} />
              )}
          </div>
          <div className={classes.links}>
            {!canViewPremiumData && (
              <AlertSponsorBox onClick={openSponsorModal} />
            )}
            <div className="modalContainer">
              <Links as="click" onClick={openPlanningModal}>
                What is Planning?
              </Links>
            </div>
          </div>
        </>
      )}
      {!planningPage &&
        !licensingPage &&
        licencesList.map((value, keys) => (
          <React.Fragment key={keys}>
            <WhiteBox height="204" className="license">
              <div className={classes.licencesHeading}>
                <Typography as="h6License">
                  {value.title}
                  {width < minDeskTopWidth && !value.showData && (
                    <ArrowDown onClick={() => showData(keys, true)} />
                  )}
                  {width < minDeskTopWidth && value.showData && (
                    <ArrowUp onClick={() => showData(keys, false)} />
                  )}
                </Typography>
              </div>

              <div className={classes.list}>
                {Object.prototype.hasOwnProperty.call(
                  value,
                  "guidelineMarkdown"
                ) &&
                  canViewPremiumData && (
                    <Markdown information={value.guidelineMarkdown} />
                  )}
              </div>

              {keys === 0 ? (
                <>
                  <div className={classes.links}>
                    {!canViewPremiumData && (
                      <AlertSponsorBox onClick={openSponsorModal} />
                    )}
                    <div className="modalContainer">
                      <Links as="click" onClick={openHMOModal}>
                        What is an HMO?
                      </Links>
                      <Links as="click" onClick={openLicensingModal}>
                        What is Licensing?
                      </Links>
                    </div>
                    <div className="modalContainer">
                      <Links as="click" onClick={goToLicensing}>
                        Which licence does my property need?
                      </Links>
                    </div>
                  </div>
                </>
              ) : (
                <div className={classes.links}>
                  {!canViewPremiumData && (
                    <AlertSponsorBox onClick={openSponsorModal} />
                  )}
                  <div className="modalContainer">
                    <Links as="click" onClick={openPlanningModal}>
                      What is Planning?
                    </Links>
                  </div>
                </div>
              )}
            </WhiteBox>
          </React.Fragment>
        ))}
      {loading && (
        <WhiteBox height="204" className="license">
          <div className={classes.licencesHeading}>
            <AnimationLoading height="25px" width="90%" />
          </div>
          <div className={classes.list}>
            <div className="licence-contents">
              <span />
              <AnimationLoading height="15px" />
            </div>
            <div className="licence-contents">
              <span />
              <AnimationLoading height="15px" />
            </div>
          </div>
        </WhiteBox>
      )}
      {emptyObject(licences) && !loading && (
        <WhiteBox height="204">
          <ErrorMessage
            apiError={apiError}
            className={classes.error}
            message="No licence found matching this postcode"
            subTitle="Please check you have entered a London address or postcode"
          />
        </WhiteBox>
      )}
    </div>
  );
};

LicencesList.propTypes = {
  theme: propTypes.object.isRequired,
  licences: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
  apiError: propTypes.object,
};
export default withTheme(memo(LicencesList));
