import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { style } from "./styles";

export const Radio = React.forwardRef(
  (
    {
      classes,
      name,
      label,
      id,
      checked,
      onChange,
      value,
      className,
      error,
      readOnly = false,
    },
    ref
  ) => (
    <div
      className={`${classes.radioDiv} ${className} ${
        readOnly ? classes.disabled : ""
      }`}
    >
      <input
        type="radio"
        readOnly={readOnly}
        id={id}
        name={name}
        checked={checked}
        className={`${classes.inputField} ${error ? classes.error : null}`}
        value={value}
        onChange={onChange}
        ref={ref}
      />
      <span className={`${readOnly ? "disabled" : ""} checkmark`} />
      <label htmlFor={id}>{label}</label>
      {error && <span className={classes.errorText}>{error}</span>}
    </div>
  )
);

Radio.propTypes = {
  name: propTypes.string,
  checked: propTypes.bool,
  classes: propTypes.object,
  onChange: propTypes.func,
  className: propTypes.string,
  id: propTypes.string.isRequired,
  readOnly: propTypes.bool,
};

Radio.defaultProps = {
  name: "name",
  classes: {},
  onChange: () => null,
  value: "",
  className: "",
};

export default withStyles(style)(Radio);
