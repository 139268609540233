import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Button, Links } from "components";
import { Input, Typography } from "v2/components";
import { ReactComponent as HelpIcon } from "images/help.svg";
import Styles from "./styles";

const Address = ({
  values,
  errors,
  classes,
  onClick,
  onChange,
  onBlur,
  onFocus,
  newSearch = () => null,
  className = "",
  startNew = false,
  showPopup = () => {},
  isModel = false,
  buttonText = "Find address",
}) => (
  <div className={classes.form}>
    <div className="input-div">
      <Typography as="label" className={classes.label}>
        Flat Number (if applicable)
      </Typography>
      <Input
        name="flatName"
        onFocus={onFocus}
        onChange={onChange}
        value={values.flatName}
        placeholder="Flat Number (if applicable)"
      />
    </div>
    <div className="input-div">
      <Typography as="label" className={classes.label}>
        Building Name (if applicable)
      </Typography>
      <Input
        name="buildingName"
        onFocus={onFocus}
        onChange={onChange}
        value={values.buildingName}
        placeholder="Building Name (if applicable)"
      />
    </div>
    {!isModel && (
      <div className="input-div">
        <Typography as="label" className={classes.label}>
          <div className="label-icon">
            <span>UPRN</span>
            <HelpIcon onClick={showPopup} />
          </div>
        </Typography>
        <Input
          name="uprn"
          value={values.uprn}
          onChange={onChange}
          placeholder="UPRN"
        />
      </div>
    )}
    <div className="input-div">
      <Typography as="label" className={classes.label}>
        Building Number
      </Typography>
      <Input
        name="buildingNumber"
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={values.buildingNumber}
        error={errors.buildingNumber}
        placeholder="Building Number"
      />
    </div>
    <div className="input-div">
      <Typography as="label" className={classes.label}>
        Street Name
      </Typography>
      <Input
        name="address_line_one"
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={values.address_line_one}
        error={errors.address_line_one}
        placeholder="Street Name"
      />
    </div>

    <div className="input-div">
      <Typography as="label" className={classes.label}>
        City
      </Typography>
      <Input
        name="town"
        value={values.town}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        error={errors.town}
        placeholder="City"
      />
    </div>

    <div className="input-div">
      <Typography as="label" className={classes.label}>
        Postcode
      </Typography>
      <Input
        name="postcode"
        value={values.postcode}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        error={errors.postcode}
        placeholder="Postcode"
      />
    </div>
    <div className={classes.button}>
      {startNew && (
        <Links as="click" className="address-link" onClick={newSearch}>
          Start new search
        </Links>
      )}
      <Button className={className} onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  </div>
);

Address.propTypes = {
  classes: propTypes.object,
  className: propTypes.string,
  onClick: propTypes.func.isRequired,
  onBlur: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  onFocus: propTypes.func.isRequired,
  buttonText: propTypes.string,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  startNew: propTypes.bool,
  newSearch: propTypes.func,
  showPopup: propTypes.func,
  isModel: propTypes.bool,
};
export default withStyles(Styles)(Address);
