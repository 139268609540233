export const styles = ({ colors, fonts, breakpoints }) => ({
  leadgen: {
    alignItems: "center",
    "& .contents": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: colors.hexaWhite,
    },
    [breakpoints.MOBILE_DEVICE]: {
      padding: "0 !important",
      "& .contents": {
        width: "100% !important",
        margin: "0 !important",
        height: "100vh !important",
        top: "0",
      },
    },
  },
  main: {
    fontFamily: fonts.primary,
    alignItems: "center",
    fontStyle: "normal",
    padding: "2rem 1rem 2rem 1rem",
    "& .heading": {
      width: "100%",
      height: "auto",
      display: "flex",
      "& .content": {
        display: "flex",
        justifyContent: "center",
        "& svg": {
          width: "2rem",
          height: "auto",
        },
        "& .heading-text": {
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "1.1rem",
          lineHeight: "24px",
          alignItems: "center",
          height: 54,
          letterSpacing: "0.002em",
          color: colors.black,
        },
      },
    },
    "& .form": {
      marginTop: "2rem",
      "& .form-control": {
        width: "100%",
        marginBottom: "1rem",
        position: "relative",

        "& .label": {
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "15px",
          lineHeight: "24px",
          letterSpacing: "0.002em",
          color: colors.labelColor,
          marginBottom: "14px",
        },
      },
      "& .checkbox": {
        display: "flex",
        flexDirection: "row",
        marginTop: "2rem",
        "& a": {
          color: colors.primary,
        },
        "& :nth-child(1)": {
          width: "unset",

          marginRight: "1rem",
        },

        "& .checkbox-text": {
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "12px",
          lineHeight: "14px",
          letterSpacing: "0.01em",
          color: colors.black,
          alignSelf: "center",
        },
        "& input": {
          width: "20px !important",
        },
      },
    },
    "& .submit-button": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "3rem",
      "& .cancel-button": {
        backgroundColor: colors.hexaWhite,
        color: colors.hamptonsSecondary,
        border: `2px solid ${colors.inputGrey}`,
        boxSizing: "border-box",
        borderRadius: "4px",
        marginRight: "10px",
        [breakpoints.MOBILE_DEVICE]: {
          margin: "0",
        },
      },
      "& button": {
        width: 104,
        height: 45,
        backgroundColor: colors.hamptonsSecondary,
        color: colors.hexaWhite,
        [breakpoints.MOBILE_DEVICE]: {
          marginTop: "10px",
        },
      },
      [breakpoints.MOBILE_DEVICE]: {
        position: "fixed",
        justifyContent: "center",
        flexDirection: "column-reverse",
        alignItems: "center",
        width: "100%",
      },
    },
  },
  section: {
    margin: "24px 0",
    "& .list-view": {
      paddingTop: "8px",
    },
  },
  bottomSection: {
    "& .number-view> div": {
      paddingBottom: 10,
    },
  },
});
