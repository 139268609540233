import propTypes from "prop-types";

import Select from "react-select";
import Label from "../Label";
import "./react-select.css";
import { optionsType } from "./SelectInputTypes";
const SelectInput = ({
  name,
  variant,
  onChange,
  onBlur,
  onFocus,
  error,
  readOnly = false,
  isClearable = false,
  size,
  options = [],
  classes,
  inputRef,
  id,
  ...props
}) => {
  return (
    <Label
      onInputTip={props.onInputTip}
      error={props.error}
      label={props.label}
    >
      <Select
        id={id}
        ref={inputRef}
        placeholder={props.placeholder}
        className={variant}
        classNamePrefix={variant}
        defaultValue={props.defaultValue}
        options={options}
        isDisabled={readOnly}
        isClearable={isClearable}
        onChange={props.onOptionChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
    </Label>
  );
};

SelectInput.propTypes = {
  onOptionChange: propTypes.func,
  options: optionsType.isRequired,
  onChange: propTypes.func,
  classes: propTypes.object,
  listClassName: propTypes.string,
  readOnly: propTypes.bool,
  variant: propTypes.oneOf(["react-select", "secondary"]),
};

SelectInput.defaultProps = {
  variant: "react-select",
  label: "",
};

export default SelectInput;
