import { createUseStyles } from "react-jss";
import Theme from "theme";

const { breakpoints } = Theme;
export const useStyles = createUseStyles({
  main: {
    width: "416px",
    [breakpoints.RESPONSIVE]: {
      width: "100%",
    },
    backgroundColor: ({ colors: { hexaWhite } }) => hexaWhite,
    minHeight: "132px",
    height: "auto",
    "& .address-line": {
      border: ({ colors: { primary } }) => `2px solid ${primary}`,
      borderRadius: "4px 4px 0px 0px",
    },
    "&  .content": {
      padding: "27px 0 0 24px",
    },
  },
  locationIcon: {
    margin: "0 23.5px 0 3px",
    "& svg": {
      marginRight: "23.5",
    },
    "& path": {
      stroke: ({ colors: { black } }) => black,
    },
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    "& .heading": {
      color: ({ colors: { black } }) => black,
    },
    "& path": {
      stroke: ({ colors: { black } }) => black,
    },
    [breakpoints.RESPONSIVE]: {
      width: "100%",
    },
    [breakpoints.MOBILE_DEVICE]: {
      width: "87%",
    },
    "& svg": {
      marginRight: "29px",
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
    },
  },
  headingDiv: {
    display: "flex",
  },
  buttonDiv: {
    marginTop: "24px",
    paddingBottom: "24px",
    "& .link": {
      marginLeft: "32px",
      color: ({ colors: { hamptonsSecondary } }) => hamptonsSecondary,
    },
    "& button": {
      padding: "0px",
      color: ({ colors: { hamptonsSecondary } }) => hamptonsSecondary,
      border: ({ colors: { hamptonsSecondary } }) =>
        `1px solid${hamptonsSecondary}`,
    },
  },
  addressList: {
    margin: "16px 29px 0 46px",
    color: ({ colors: { black } }) => black,
    "& u": {
      textDecorationLine: "none",
      "& .span": {
        paddingLeft: "22px",
      },
      "& .firstSpan": {
        paddingLeft: "0",
      },
    },
    "& li": {
      paddingTop: "8px",
    },
  },
});
