import mixpanel from "mixpanel-browser";

const debugMode = {
  development: true,
  production: false,
};

const developmentMode = {
  development: true,
  production: true,
};
const isDevEnv = developmentMode[process.env.NODE_ENV];
const actions = {
  init: () => {
    if (isDevEnv && process.env.REACT_APP_MIXPLANTOKEN)
      mixpanel.init(process.env.REACT_APP_MIXPLANTOKEN, {
        debug: debugMode[process.env.NODE_ENV],
        ignore_dnt: true,
      });
  },
  identify: (id) => {
    if (isDevEnv && process.env.REACT_APP_MIXPLANTOKEN) mixpanel.identify(id);
  },
  alias: (id) => {
    if (isDevEnv && process.env.REACT_APP_MIXPLANTOKEN) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (isDevEnv && process.env.REACT_APP_MIXPLANTOKEN)
      mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (isDevEnv && process.env.REACT_APP_MIXPLANTOKEN)
        mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (!isDevEnv && process.env.REACT_APP_MIXPLANTOKEN) mixpanel.reset();
  },
};
export default actions;
