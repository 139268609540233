import { useDomainAction } from "Apis/YunoApi/useApiDomainActions";
import { useApiPackagesActions } from "Apis/YunoApi/useApiPackagesActions";
import { useApiUserActions } from "Apis/YunoApi/useApiUserActions";
import { propertyType } from "constants";
import useModal from "Hooks/useModal";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { checkWebInIframe, getLoginInfo } from "utils";
import { PhoneNumberModal } from "v2/components/ModalV2/PhoneNumberModal/PhoneNumberModal";
import { Spinner } from "v2/components/Spinner/Spinner";
import { userRoles } from "./environmentConstants";

const IFrameContext = createContext();
const UserContext = createContext();
const CustomerContext = createContext();
const PackagesContext = createContext();

export function useIsInIframeCheck() {
  return useContext(IFrameContext);
}

export function useUserData() {
  return useContext(UserContext);
}

export function useCustomer() {
  return useContext(CustomerContext);
}

export function usePackages() {
  return useContext(PackagesContext);
}
export function EnvironmentProvider(props) {
  const inIframe = checkWebInIframe;

  const history = useHistory();

  const getLoginInfoCallback = () => {
    const loginInfo = getLoginInfo();
    return {
      ...getLoginInfo(),
      isCustomerUser:
        loginInfo.role === userRoles.customerUser || loginInfo.role === "",
      isOperator: loginInfo.role === userRoles.operator,
      isBusinessUser: loginInfo.role === userRoles.business,
    };
  };
  const [user, setUser] = useState(getLoginInfoCallback);
  const { getDomainInfo } = useDomainAction();
  const { getPackages } = useApiPackagesActions();
  const { updatePhoneNumber } = useApiUserActions();
  const [isPhoneNumberModal, openPhoneModal, closePhoneModal] = useModal(
    !!user.userId && !user.phoneNumber
  );
  const [propertyType, setPropertyType] = useState();

  useEffect(() => {
    getDomainInfo.trigger();
  }, []);

  useEffect(() => {
    if (propertyType) {
      getPackages.trigger(propertyType);
    } else {
      getPackages.trigger();
    }
  }, [propertyType]);

  useEffect(() => {
    return history.listen(() => {
      //This so we can upload the user data when there is a url change
      //in this case it's useful for when the user logins and gets redirect to the front page
      setUser(getLoginInfo);
    });
  }, []);

  useEffect(() => {
    if (user.userId && !user.phoneNumber) {
      openPhoneModal();
    }
  }, [user]);

  useEffect(() => {
    if (updatePhoneNumber.result && updatePhoneNumber.state === "idle") {
      closePhoneModal();
    }
  }, [updatePhoneNumber.result]);
  if (!getDomainInfo.result) {
    return <Spinner />;
  }
  return (
    <IFrameContext.Provider value={inIframe}>
      <UserContext.Provider value={user}>
        <CustomerContext.Provider value={getDomainInfo.result}>
          <PackagesContext.Provider
            value={[getPackages.result, propertyType, setPropertyType]}
          >
            {isPhoneNumberModal &&
              window.location.pathname !== "/start-search" &&
              !inIframe && (
                <PhoneNumberModal
                  onClose={closePhoneModal}
                  error={updatePhoneNumber.error}
                  onSubmit={updatePhoneNumber.trigger}
                />
              )}
            {updatePhoneNumber.state === "loading" && <Spinner />}
            {props.children}
          </PackagesContext.Provider>
        </CustomerContext.Provider>
      </UserContext.Provider>
    </IFrameContext.Provider>
  );
}
