import {
  usePropertyAllowedActions,
  usePropertyCertificateActions,
  usePropertyDetails,
} from "context/propertyContext/PropertyContext";
import propTypes from "prop-types";
import { AlertBox, Typography } from "v2/components";
import Flex from "v2/components/Layout/Flex";
import Certificate from "v2/components/PreLicenceList/PreLicense/Certificate";
import JourneyLayout from "../../JourneyLayout/JourneyLayout";

const Certificates = (props) => {
  const propertyDetails = usePropertyDetails();
  const certificateActions = usePropertyCertificateActions();
  const allowedPropertyActions = usePropertyAllowedActions();
  return (
    <JourneyLayout
      name={props.name}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      nextName={props.nextName}
    >
      <Flex direction="column">
        <Typography>
          Based on the information you have provided this is the most likely
          certification and documentation you will require.
        </Typography>
        <br />
        <Typography>
          The requirement for some of the items listed will be based on a risk
          assessment. For example, a Fire Safety Risk Assessment will specify
          the grade and location of the required alarm system and fire doors.
        </Typography>
        <br></br>
        {propertyDetails.certificates.map((certificate) => (
          <Certificate
            key={certificate.id}
            actions={certificateActions}
            certificate={certificate}
            canUploadFiles={allowedPropertyActions.canViewPremiumData}
          />
        ))}
        {propertyDetails.certificates.length === 0 && (
          <AlertBox onClick={() => console.log("YOU WILL BE REDIRECT")}>
            <Flex direction="column">
              <Typography as="label">
                Required certification is dependant on who is living in the
                property.
              </Typography>
              <Typography as="label">
                Add tenant information to view certification
              </Typography>
            </Flex>
          </AlertBox>
        )}
      </Flex>
    </JourneyLayout>
  );
};
Certificates.propTypes = {
  onNext: propTypes.func,
  onPrevious: propTypes.func,
};
export default Certificates;
