export const Styles = ({ colors, fonts, breakpoints }) => ({
  navBar: {
    color: colors.labelColor,
    display: "flex",
    justifyContent: "center",
    fontFamily: fonts.primary,
    fontStyle: "normal",
    fontWeight: "400",
    alignItems: "center",
    padding: "0 20px",
    top: "10px",
    left: 0,
    "& .heading": {
      fontWeight: "600",
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: "0.002em",
      color: colors.grey2,
      "& a": {
        color: colors.grey2,
        textDecoration: "none",
        paddingRight: "15px",
      },
    },
    "& .address": {
      color: colors.labelColor,
      paddingLeft: "15px",
      [breakpoints.IFRAMERESPONSIVE]: {
        paddingLeft: 0,
      },
    },
    "& .small-address": {
      fontSize: "11px",
      wordBreak: "break-word",
    },
  },
  hDivider: {
    paddingLeft: "0.4rem",
  },
  viewDetails: {
    color: colors.primary,
    marginLeft: "0.4rem",
    cursor: "pointer",
    outline: "none",
    [breakpoints.MOBILE_DEVICE]: {
      marginLeft: 10,
      "& .view-icon": {
        position: "fixed",
        right: 0,
        marginRight: "25px",
        top: 0,
        marginTop: "33px",
      },
    },
  },
});
