import { Typography } from "v2/components";
import Flex from "v2/components/Layout/Flex";
import { formatter } from "yuno-formatting";
import { CompareRents } from "./AnalyticsCompoenents/CompareRents/CompareRents";
import styles from "./PropertyInfo.module.css";
const Overview = (props) => {
  const uprnTitles = props.analytics["uprn-title"];
  const titleInfo = props.analytics["title-info"];
  return (
    <>
      {/* TODO hide info (can view premium data) */}
      <span className={styles.title}>
        <Typography as="h3">Overview</Typography>
      </span>
      <Flex direction="column">
        <Typography as="propertyInfoAnalytics">Address</Typography>
        <Typography as="propertyInfoAnalyticsData">
          {formatter.formatShortAddress(props.propertyDetails)}
        </Typography>
      </Flex>
      <Flex direction="column">
        <Typography as="propertyInfoAnalytics">UPRN</Typography>
        <Typography as="propertyInfoAnalyticsData">{` ${props.propertyDetails.UPRN}`}</Typography>
      </Flex>
      <Flex direction="column">
        <Typography as="propertyInfoAnalytics">Property Type </Typography>
        <Typography as="propertyInfoAnalyticsData">{` ${
          props.propertyDetails.propertyType ||
          " set the property type on the form"
        }`}</Typography>
      </Flex>
      {uprnTitles.map((uprnTitle) => (
        <Flex direction="column">
          <Typography as="propertyInfoAnalytics">
            {"Latest Title Number"}
          </Typography>
          <Typography as="propertyInfoAnalyticsData">
            {` ${uprnTitle.data.data.title_data[0].title_number} - ${uprnTitle.data.data.title_data[0].title_class}`}
          </Typography>
        </Flex>
      ))}
      {titleInfo.map((title) => (
        <>
          <Flex direction="column">
            <Typography as="propertyInfoAnalytics">Last Sold Price</Typography>
            <Typography as="propertyInfoAnalyticsData">
              {title.data.data.last_sold.amount}
            </Typography>

            {title.data.data.last_sold.amount === null && (
              <Typography as="propertyInfoAnalyticsData">
                {"Data not available"}
              </Typography>
            )}
          </Flex>

          <Flex direction="column">
            <Typography as="propertyInfoAnalytics">
              {"Last Sold Date"}
            </Typography>
            <Typography as="propertyInfoAnalyticsData">
              {title.data.data.last_sold.date}
            </Typography>

            {title.data.data.last_sold.date === null && (
              <Typography as="propertyInfoAnalyticsData">
                {"Data not available"}
              </Typography>
            )}
          </Flex>
        </>
      ))}
    </>
  );
};

export const propertyAnalyticsPath = [
  {
    name: "Compare Rents by Letting Type",
    path: "/compareRents",
    analyticName: "compareRents",
    dataButton: "View Rental Comparison",
    dataTypes: [
      "yields",
      "rents-hmo",
      "rents",
      "lha-rate",
      "rentalizer-summary",
      /*"demand",*/
    ],
    Component: CompareRents,
  },
  {
    name: "Overview",
    path: "/overview",
    analyticName: "overview",
    dataTypes: ["uprn-title", "title-info"],
    dataButton: "View Title Info & Sale Price",
    Component: Overview,
  },
  // {
  //   name: "Sales",
  //   path: "/sales",
  //   analyticName: "something",
  //   dataTypes: [],
  //   Component: Sales,
  // },
  // {
  //   name: "Amenities, Schools and Demographics",
  //   path: "/demographics",
  //   analyticName: "something",
  //   dataTypes: [],
  //   Component: Demographics,
  // },
  // {
  //   name: "Property Attributes",
  //   path: "/property-attributes",
  //   analyticName: ["something"],
  //   dataTypes: [],
  //   Component: Attributes,
  // },
];
