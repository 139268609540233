import { useState } from "react";
import propTypes from "prop-types";
import { Typography, Button } from "v2/components";
import List from "../List/List";
import styles from "./Address.module.css";

const AddressList = (props) => {
  const [selectedFromPossibleMatches, setSelectedFromPossibleMatches] =
    useState(null);

  const onListItemSelected = (key) => {
    props.onChangeAddressToConfirm(props.possibleMatches[key]);
    setSelectedFromPossibleMatches(props.possibleMatches[key]);
  };
  const updateProperty = () => {
    props.onConfirmMatch();
    props.push();
    props.closeModal();
  };
  return (
    <>
      <Typography as="h6manualInsert">{props.title}</Typography>
      <List
        noIcon={props.noIcon}
        name="address"
        onClick={onListItemSelected}
        options={props.possibleMatches}
      />
      {!selectedFromPossibleMatches && !props.noButton && (
        <Button
          variant="secondary"
          onClick={props.onNotFoundInList}
          actionName="I couldn't find it"
        />
      )}
      {!!selectedFromPossibleMatches && !props.noUPRN && (
        <>
          <Typography as="h6manualInsert">{props.matchInfo}</Typography>
          <Typography as="list">{selectedFromPossibleMatches.value}</Typography>
          <Button
            onClick={props.onConfirmMatch}
            actionName="Create property with address"
          />
        </>
      )}
      {!!selectedFromPossibleMatches && props.noUPRN && (
        <div className={styles.noUPRN}>
          <Typography as="h6manualInsert">{props.matchInfo}</Typography>
          <Typography as="list">{selectedFromPossibleMatches.value}</Typography>
          <Button
            onClick={updateProperty}
            actionName="Update property with address"
          />
        </div>
      )}
    </>
  );
};

AddressList.propTypes = {
  title: propTypes.string.isRequired,
  onNotFoundInList: propTypes.func.isRequired,
  matchInfo: propTypes.string.isRequired,
  possibleMatches: propTypes.array.isRequired,
  onConfirmMatch: propTypes.func.isRequired,
  onChangeAddressToConfirm: propTypes.func.isRequired,
};
export default AddressList;
