import React from "react";
import propTypes from "prop-types";
import styles from "./MessageBanner.module.css";

const MessageBanner = ({ children }) => (
  <div className={styles.message}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="home-icon"
    >
      <path
        d="M8.00004 15.1666C11.9581 15.1666 15.1667 11.958 15.1667 7.99992C15.1667 4.04188 11.9581 0.833252 8.00004 0.833252C4.042 0.833252 0.833374 4.04188 0.833374 7.99992C0.833374 11.958 4.042 15.1666 8.00004 15.1666Z"
        stroke="#414040"
      ></path>
      <rect
        x="7.33337"
        y="7.33325"
        width="1.33333"
        height="4.66667"
        rx="0.666666"
        fill="#414040"
      ></rect>
      <rect
        x="7.33337"
        y="4"
        width="1.33333"
        height="2"
        rx="0.666666"
        fill="#414040"
      ></rect>
    </svg>
    {children}
  </div>
);

MessageBanner.propTypes = {
  children: propTypes.object.isRequired,
};

export default MessageBanner;
