import propTypes from "prop-types";
import { useToggle } from "Hooks/useToggle";
import { useDidMountEffect } from "Hooks/useDidMountEffect";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as ArrowUp } from "images/arrowup.svg";
import styles from "./ExpandIcon.module.css";
import Typography from "../Typography/Typography";

export default function ExpandIcon(props) {
  const [isOpen, toggle] = useToggle(!!props.isOpen);

  useDidMountEffect(() => {
    props.onClick(isOpen);
  }, [isOpen]);
  useWhyDidYouUpdate("Expand Icon", props);
  if (!isOpen) {
    return (
      <span className={styles.container}>
        <ArrowDown className={styles.icon} onClick={toggle} />
        <Typography as="licenseName">
          <span className={styles.toolTipText}>Expand</span>
        </Typography>
      </span>
    );
  } else {
    return (
      <span className={styles.container}>
        <ArrowUp className={styles.icon} onClick={toggle} />
        <Typography as="licenseName">
          <span className={styles.toolTipText}>Close</span>
        </Typography>
      </span>
    );
  }
}

ExpandIcon.propTypes = {
  isOpen: propTypes.bool,
  onClick: propTypes.func.isRequired,
};

ExpandIcon.defaultProps = {
  isOpen: false,
};
