import { history } from "utils";

const {
  location: { state = {} },
} = history;
export const initialState = state?.propertyForm || {
  numberOfTenants: "",
  numberOfHouseholds: "",
  tenancyType: "Shared tenancy (AST)",
  numberOfStoreys: "",
  propertyCategory: "Unknown",
  propertyType: "",
  roomUnit: "",
  commercialPremises: "Unknown",
  buildingConstructionType: "Unknown",
  knowWhoLiveInProperty: false,
};

export const errorMessage = {
  numberOfStoreys: "",
  propertyType: "",
};

export const disabledInputs = {
  Flat: {},
  House: {
    commercialPremises: true,
  },
  Building: {
    tenancyType: true,
    numberOfTenants: true,
    numberOfHouseholds: true,
  },
  "": {},
};
export const propertyErrors = {
  Building: {
    propertyType: true,
    numberOfStoreys: true,
    roomsOrUnits: true,
    commercialPremises: true,
    buildingConstructionType: true,
  },
  Flat: {
    true: {
      propertyType: true,
      numberOfStoreys: true,
      roomsOrUnits: true,
      tenancyType: true,
      numberOfTenants: true,
      numberOfHouseholds: true,
      propertyCategory: true,
      commercialPremises: true,
      buildingConstructionType: true,
    },
    false: {
      propertyType: true,
      numberOfStoreys: true,
      commercialPremises: true,
      buildingConstructionType: true,
    },
  },
  House: {
    true: {
      propertyType: true,
      numberOfStoreys: true,
      roomsOrUnits: true,
      tenancyType: true,
      numberOfTenants: true,
      numberOfHouseholds: true,
      propertyCategory: true,
    },
    false: {
      propertyType: true,
      numberOfStoreys: true,
    },
  },
};
