import React, { useState } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, AddProperty, HouseHoldPopUp } from "components";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { styles } from "./styles";

const setting = {
  width: 624,
  height: 730,
  top: "0%",
};
const PropertyDetailModal = ({
  isOpen,
  onClose,
  classes,
  propertyDetails,
  onChange,
  onBlur,
  onSave,
  onFocus,
  errors = {},
  disabled = {},
  addRequired = () => null,
}) => {
  const [showHouseHold, setShowHouseHold] = useState(false);
  const [propertyData, setPropertyData] = useState("");
  const showHouseHoldPop = (data) => {
    setPropertyData(data);
    setShowHouseHold(!showHouseHold);
  };
  return (
    <Modal isOpen={isOpen} setting={setting} className={classes.main}>
      <div>
        {showHouseHold && (
          <HouseHoldPopUp
            isOpen={showHouseHold}
            data={propertyData}
            onClose={showHouseHoldPop}
          />
        )}
        <div className={classes.text}>
          <Typography as="h3"> Change property details </Typography>
          <CrossIcon onClick={onClose} onKeyDown={onClose} />
        </div>
        <div className={classes.inputs}>
          <AddProperty
            values={propertyDetails}
            errors={errors}
            onSubmit={onSave}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            addRequired={addRequired}
            buttonText="Update"
            isModal
            houseHoldInfo={showHouseHoldPop}
            onClose={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};
PropertyDetailModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  propertyDetails: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  onBlur: propTypes.func.isRequired,
  onFocus: propTypes.func.isRequired,
  errors: propTypes.object,
  addRequired: propTypes.func,
  disabled: propTypes.object,
};

export default withStyles(styles)(PropertyDetailModal);
