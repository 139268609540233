import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { Styles } from "./styles";

const Table = ({ children, classes }) => (
  <table className={classes.table}>{children}</table>
);
Table.propTypes = {
  children: PropTypes.any,
};

export default withStyles(Styles)(Table);
