export const initialState = {
  documents: {
    certificates: [],
    licences: [],
    planning: [],
    totalCost: "XX.XX",
  },
  apiError: {},
  loading: false,
};
