export const Styles = ({ colors, breakpoints }) => ({
  main: {
    display: "flex",
    padding: "20px 14px 0 14px",
    boxSizing: "border-box",
    width: "100%",
    flexDirection: "column",
    background: colors.listBackground,
    height: "auto",
    borderBottom: `1px solid ${colors.borderColor}`,
    "& .search-input": {
      width: "100%",
      display: "flex",
      marginBottom: "10px",
      "& input": {
        width: "100%",
        height: "10px",
      },
    },
    "& .heading": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "14px",
      [breakpoints.RESPONSIVE]: {
        width: "100%",
      },
      "& svg": {
        display: "block",
        marginRight: "20px",
        cursor: "pointer",
        "& path": {
          stroke: colors.black,
        },
      },
      "& .filter-heading": {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
        color: colors.black,
      },
    },
    "& .list-data": {
      height: "auto",
      overflowY: "auto",
      maxHeight: 352,
      marginTop: "12px",
      "& ::-webkit-scrollbar": {
        width: "6px",
      },
      "& ::-webkit-scrollbar-track": {
        background: colors.hexaWhite,
      },
      "& ::-webkit-scrollbar-thumb": {
        background: colors.hamptonsNewDisabled,
        borderRadius: "40px",
      },
      "& .list-info": {
        marginBottom: "19px",
        "&  label": {
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "15px",
          lineHeight: "18px",
        },
        "& input:checked ~ .checkmark": {
          backgroundColor: colors.primary,
          "&::after": {
            left: "5px",
            top: "0",
            width: "3px",
            height: "8px",
            borderWidth: "0 2px 2px 0",
            "-webkit-transform": "rotate(45deg)",
            "-ms-transform": "rotate(45deg)",
            transform: "rotate(45deg)",
            border: `solid ${colors.hexaWhite}`,
          },
        },
      },
    },
  },
});
