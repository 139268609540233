import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import propTypes from "prop-types";

import Link from "v2/components/Link/Link";
import Markdown from "v2/components/Markdown/Markdown";
import ModalV2 from "../Modal";
import styles from "./TooltipModal.module.css";

const TooltipModal = (props) => {
  return (
    <ModalV2 onClose={props.onClose}>
      <div className={styles.container}>
        <CrossIcon onClick={props.onClose} />

        <Markdown information={props.content} components={{ Link }} />
      </div>
    </ModalV2>
  );
};

TooltipModal.propTypes = {
  onClose: propTypes.func,
  content: propTypes.string,
};

export default TooltipModal;
