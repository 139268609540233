import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import React from "react";
import style from "./Radio.module.css";

const Radio = (props) => {
  const onChooseOption = React.useCallback(() => {
    if (!props.readOnly) {
      props.onChooseOption(props.option);
    }
  }, []);
  useWhyDidYouUpdate("Radio", props);
  return (
    <label
      className={`${props.readOnly ? style.readOnly : style.container}`}
      onClick={onChooseOption}
    >
      <span className={style.checkmark}>
        <span className={style.background} />
        <span
          className={`${
            props.readOnly ? style.uncheckedReadOnly : style.unchecked
          }`}
        />
        {props.checked && <span className={style.checked} />}
      </span>
      <span className={style.label}>{props.option}</span>
      <input
        readOnly={props.readOnly}
        type="checkbox"
        value={props.option}
        defaultChecked={props.checked}
      />
    </label>
  );
};

export default Radio;
