export const styles = ({ colors, fonts, breakpoints }) => ({
  main: {
    width: 304,
    minHeight: 92,
    height: "auto",
    background: colors.hexaWhite,
    display: "flex",
    alignItems: "center",
    borderRadius: 6,
    [breakpoints.RESPONSIVE]: {
      width: 144,
    },
    "& .logo": {
      width: 56,
      height: 56,
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 12px",
    },
    "& .text": {
      fontFamily: fonts.primay,
      fontStyle: "normal",
      fontWeight: "600",
      display: "flex",
      flexDirection: "column",
      [breakpoints.RESPONSIVE]: {
        width: "100%",
        marginLeft: 20,
        justifyContent: "center",
      },
    },
    "& .heading": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.002em",
      color: colors.grey2,
      wordWrap: "break-all",
    },
    "& .counts": {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
});
