import propTypes from "prop-types";

import { createPortal } from "react-dom";
import styles from "./Overlay.module.css";

const Overlay = (props) => {
  return createPortal(
    <div className={`${styles.container} ${styles[props.variant]}`}>
      {props.children}
    </div>,
    document.getElementById("modal")
  );
};

Overlay.propTypes = {
  variant: propTypes.oneOf(["default", "light"]),
};

Overlay.defaultProps = {
  variant: "default",
};

export { Overlay };
