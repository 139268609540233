import { useState, useRef, useMemo, useCallback } from "react";
import { Marker, Popup, Circle } from "react-leaflet";
import styles from "./DraggableMarker.module.css";
import { LatLng } from "leaflet";
const DraggableMarker = (props) => {
  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState(props.coordinates);
  const markerRef = useRef(null);
  const [circle, setCircle] = useState(false);
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);
  const eventHandlers = useMemo(
    () => ({
      dragend(e) {
        const marker = markerRef.current;
        if (marker != null) {
          const latLng = e.target.getLatLng();
          const distance = new LatLng(position[0], position[1]).distanceTo(
            latLng
          );
          // if (props.noUPRN ? distance > props.noUPRN : distance > 200) {
          if (false) {
            alert("Please ensure the new pin location is inside the circle.");
          } else {
            if (
              window.confirm(
                "Are you sure you want to update the property location?"
              )
            ) {
              setCircle(false);
              setPosition(marker.getLatLng());
              props.onNewCoordinate(marker.getLatLng());
            }
          }
        }
      },
      dblclick() {
        toggleDraggable();
        setCircle(true);
      },
    }),
    []
  );
  return (
    <div className={`${props.readOnly ? styles.readOnly : ""}`}>
      <Marker
        draggable={props.readOnly ? false : draggable}
        eventHandlers={props.readOnly ? null : eventHandlers}
        position={position}
        ref={markerRef}
      >
        {!props.readOnly && (
          <Popup minWidth={90} closeButton={false}>
            <span>
              Double click marker and place it on top of your property.
            </span>
          </Popup>
        )}
        {circle && (
          <Circle
            center={{ lat: props.coordinates[0], lng: props.coordinates[1] }}
            fillColor="blue"
            // radius={props.noUPRN ? props.noUPRN : 200}
            radius={2000000}
          />
        )}
      </Marker>
    </div>
  );
};

export default DraggableMarker;
