export const Styles = ({ colors, fonts }) => ({
  spanClick: {
    fontStyle: "normal",
    fontWeight: "600",
    color: ({ color }) => color || colors.primary,
    letterSpacing: "0.002em",
    fontSize: ({ fontSize = "16px" }) => fontSize,
    lineHeight: ({ lineHeight = "24px" }) => lineHeight,
    margin: ({ margin = "0" }) => margin,
    width: ({ width }) => width || "130px",
    minHeight: "24px",
    height: "auto",
    textAlign: "center",
    textDecorationLine: "underline",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },
  atag: {
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },
});
