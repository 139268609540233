import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { ReactComponent as HelpIcon } from "images/help.svg";
import { ReactComponent as GroupIcon } from "images/group.svg";
import { ReactComponent as CompleteIcon } from "images/completed.svg";
import { Typography } from "v2/components";
import { councilText } from "constants/AppConstants";
import { styles } from "./styles";

const ComplicatedCouncil = ({
  classes,
  className = "",
  manualCheckRequested = false,
  manualCheckComplete = false,
  onConfirm,
}) => {
  const [currentClass, setCurrentClasses] = useState("yellow");
  const [title, setTitle] = useState(councilText.first);
  useEffect(() => {
    switch (true) {
      case !manualCheckRequested:
        setCurrentClasses("red");
        setTitle(councilText.first);
        break;
      case manualCheckRequested && !manualCheckComplete:
        setCurrentClasses("orange");
        setTitle(councilText.second);
        break;
      case manualCheckRequested && manualCheckComplete:
        setCurrentClasses("green");
        setTitle(councilText.third);
        break;
      default:
        setCurrentClasses("yellow");
        break;
    }
  }, [manualCheckRequested, manualCheckComplete]);
  return (
    <div className={`${classes.council} ${classes[currentClass]}`}>
      <div className={className}>
        {(manualCheckRequested === false || !manualCheckRequested) && (
          <HelpIcon className="home-icon" />
        )}
        {manualCheckRequested && !manualCheckComplete && (
          <GroupIcon className="home-icon" />
        )}
        {manualCheckRequested && manualCheckComplete && (
          <CompleteIcon className="home-icon" />
        )}
        <div className={classes.heading}>
          <div className={classes.buttonRow}>
            <Typography as="h6MapMarker" className="text">
              {title}
            </Typography>
            <button onClick={onConfirm} className={classes.confirmButton}>
              Confirm Pin Location
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ComplicatedCouncil.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  manualCheckRequested: PropTypes.bool,
  manualCheckComplete: PropTypes.bool,
  onConfirm: PropTypes.func,
};

export default withStyles(styles)(memo(ComplicatedCouncil));
