import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as PlusIcon } from "images/add.svg";
import styles from "./AddIcon.module.css";
import Typography from "../Typography/Typography";
import { useToggle } from "Hooks/useToggle";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import { ReactComponent as CircleCheckIcon } from "images/circlecheck.svg";

const AddIcon = (props) => {
  const { isDisabled, onClick } = props;
  const [isSelected, toggle] = useToggle(props.isSelected);

  const onToggle = React.useCallback(() => {
    if (!isDisabled) {
      onClick({
        value: !isSelected,
      });
      toggle();
    }
  }, [isDisabled, isSelected, toggle, onClick]);
  useWhyDidYouUpdate("AddIcon", props);
  return (
    <div
      id={props.id}
      className={`${styles.container} ${props.isDisabled && styles.disabled}`}
      onClick={onToggle}
    >
      {!isSelected && (
        <>
          <PlusIcon />
          {props.toolTip && !props.isDisabled && (
            <div className={styles.tooltip}>
              <Typography>{props.toolTip}</Typography>
            </div>
          )}
        </>
      )}
      {isSelected && (
        <>
          <CircleCheckIcon />
          {props.selectedToolTip && !props.isDisabled && (
            <div className={styles.tooltip}>
              <Typography>{props.selectedToolTip}</Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
};

AddIcon.propTypes = {
  id: PropTypes.string,
  toolTip: PropTypes.string,
  selectedToolTip: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

AddIcon.defaultProps = {
  id: "",
  isSelected: false,
  isDisabled: false,
  onClick: () => {},
};

export default AddIcon;
