import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  main: {
    "& .title": {
      display: "flex",
      "& p": {
        margin: "0 0 0 10px",
      },
    },
  },
});
