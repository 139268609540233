export const styles = ({ colors, fonts }) => ({
  main: {
    display: "flex",
    height: "64px",
    justifyContent: "space-between",
    width: ({ width = "100%" }) => width,
    background: colors.primary,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    "& img": {
      margin: "23.67px 18.5px",
    },
    "& span": {
      width: "auto",
      height: "20px",
      left: "52px",
      top: "22px",
      fontFamily: fonts.primary,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: colors.hexaWhite,
    },
  },
  button: {
    margin: "16px",
  },
});
