import React, { useEffect, memo } from "react";
import propTypes from "prop-types";
import { withTheme } from "react-jss";
import { useStyles } from "./style";

const Header = React.forwardRef(
  (
    {
      theme,
      children,
      isOpen,
      className = "",
      setting = {
        width: 400,
        height: 280,
        top: 200,
      },
    },
    ref
  ) => {
    const classes = useStyles({ theme, setting });
    useEffect(() => {
      if (isOpen) {
        document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
      }
      return () => {
        document.getElementsByTagName("BODY")[0].style.overflow = "";
      };
    }, [isOpen]);
    return (
      <>
        {isOpen && (
          <div className={`${classes.modal} ${className}`}>
            <div className={`${classes.content} contents`} ref={ref}>
              {children}
            </div>
          </div>
        )}
      </>
    );
  }
);

Header.propTypes = {
  theme: propTypes.object.isRequired,
  children: propTypes.any.isRequired,
  isOpen: propTypes.bool.isRequired,
  setting: propTypes.object,
  className: propTypes.string,
};

export default withTheme(memo(Header));
