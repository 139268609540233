export const Styles = ({ colors }) => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: colors.listBackground,
    height: "55px",
    marginBottom: "20px",
    "& .text": {
      color: colors.placeholderColor,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "120%",
      marginLeft: "14px",
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .link": {
      marginRight: "13px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.01em",
      display: "flex",
      justifyContent: "flex-end",
      color: colors.primary,
    },
  },
});
