import propTypes from "prop-types";
import Typography from "../Typography/Typography";
import styles from "./AlertSponsorBox.module.css";
import { Button } from "../Form";
const AlertSponsorBox = (props) => {
  return (
    <div className={styles.container}>
      <Typography as="">
        Important features are not available in this free version of the app.
        Unlock all features by simply picking a sponsor for your property.
      </Typography>

      <Button
        variant="discreet"
        size="large"
        actionName="Pick a sponsor"
        onClick={props.onClick}
      />
    </div>
  );
};

AlertSponsorBox.propTypes = {
  onClick: propTypes.func,
};

AlertSponsorBox.defaultProps = {
  onClick: () => {},
};

export default AlertSponsorBox;
