export const setting = {
  width: 655,
  height: 1403,
  top: "2%",
};
export const formValues = [
  {
    firstName: "",
    lastName: "",
    email: "",
  },
];
