import React from "react";
import PropTypes from "prop-types";

export const Form = ({ onSubmit, children, className = "" }) => (
  <form className={className} onSubmit={onSubmit}>
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Form;
