import PropTypes from "prop-types";
import { Typography } from "v2/components";

import styles from "./PropertyBox.module.css";

const PropertyBox = ({ headingClass, ...props }) => (
  <div className={styles.container}>
    <div className={styles[headingClass]}>
      {props.showIcon && <div className={styles.icon}>{props.content}</div>}
      <Typography as="h6PropertyBox">{props.heading}</Typography>
    </div>
    <div className={styles.contentDetails}>{props.children}</div>
  </div>
);

PropertyBox.propTypes = {
  heading: PropTypes.string.isRequired,
  heading: PropTypes.string,
  content: PropTypes.object,
  children: PropTypes.any.isRequired,
  showIcon: PropTypes.bool,
};

export default PropertyBox;
