export const tabList = {
  property: "property-info",
  requirements: "requirements",
  plans: "plans",
};

export const tempHide = true;
export const nextTab = {
  "property-info": {
    text: "Check requirements",
    tab: "requirements",
  },
  requirements: {
    text: "Comply",
    tab: "plans",
  },
};
export const propertyStatus = {
  workProgress: "Work in progress",
  itemOutstanding: "Items outstanding",
};
export const selectDocumentKey = {
  index: 0,
  id: 0,
  doucmentInfo: {},
  type: "",
};
export const defaultPackage = "Platinum";
export const hideTemp = true;
export const complicatedCouncil = {
  heading: "This property is in a complicated council scheme",
  subheading: `Let us check it manually to confirm compliance requirements, 
  please click the live chat and add the property address as a reference,
 along with your email and we will get going`,
};
