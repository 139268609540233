const convertFile = (file) => ({
  name: file.name,
  url: file.documentUrl || file.url,
  id: file.uploadFileId || file.id,
});
const convertCertificate = (document, name) => ({
  name,
  reference: document.licenceRefNo,
  expiryDate: document.expiryDate,
  issueDate: document.issueDate,
  id: document.propertyEvidenceUploadId,
  files: document.uploadFiles
    ? document.uploadFiles.map(convertFile)
    : document.files,
});
export const convertCertificates = (certificate) => ({
  name: certificate.label,
  price: certificate.price,
  description: certificate.snippet,
  information: certificate.description,
  id: certificate.propertyEvidenceId,
  isSelected: certificate.ordered,
  isRequiredOptional: !certificate.requireDeclaration,
  isNotRequiredByUser: !!certificate.requireDeclarationChecked,
  documents: certificate.evidenceUploads.map((evidence) =>
    convertCertificate(evidence, certificate.label)
  ),
});
export const convertLicence = (licence, name) => ({
  name,
  itemId: licence.propertyItemUploadId,
  reference: licence.licenceRefNo,
  expiryDate: licence.expiryDate,
  issueDate: licence.issueDate,
  isSelected: licence.ordered,
  documentType: {
    label: licence.documentType,
    value: licence.documentType,
  },
  files: licence.uploadFiles.map(convertFile),
});

export const addLicenceToPropertyDetails = (propertyDetails, licenceData) => ({
  ...propertyDetails,
  licences: propertyDetails.licences.map((licence) => {
    if (licence.propertyItemId == licenceData.propertyItemId) {
      return {
        ...licence,
        documents: [...licence.documents, convertLicence(licenceData)],
      };
    }
    return licence;
  }),
  planning: propertyDetails.planning.map((planning) => {
    if (planning.propertyItemId == licenceData.propertyItemId) {
      return {
        ...planning,
        documents: [...planning.documents, convertLicence(licenceData)],
      };
    }
    return planning;
  }),
});
export const updateLicenceInPropertyDetails = (
  propertyDetails,
  licenceData = {}
) => ({
  ...propertyDetails,
  licences: propertyDetails.licences.map((licence) => {
    return {
      ...licence,
      documents: licence.documents.map((document) => {
        if (document.itemId == licenceData.itemId) {
          return {
            ...document,
            ...licenceData,
            documentType: {
              label: licenceData?.documentType || document.documentType.label,
              value: licenceData?.documentType || document.documentType.value,
            },
          };
        }
        return document;
      }),
    };
  }),
  planning: propertyDetails.planning.map((planning) => {
    return {
      ...planning,
      documents: planning.documents.map((document) => {
        if (document.itemId == licenceData.itemId) {
          return {
            ...document,
            ...licenceData,
            documentType: {
              label: licenceData?.documentType || document.documentType.label,
              value: licenceData?.documentType || document.documentType.value,
            },
          };
        }
        return document;
      }),
    };
  }),
});

export const updateLicenceFilesInPropertyDetails = (
  propertyDetails,
  filesData
) => ({
  ...propertyDetails,
  licences: propertyDetails.licences.map((licence) => {
    return {
      ...licence,
      documents: licence.documents.map((document) => {
        if (document.itemId == filesData.itemId) {
          return {
            ...document,
            files: filesData.files.map(convertFile),
          };
        }
        return document;
      }),
    };
  }),
  planning: propertyDetails.planning.map((planning) => {
    return {
      ...planning,
      documents: planning.documents.map((document) => {
        if (document.itemId == filesData.itemId) {
          return {
            ...document,
            files: filesData.files.map(convertFile),
          };
        }
        return document;
      }),
    };
  }),
});

export const updateLicenceOrderedInPropertyDetails = (
  propertyDetails,
  licenceItemData
) => ({
  ...propertyDetails,
  licences: propertyDetails.licences.map((licence) => {
    return {
      ...licence,
      ordered:
        licence.propertyItemId === licenceItemData.itemId
          ? licenceItemData.value
          : licence.ordered,
    };
  }),
  planning: propertyDetails.planning.map((planning) => {
    return {
      ...planning,
      ordered:
        planning.propertyItemId === licenceItemData.itemId
          ? licenceItemData.value
          : planning.ordered,
    };
  }),
});

export const deleteLicenceFileInPropertyDetails = (
  propertyDetails,
  deletedFileData
) => ({
  ...propertyDetails,
  licences: propertyDetails.licences.map((licence) => {
    return {
      ...licence,
      documents: licence.documents.map((document) => {
        if (document.itemId == deletedFileData.itemId) {
          return {
            ...document,
            files: document.files.filter(
              (file) => file.id !== deletedFileData.fileId
            ),
          };
        }
        return document;
      }),
    };
  }),
  planning: propertyDetails.planning.map((planning) => {
    return {
      ...planning,
      documents: planning.documents.map((document) => {
        if (document.itemId == deletedFileData.itemId) {
          return {
            ...document,
            files: document.files.filter(
              (file) => file.id !== deletedFileData.fileId
            ),
          };
        }
        return document;
      }),
    };
  }),
});

export const addCertificateInPropertyDetails = (
  propertyDetails,
  certificateData
) => ({
  ...propertyDetails,
  certificates: propertyDetails.certificates.map((certificate) => {
    if (certificate.id == certificateData.propertyEvidenceId) {
      return {
        ...certificate,
        documents: [
          ...certificate.documents,
          convertCertificate(certificateData, certificate.name),
        ],
      };
    }
    return certificate;
  }),
});

export const deleteCertificateInPropertyDetails = (
  propertyDetails,
  deletedCertificateData
) => ({
  ...propertyDetails,
  certificates: propertyDetails.certificates.map((certificate) => {
    return {
      ...certificate,
      documents: certificate.documents.filter(
        (document) => document.id !== deletedCertificateData.id
      ),
    };
  }),
});

export const updateCertificateOrderStatusInPropertyDetails = (
  propertyDetails,
  certificateData
) => ({
  ...propertyDetails,
  certificates: propertyDetails.certificates.map((certificate) => {
    if (certificate.id == certificateData.certificateId) {
      certificate.isSelected = certificateData.value;
    }
    return certificate;
  }),
});

export const updateCertificateInPropertyDetails = (
  propertyDetails,
  updatedCertificateData
) => ({
  ...propertyDetails,
  certificates: propertyDetails.certificates.map((certificate) => {
    return {
      ...certificate,
      documents: certificate.documents.map((document) => {
        if (document.id == updatedCertificateData.certificateId) {
          return {
            ...document,
            ...updatedCertificateData,
          };
        }
        return document;
      }),
    };
  }),
});
export const updateCertificateFilesInPropertyDetails = (
  propertyDetails,
  updatedCertificateData
) => ({
  ...propertyDetails,
  certificates: propertyDetails.certificates.map((certificate) => {
    return {
      ...certificate,
      documents: certificate.documents.map((document) => {
        return {
          ...document,
          files: updatedCertificateData.files.map(convertFile),
        };
      }),
    };
  }),
});

export const deleteCertificateFilesInPropertyDetails = (
  propertyDetails,
  deletedCertificateFileData
) => ({
  ...propertyDetails,
  certificates: propertyDetails.certificates.map((certificate) => {
    return {
      ...certificate,
      documents: certificate.documents.map((document) => {
        return {
          ...document,
          files: document.files.filter(
            (file) => file.id !== deletedCertificateFileData.fileId
          ),
        };
      }),
    };
  }),
});
