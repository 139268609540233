import {
  usePropertyDetails,
  usePropertySponsorContext,
  usePropertyUpdate,
} from "context/propertyContext/PropertyContext";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import { ReactComponent as ArrowRight } from "images/arrowrightlong.svg";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Typography } from "v2/components";
import SponsorContainer from "v2/containers/PropertyDetails/PropertyReport/SponsorContainer";
import styles from "./TabView.module.css";
import { ReactComponent as Upgrade } from "images/upgrade.svg";

const TabView = (props) => {
  const { openSponsorModal } = usePropertySponsorContext();
  let { url } = useRouteMatch();
  const propertyDetails = usePropertyDetails();
  const { pressGo } = usePropertyUpdate();
  const tabPathInfo = 0;
  const [tabPathJourney, setTabPathJourney] = useState(false);
  const tabStyle = styles.selected;

  useEffect(() => {
    if (window.location.pathname.includes("property-info")) {
      setTabPathJourney(false);
    }
    if (window.location.pathname.includes("journey")) {
      setTabPathJourney(false);
    }
    if (window.location.pathname.includes("get-support")) {
      setTabPathJourney(true);
    }
  }, [window.location.pathname]);

  useWhyDidYouUpdate("TabView", props);
  return (
    <div className={styles.container} id="Stonly_tabview">
      <div className={styles.wrap}>
        <div className={styles.stepWrap}>
          <div className={styles.steps}>
            {props.tabList.map((tab, index) => (
              <NavLink
                className={(isActive) =>
                  (!isActive && tabPathInfo === index) ||
                  (!isActive && tabPathJourney === true)
                    ? `${styles.link} ${tabStyle}`
                    : styles.link
                }
                activeClassName={`${styles.link} ${styles.selected}`}
                to={{
                  pathname: `${url}/${tab.path}`,
                  state: {
                    page: tab.name,
                  },
                }}
                key={tab.path}
                onClick={async () => {
                  if (tab.path === "journey") await pressGo();
                }}
              >
                <tab.icon />
                <Typography as="tabText">{tab.name}</Typography>
                {index < props.tabList.length - 1 && <ArrowRight />}
              </NavLink>
            ))}
          </div>
          <div className={styles.sponsoredBy}>
            {propertyDetails.sponsor ? (
              <div className={styles.upgradeButton}>
                <SponsorContainer
                  name={propertyDetails.sponsor.name}
                  image={propertyDetails.sponsor.logoUrl}
                />
              </div>
            ) : (
              (window.location.origin === "https://app.uat.goyuno.com" ||
                window.location.origin === "https://app.goyuno.com" ||
                window.location.origin === "http://localhost:3000") && (
                <div className={styles.upgradeButton}>
                  <Upgrade onClick={openSponsorModal} />
                </div>
              )
            )}
          </div>
        </div>

        {window.location.pathname.includes("property-info") && (
          <div
            className={`${styles.progressBar} ${styles.progressBarSizeSmall}`}
          />
        )}
        {window.location.pathname.includes("journey") && (
          <div
            className={`${styles.progressBar} ${styles.progressBarSizeMedium}`}
          />
        )}
        {window.location.pathname.includes("get-support") && (
          <div
            className={`${styles.progressBar} ${styles.progressBarSizeLarge}`}
          />
        )}
      </div>
    </div>
  );
};

TabView.propTypes = {
  propertyDetails: PropTypes.object,
};

export default TabView;
