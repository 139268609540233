import React, { memo, useEffect, useRef } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Modal, Links } from "components";
import { Typography } from "v2/components";
import { propertyTypeData } from "constants/AppConstants";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import { useWindowDimensions } from "utils";
import theme from "theme";
import { styles } from "./styles";

const { colors } = theme;
const setting = {
  width: 640,
  height: 400,
  top: 80,
  color: colors.hexaWhite,
};
const HouseHold = ({ isOpen, onClose, classes, data = "" }) => {
  const wrapperRef = useRef(null);
  const { width } = useWindowDimensions();
  const propertyData = propertyTypeData[data];
  const handleClick = ({ target, offsetX }) => {
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      if (offsetX <= target.clientWidth) {
        onClose();
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
  const {
    first: { text = [], heading = "", subHeading = "" },
  } = propertyTypeData[data] || {
    first: { text: [], heading: "", subHeading: "" },
  };
  let propertyKeys = { ...propertyData };
  delete propertyKeys.first;
  propertyKeys = Object.keys(propertyKeys);
  setting.width = width > 800 ? 640 : "80%";
  return (
    <Modal
      isOpen={isOpen}
      setting={setting}
      className={classes.houseHold}
      ref={wrapperRef}
    >
      <div className={classes.main}>
        <div className={classes.text}>
          <Typography as="subtitle" className="pop-up-heading">
            {heading}
          </Typography>
          <CrossIcon onClick={onClose} onKeyDown={onClose} />
        </div>
        {subHeading && (
          <div className={classes.section}>
            <div className="list-view">
              <span>{subHeading}</span>
            </div>
          </div>
        )}

        <div className="number-view">
          {text.map((val, key) => (
            <div key={`${key}-text`}>
              <div>{val}</div>
            </div>
          ))}
        </div>
        {propertyKeys.length > 0 &&
          propertyKeys.map((value, key) => (
            <React.Fragment key={key}>
              <div className={classes.bottomSection}>
                <Typography as="p" fontWeight="600">
                  {propertyData[value].heading}
                </Typography>
                {propertyData[value].subHeading && (
                  <div className={classes.section}>
                    <div className="list-view">
                      <span>{propertyData[value].subHeading}</span>
                    </div>
                  </div>
                )}
                <div className="number-view">
                  {propertyData[value].text.map((val, key) => (
                    <div key={`${key}-text`}>
                      <span>{val}</span> <br />
                    </div>
                  ))}
                  {Object.prototype.hasOwnProperty.call(
                    propertyData[value],
                    "link"
                  ) && (
                    <span>
                      {propertyData[value].link.text}{" "}
                      <Links as="_blank" path={propertyData[value].link.url}>
                        {propertyData[value].link.linkText}
                      </Links>
                    </span>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
      </div>
    </Modal>
  );
};
HouseHold.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  data: propTypes.string,
};

export default withStyles(styles)(memo(HouseHold));
