import styles from "./SendEmailModal.module.css";
import { Typography } from "v2/components";
import { ReactComponent as CrossIcon } from "../../../../../images/crossIcon.svg";
import React from "react";
import Input from "v2/components/Form/Input/Input";
import useApiEmailActions from "Apis/YunoApi/useApiEmailActions";
import Button from "../../../../../v2/components/Form/Button/Button";
import useInput from "Hooks/useInput";
const SendEmailModal = (props: any) => {
  const { sendRentalQuery } = useApiEmailActions();
  const user = props.user;
  const { uuid } = props.property;

  const [firstName, firstNameError, setFirstName, checkFirstName] = useInput(
    user.firstName ? user.firstName : "",
    "This field is required"
  );
  const [lastName, lastNameError, setLastName, checkLastName] = useInput(
    user.lastName ? user.lastName : "",
    "This field is required"
  );
  const [email, emailError, setEmail, checkEmail] = useInput(
    user.email ? user.email : "",
    "An email is required"
  );
  const [number, numberError, setNumber, checkNumber] = useInput(
    user.phoneNumber ? user.phoneNumber : "",
    "An email is required"
  );
  const [details, detailsError, setDetails, checkDetails] = useInput(
    "",
    "Details are required"
  );

  const checkFormFields = React.useCallback(() => {
    checkFirstName();
    checkLastName();
    checkEmail();
    checkNumber();
  }, [checkFirstName, checkLastName, checkEmail, checkNumber]);

  const sendEmail = () => {
    checkFormFields();
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      number !== "" &&
      details !== ""
    ) {
      sendRentalQuery.trigger({
        firstName,
        lastName,
        email,
        number,
        details,
        propertyUuid: uuid,
      });
      props.onClose();
    }
  };
  const firstNameErrors = { error: firstNameError };
  const lastNameErrors = { error: lastNameError };
  const emailErrors = { error: emailError };
  const numberErrors = { error: numberError };
  const detailsErrors = { error: detailsError };

  return (
    <div className={styles.container}>
      <div className={styles.closeIcon}>
        <CrossIcon onClick={props.onClose} />
      </div>
      <div className={styles.title}>
        <Typography as="title">Get in Contact</Typography>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.subtitle}>
          <Typography>Your Details:</Typography>
        </div>
        <div className={styles.formRow}>
          <Input
            label="First Name"
            placeholder="First Name"
            value={firstName}
            onChange={setFirstName}
            {...firstNameErrors}
          />
          <Input
            label="Last Name"
            placeholder="Last Name"
            value={lastName}
            onChange={setLastName}
            {...lastNameErrors}
          />
        </div>
        <div className={styles.formRow}>
          <Input
            label="Email Address"
            placeholder="Enter your email address"
            value={email}
            onChange={setEmail}
            {...emailErrors}
          />
          <Input
            label="Contact Number"
            placeholder="Enter your contact number"
            value={number}
            onChange={setNumber}
            {...numberErrors}
          />
        </div>
        <div className={styles.horBar}></div>
        <div className={styles.subtitle}>
          <Typography>Message:</Typography>
        </div>
        <textarea
          className={styles.messageBox}
          name="message"
          placeholder="Write your message here"
          rows={10}
          value={details}
          onChange={setDetails}
          {...detailsErrors}
        />
        <div className={styles.bottomButtons}>
          <Button
            actionName="Cancel"
            variant="secondary"
            onClick={props.onClose}
          />
          <Button actionName="Submit" variant="primary" onClick={sendEmail} />
        </div>
      </div>
    </div>
  );
};

export default SendEmailModal;
