import { useState, useEffect, useRef, useCallback } from "react";
import propTypes from "prop-types";
import styles from "./Input.module.css";
import Label from "../Label";
import { useDebounce } from "Hooks";

const InputDebounce = (props) => {
  const [inputValue, setInputValue] = useState(props.value);
  const debouncedSearchTerm = useDebounce(
    inputValue,
    props.delay ? props.delay : 1
  );
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    props.onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);
  // useWhyDidYouUpdate("InputDebounce", props);
  const onChange = useCallback(
    (e) => {
      props.onImmediateChange();
      setInputValue(e.target.value);
    },
    [props]
  );
  return (
    <Label
      id={props.id}
      label={props.label}
      onInputTip={props.onInputTip}
      error={props.error}
    >
      <span
        className={`${styles.container} ${
          props.error ? styles.inputError : ""
        }`}
      >
        <input
          autoComplete={props.autocomplete ? "on" : "off"}
          readOnly={props.readOnly}
          type={props.type}
          placeholder={props.placeholder}
          name={props.name}
          className={`${
            props.variant ? styles[props.variant] : styles.default
          } ${props.error ? styles.inputError : ""}`}
          value={inputValue}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={onChange}
          onKeyDown={props.onKeyDown}
        />
      </span>
    </Label>
  );
};

InputDebounce.propTypes = {
  id: propTypes.string,
  label: propTypes.string,
  onInputTip: propTypes.func,
  type: propTypes.string,
  placeholder: propTypes.string,
  name: propTypes.string,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  onKeyDown: propTypes.func,
  className: propTypes.string,
  readOnly: propTypes.bool,
  autocomplete: propTypes.bool,
  variant: propTypes.oneOf(["default", "dashboardFilter"]),
};

InputDebounce.defaultProps = {
  id: "",
  type: "text",
  variant: "default",
  placeholder: "text",
  name: "name",
  classes: {},
  onChange: () => null,
  onImmediateChange: () => {},
  value: "",
  onBlur: () => null,
  onFocus: () => null,
  onKeyDown: () => null,
  className: "",
  readOnly: false,
  autocomplete: true,
};
export default InputDebounce;
