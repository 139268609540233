import { useFormik } from "formik";
import { Button, Form, Input } from "../Form";
import Typography from "../Typography/Typography";
import styles from "./BusinessUpgrade.module.css";

const BusinessUpgrade = (props) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      logoUrl: "",
      address: "",
    },
    onSubmit: props.onSubmit,
  });
  return (
    <Form onSubmit={formik.handleSubmit} className={styles.container}>
      <div className={styles.header}>
        <Typography as="h1"> Upgrade your business</Typography>
        <Typography>
          Start sponsoring districts and getting leads to boost your business.
        </Typography>
      </div>
      <Input
        label="Business name"
        id="name"
        name="name"
        placeholder="Enter you business name"
        value={formik.values.name}
        onChange={formik.handleChange}
      />
      <Input
        id="logoUrl"
        name="logoUrl"
        label="Logo url"
        placeholder="Enter a url for you business logo"
        value={formik.values.logoUrl}
        onChange={formik.handleChange}
      />
      <Input
        id="address"
        name="address"
        label="Business Address"
        placeholder="Enter the business url"
        value={formik.values.address}
        onChange={formik.handleChange}
      />
      <footer>
        {props.onCancel && (
          <Button
            actionName="I'll do it later"
            variant="secondary"
            onClick={props.onCancel}
          />
        )}
        {!props.onCancel && <div />}
        <Button actionName="Confirm" type="submit" />
      </footer>
    </Form>
  );
};

BusinessUpgrade.propTypes = {
  onSubmit: () => {},
};

export default BusinessUpgrade;
