export const styles = ({ colors }) => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
    "& .main-heading": {
      color: colors.black,
    },
    "& .button": {
      width: 180,
      height: 48,
    },
    "& .br-4": {
      borderRadius: "4px",
    },
    "& .btn-sec": {
      border: "1.4px solid #4EAEA5",
      background: "transparent",
      marginLeft: "auto",
      marginRight: "10px",
      color: "#4EAEA5",
    },
  },
});
