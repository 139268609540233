import { LeadgenData, privacyPolicyURL } from "constants/AppConstants";
import { ReactComponent as CrossIcon } from "images/crossIcon.svg";
import propTypes from "prop-types";
import React, { useState } from "react";
import withStyles from "react-jss";
import {
  checkAllRequiredFields,
  checkRequiredField,
  useWindowDimensions,
  validateEmail,
  validatePhone,
} from "utils";
import { Button, Form, Input, Links, Modal, Typography } from "v2/components";
import { styles } from "./styles";

const setting = {
  width: "494px",
  height: "580px",
  top: "0",
};
const { heading } = LeadgenData;
const LeadgenModal = ({ isOpen, onClose, classes, onSubmit }) => {
  const { width } = useWindowDimensions();
  setting.width = width > 800 ? 400 : "80%";
  const [leadgenForm, setLeadgenForm] = useState({
    form: {},
    error: {},
  });
  const onFocus = ({ target: { name } }) => {
    setLeadgenForm({
      ...leadgenForm,
      error: {
        ...leadgenForm.error,
        [name]: "",
      },
    });
  };
  const checkAllLeadgenError = () => {
    const {
      form: { firstName, lastName, email, phone },
    } = leadgenForm;
    const errorObject = {
      ...checkAllRequiredFields({ firstName, lastName, email, phone }),
      ...validateEmail("email", email),
      ...validatePhone("phone", phone),
    };
    setLeadgenForm({ ...leadgenForm, error: { ...errorObject } });
    return Object.values(errorObject).every((errorMsg) => !errorMsg);
  };
  const addPersonlInfo = (event) => {
    event.preventDefault();
    if (checkAllLeadgenError()) {
      onSubmit(form);
    }
  };
  const onChange = ({ target: { name, value } }) => {
    setLeadgenForm({
      ...leadgenForm,
      form: {
        ...leadgenForm.form,
        [name]: value,
      },
    });
  };
  const onBlur = ({ target: { name, value } }) => {
    const checkError = {};
    switch (name) {
      case "email":
        Object.assign(checkError, validateEmail(name, value));
        break;
      case "phone":
        Object.assign(checkError, validatePhone(name, value));
        break;
      default:
        Object.assign(checkError, checkRequiredField(name, value));
        break;
    }
    setLeadgenForm({
      ...leadgenForm,
      error: {
        ...leadgenForm.error,
        ...checkError,
      },
    });
  };

  const { form, error } = leadgenForm;
  return (
    <Modal isOpen={isOpen} setting={setting} className={classes.leadgen}>
      <div className={classes.main}>
        <div className="heading">
          <div className="content">
            <Typography as="subtitle" className="heading-text">
              {heading}
            </Typography>
            <CrossIcon onClick={onClose} />
          </div>
        </div>

        <Form onSubmit={addPersonlInfo}>
          <div className="form">
            <div className="form-control">
              <Typography as="label" className="label">
                First Name
              </Typography>
              <Input
                name="firstName"
                value={form.firstName}
                error={error.firstName}
                type="text"
                placeholder="Enter your first name"
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
              />
            </div>
            <div className="form-control">
              <Typography as="label" className="label">
                Last Name
              </Typography>
              <Input
                name="lastName"
                value={form.lastName}
                error={error.lastName}
                type="text"
                placeholder="Enter your last name"
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
              />
            </div>
            <div className="form-control">
              <Typography as="label" className="label">
                Email address
              </Typography>
              <Input
                name="email"
                value={form.email}
                error={error.email}
                type="email"
                placeholder="Enter your email address"
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
              />
            </div>
            <div className="form-control">
              <Typography as="label" className="label">
                Phone number
              </Typography>
              <Input
                name="phone"
                value={form.phone}
                error={error.phone}
                type="text"
                placeholder="Enter your phone number "
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
              />
            </div>
            <div className="form-control checkbox">
              <Input
                type="checkbox"
                name="acceptPolicy"
                value={form.acceptPolicy}
                onChange={onChange}
              />
              <Typography as="subtitle" className="checkbox-text">
                Check here to indicate that you have read and agree to the terms
                of our{" "}
                <Links as="_blank" path={privacyPolicyURL}>
                  Privacy Policy
                </Links>
              </Typography>
            </div>
            <div className="submit-button">
              <Button
                onClick={onClose}
                className="cancel-button"
                actionName="Cancel"
              />
              <Button type="submit" actionName="Submit" />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
LeadgenModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  onSubmit: propTypes.func.isRequired,
};

export default withStyles(styles)(LeadgenModal);
