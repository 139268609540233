import { ADDRESS } from "./constants";
import { initialState } from "./initialState";

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADDRESS:
      return { ...state, address: { ...payload } };
    default:
      return state;
  }
};
