import propTypes from "prop-types";

import { Links } from "v2/components";
import Typography from "../Typography/Typography";
import styles from "./PackagePreview.module.css";
function PackagePreview(props) {
  return (
    <div className={styles.container}>
      <Typography>£{props.price}</Typography>
      {/* temporary removed */}
      {/* <Typography>{props.currentPackage}</Typography> */}
      <Links as="click" onClick={props.openPackageModal}>
        <Typography>Change package</Typography>
      </Links>
    </div>
  );
}

PackagePreview.propTypes = {
  price: propTypes.string.isRequired,
  currentPackage: propTypes.string,
};

export default PackagePreview;
