import { CHANGE_FORM, REMOVE_FORM } from "./constants";
import { initialState } from "./initialState";

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_FORM:
      return { ...state, ...payload };
    case REMOVE_FORM:
      return payload;
    default:
      return Object.keys(state).length === 0 ? initialState : state;
  }
};
