import React, { memo } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";
import { Links } from "components";
import { Typography } from "v2/components";
import { Styles } from "./Styles";

const DocumentUpload = ({
  classes,
  onClick,
  text,
  linkText = "Upload document",
}) => (
  <div className={classes.main}>
    <Typography as="h6Documents">{text}</Typography>
    <Links as="click" className="link" onClick={onClick}>
      {linkText}
    </Links>
  </div>
);
DocumentUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
};
export default withStyles(Styles)(memo(DocumentUpload));
