import { useAsync } from "Hooks/useAsync";
import { useEffect, useState } from "react";
import { POST } from "utils";

type UploadFilesData = {
  presignedURL: string;
  files: File[];
};

type PreSignedItem = {
  postData: {
    url: string;
    fields: { [key: string]: string };
  };
};

type UseUploadToS3Hook = [
  (data: UploadFilesData) => void,
  Array<PreSignedItem>,
  string | null
];

export default function useUploadFilesToS3(): UseUploadToS3Hook {
  const [onUploadFilesData, setOnUploadFilesData] = useState<UploadFilesData>();
  const [onPresignedSuccessData, setOnPresignedSuccessData] = useState<
    Array<PreSignedItem>
  >([]);
  const [preSignedList, setPreSignedList] = useState<Array<PreSignedItem>>([]);

  const preSignRequest = async () => {
    if (onUploadFilesData) {
      const { files, presignedURL } = onUploadFilesData;

      return POST(presignedURL, {
        contentType: files.map(({ type }) => type),
      });
    }
  };

  const uploadFilesRequest = async () => {
    if (onPresignedSuccessData && onPresignedSuccessData.length) {
      await Promise.all(
        onPresignedSuccessData.map((presignedUrl, index) => {
          const data = presignedUrl.postData || null;
          const formData = new FormData();
          if (data && data.fields) {
            Object.keys(data.fields).forEach((key) => {
              if (key !== "bucket_name") {
                formData.append(key, data.fields[key]);
              }
            });
          }
          if (onUploadFilesData) {
            formData.append("file", onUploadFilesData.files[index]);
            const options = {
              headers: {
                "Content-Type": onUploadFilesData.files[index].type,
              },
            };
            return POST(data.url, formData, options);
          }
        })
      );
    }
  };

  const preSignPromise = useAsync(preSignRequest, false);
  const uploadFilesPromise = useAsync(uploadFilesRequest, false);

  useEffect(() => {
    if (onUploadFilesData) {
      preSignPromise.execute();
    }
  }, [onUploadFilesData]);

  useEffect(() => {
    if (preSignPromise.status === "success" && preSignPromise.value?.data) {
      setOnPresignedSuccessData(preSignPromise.value?.data);
    }
  }, [preSignPromise.status]);

  useEffect(() => {
    if (onPresignedSuccessData) {
      uploadFilesPromise.execute();
    }
  }, [onPresignedSuccessData]);
  useEffect(() => {
    if (uploadFilesPromise.status === "success") {
      setPreSignedList(onPresignedSuccessData);
    }
  }, [uploadFilesPromise.status]);

  return [
    setOnUploadFilesData,
    preSignedList,
    preSignPromise.error || uploadFilesPromise.error,
  ];
}
