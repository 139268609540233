export const styles = ({ colors, fonts, breakpoints }) => ({
  main: {
    [breakpoints.MOBILE_DEVICE]: {
      paddingTop: 0,
      height: "100vh",
      width: "100%",
      "& .contents": {
        width: "100%",
        height: "100%",
      },
    },
  },
  text: {
    display: "flex",
    justifyContent: "space-between",
    "& h3": {
      fontStyle: "normal",
      letterSpacing: ({ spacing = "0.002em" }) => spacing,
      fontWeight: ({ weight }) => weight || "bold",
      fontFamily: fonts.primary,
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.hamptonsSecondary,
      margin: "24px",
    },
    "& svg": {
      margin: "24px",
      cursor: "pointer",
    },
  },
  inputs: {
    margin: "0 24px",
    position: "relative",
    "& span": {
      position: "absolute",
      bottom: "-19px",
    },
    "& input": {
      width: "352px",
      height: "48px",
      margin: "0 24px 0 24px",
      boxSizing: "border-box",
      [breakpoints.MOBILE_DEVICE]: {
        width: "100%",
      },
      "&:focus": {
        border: `2px solid ${colors.hamptonsFocus}`,
      },
    },
    "& Button": {
      margin: "0",
      width: "100%",
    },
  },
  bottom: {
    margin: "0px 24px",
  },
  cancelDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    paddingBottom: "22px",
    width: "100%",
    alignItems: "center",
    "& .cancel-button": {
      width: "100%",
      background: colors.hamptonsSecondary,
    },
  },
});
