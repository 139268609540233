import React from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import { Input, Button, Row, Form, Select } from "components";
import { Typography } from "v2/components";
import {
  propertyCategory,
  tenancyType,
  propertyType,
  propertyTypes,
  commercialPremisise,
  propertyFields,
} from "constants/AppConstants";
import { ReactComponent as HelpIcon } from "images/question.svg";
import Styles from "./styles";

const AddProperty = ({
  values,
  errors,
  classes,
  onSubmit,
  onChange,
  onBlur,
  onFocus,
  className = "",
  onClose = () => null,
  isModal = false,
  buttonText = "Check result",
  houseHoldInfo = () => null,
  addRequired = () => null,
  disabled,
}) => (
  <Form onSubmit={onSubmit} className={`${classes.form} modal-form`}>
    <Row className={`${classes.select} modal-select`}>
      <div className="input-div">
        <div className={classes.labelDiv}>
          <Typography as="label" className={classes.label}>
            Property Type
            <div className="required-start">*</div>
          </Typography>

          <HelpIcon
            className="help-icon"
            onClick={() => houseHoldInfo(propertyFields.propertyType)}
          />
        </div>

        <Select
          disabled={disabled.propertyType}
          name="propertyType"
          onChange={(event) => {
            onChange(event);
            addRequired(event);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          options={propertyType}
          selected={values.propertyType}
          error={errors.propertyType}
        />
      </div>
      <div className="input-div">
        <div className={classes.labelDiv}>
          <Typography as="label" className={classes.label}>
            Property category
          </Typography>

          {/* temporary removed */}
          {/* <HelpIcon
            className="help-icon"
            onClick={() => houseHoldInfo(propertyFields.propertyCategory)}
          /> */}
        </div>

        <Select
          disabled={disabled.propertyCategory}
          name="propertyCategory"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          options={propertyCategory}
          selected={values.propertyCategory}
          error={errors.propertyCategory}
        />
      </div>
    </Row>
    <Row className={`${classes.select} modal-select`}>
      <div className="input-div last-select">
        <div className={classes.labelDiv}>
          <Typography as="label" className={`${classes.label} long-label`}>
            Commercial premises in building
            {propertyTypes.Flat === values.propertyType && (
              <div className="required-start">*</div>
            )}
          </Typography>
          <HelpIcon
            className="help-icon"
            onClick={() => houseHoldInfo(propertyFields.commercialPremises)}
          />
        </div>
        <Select
          disabled={disabled.commercialPremises}
          name="commercialPremises"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          options={commercialPremisise}
          selected={values.commercialPremises}
          error={errors.commercialPremises}
        />
      </div>
      {isModal && (
        <div className="input-div">
          <div className="info">
            <div className={classes.labelDiv}>
              <Typography as="label" className={classes.label}>
                Number of storeys
                <div className="required-start">*</div>
              </Typography>
              <HelpIcon
                className="help-icon"
                onClick={() => houseHoldInfo(propertyFields.numberStoreys)}
              />
            </div>
            <Input
              readOnly={disabled.numberOfStoreys}
              type="number"
              name="numberOfStoreys"
              onChange={onChange}
              onBlur={onBlur}
              min={0}
              onFocus={onFocus}
              value={values.numberOfStoreys}
              error={errors.numberOfStoreys}
              placeholder=""
            />
          </div>
        </div>
      )}
    </Row>
    <Row className={`${classes.select} modal-select`}>
      <div className="input-div">
        <div className={classes.labelDiv}>
          <Typography as="label" className={classes.label}>
            Tenancy type
          </Typography>
          <HelpIcon
            className="help-icon"
            onClick={() => houseHoldInfo(propertyFields.tenancyType)}
          />
        </div>
        <Select
          disabled={disabled.tenancyType}
          name="tenancyType"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          options={tenancyType}
          selected={values.tenancyType}
          error={errors.tenancyType}
        />
      </div>
    </Row>
    <Row className={`${classes.select} modal-select`}>
      <div className="input-div">
        <div className="info">
          <div className={classes.labelDiv}>
            <Typography as="label" className={classes.label}>
              Number of people
            </Typography>
            <HelpIcon
              className="help-icon"
              onClick={() => houseHoldInfo(propertyFields.numberPeople)}
            />
          </div>
          <Input
            readOnly={disabled.numberOfTenants}
            type="number"
            min={0}
            name="numberOfTenants"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={values.numberOfTenants || ""}
            error={errors.numberOfTenants}
            placeholder=""
          />
        </div>
      </div>

      <div className="input-div">
        <div className="info-second">
          <div className={classes.labelDiv}>
            <Typography as="label" className={classes.label}>
              Number of households
            </Typography>

            <HelpIcon
              className="help-icon"
              onClick={() => houseHoldInfo(propertyFields.houseHoldData)}
            />
          </div>
          <Input
            readOnly={disabled.numberOfHouseholds}
            type="number"
            min={0}
            name="numberOfHouseholds"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={values.numberOfHouseholds || ""}
            error={errors.numberOfHouseholds}
            placeholder=""
          />
        </div>
      </div>
      {!isModal && (
        <div className="input-div">
          <div className="info">
            <div className={classes.labelDiv}>
              <Typography as="label" className={classes.label}>
                Number of storeys
                <div className="required-start">*</div>
              </Typography>
              <HelpIcon
                className="help-icon"
                onClick={() => houseHoldInfo(propertyFields.numberStoreys)}
              />
            </div>
            <Input
              readOnly={disabled.numberOfStoreys}
              type="number"
              min={0}
              name="numberOfStoreys"
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              value={values.numberOfStoreys}
              error={errors.numberOfStoreys}
              placeholder=""
            />
          </div>
        </div>
      )}
      <div className="input-div room-input">
        <div className="last-input">
          <div className={classes.labelDiv}>
            <Typography as="label" className={classes.label}>
              Bedrooms / units
            </Typography>
            <HelpIcon
              className="help-icon"
              onClick={() => houseHoldInfo(propertyFields.numberRooms)}
            />
          </div>

          <Input
            readOnly={disabled.roomUnit}
            type="number"
            name="roomUnit"
            min={0}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={values.roomUnit}
            error={errors.roomUnit}
            placeholder=""
          />
        </div>
      </div>
    </Row>
    <div className={`${classes.button} modal-button`}>
      {isModal && (
        <div className="modal-cancel">
          <Button onClick={onClose}>Close</Button>
        </div>
      )}
      <Button className={className} type="submit">
        {buttonText}
      </Button>
    </div>
  </Form>
);

AddProperty.propTypes = {
  classes: propTypes.object,
  className: propTypes.string,
  onSubmit: propTypes.func.isRequired,
  onBlur: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  onFocus: propTypes.func.isRequired,
  buttonText: propTypes.string,
  values: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
  onClose: propTypes.func,
  isModal: propTypes.bool,
  houseHoldInfo: propTypes.func,
  addRequired: propTypes.func,
  disabled: propTypes.object.isRequired,
};
export default withStyles(Styles)(AddProperty);
