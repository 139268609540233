import { USRE_INFO } from "./constants";
import { initialState } from "./initialState";

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USRE_INFO:
      return { ...state, ...payload };
    default:
      return state;
  }
};
