import React, { useState, memo, useEffect, useRef } from "react";
import PropType from "prop-types";
import withStyle from "react-jss";
import { Input } from "v2/components";
import { List } from "components";
import { ReactComponent as ArrowDown } from "images/arrowdown.svg";
import { ReactComponent as ArrowUP } from "images/arrowup.svg";
import { useOnClickOutside } from "Hooks/ClickOutside";
import { Styles } from "./Styles";

const CustomSearch = ({
  name,
  onChange,
  selected,
  onBlur,
  onFocus,
  error,
  readOnly = false,
  size,
  options = [],
  classes,
  listClassName = "",
  className = "",
  ...props
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  const wasInsideClick = useOnClickOutside(ref);
  const [inputValue, setInputValue] = useState(selected);
  const [placeHolder, setPlaceHolder] = useState("Please select a option");
  useEffect(() => {
    const selectedValue = options.find((value) => value.key === selected);
    if (selectedValue && selectedValue.value) {
      if (selectedValue.key.trim().length) {
        setInputValue(selectedValue.value);
      } else {
        setPlaceHolder(selectedValue.value);
      }
    }
  }, [options]);
  const handleChange = (id) => {
    setInputValue(options.find((value) => value.key === id).value);
    onBlur({ target: { name, value: id } });
    onChange({ target: { name, value: id } });
    setShow(false);
  };

  function handleOnFocus() {
    setShow(!readOnly);
    onFocus({ target: { name } });
  }

  function handleOnBlur() {
    setShow(wasInsideClick);
  }

  return (
    <div ref={ref} className={classes.main}>
      <div className="input-div-inactive">
        <Input
          {...props}
          value={inputValue}
          placeholder={placeHolder}
          className={`${className} ${readOnly ? "disabled-input" : ""}`}
          onFocus={() => handleOnFocus(setShow, readOnly, onFocus, name)}
          onBlur={handleOnBlur}
          autoComplete="off"
          readOnly
          name={name}
          error={error}
        />
        {!show && (
          <ArrowDown
            className={`arrow ${readOnly ? "disabled" : ""}`}
            onClick={() => setShow(true)}
          />
        )}
        {show && (
          <ArrowUP
            className={`arrow-up ${readOnly ? "disabled" : ""}`}
            onClick={() => setShow(false)}
          />
        )}
        {show && options.length > 0 && (
          <List
            className={`list-menu ${listClassName}`}
            options={options}
            onClick={handleChange}
          />
        )}
      </div>
    </div>
  );
};
CustomSearch.propType = {
  options: PropType.array,
  onChange: PropType.func.isRequired,
  classes: PropType.object.isRequired,
  className: PropType.string,
  listClassName: PropType.string,
  readOnly: PropType.bool,
};

export default withStyle(Styles)(memo(CustomSearch));
