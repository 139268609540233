import { useFormik } from "formik";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import { ReactComponent as SponsorIcon } from "images/sponsor.svg";

import { ReactComponent as QuestionMark } from "images/questionMark.svg";
import propTypes from "prop-types";
import React from "react";
import mixpanel from "utils/mixPanel";

import * as yup from "yup";
import Button from "../Form/Button/Button";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import Typography from "../Typography/Typography";
import styles from "./PickSponsor.module.css";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <SponsorIcon />

      <Typography as="h1">Pick a sponsor</Typography>

      <span className={styles.subtitle}>
        <Typography as="h3">
          Please pick who you want to sponsor that property. <br />
          Our local sponsors may reach out, is this ok with you?
        </Typography>
      </span>
    </div>
  );
};

const NoSponsorLogo = () => {
  return (
    <div className={styles.logo}>
      <SponsorIcon />
      <span className={styles.title}>
        <Typography as="h1">Lets get you a free upgrade!</Typography>
      </span>
      <span className={styles.noSponsorsSubtitle}>
        <Typography as="h3">
          When you suggest a sponsor business we will upgrade you automatically
          for free.
          <QuestionMark id="questionMark" />
        </Typography>
      </span>
    </div>
  );
};

const Sponsor = (props) => {
  return (
    <div
      className={`${styles.sponsor} ${
        props.premiumSponsor && styles.premiumSponsor
      } ${!!props.isSelected && styles.selected}`}
      id={JSON.stringify(props)}
      onClick={props.handleSponsorSelection}
    >
      <img src={props.image} alt={props.name} id={JSON.stringify(props)} />
    </div>
  );
};

const SponsorSuggestionFormValidationSchema = yup.object().shape({
  email: yup.string().email().required("A valid email is required"),
  firstName: yup.string().required("first name is required"),
  lastName: yup.string().required("last name is required"),
  number: yup.string().required("A phone number is required"),
});

const PickSponsor = ({ onConfirm, ...props }) => {
  const [premiumSponsorData, ...otherSponsors] = props.sponsors;
  const [selectedSponsor, setSelectedSponsor] = React.useState();
  React.useEffect(() => {
    if (props.sponsors.length === 1) {
      setSelectedSponsor(props.sponsors[0]);
    }
  }, [props.sponsors]);

  const handleSponsorSelection = React.useCallback((event) => {
    setSelectedSponsor(JSON.parse(event.target.id));
  }, []);
  const onConfirmCallback = React.useCallback(() => {
    onConfirm(selectedSponsor);
    mixpanel.track("Sponsor picked - sponsor details", selectedSponsor);
  }, [selectedSponsor, onConfirm]);
  const onClickCancel = () => {
    props.onCancel();
    mixpanel.track("Sponsor not selected");
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: props.onSubmitSponsorSuggestions,
    validationSchema: SponsorSuggestionFormValidationSchema,
  });
  useWhyDidYouUpdate("PickSponsor", props);
  if (premiumSponsorData) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Logo />
        </div>
        <Sponsor
          id="Stonly_PremiumSponsor"
          {...premiumSponsorData}
          premiumSponsor
          handleSponsorSelection={handleSponsorSelection}
          isSelected={selectedSponsor?.id === props.sponsors[0].id}
        />
        {otherSponsors && (
          <div className={styles.secondaryProviders}>
            {otherSponsors.map((sponsorData) => (
              <Sponsor
                {...sponsorData}
                key={sponsorData.id}
                isSelected={sponsorData.id === selectedSponsor?.id}
                handleSponsorSelection={handleSponsorSelection}
              />
            ))}
          </div>
        )}
        <footer>
          {props.onCancel && (
            <Button
              id="Stonly_DoitLater"
              actionName="I'll do it later"
              variant="secondary"
              onClick={onClickCancel}
            />
          )}
          {!props.onCancel && <div />}

          <div />
          <span id="Stonly_Continue">
            <Button
              actionName="Confirm"
              disabled={!selectedSponsor}
              onClick={onConfirmCallback}
            />
          </span>
        </footer>
        <div className={styles.termsAndConditions}>
          <TermsAndConditions actionName={"Confirm"} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Logo />
        </div>
        <Sponsor
          id="Stonly_PremiumSponsor"
          {...premiumSponsorData}
          premiumSponsor
          handleSponsorSelection={handleSponsorSelection}
        />
        <footer>
          {props.onCancel && (
            <Button
              id="Stonly_DoitLater"
              actionName="I'll do it later"
              variant="secondary"
              onClick={onClickCancel}
            />
          )}
          {!props.onCancel && <div />}

          <div />
          <span id="Stonly_Continue">
            <Button
              actionName="Confirm"
              disabled={!selectedSponsor}
              onClick={onConfirmCallback}
            />
          </span>
        </footer>
        <div className={styles.termsAndConditions}>
          <TermsAndConditions actionName={"Confirm"} />
        </div>
      </div>
    );
  }
  // if (!premiumSponsorData) {
  //   return (
  //     <div className={styles.container}>
  //       <div className={styles.backgroundWave}>
  //         <Wave />
  //       </div>
  //       <NoSponsorLogo />
  //       <Form onSubmit={formik.handleSubmit}>
  //         <div className={styles.formContainer}>
  //           <div className={styles.sponsorForm}>
  //             <Input
  //               type="text"
  //               id="firstName"
  //               name="firstName"
  //               value={formik.values.firstName}
  //               placeholder=" Enter the first name"
  //               label="Enter the first name"
  //               onChange={formik.handleChange}
  //               error={formik.errors.firstName}
  //               noSponsor="noSponsor"
  //             />
  //             <Input
  //               type="text"
  //               id="lastName"
  //               name="lastName"
  //               value={formik.values.lastName}
  //               placeholder=" Enter the last name"
  //               label="Enter the last name"
  //               onChange={formik.handleChange}
  //               error={formik.errors.lastName}
  //               noSponsor="noSponsor"
  //             />
  //             <Input
  //               type="email"
  //               id="email"
  //               name="email"
  //               value={formik.values.email}
  //               placeholder=" Enter the business email"
  //               label="Business email"
  //               onChange={formik.handleChange}
  //               error={formik.errors.email}
  //               noSponsor="noSponsor"
  //             />
  //             <Input
  //               type="text"
  //               id="number"
  //               name="number"
  //               value={formik.values.number}
  //               placeholder=" Enter the contact number"
  //               label="Contact number"
  //               onChange={formik.handleChange}
  //               error={formik.errors.number}
  //               noSponsor="noSponsor"
  //             />
  //           </div>

  //           <div className={styles.noSponsorFooter}>
  //             {props.onCancel && (
  //               <Button
  //                 id="Stonly_DoitLater"
  //                 actionName="I'll do it later"
  //                 variant="greyed"
  //                 onClick={props.onCancel}
  //               />
  //             )}

  //             <Button
  //               actionName="Suggest a local sponsor"
  //               type="submit"
  //               id="Stonly_Continue"
  //             />
  //           </div>
  //         </div>
  //       </Form>
  //       <div className={styles.bottomText}>
  //         <Typography as="pickSponsor">Sponsors can be </Typography>
  //         <Typography as="pickSponsorBold">
  //           {`Mortgage & Insurance Brokers, Conveyancers, Property Managers, Letting & Estate Agents, and Surveyors,`}
  //         </Typography>
  //         <Typography as="pickSponsor">
  //           {` please suggest someone you know, and trust, so everyone can benefit.`}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // }
};

PickSponsor.propTypes = {
  onConfirm: propTypes.func,
  onSubmitSponsorSuggestions: propTypes.func,
  sponsors: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      image: propTypes.string,
    })
  ),
};

PickSponsor.defaultProps = {
  onConfirm: () => {},
  onSubmitSponsorSuggestions: () => {},
  sponsors: [],
};

export default PickSponsor;
