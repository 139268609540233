export const styles = ({ colors, fonts, breakpoints }) => ({
  main: {
    [breakpoints.MOBILE_DEVICE]: {
      paddingTop: 0,
      "& .contents": {
        paddingBottom: "100px",
        width: "100% !important",
        height: "100% !important",
      },
    },
  },
  text: {
    display: "flex",
    justifyContent: "space-between",
    "& h3": {
      fontStyle: "normal",
      letterSpacing: ({ spacing = "0.002em" }) => spacing,
      fontWeight: ({ weight }) => weight || "bold",
      fontFamily: fonts.primary,
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.hamptonsSecondary,
      margin: "24px",
    },
    "& svg": {
      margin: "24px",
      cursor: "pointer",
    },
  },
  inputs: {
    margin: "0 24px",
    position: "relative",
    "& .modal-form": {
      marginTop: 0,
      [breakpoints.IPAD]: {
        width: "100%",
      },
      [breakpoints.MOBILE_DEVICE]: {
        "& .input-div": {
          width: "100%",
          "& select": {
            width: "100%",
          },
          "& input": {
            width: "100%",
          },
        },
      },
    },
    "& .modal-button": {
      display: "flex",
      justifyContent: "space-between",
      [breakpoints.RESPONSIVE]: {
        background: colors.hexaWhite,
        bottom: "0 !important",
      },
      "& button": {
        width: 272,
        height: 48,
      },
      "& .modal-cancel": {
        display: "flex",
        width: 272,
        height: 48,
        alignItems: "center",
        "& button": {
          background: colors.hamptonsSecondary,
        },
        "& span": {
          position: "none",
          bottom: "0",
          width: 240,
          height: 32,
        },
        [breakpoints.MOBILE_DEVICE]: {
          justifyContent: "center",
          width: "100%",
        },
      },
      [breakpoints.MOBILE_DEVICE]: {
        flexDirection: "column-reverse",
        height: "auto",
      },
    },
    "& .modal-input": {
      [breakpoints.MOBILE_DEVICE]: {
        justifyContent: "flex-start",
        "& .input-div": {
          width: "120px !important",
          marginRight: "39px",
        },
      },
      "& .info-second": {
        width: 120,
        height: "auto",
        marginRight: 32,
        [breakpoints.MOBILE_DEVICE]: {
          marginRight: 0,
        },
      },
      "& .info": {
        width: 120,
        height: "auto",
        marginRight: 32,
      },
      "& .room-input": {
        "& .last-input": {
          "& input": {
            marginTop: 24,
          },
        },
        [breakpoints.MOBILE_DEVICE]: {
          width: "100%",
        },
      },

      "& input": {
        width: 120,
        height: 48,
      },
    },
    "& .modal-select": {
      display: "flex",
      justifyContent: "space-between",
      [breakpoints.MOBILE_DEVICE]: {
        justifyContent: "inherit",
      },
      "& .model-icon": {
        paddingBottom: 15,
      },
      "& .long-label": {
        width: "auto",
        maxWidth: "242px",
      },
      "& select": {
        width: 272,
        height: 48,
        paddingRight: "22px",
      },
      "& input": {
        width: 272,
        height: 48,
        paddingRight: "22px",
      },
    },
    "& span": {
      position: "absolute",
      bottom: "-19px",
    },
    "& input": {
      width: "352px",
      height: "48px",
      margin: "0 24px 0 24px",
      boxSizing: "border-box",
      "&:focus": {
        border: `2px solid ${colors.hamptonsFocus}`,
      },
    },
    "& Button": {
      margin: "0",
      width: "100%",
    },
  },
  cancelDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    paddingBottom: "20px",
  },
});
