import {
  addItemFilesURL,
  confirmItemUploadURL,
  deleteItemUploadURL,
  presignedItemURL,
  updateItemOrdered,
  updateItemURL,
} from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { useEffect, useState } from "react";
import { DELETE, Patch, POST, PUT } from "utils";
import mixpanel from "../../utils/mixPanel";
import useUploadFilesToS3 from "./useUploadFilesToS3";

/*
 *TODO REfactor this file to use the global Types for API REsponse, Error and Action 

import { useAsync } from "Hooks/useAsync";
import { GET } from "utils";
type Example = {
  name: string
};

type PackagesActions = {
  getPackages: APIAction<string, Example>;
};

export const useApiExampleActions = (): PackagesActions => {
  const getExample = async (packageName) => {
    return GET(packagesURL(packageName));
  };

  const getExamplePromise = useAsync<
    APIActionResponse<Example>,
    APIActionError
  >(getExample, false);
  return {
    getExamples: {
      trigger: getExamplePromise.execute,
      state: getExamplePromise.status === "pending" ? "loading" : "idle",
      error: getExamplePromise.error?.errorDetails,
      result: getExamplePromise.value?.data || null,
    },
  };
}; */

type ErrorType = {
  errorDetails: {
    code: string;
    details: Array<{
      location: string;
      msg: string;
      param: string;
      value: string;
    }>;
    message: string;
  };
} | null;

type Action = {
  trigger: (data: any) => void;
  result: any;
  error:
    | {
        code: string;
        details: Array<{
          location: string;
          msg: string;
          param: string;
          value: string;
        }>;
        message: string;
      }
    | undefined;
};

type CreateLicenceData = {
  licenceId: string;
  issueDate: string;
  expiryDate: string;
  reference: string;
  files: [File];
};
type CreateFile = {
  itemId: string;
  files: Array<File>;
};
type UpdateLicenceData = {
  expiryDate: string;
  issueDate?: string;
  reference: string;
  documentType: string;
  itemId: string;
  files: Array<File>;
};

type ItemOrderedData = {
  itemId: string;
  value: boolean;
};

type DeleteFileData = {
  itemId: string;
  fileId: string;
};

type usePropertyItemActionsProps = {
  propertyId: string;
};

const buildPresignedURLS = (
  presignedUrls: Array<{
    postData: { url: string; fields: { [key: string]: string } };
  }> = [],
  files: Array<{ name: string }>
) =>
  presignedUrls.map(({ postData }, index) => ({
    documentUrl: `${postData.url}/${postData.fields.key}`,
    name: files[index].name,
  }));

export default function useApiPropertyItemActions(
  props: usePropertyItemActionsProps
): Array<Action> {
  const [onCreateData, setOnCreateData] = useState<CreateLicenceData>();
  const [onUpdateData, setOnUpdateItemData] = useState<UpdateLicenceData>();
  const [onUpdatedItemOrderData, setOnUpdatedItemOrderData] =
    useState<ItemOrderedData>();
  const [onCreateFilesData, setOnCreateFilesData] = useState<CreateFile>();
  const [onDeleteFileData, setOnDeleteFileData] = useState<DeleteFileData>();
  const [onUploadFilesToS3, presignedUrls, uploadToS3Error] =
    useUploadFilesToS3();

  const createRequest = async () => {
    if (onCreateData) {
      const { licenceId: itemId, reference, ...data } = onCreateData;
      mixpanel.track("Upload Document", {
        propertyId: props.propertyId,
        itemId,
        reference,
      });
      return POST(confirmItemUploadURL(props.propertyId, itemId), {
        ...data,
        licenceRefNo: reference,
        uploadFiles: !presignedUrls
          ? []
          : presignedUrls.map(({ postData }, index) => ({
              documentUrl: `${postData.url}/${postData.fields.key}`,
              name: data.files[index].name,
            })),
      });
    }
  };
  const createFilesRequest = async () => {
    if (onCreateFilesData) {
      const { itemId, files } = onCreateFilesData;
      const newFiles = await Patch(addItemFilesURL(props.propertyId, itemId), {
        uploadFiles: buildPresignedURLS(presignedUrls, files),
      });
      return {
        itemId,
        files: newFiles.data,
      };
    }
  };

  const updateItemRequest = async () => {
    if (onUpdateData) {
      const { expiryDate, issueDate, reference, itemId, documentType } =
        onUpdateData;
      setOnCreateFilesData({
        itemId,
        files: onUpdateData.files
          ? (onUpdateData.files.filter(
              (file) => file instanceof File
            ) as Array<File>)
          : [],
      });
      return PUT(updateItemURL(props.propertyId, itemId), {
        licenceRefNo: reference,
        issueDate,
        expiryDate,
        documentType,
      });
    }
  };

  const updateOrderedItemRequest = async () => {
    if (onUpdatedItemOrderData) {
      const { itemId } = onUpdatedItemOrderData;
      const resultPromise = Patch(updateItemOrdered(props.propertyId, itemId), {
        ordered: onUpdatedItemOrderData.value,
      });
      mixpanel.track("Add to order", {
        propertyId: props.propertyId,
        itemId,
        value: onUpdatedItemOrderData.value,
      });
      return await resultPromise;
    }
  };

  const deleteFileRequest = async () => {
    if (onDeleteFileData) {
      const { itemId, fileId } = onDeleteFileData;
      return DELETE(deleteItemUploadURL(props.propertyId, itemId, fileId));
    }
  };

  const createPromise = useAsync<any, ErrorType>(createRequest, false);
  const createFilesPromise = useAsync<any, ErrorType>(
    createFilesRequest,
    false
  );
  const deleteFilePromise = useAsync<any, ErrorType>(deleteFileRequest, false);
  const updateItemPromise = useAsync<any, ErrorType>(updateItemRequest, false);
  const updateOrderedItemPromise = useAsync<any, ErrorType>(
    updateOrderedItemRequest,
    false
  );

  useEffect(() => {
    if (onCreateData) {
      if (onCreateData?.files.length) {
        onUploadFilesToS3({
          ...onCreateData,
          presignedURL: presignedItemURL(
            props.propertyId,
            onCreateData.licenceId
          ),
        });
      } else {
        createPromise.execute();
        setOnCreateData(undefined);
      }
    }
  }, [onCreateData]);

  useEffect(() => {
    if (onCreateFilesData && onCreateFilesData.files.length) {
      onUploadFilesToS3({
        ...onCreateFilesData,
        presignedURL: presignedItemURL(
          props.propertyId,
          onCreateFilesData.itemId
        ),
      });
    }
  }, [onCreateFilesData]);

  useEffect(() => {
    if (presignedUrls && !uploadToS3Error) {
      if (onCreateData?.files.length) {
        createPromise.execute();
      }
      if (onCreateFilesData) {
        createFilesPromise.execute();
      }
      setOnCreateData(undefined);
      setOnCreateFilesData(undefined);
    }
  }, [presignedUrls]);

  useEffect(() => {
    if (onDeleteFileData) {
      deleteFilePromise.execute();
    }
  }, [onDeleteFileData]);

  useEffect(() => {
    if (onUpdateData) {
      updateItemPromise.execute();
    }
  }, [onUpdateData]);

  useEffect(() => {
    if (onUpdatedItemOrderData) {
      updateOrderedItemPromise.execute();
    }
  }, [onUpdatedItemOrderData]);

  return [
    {
      trigger: setOnCreateData,
      error: createPromise.error?.errorDetails,
      result: createPromise.value?.data,
    },
    {
      trigger: setOnCreateFilesData,
      error: createFilesPromise.error?.errorDetails,
      result: createFilesPromise.value,
    },
    {
      trigger: setOnUpdateItemData,
      error: updateItemPromise.error?.errorDetails,
      result: { ...updateItemPromise.value?.data, onUpdateData },
    },
    {
      trigger: setOnUpdatedItemOrderData,
      error: updateOrderedItemPromise.error?.errorDetails,
      result:
        updateOrderedItemPromise.status === "success" && onUpdatedItemOrderData,
    },
    {
      trigger: setOnDeleteFileData,
      error: deleteFilePromise.error?.errorDetails,
      result: deleteFilePromise.status === "success" && onDeleteFileData,
    },
  ];
}
