import React from "react";
import propTypes from "prop-types";
import styles from "./Checkbox.module.css";
import Label from "../Label";

const CheckBox = (props) => {
  const [isChecked, setIsChecked] = React.useState(!!props.defaultChecked);

  const onChange = () => {
    setIsChecked(!isChecked);
    props.onChange(!isChecked);
  };

  return (
    <Label
      label={props.label}
      onInputTip={props.onInputTip}
      error={props.error}
    >
      <div className={styles.container}>
        <input
          disabled={props.disabled}
          type="checkbox"
          name={props.name}
          className={props.error ? styles.error : ""}
          value={props.value}
          defaultChecked={isChecked ? "checked" : ""}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={onChange}
        />
        <span className={styles.checkmark} />
      </div>
    </Label>
  );
};

CheckBox.propTypes = {
  name: propTypes.string,
  classes: propTypes.object,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  className: propTypes.string,
  disabled: propTypes.bool,
  defaultChecked: propTypes.string,
  label: propTypes.string,
};

CheckBox.defaultProps = {
  name: "name",
  classes: {},
  onChange: () => null,
  value: "",
  onBlur: () => null,
  onFocus: () => null,
  className: "",
  disabled: false,
  label: "",
};

export default CheckBox;
