import Label from "../Label";
import styles from "./RadioGroup.module.css";
import Radio from "../Radio";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";

const RadioGroup = (props) => {
  useWhyDidYouUpdate(`Radio Group - ${props.label}`, props);
  return (
    <Label
      label={props.label}
      onInputTip={props.onInputTip}
      shouldPreventDefault={true}
    >
      <span className={styles.container}>
        {props.options.map((option) => (
          <Radio
            key={option}
            option={option}
            checked={option === props.selected}
            readOnly={props.readOnly}
            onChooseOption={props.onChooseOption}
          />
        ))}
      </span>
    </Label>
  );
};

export default RadioGroup;
